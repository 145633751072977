import { latest as latestTypes, v0_9_0, v1_0_0 } from '@libs/waymark-video/video-descriptor-types';

export type LatestVideoDescriptor = latestTypes.VideoDescriptor;

/** Helpful any+latest exports */
type VideoDescriptor_v0_9_0 = v0_9_0.VideoDescriptor;
type VideoDescriptor_v1_0_0 = v1_0_0.VideoDescriptor;
type VideoDescriptor_v1_0_1 = v1_0_0.VideoDescriptor; // The type does not change for this patch.
export type AnyVideoDescriptor =
  | VideoDescriptor_v0_9_0
  | VideoDescriptor_v1_0_0
  | VideoDescriptor_v1_0_1;

export { VideoDescriptor_v0_9_0, VideoDescriptor_v1_0_0, VideoDescriptor_v1_0_1 };

export const LATEST_VIDEO_DESCRIPTOR_VERSION: LatestVideoDescriptor['version'] = '1.0.1';

export interface Migration<
  TInputVideoDescriptor extends AnyVideoDescriptor,
  // The output can't be the same as the input version, especially not for the initial v0.9.0 VD.
  TOutputVideoDescriptor extends AnyVideoDescriptor,
> {
  /**
   * The input version for the data we are migrating.
   */
  inputVersion: TInputVideoDescriptor extends VideoDescriptor_v0_9_0
    ? // The initial v0.9.0 VD does not have a version field, but all future versions will.
      '0.9.0'
    : TInputVideoDescriptor['version'];
  /** The version we are migrating to. */
  targetVersion: TOutputVideoDescriptor extends VideoDescriptor_v0_9_0
    ? '0.9.0'
    : TOutputVideoDescriptor['version'];
  /** A description of the migration */
  description: string;
  /** Name of migration (identical to file name by convention, second-order sort) */
  name: string;
  implementation: (inputVideoDescriptor: TInputVideoDescriptor) => Promise<TOutputVideoDescriptor>;
}
