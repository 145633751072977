// import coconut from "coconutjs";

import WebSocket from 'isomorphic-ws';

import { getUrl } from './sourceVideos';
import { getCocountFormatEntry } from './processingDefinitions';
import { bulkProcessingJobs, VPSBulkJobKey } from './bulkProcessingJobs';
import { VPSEnvironmentKey } from './environments';

const generateJobId = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
};

export interface VPSWebsocketMessageData {
  id: string;
}

export default async function beginProcessing(
  sourceVideoKey: string,
  bulkProcessingJobKey: VPSBulkJobKey,
  environmentKey: VPSEnvironmentKey,
  websocketEndpoint: string,
) {
  const sourceUrl = getUrl(sourceVideoKey, environmentKey);
  const processingDefinitionKeys = bulkProcessingJobs[bulkProcessingJobKey].processingDefinitions;

  const jobInstructions = {
    source: sourceUrl,
    outputs: Object.fromEntries(
      processingDefinitionKeys.map((processingDefinitionKey) =>
        getCocountFormatEntry(sourceVideoKey, processingDefinitionKey, environmentKey, {
          accessKeyId: '{{COCONUT_AWS_ACCESS_KEY_ID}}',
          secretAccessKey: '{{COCONUT_AWS_SECRET_ACCESS_KEY}}',
        }),
      ),
    ),
  };

  const connection = new WebSocket(websocketEndpoint);

  await new Promise((resolve, reject) => {
    connection.onopen = resolve;
    connection.onerror = reject;
  });

  const jobId = generateJobId();

  const message = {
    action: 'sendmessage',
    data: {
      id: jobId,
      source: jobInstructions.source,
      outputs: jobInstructions.outputs,
    },
  };
  connection.send(JSON.stringify(message));

  return new Promise<VPSWebsocketMessageData>((resolve) => {
    const onReceiveMessage = (event: WebSocket.MessageEvent) => {
      if (typeof event.data !== 'string') {
        throw new Error('VPS SDK error: received unexpected non-string message from websocket');
      }
      const messageData: VPSWebsocketMessageData = JSON.parse(event.data);
      if (messageData.id === jobId) {
        resolve(messageData);
        connection.removeEventListener('message', onReceiveMessage);
        return;
      }
    };
    connection.addEventListener('message', onReceiveMessage);
  });
}
