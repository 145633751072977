import { BooleanInt } from './helpers';
import { Shape } from './shapes';
import {
  MultiDimensionalValue,
  MultiDimensionalValueKeyframed,
  Value,
  ValueKeyframed,
} from './values';

export interface TextDocument {
  /** Text Document Data Keyframes */
  k: TextDocumentKeyframe[];
}

export interface TextDocumentKeyframe {
  /** Keyframe Time */
  t: number;
  /** Text Properties */
  s: TextProperties;
}

export interface TextMoreOptions {
  /** Text Anchor Point Grouping (AE Property "Anchor Point Grouping")*/
  g: AnchorPointGrouping;
  /** Text Grouping Alignment (AE Property "Grouping Alignment") */
  a: MultiDimensionalValue | MultiDimensionalValueKeyframed;
}

export enum TextJustification {
  Left = 0,
  Right = 1,
  Center = 2,
  FullJustifyLastLineLeft = 3,
  FullJustifyLastLineRight = 4,
  FullJustifyLastLineCenter = 5,
  FullJustifyLastLineFull = 6,
}

/** [width, height] */
export type BoxTextSize = [number, number];

/** [x, y] */
export type BoxTextPosition = [number, number];

/**
 * 0 = NONE
 * 1 = UPPERCASE
 * 2 = SMALLCAPS
 */
export type TextCapitalization = 0 | 1 | 2;

export interface TextProperties {
  /** Text Font */
  f: string;
  /** Text Fill Color (0-1) */
  fc?: number[];
  /** Text Stroke Color (0-1) */
  sc?: number[];
  /** Text Stroke Width */
  sw?: number;
  /**
   * "Stroke over fill"?
   * Indicates the rendering order for the fill and stroke of a Text layer. When true, the stroke appears over the fill.
   */
  of?: boolean;
  /** Text Justification */
  j: TextJustification;
  /** Text Font Size */
  s: number;
  /** Text String Value */
  t: string;
  /** Text Capitalization */
  ca?: TextCapitalization;
  /** Text Tracking */
  tr: number;
  /** Text leading or line height */
  lh: number;
  /** Text baselineShift or 0 */
  bs?: number;
  /** (If box text) Box text size (textDocument.boxTextSize) */
  sz?: BoxTextSize;
  /** (If box text) Box text position (textDocument.boxTextPos) */
  ps?: BoxTextPosition;
  /** Text baseline shift (textDocument.baselineShift) */
  ls: number;
}

/**
 * 1: percentage,
 * 2: index,
 */
export enum RangeUnit {
  Percentage = 1,
  Index = 2,
}

export enum TextBased {
  Characters = 1,
  CharactersExcludingSpaces = 2,
  Word = 3,
  Line = 4,
}
export enum AnchorPointGrouping {
  Characters = 1,
  Word = 2,
  Line = 3,
  All = 4,
}

export enum RangeShape {
  Square = 1,
  RampUp = 2,
  RampDown = 3,
  Triangle = 4,
  Round = 5,
  Smooth = 6,
}

export interface BaseTextAnimatorRangeSelector {
  /** Selector Type. Expressible, or Normal. */
  t: 0;
  /** Selector Max Amount */
  a: { k: 100 } | Value | ValueKeyframed;
  /** Levels Min Ease (AE Property: "ADBE Text Levels Min Ease") */
  ne: { k: 0 } | Value | ValueKeyframed;
  /** Levels Max Ease (AE Property: "ADBE Text Levels Max Ease") */
  xe: { k: 0 } | Value | ValueKeyframed;
  /**
   * Selector Based On: Characters, Characters Exluding Spaces, Lines, Words (AE Property: "ADBE Text Range Type2")
   * */
  b: TextBased;
  /** Selector Randomize Order (AE Property: "ADBE Text Randomize Order") */
  rn: BooleanInt;
  /**
   * Selector Shape (AE Property: "ADBE Text Range Shape")
   * */
  sh: RangeShape;
  r: RangeUnit;
}

export interface TextAnimatorPercentRangeSelector extends BaseTextAnimatorRangeSelector {
  r: RangeUnit.Percentage;
  /** "ADBE Text Percent Start" should be treated as 0% if it doesn't exist */
  s?: Value | ValueKeyframed;
  /** "ADBE Text Percent End" should be treated as 100% if it doesn't exist */
  e?: Value | ValueKeyframed;
  /** "ADBE Text Percent Offset" should be treated as 0% if it doesn't exist */
  o?: Value | ValueKeyframed;
}

export interface TextAnimatorIndexRangeSelector extends BaseTextAnimatorRangeSelector {
  r: RangeUnit.Index;
  /** "ADBE Text Index Start" should be treated as 0 if it doesn't exist */
  s?: Value | ValueKeyframed;
  /** "ADBE Text Index End" */
  e: Value | ValueKeyframed;
  /** "ADBE Text Index Offset" should be treated as 0 if it doesn't exist */
  o?: Value | ValueKeyframed;
}

export interface TextAnimatorExpressionSelector {
  /**
   * Selector Based On: Characters, Characters Exluding Spaces, Lines, Words (AE Property: "ADBE Text Range Type2")
   * */
  b: TextBased;
  t: 1;
  /**
   * Expression string
   */
  x: string;
  /**
   * Generated expression functions
   */
  xf?: string[];
}

export interface TextAnimatorDefaultHundredPercentRangeSelector {
  /** Selector Type. Expressible, or Normal. */
  t: 0;
  /** Levels Max Ease (AE Property: "ADBE Text Levels Max Ease") */
  xe: { k: 0 };
  /** Levels Min Ease (AE Property: "ADBE Text Levels Min Ease") */
  ne: { k: 0 };
  /** Selector Max Amount */
  a: { k: 100 };
  /**
   * Selector Based On: Characters, Characters Exluding Spaces, Lines, Words (AE Property: "ADBE Text Range Type2")
   *
   * This is set to 0 based on a bodymovin bug, but should be treated as TextBased.Characters, which is 1:
   * https://github.com/stikdev/bodymovin-extension-private/blob/5109465daf3d5fc902d66267b4159f9676f1029d/bundle/jsx/utils/textAnimatorHelper.jsx#L86
   * */
  b: 0;
  /**
   * Selector Shape (AE Property: "ADBE Text Range Shape")
   * This is set to 0 based on a bodymovin bug, but should be treated as RangeShape.Square, which is 1:
   * https://github.com/stikdev/bodymovin-extension-private/blob/5109465daf3d5fc902d66267b4159f9676f1029d/bundle/jsx/utils/textAnimatorHelper.jsx#L86
   * */
  sh: 0;
  /** "ADBE Text Percent Start" should be treated as 0% if it doesn't exist */
  s: { k: 0 };
  /** "ADBE Text Percent End" should be treated as 100% if it doesn't exist */
  e: { k: 100 };
  /** "ADBE Text Percent Offset" should be treated as 0% if it doesn't exist */
  o: { k: 0 };
  /** NOTE: Bodymovin is not setting "r" here, but the range should be treated as a percent */
}

export type TextRangeSelector =
  | TextAnimatorPercentRangeSelector
  | TextAnimatorIndexRangeSelector
  | TextAnimatorExpressionSelector
  | TextAnimatorDefaultHundredPercentRangeSelector;

export interface CharacterData {
  /** Character Composing Shapes */
  shapes: CharacterShape[];
}

export interface CharacterShape {
  /** Character Items */
  items: CharacterItems;
}

export interface CharacterItems {
  /** Character Items Keys */
  keys: Shape;
}

export interface TextPathData {
  /** (AE Property "Path") */
  m: number;
  /** (AE Property "First Margin") */
  f: ValueKeyframed;
  /** (AE Property "Last Margin") */
  l: ValueKeyframed;
  /** (AE Property "Force Alignment") */
  a: ValueKeyframed;
  /** (AE Property "Perpendicular To Path") */
  p: ValueKeyframed;
  /** (AE Property "Reverse Path") */
  r: ValueKeyframed;
}
export interface TextData {
  /** Text More Options */
  m: TextMoreOptions;
  /** Text Document Data */
  d: TextDocument;
  /** Text Path ("")*/
  p: TextPathData | Record<string, never>;
  /** Text animators */
  a: TextAnimator[];
}

export interface TextAnimator {
  /** Name of the text animator */
  nm: string;
  /** Text animator animated properties */
  a: TextAnimatedProperties;
  /** Animators Text Selectors */
  s: TextRangeSelector;
}

export interface TextAnimatedProperties {
  /** Text animator Position ('ADBE Text Position 3D') */
  p?: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Text animator Anchor Point ('ADBE Text Anchor Point 3D') */
  a?: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Text animator Scale ('ADBE Text Scale 3D') */
  s?: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Text animator Skew ('ADBE Text Skew') */
  sk?: Value | ValueKeyframed;
  /** Text animator Skew Axis ('ADBE Text Skew Axis') */
  sa?: Value | ValueKeyframed;
  /** Text animator Rotation ('ADBE Text Rotation') */
  r?: Value | ValueKeyframed;
  /** Text animator Rotation X ('ADBE Text Rotation X') */
  rx?: Value | ValueKeyframed;
  /** Text animator Rotation Y ('ADBE Text Rotation Y') */
  ry?: Value | ValueKeyframed;
  /** Text animator Opacity ('ADBE Text Opacity') */
  o?: Value | ValueKeyframed;
  /** Text animator Stroke Width ('ADBE Text Stroke Width') */
  sw?: Value | ValueKeyframed;
  /** Text animator Stroke Color ('ADBE Text Stroke Color') */
  sc?: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Text animator Stroke Hue ('ADBE Text Stroke Hue') */
  sh?: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Text animator Stroke Saturation ('ADBE Text Stroke Saturation') */
  ss?: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Text animator Stroke Brightness ('ADBE Text Stroke Brightness') */
  sb?: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Text animator Stroke Opacity ('ADBE Text Stroke Opacity') */
  so?: Value | ValueKeyframed;
  /** Text animator Fill Color ('ADBE Text Fill Color') */
  fc?: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Text animator Fill Hue ('ADBE Text Fill Hue') */
  fh?: Value | ValueKeyframed;
  /** Text animator Fill Saturation ('ADBE Text Fill Saturation') */
  fs?: Value | ValueKeyframed;
  /** Text animator Fill Brightness ('ADBE Text Fill Brightness') */
  fb?: Value | ValueKeyframed;
  /** Text animator Fill Opacity ('ADBE Text Fill Opacity') */
  fo?: Value | ValueKeyframed;
  /** Text animator Tracking ('ADBE Text Tracking Amount') */
  t?: Value | ValueKeyframed;
  /**
   * Text animator Text Blur ('ADBE Text Blur')
   *
   * NOTE: We'll have to update our fork of bodymovin to export this
   * */
  bl?: Value | ValueKeyframed;
  /**
   * Text animator Text Blur ('ADBE Text Line Spacing')
   *
   * NOTE: We'll have to update our fork of bodymovin to export this
   * */
  ls?: Value | ValueKeyframed;
}
