export const clamp = (value: number, min: number, max: number): number =>
  Math.min(Math.max(value, min), max);

export const toPrecision = (value: number, precision: number): number => {
  const multiplier = Math.pow(10, precision);
  return (
    Math.round(
      // Adding this small epsilon value helps reduce floating point errors.
      // Probably not necessary for most use cases, but we'll try to be as correct as possible.
      (value + Number.EPSILON) * multiplier,
    ) / multiplier
  );
};
