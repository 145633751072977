/**
 * Similar to Node's path.join, but browser-compatible.
 *
 * Usage:
 * pathJoin('foo/', '/bar/bat/', 'baz.png') => 'foo/bar/bat/baz.png'
 */
export function pathJoin(...parts: string[]) {
  const separator = '/';
  parts = parts.map((part, index) => {
    if (index) {
      part = part.replace(new RegExp('^' + separator), '');
    }
    if (index !== parts.length - 1) {
      part = part.replace(new RegExp(separator + '$'), '');
    }
    return part;
  });
  return parts.join(separator);
}
