export const dropShadows = {
  small: '0px 4px 4px rgba(0, 0, 0, 0.04), 0px 12px 24px rgba(0, 0, 0, 0.05)',
  medium: '0px 2px 2px rgba(0, 0, 0, 0.02), 0px 6px 12px rgba(0, 0, 0, 0.05)',
  large: '0 4px 8px rgba(0, 0, 0, 0.1)',
  // Same size as medium shadow but with stronger color to help differentiate
  // elements against the same background color
  mediumHeavy: '0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 12px rgba(0, 0, 0, 0.14)',
};

/**
 * Duplicates of the box-shadow values defined above in dropShadows, but in the format of a drop-shadow() CSS filter.
 */
export const dropShadowFilters = {
  small: `drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.05))`,
  medium: `drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.02)) drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.05))`,
  large: `drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))`,
  mediumHeavy: `drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.14))`,
};
