import BezierEasing from 'bezier-easing';

/**
 * Class for an ease, which specifys the rate of change of a tween over time.
 * Default is a linear ease
 *
 * @class      Ease (name)
 */
export default class Ease {
  calcBezier: BezierEasing.EasingFunction;

  /**
   * @param {Number}  x1  The x point of the "in" handle
   * @param {Number}  y1  The y point of the "in" handle
   * @param {Number}  y2  The x point of the "out" handle
   * @param {Number}  y2  The y point of the "out" handle
   */
  constructor(x1 = 0, y1 = 0, x2 = 1, y2 = 1) {
    // After Effects will sometimes export bezier X points outside of the 0-1
    // range, but appears to treat them as if they were within the range.
    this.calcBezier = BezierEasing(
      Math.min(Math.max(x1, 0), 1),
      y1,
      Math.min(Math.max(x2, 0), 1),
      y2,
    );
  }

  /**
   * Gets the ratio of completeness of the tween at a given percent of time through the tween
   *
   * @param      {Number}  progress  The progress
   * @return     {Number}  The ratio.
   */
  getRatio(progress: number) {
    return this.calcBezier(progress);
  }
}

// Helpful ease presets. These map to the CSS easing function presets (https://developer.mozilla.org/en-US/docs/Web/CSS/easing-function)
export const easePresets = {
  ease: new Ease(0.25, 0.1, 0.25, 1),
  easeIn: new Ease(0.42, 0, 1, 1),
  easeOut: new Ease(0, 0, 0.58, 1),
  easeInOut: new Ease(0.42, 0, 0.58, 1),
  linear: new Ease(0, 0, 1, 1),
};

export const LinearEase = easePresets.linear;
