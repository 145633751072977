if (typeof process !== 'undefined' && process.versions?.node) {
  // We're running in a Node.js environment, so we'll suppress Node's extremely loud and useless EventTarget max event listener warnings (thanks Node, very helpful)

  // This is a dumb clown way of solving a dumb clown problem. The only way to suppress Node's max listeners warning is by importing the "node:events" module
  // and setting the max listeners to Infinity. Of course, Webpack tries to resolve that import and fails, so my lazy solution is to wrap that import
  // in a string and use `eval` to evaluate and run the code at runtime. This way it will be left alone by Webpack and still be able to run in Node.
  eval(
    /* js */ `const {EventEmitter} = require("node:events"); EventEmitter.setMaxListeners(Infinity);`,
  );
}

/**
 * CustomEventTarget is a class which wraps the EventTarget class and provides a type-safe way to dispatch and listen for custom events.
 *
 * If you have a class which you would like to emit custom events, you can extend CustomEventTarget and define a map of valid event names
 * and their associated event detail types. If an event does not have any associated detail value, you can use `void` as the type.
 *
 * @example
 * ```ts
 * class MyClass extends CustomEventTarget<{
 *  myEvent: string;
 *  eventWithNoValue: void;
 * }> {}
 *
 * const myClass = new MyClass();
 * myClass.addEventListener('myEvent', (event) => { typeof event.detail === "string" });
 *
 * myClass.dispatchEvent('myEvent', 'Hello, world!');
 * myClass.dispatchEvent('eventWithNoValue');
 * ```
 */
export class CustomEventTarget<TEventMap extends Record<string, unknown>> {
  eventTarget: EventTarget;

  constructor() {
    this.eventTarget = new EventTarget();
  }

  addEventListener<
    // TypeScript infers number | symbol along with string for keyof even if it should know better, so we need to filter out those types with Extract
    TEventType extends Extract<keyof TEventMap, string>,
  >(
    type: TEventType,
    listener: (event: CustomEvent<TEventMap[TEventType]>) => void,
    options?: boolean | AddEventListenerOptions,
  ) {
    return this.eventTarget.addEventListener(type, listener as any, options);
  }

  removeEventListener<
    // TypeScript infers number | symbol along with string for keyof even if it should know better, so we need to filter out those types with Extract
    TEventType extends Extract<keyof TEventMap, string>,
  >(
    type: TEventType,
    listener: (event: CustomEvent<TEventMap[TEventType]>) => void,
    options?: boolean | EventListenerOptions,
  ) {
    return this.eventTarget.removeEventListener(type, listener as any, options);
  }

  /**
   * @param {TEventType} eventName
   * @param {TEventDetailValue} eventDetailValue - Value for the custom event; if the event has a void value, this is optional
   */
  dispatchEvent<
    // Extract all keys from TEventMap where the value is void.
    // We'll overload to make the 2nd eventDetailValue arg optional if the event has a void detail value.
    TEventType extends {
      [K in keyof TEventMap]: TEventMap[K] extends void ? K : never;
    }[keyof TEventMap],
  >(eventName: TEventType, eventDetailValue?: undefined): boolean;
  // Overload requires 2nd eventDetailValue arg if the event has a non-void detail value
  dispatchEvent<
    TEventType extends keyof TEventMap,
    TEventDetailValue extends TEventMap[TEventType],
  >(eventName: TEventType, eventDetailValue: TEventDetailValue): boolean;
  dispatchEvent<
    // TypeScript infers number | symbol along with string for keyof even if it should know better, so we need to filter out those types with Extract
    TEventType extends Extract<keyof TEventMap, string>,
    TEventDetailValue extends TEventMap[TEventType],
  >(eventName: TEventType, eventDetailValue?: TEventDetailValue): boolean {
    return this.eventTarget.dispatchEvent(new CustomEvent(eventName, { detail: eventDetailValue }));
  }
}
