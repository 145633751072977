import type { GlobalSettings } from '../globalSettings';

export enum RendererAssetQuality {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum RendererEffectQuality {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum RendererTimeSyncVideoSeekMode {
  Accurate = 'accurate',
  Fast = 'fast',
}

export enum RendererEffects {
  MotionBlur = 'motionBlur',
  DropShadow = 'dropShadow',
}

export type RendererSettings = {
  'renderingEngine.areAssetDataWarningsEnabled': boolean;
  'renderingEngine.areLocalAssetsEnabled': boolean;
  'renderingEngine.assetQuality': `${RendererAssetQuality}`;
  'renderingEngine.effectQuality': `${RendererEffectQuality}`;
  'renderingEngine.disabledEffects': `${RendererEffects}`[];
  'renderingEngine.timeSyncVideoSeekMode': `${RendererTimeSyncVideoSeekMode}`;
};

export const applyDefaultRenderingEngineSettings = (settings: GlobalSettings<RendererSettings>) =>
  settings
    .set('renderingEngine.areAssetDataWarningsEnabled', true)
    .set('renderingEngine.areLocalAssetsEnabled', false)
    .set('renderingEngine.assetQuality', RendererAssetQuality.High)
    .set('renderingEngine.effectQuality', RendererEffectQuality.High)
    .set('renderingEngine.disabledEffects', [])
    .set('renderingEngine.timeSyncVideoSeekMode', RendererTimeSyncVideoSeekMode.Fast);
