import _ from 'lodash';

/**
 * Returns a new method that calls the provided selector on the relevant
 * subset of the `state`, based on the dotstring path to the local state.
 * @param  {string} path Dotstring ('account.subscriptions') path to the local slice within
 *        the global state object.
 */
const fromRoot =
  (path) =>
  (selector) =>
  (state, ...args) =>
    selector(_.get(state, path), ...args);

/**
 * For the provided selector and a dot-string path to the relevant local state
 * slice, raturns a new selector function that operates on the global state.
 * @template {(...args: any[]) => T} TSelectorFunction
 * @param  {TSelectorFunction} selector Selector function
 * @param  {string} path Dot string path to the relevant local state slice.
 * @returns {TSelectorFunction} A new selector function that operates on the global state.
 */
export const globalizeSelector = (selector, path) => fromRoot(path)(selector);
