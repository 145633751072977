import { SwitchOption } from '../switches';
import { FootageLayer, ImageLayer, TextLayer } from '../layers';
import { ImageReference, TextReference } from '../references';

/**
 * Util gets an array of editable "form field content" for a given switch option.
 * This maps to what fields should be displayed in the editor form for a given switch,
 * but is also meaningful in the autofill service.
 */
export const getEditableFormFieldLayersForSwitchOption = (switchOption: SwitchOption) => {
  const allSelectedLayers = switchOption.layers;

  const references = new Set<TextReference | ImageReference>();

  const layersAndReferences = new Array<
    TextLayer | ImageLayer | FootageLayer | TextReference | ImageReference
  >();

  for (const layer of allSelectedLayers) {
    if (!layer.getCanEditFieldType('content')) {
      continue;
    }

    if (layer.textContentReference || layer.imageContentReference) {
      if (layer.textContentReference && !references.has(layer.textContentReference)) {
        references.add(layer.textContentReference);
        layersAndReferences.push(layer.textContentReference);
      }

      if (layer.imageContentReference && !references.has(layer.imageContentReference)) {
        references.add(layer.imageContentReference);
        layersAndReferences.push(layer.imageContentReference);
      }

      continue;
    }

    if (
      layer instanceof TextLayer ||
      layer instanceof ImageLayer ||
      layer instanceof FootageLayer
    ) {
      layersAndReferences.push(layer);
    }
  }

  return layersAndReferences;
};
