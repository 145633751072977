import { chunk, flatten, remove, get, omit, pick } from 'lodash';
import { v0_9_0, latest } from '@libs/waymark-video/video-descriptor-types';
import { uuid } from '../utils';
import Timeline from './Timeline';
import Ease, { LinearEase } from './Ease';
import { AudioLayer, FootageLayer, ImageLayer, TextLayer, VideoDescriptor } from '..';

const isOneDBezierArray = (bezier: v0_9_0.BezierCurve): bezier is v0_9_0.OneDArrayBezierCurve =>
  Array.isArray(bezier.x) && Array.isArray(bezier.y) && bezier.x.length === 1;
const isTwoDBezier = (bezier: v0_9_0.BezierCurve): bezier is v0_9_0.TwoDBezierCurve =>
  Array.isArray(bezier.x) && Array.isArray(bezier.y) && bezier.x.length === 2;
const isThreeDBezier = (bezier: v0_9_0.BezierCurve): bezier is v0_9_0.ThreeDBezierCurve =>
  Array.isArray(bezier.x) && Array.isArray(bezier.y) && bezier.x.length === 3;

/**
 * Gets the control points that describe a bezier ease points from a bodymovin keyframe.
 *
 * @param      {v0_9_0.ValueKeyframe}  keyframe           The keyframe object from bodymovin
 * @param      {number}  [propertyIndex=0]  The property index (because bodymovin tweens can store multiple properties)
 * @return     {Array}   The bezier ease points from bodymovin.
 */
export function getBezierEasePointsFromBodymovin(
  keyframe: v0_9_0.ValueKeyframe,
  propertyIndex: number = 0,
): [number, number, number, number] {
  if (keyframe.o === undefined || keyframe.i === undefined) {
    throw new Error('You cannot get ease points if there is no bezier curve defined.');
  }

  const easeBezierPoints: [number, number, number, number] = [0, 0, 0, 0];

  if (isOneDBezierArray(keyframe.o) || isTwoDBezier(keyframe.o) || isThreeDBezier(keyframe.o)) {
    // If it's exporting an array of the same value for ease curves, take the first value
    easeBezierPoints[0] =
      keyframe.o.x.length - 1 < propertyIndex ? keyframe.o.x[0] : keyframe.o.x[propertyIndex];
    easeBezierPoints[1] =
      keyframe.o.y.length - 1 < propertyIndex ? keyframe.o.y[0] : keyframe.o.y[propertyIndex];
  } else {
    easeBezierPoints[0] = keyframe.o.x;
    easeBezierPoints[1] = keyframe.o.y;
  }

  if (isOneDBezierArray(keyframe.i) || isTwoDBezier(keyframe.i) || isThreeDBezier(keyframe.i)) {
    // If it's exporting an array of the same value for ease curves, take the first value
    easeBezierPoints[2] =
      keyframe.i.x.length - 1 < propertyIndex ? keyframe.i.x[0] : keyframe.i.x[propertyIndex];
    easeBezierPoints[3] =
      keyframe.i.y.length - 1 < propertyIndex ? keyframe.i.y[0] : keyframe.i.y[propertyIndex];
  } else {
    easeBezierPoints[2] = keyframe.i.x;
    easeBezierPoints[3] = keyframe.i.y;
  }

  return easeBezierPoints;
}

/**
 * Removes tweens for property at a given time
 *
 * @param      {object}  layerData     The layer data
 * @param      {string}  propertyPath  The bodymovin property path ex: 'p' or 'p.x'
 * @param      {number}  tweenTime     The tween time
 * @memberof LayerDataParsing
 * @public
 */
export function removeTweenForPropertyAtTime(
  layerData: v0_9_0.WaymarkVideoLayer | v0_9_0.WaymarkAudioLayer,
  propertyPath: string,
  tweenTime: number,
) {
  const existingProperty = get(layerData, propertyPath);

  // if the existing property tween is not animated, we don't have to do anything
  if (!existingProperty || !existingProperty.a) {
    return;
  }
  const removedTweens = remove<any>(existingProperty.k, ({ t }) => t === tweenTime);

  // if we now only have one value for the property convert it to a non-animated property
  if (existingProperty.k.length === 1) {
    existingProperty.k = existingProperty.k[0].s;
    existingProperty.a = 0;
    // If we removed all the tweens, use the last one as the non-animated property
  } else if (existingProperty.k.length === 0) {
    existingProperty.k = removedTweens[removedTweens.length - 1].s;
    existingProperty.a = 0;
  }
}

const isLastTimeKeyframe = (
  keyframe: v0_9_0.ValueKeyframed['k'][number],
): keyframe is v0_9_0.LastTimeKeyframe => !('s' in keyframe);
/**
 * Apply create and apply tweens from bodymovin to a Waymark timeline.
 *
 * @param      {string[]}    propertyNames                     The property names
 * @param      {object}    keyframes                           The keyframes object from bodymovin
 * @param      {Timeline}    timeline                          The timeline to add the tweens to
 * @param      {Function}  [transformFunction=(value)=>value]  A transform function, if the data needs to be converted for PIXI
 */
export function applyTween(
  propertyNames: string[],
  keyframes: v0_9_0.ValueKeyframed,
  timeline: Timeline,
  transformFunction = (value: number): number => value,
) {
  keyframes.k.forEach((keyframe, index) => {
    // Make a tween for each separate property passed in, because it could have its own easing
    propertyNames.forEach((propertyName, propertyNameIndex) => {
      // If no properties change, do nothing
      if (isLastTimeKeyframe(keyframe)) {
        return;
      }

      const nextKeyframe = keyframes.k[index + 1];
      const startTime = keyframe.t;
      // Last keyframes are just set keyframes
      const duration = nextKeyframe ? nextKeyframe.t - keyframe.t : 0;
      let ease;
      if ('i' in keyframe && 'o' in keyframe) {
        ease = new Ease(...getBezierEasePointsFromBodymovin(keyframe, propertyNameIndex));
      } else {
        ease = LinearEase;
      }

      // This check was added when this code was brought over. TypeScript points out that
      // keyframe.s could be something other than an array but we aren't handling it. Throwing an
      // error explicitly here where previously it would have been thrown upon access.
      if (!Array.isArray(keyframe.s)) {
        throw new Error('Keyframe value should be an array');
      }

      const valueStart = transformFunction(keyframe.s[propertyNameIndex]);
      let valueEnd;

      /* If this keyframe uses hold interpolation, let's ensure we
      the object properties update at the appropriate moment. */
      if (keyframe.h === 1) {
        valueEnd = valueStart;
      } else {
        // This check was added when this code was brought over. TypeScript points out that
        // keyframe.e could be something other than an array but we aren't handling it. Throwing an
        // error explicitly here where previously it would have been thrown upon access.
        if (!Array.isArray(keyframe.e)) {
          throw new Error('Keyframe value should be an array');
        }
        valueEnd = transformFunction(keyframe.e[propertyNameIndex]);
      }

      timeline.addTween(propertyName, {
        valueStart,
        valueEnd,
        startTime,
        duration,
        ease,
      });
    });
  });
}

/**
 * Gets the property for a layer at time.
 *
 * @param      {object}         layerData                   The layer data
 * @param      {string}         bodymovinPropertyName       The bodymovin property name ex: 's' = scale
 * @param      {number}         time                        The time to get
 * @param      {number | string}  [tweenPropertyName=0]          Which property to select (either a name or an index)
 * The index of the property (for multi-dimensional properties) ex: 'a' = anchor/pivot. For the 'x' value, use 0, for 'y' use 1
 * If the property has separate tweens for each dimension, (indicated by an 's' in the property object) use a string. ex: 'p' = position. For the 'x' value, use 'x', for 'y' use 'y'
 * @param      {Function}       [transformFunction=()=>{}]  The transform function
 * @returns     {*}              The property at the time.
 * @memberof LayerDataParsing
 * @public
 */
export function getPropertyAtTime(
  layerData: v0_9_0.WaymarkVideoLayer | v0_9_0.WaymarkAudioLayer,
  bodymovinPropertyName: keyof v0_9_0.WaymarkVideoLayer | v0_9_0.WaymarkAudioLayer,
  time: number,
  tweenPropertyName: number | string = 0,
  // NOTE: This used to be a noop function. It is passed into applyTween. Was that a bug we were relying on? I don't think so. But if it *is*
  // that means this probably needs to change back to a no-op.
  transformFunction = (value: number): number => value,
): any {
  // NOTE: If you find that you *do* need this function, just know that the original implementation seemed to not work as advertised in many ways; including
  // the fact that it always threw an exception on the last line.
  throw new Error(
    'When migrating this function, we realized that it will ALWAYS throw an error on its last line. So, we are just throwing an error here to avoid converting it to typescript.',
  );
  return 1;
}

// TODO: Should we only be traversing "used" layers? i.e. What if a composition exists in the assets array, but isn't
// referenced in the main composition in any way via Precomp Source?
export const findLayer = (
  projectManifest: v0_9_0.ProjectManifest,
  predicateFn: (layer: v0_9_0.Layer) => boolean,
) => {
  for (const layer of projectManifest.layers) {
    if (predicateFn(layer)) {
      return layer;
    }
  }

  for (const asset of projectManifest.assets) {
    if ('layers' in asset) {
      for (const layer of asset.layers) {
        if (predicateFn(layer)) {
          return layer;
        }
      }
    }
  }
};

const findLayerByUUID = (projectManifest: v0_9_0.ProjectManifest, uuid: string) => {
  return findLayer(projectManifest, (layer) => 'uuid' in layer && layer.uuid === uuid);
};

/**
 * Takes a hex color and converts into a base 16 integer
 * @method hexToBase16
 * @param  {String}   colorHex   A hex string in the format of '#FFFFFF', 'FFFFFF', '#FFF', or 'FFF'
 * @return {Interger}            An integer representation of the input
 */
export function hexStringToBase16(colorHex: string) {
  let formattedHex = colorHex.replace('#', '');

  // Normally a three digit hex value should be evaluated as such,
  // but three digit hex colors are shorthand for their six digit counterparts.
  if (formattedHex.length === 3) {
    // 123 -> 112233
    formattedHex = formattedHex
      .split('')
      .map((v) => v + v)
      .join('');
  }

  /* eslint-disable-next-line prefer-numeric-literals */
  return parseInt(formattedHex, 16);
}

/**
 * Update the properties on a layer related to its texture
 *
 * @param      {object}  layerData          The layer data
 * @param      {object}  payload            The payload
 * @param      {object}  payload.fitFillAlignment  The texture's alignment (defaults to Center, Center)
 */
type TextureLayerProperiesPayload = v0_9_0.LayerTextureAdjustments & {
  fitFillAlignment?: v0_9_0.FitFillAlignment;
};

export const updateTextureLayerProperties = (
  layerData: v0_9_0.ImageLayer | v0_9_0.WaymarkVideoLayer,
  existingAsset: v0_9_0.ProjectManifest['assets'][number] | undefined,
  payload: TextureLayerProperiesPayload,
) => {
  const assetContentFit =
    (existingAsset && 'modifications' in existingAsset && existingAsset.modifications?.fit) ||
    undefined;
  const assetCropping =
    (existingAsset && 'modifications' in existingAsset && existingAsset.modifications?.cropping) ||
    undefined;
  const assetPadding =
    (existingAsset && 'modifications' in existingAsset && existingAsset.modifications?.padding) ||
    undefined;
  const assetZoom =
    (existingAsset && 'modifications' in existingAsset && existingAsset.modifications?.zoom) ||
    undefined;
  const assetBackgroundFill =
    (existingAsset &&
      'modifications' in existingAsset &&
      existingAsset.modifications?.backgroundFill) ||
    undefined;
  const assetFitFillAlignment =
    (existingAsset &&
      'modifications' in existingAsset &&
      existingAsset.modifications?.fitFillAlignment) ||
    undefined;
  const finalContentFit = payload.contentFit || assetContentFit || undefined;

  // Add content properties from the payloiad
  layerData.contentFit = finalContentFit;
  layerData.contentBackgroundFill =
    finalContentFit === 'fill' ? payload.contentBackgroundFill || assetBackgroundFill : undefined;
  layerData.contentCropping =
    finalContentFit === 'fill' ? payload.contentCropping || assetCropping : undefined;
  layerData.contentFitFillAlignment =
    payload.fitFillAlignment || assetFitFillAlignment || undefined;
  layerData.contentPadding =
    finalContentFit === 'crop' ? payload.contentPadding || assetPadding : undefined;
  layerData.contentZoom = finalContentFit === 'crop' ? payload.contentZoom || assetZoom : undefined;

  if (payload.fitFillAlignment) {
    console.warn(
      'fitFillAlignment on layerData has been deprecated, please use contentFitFillAlignment',
    );
  }
};

// TODO: Make this use the UUIDs
export const formatFontAssetId = ({ family = 'default', weight = 400, style = 'normal' }) =>
  `${family}.${weight}.${style}`;

/**
 * Update the asset in the video data's assets Array
 *
 * @param      {object[]}  assets        The assets
 * @param      {object}    newAsset      The new asset
 * @param      {boolean}   shouldCreate  if the asset is not found, can we create a new asset?
 * @memberof AssetDataParsing
 * @returns {object} The updated asset object
 * @public
 */
export function updateAssetData(
  assets: v0_9_0.ProjectManifest['assets'],
  newAsset: v0_9_0.ImageAsset | v0_9_0.AudioAsset | v0_9_0.VideoAsset,
  shouldCreate = false,
) {
  if (newAsset.id === undefined) {
    throw Error('Could not update asset data for asset without an id');
  }
  const asset = assets.find(({ id }) => id === newAsset.id) as typeof newAsset | undefined;

  if (!asset) {
    if (!shouldCreate) {
      throw Error(`Could not find asset with id ${newAsset.id}`);
    }

    assets.push(newAsset);
    return newAsset;
  }

  for (const key in newAsset) {
    (asset as any)[key] = newAsset[key as keyof typeof newAsset];
  }

  return asset;
}

interface UpdateLayerContentPropertyPayload {
  content?: Partial<v0_9_0.ImageAsset | v0_9_0.AudioAsset | v0_9_0.VideoAsset>;
}
/**
 * Update the asset content for a layer
 *
 * @param {object} layerData The layer data
 * @param {object[]} assets The assets array (from bodymovin)
 * @param {object} payload The payload
 * @param {object} payload.content The payload's content property ex: {type, key, location},
 * @returns {object} The new asset created from the payload
 */
export const updateLayerContentProperty = (
  layerData: v0_9_0.ImageLayer | v0_9_0.WaymarkVideoLayer | v0_9_0.WaymarkAudioLayer,
  assets: v0_9_0.ProjectManifest['assets'],
  payload: UpdateLayerContentPropertyPayload,
) => {
  const { content } = payload;
  let newAsset;

  // Update the asset (content) associated with this layer
  if (content) {
    newAsset = structuredClone(content);
    if (newAsset.id === undefined) {
      // Sometimes we will have a waymark audio layer without a refId
      if (!layerData.refId) {
        layerData.refId = `audio_${uuid()}`;
      }

      newAsset.id = layerData.refId;
    }
    newAsset = updateAssetData(
      assets,
      newAsset as v0_9_0.ImageAsset | v0_9_0.VideoAsset | v0_9_0.AudioAsset,
      true,
    );
    layerData.refId = newAsset.id;
  }

  return newAsset;
};

type OptionalPick<T, K extends PropertyKey> = Pick<T, Extract<keyof T, K>>;
/**
 * Update control properties on a layer that has a time dimension (Video, Audio, Pre-Compositions)
 *
 *  payload: {
 *   contentTrimStartTime: 3.3,
 *   contentTrimDuration: 9.5,
 *   contentPlaybackDuration: 250,
 *   // not implemented
 *   contentLoopType: one of ['none', 'loop', 'bounce'] default: 'none'
 *  }
 *
 * @param      {object}  layerData  The layer data
 * @param      {object}  payload    The payload
 * @param      {number}  payload.contentTrimStartTime     The frame the video should start at (in seconds)
 * @param      {number}  payload.contentTrimDuration      The duration of the video in seconds (if less than the layer duration, it will be played back slower, greater than played back faster)
 * @param      {number}  payload.contentPlaybackDuration  Changes the playback rate of the video inside the layer in frames (how long in the layer timeline it should take).
 * Longer times than duration will slow the playback rate, shorter times will speed up the playback rate.
 * @param      {number}  framerate  The framerate of the template
 */
export const updateLayerContentTimeProperties = (
  layerData: v0_9_0.WaymarkVideoLayer | v0_9_0.WaymarkAudioLayer,
  payload: Partial<
    Pick<
      v0_9_0.LayerPlaybackAdjustments,
      'contentTrimStartTime' | 'contentTrimDuration' | 'contentPlaybackDuration'
    >
  >,
  framerate: number = 30,
) => {
  const { ip: layerInPoint, op: layerOutPoint } = layerData;
  const layerDuration = layerOutPoint - layerInPoint;

  // Trim
  // Because contentTrimDuration is in seconds, for the default we must divide the layerDuration by the framerate
  const { contentTrimStartTime = 0, contentTrimDuration = layerDuration / framerate } = payload;
  // The default should be the length of the trimmed video.
  // Because contentPlaybackDuration is in frames, for the default we must multiply contentTrimDuration by the framerate
  const { contentPlaybackDuration = contentTrimDuration * framerate } = payload;

  const contentEndTime = contentTrimStartTime + contentTrimDuration;

  const timeRemapTween = {
    s: [contentTrimStartTime],
    e: [contentEndTime],
    t: layerInPoint,
  };

  const outTween = {
    // By default, the final frame will be held if the contentTrimDuration is less than the layer's duration
    t: layerInPoint + contentPlaybackDuration,
  };

  const tweens = [timeRemapTween, outTween];

  // Remove all time remapping tweens
  // TODO: When we have better rules around this we may want to be more selective
  delete layerData.tm;
  // Now add the new tweens
  spliceTweensForProperty(layerData, 'tm', tweens);
};

const orderTweensByTime = (tweens: any[] = []) => {
  tweens.sort(({ t: aTime }, { t: bTime }) => aTime - bTime);
};

/**
 * Adds tweens for a layer's property, overwriting tweens that occur at the time if selected
 *
 * @param      {object}  layerData  The video data for layer
 * @param      {string}  property   The property
 * @param      {object[]}  tweens      The tween
 * @memberof LayerDataParsing
 * @public
 */
export function spliceTweensForProperty(
  layerData: v0_9_0.WaymarkVideoLayer | v0_9_0.WaymarkAudioLayer,
  property: 'tm' | 'volume',
  tweens: any[] = [],
) {
  orderTweensByTime(tweens);
  let existingProperty: any = layerData[property];

  // if the existing property does not exist, we'll have to create it with the first tween's starting value
  if (existingProperty === undefined) {
    existingProperty = {
      k: tweens[0].s,
      a: 0,
    };
    // eslint-disable-next-line no-param-reassign
    layerData[property] = existingProperty;
  }

  // if the existing property tween is not animated, we'll have to create a fake tween to begin with
  if (!existingProperty.a) {
    const startingValue = Array.isArray(existingProperty.k)
      ? existingProperty.k
      : [existingProperty.k];

    existingProperty.k = [
      {
        s: startingValue,
        h: 1,
        t: 0,
      },
    ];

    existingProperty.a = 1;
  }

  const tweensTimeStart = tweens[0].t;
  const tweensTimeEnd = tweens[tweens.length - 1].t;

  // Remove any tweens inbetween the spliced tweens
  remove(existingProperty.k, ({ t }) => t >= tweensTimeStart && t <= tweensTimeEnd);

  // Add the tweens to the property
  existingProperty.k.push(...tweens);

  // Sort the tweens by starting time
  orderTweensByTime(existingProperty.k);
}

/**
 * Update a manifest shapes array for a color array.
 *
 * @param {object[]} shapes The shapes to update
 * @param {number[]} colorArray An array [r,g,b] of the fill color
 */
export function updateShapesFillColor(
  shapes: v0_9_0.ShapeLayer['shapes'] | v0_9_0.GroupShape['it'],
  colorArray: [number, number, number],
) {
  for (const shape of shapes) {
    switch (shape.ty) {
      case 'gr': {
        updateShapesFillColor(shape.it, colorArray);
        break;
      }
      case 'fl': {
        const colorValue = shape.c.k;
        [colorValue[0], colorValue[1], colorValue[2]] = colorArray;
        break;
      }
      default: {
        break;
      }
    }
  }
}

/**
 * Convert hex value to a float color array (n >= 0.0 && n <= 1.0).
 * Input hex is expected to be an unquoted hex constant
 * or it's decimal representation.
 *
 * @param   {number}   hex  Hexadecimal number
 * @return  {[float]}       Array of 0.0 <= n <= 1.0 floating point RGB values
 */
export function hexToColorArray(hex: number) {
  // From https://stackoverflow.com/a/29241510
  const red = Math.floor(hex / (256 * 256));
  const green = Math.floor(hex / 256) % 256;
  const blue = hex % 256;

  const colorArray = [red, green, blue].map((color) => color / 255) as [number, number, number];

  return colorArray;
}

/**
 * Converts a hexadecimal color number to a string.
 * @example
 * hexToString(0xffffff); // returns "#ffffff"
 */
export const hexToString = (hex: number) => {
  return `#${hex.toString(16).padStart(6, '0')}`;
};

export const forceHexNumber = (hex: string | number) => {
  if (typeof hex === 'string') {
    return hexStringToBase16(hex);
  }

  return hex;
};

/**
 * Gets the midpoint color array between a starting and ending color array
 *
 * @param      {number[]}  startingColorArray  The starting color array
 * @param      {number[]}  endingColorArray    The ending color array
 * @param      {number}  midpointPosition    The midpoint position
 * @returns     {Array}   The midpoint color array.
 */
function getMidpointColorArray(
  startingColorArray: [number, number, number, number],
  endingColorArray: [number, number, number, number],
  midpointPosition: number,
) {
  const [startingPosition, startingRed, startingGreen, startingBlue] = startingColorArray;
  const [endingPosition, endingRed, endingGreen, endingBlue] = endingColorArray;

  const totalDistance = endingPosition - startingPosition;
  const relativePosition = (midpointPosition - startingPosition) / totalDistance;

  return [
    midpointPosition,
    (endingRed - startingRed) * relativePosition + startingRed,
    (endingGreen - startingGreen) * relativePosition + startingGreen,
    (endingBlue - startingBlue) * relativePosition + startingBlue,
  ] as [number, number, number, number];
}

/**
 * Update a manifest shapes array for a color array.
 *
 * @param {object[]} shapes The shapes to update
 * @param {number[]} colorArray An array [r,g,b] of the fill color
 * @param {number} stepIndex What gradient step to update
 */
export function updateShapeGradientFillColorSteps(
  shapes: v0_9_0.ShapeLayer['shapes'] | v0_9_0.GroupShape['it'],
  colorArray: [number, number, number],
  stepIndex: number,
) {
  shapes.forEach((shape) => {
    switch (shape.ty) {
      case 'gr': {
        updateShapeGradientFillColorSteps(shape.it, colorArray, stepIndex);
        break;
      }
      case 'gf': {
        const totalGradientStepsCount = shape.g.p;
        const gradientSteps = shape.g.k.k;

        // Chunk the stops so they're easier to work with
        const colorStopsArray = chunk(gradientSteps.slice(0, totalGradientStepsCount * 4), 4) as [
          number,
          number,
          number,
          number,
        ][];

        // Alter the stop we want to change directly
        // Bodymovin exports the color steps with midpoints counting as independent
        // color steps. For the sake of our authors, we won't consider the mipoints, but
        // instead alter the midpoint steps programatically.
        const trueStepIndex = stepIndex * 2;
        const stopColor = colorStopsArray[trueStepIndex];
        stopColor.splice(1, 3, ...colorArray);

        // If this is not the first step, alter the previous midpoiunt
        if (trueStepIndex > 1) {
          colorStopsArray[trueStepIndex - 1] = getMidpointColorArray(
            colorStopsArray[trueStepIndex - 2],
            stopColor,
            colorStopsArray[trueStepIndex - 1][0],
          );
        }
        // If this is not the last step, alter the next midpoiunt
        if (trueStepIndex < colorStopsArray.length - 2) {
          colorStopsArray[trueStepIndex + 1] = getMidpointColorArray(
            stopColor,
            colorStopsArray[trueStepIndex + 2],
            colorStopsArray[trueStepIndex + 1][0],
          );
        }

        // eslint-disable-next-line no-param-reassign
        shape.g.k.k = flatten(colorStopsArray);
        break;
      }
      default: {
        break;
      }
    }
  });
}

// A default value that works well for audio ducking
const DEFAULT_DUCKING_VOLUME = 0.3;

// The time (in frames) it takes for the audio to change
const DEFAULT_CHANGE_DURATION = 15;

// A default ease that works well for audio ducking
const DEFAULT_DUCKING_EASE = {
  i: {
    x: [0.72],
    y: [0.37],
  },
  o: {
    x: [0.72],
    y: [0.37],
  },
  n: ['0p72_0p37_0p72_0p37'],
};

const VOLUME_CHANGES_TYPES = {
  targetDucking: 'targetDucking',
  // 'audioLevels',
};

/**
 * Update media control properties on a layer
 *
 * payload: {
 *   isMuted: true,
 *   volume: .8,
 *   volumeChanges: [{
 *       type: 'targetDucking'
 *       duckingTarget: '[UUID]',
 *       targetVolume: .3
 *   }, {
 *       // TODO: Future Option?
 *       type: 'audioLevels',
 *       volume: .8, // The volume of the audio
 *       ease: null (DEFAULT_DUCKING_EASE) || bodymovin compatible ease {"i": {}, "o": {}},
 *       startFrame: 20, // Start time in frames
 *       duration: 50, // Duration of the change in frames
 *   }]
 *  }
 *
 * @param      {object}   layerData          The layer data
 * @param      {object}   projectManifest    The whole project manifest from bodymovin
 * @param      {object}   payload            The payload
 * @param      {number}   payload.volume     The overall (Master) volume for an audio layer
 * @param      {number}   payload.isMuted    If the audio overall is muted
 * @param      {object[]} payload.volumeChanges   An array of changes to the volume (used for ducking, fade outs, etc)
 * @param      {string}   payload.volumeChanges.type     An internal type, used to identify different ways to perform a change. Currently only valid option is 'targetDucking'
 * @param      {string}   payload.volumeChanges.duckingTarget     Another layer to be used for the start and end times of the change
 * @param      {number}   payload.volumeChanges.targetVolume      The volume the audio should duck to
 */
export const updateMediaLayerProperties = (
  layerData: v0_9_0.WaymarkVideoLayer | v0_9_0.WaymarkAudioLayer,
  projectManifest: v0_9_0.ProjectManifest,
  payload: {
    volume?: v0_9_0.LayerPlaybackAdjustments['masterVolume'];
    isMuted?: v0_9_0.LayerPlaybackAdjustments['isMuted'];
    volumeChanges?: v0_9_0.LayerPlaybackAdjustments['volumeChanges'];
    modifications?: any;
  },
) => {
  const { isMuted, modifications, volume, volumeChanges = [] } = payload;

  // Update modifications if supplied
  if (modifications !== undefined && layerData.ty === v0_9_0.LayerType.WaymarkVideo) {
    layerData.modifications = payload.modifications;
  }

  // Update isMuted
  if (isMuted !== undefined) {
    layerData.isMuted = isMuted;
  }

  // Update the master audio level
  if (volume !== undefined) {
    layerData.masterVolume = volume;
  }

  let tweens = [];
  volumeChanges.forEach((volumeChange) => {
    switch (volumeChange.type) {
      case VOLUME_CHANGES_TYPES.targetDucking: {
        // If we have a ducking layer, use that as the basis for the tween
        const duckingTarget = findLayerByUUID(projectManifest, volumeChange.duckingTarget);

        if (!duckingTarget) {
          throw Error(`Could not find ducking layer ${volumeChange.duckingTarget}`);
        }

        const startFrame = duckingTarget.ip;
        const duration = duckingTarget.op - duckingTarget.ip;

        let startingVolume: number;
        try {
          startingVolume = getPropertyAtTime(layerData, 'volume', startFrame);
        } catch (e) {
          // if we don't have a property, it's because it's not included by default in the bodymovin export
          startingVolume = 1;
        }

        const { targetVolume = DEFAULT_DUCKING_VOLUME } = volumeChange;

        // Construct the tweens for the audio ducking
        // The first tween (when the volume decreases)
        const duckingOutTween = {
          ...DEFAULT_DUCKING_EASE,
          s: [startingVolume],
          e: [targetVolume],
          t: startFrame,
        };

        // The tween for when the volume is decreased
        const holdTween = {
          s: [targetVolume],
          h: 1,
          t: startFrame + DEFAULT_CHANGE_DURATION,
        };

        // The tween for when the volume is increased back to the normal volume
        const duckingInTween = {
          ...DEFAULT_DUCKING_EASE,
          s: [targetVolume],
          e: [startingVolume],
          t: startFrame + duration - DEFAULT_CHANGE_DURATION,
        };

        // A Tween to set the duration of the duckingInTween
        const durationTween = {
          t: startFrame + duration,
        };

        tweens = [duckingOutTween, holdTween, duckingInTween, durationTween];
        if (!layerData.volume) {
          layerData.volume = {
            a: 0,
            k: startingVolume,
          };
        }

        // Remove tweens that already existed at these times
        tweens.forEach(({ t }) => {
          removeTweenForPropertyAtTime(layerData, 'volume', t);
        });
        // Now add the new tweens
        spliceTweensForProperty(layerData, 'volume', tweens);
        break;
      }
      default: {
        console.error(`Change operation for layer ${layerData} requires a volumeChange type`);
        break;
      }
    }
  });
};

/**
 * Sanitizes the configuration by making sure image and imageOverride layers are formatted with a root level content object.
 * If a layer has both .content.location and .location, it will merge them, and .content.location will be used.
 * This ensures .content is used for content-related concerns.
 * This is to fix an issue in older configurations where the image and imageOverride layers were not formatted correctly.
 * @param VideoConfiguration
 * @returns VideoConfiguration
 * @example
 * Before:
 * 'imageOverride--1234': {
 *    location: {},
 *    type: 'image',
 *    modifications: { fit: 'crop', zoom: {} }
 * },
 * After:
 * 'imageOverride--1234': {
 *    content: {
 *      location: {},
 *      type: 'image',
 *      modifications: { fit: 'crop', zoom: {} }
 *    }
 * },
 */
export const getSanitizedConfiguration = (configuration: v0_9_0.VideoConfiguration) => {
  const tempConfiguration = structuredClone(configuration);

  Object.keys(tempConfiguration).forEach((key) => {
    if (key.includes('image--') || key.includes('imageOverride--')) {
      const fieldConfigurationValue = Object(tempConfiguration[key]);

      tempConfiguration[key] = {
        ...omit(fieldConfigurationValue, 'location', 'type', 'modifications'),
        content: {
          ...pick(fieldConfigurationValue, 'location', 'type', 'modifications'),
          ...fieldConfigurationValue['content'],
        },
      };
    }
  });

  return tempConfiguration;
};

const isTextConfigurationValue = (
  configurationKey: string,
  configurationValue: v0_9_0.FieldConfigurationValue,
): configurationValue is v0_9_0.TextFieldConfigurationValue => {
  return (
    configurationKey.includes('text--') &&
    typeof configurationValue === 'object' &&
    'content' in configurationValue
  );
};

const isImageConfigurationValue = (
  configurationKey: string,
  configurationValue: v0_9_0.FieldConfigurationValue,
): configurationValue is v0_9_0.ImageFieldConfigurationValue => {
  return (
    configurationKey.includes('image--') &&
    typeof configurationValue === 'object' &&
    'content' in configurationValue
  );
};

const isImageOverrideConfigurationValue = (
  configurationKey: string,
  configurationValue: v0_9_0.FieldConfigurationValue,
): configurationValue is v0_9_0.ImageFieldConfigurationValue => {
  return (
    configurationKey.includes('imageOverride--') &&
    typeof configurationValue === 'object' &&
    'content' in configurationValue
  );
};

const isTextOverrideConfigurationValue = (
  configurationKey: string,
  configurationValue: v0_9_0.FieldConfigurationValue,
): configurationValue is v0_9_0.TextFieldConfigurationValue => {
  return (
    configurationKey.includes('textOverride--') &&
    typeof configurationValue === 'object' &&
    'content' in configurationValue
  );
};

const isSceneSwitchConfigurationValue = (
  configurationKey: string,
  configurationValue: v0_9_0.FieldConfigurationValue,
): configurationValue is v0_9_0.LayoutSelectorFieldConfigurationValue => {
  return configurationKey.includes('sceneSwitch--');
};

const isFontOverrideConfigurationValue = (
  configurationKey: string,
  configurationValue: v0_9_0.FieldConfigurationValue,
): configurationValue is v0_9_0.FontOverrideConfigurationValue => {
  return configurationKey.includes('fontOverride--');
};

const isColorOverrideConfigurationValue = (
  configurationKey: string,
  configurationValue: v0_9_0.FieldConfigurationValue,
): configurationValue is v0_9_0.ColorOverrideConfigurationValue => {
  return configurationKey.includes('colorOverride--');
};

const isVideoConfigurationValue = (
  configurationKey: string,
  configurationValue: v0_9_0.FieldConfigurationValue,
): configurationValue is v0_9_0.VideoFieldConfigurationValue => {
  return configurationKey.includes('video--');
};

const isWaymarkImageLocation = (
  location: latest.AssetLocation,
): location is latest.WaymarkImageLocation => {
  return (
    location.plugin === 'waymark' &&
    [
      'socialproofImagesWeb',
      'socialproofCustomerAssets',
      'socialproofS3Assets',
      'waymarkMattkahlScratch',
      'waymarkTemplateStudio',
    ].includes(location.type)
  );
};

const isLegacyWaymarkVideoLocation = (
  location: latest.AssetLocation,
): location is latest.LegacyWaymarkVideoLocation => {
  return isWaymarkImageLocation(location) && 'legacyTimecode' in location;
};

const isWaymarkVpsLocation = (
  location: latest.AssetLocation,
): location is latest.WaymarkVpsLocation => {
  return 'plugin' in location && location.plugin === 'waymark-vps';
};

const isWaymarkApsLocation = (
  location: latest.AssetLocation,
): location is latest.WaymarkApsLocation => {
  return 'plugin' in location && location.plugin === 'waymark-aps';
};

/**
 * Derive the updated configuration from the original configuration and an active video descriptor.
 *
 * It supports:
 * - Text content and override changes
 * - Image content and override changes
 * - Video content changes
 * - Font changes
 * - Color changes
 * - Scene switch changes
 * - Background audio content and volume changes
 *
 * This is a temporary solution used for editing variants (which still rely on the old configuration format).
 */
export const deriveUpdatedConfiguration = (
  originalConfiguration: v0_9_0.VideoConfiguration,
  activeVideoDescriptor: VideoDescriptor,
) => {
  const newConfiguration = structuredClone(originalConfiguration);

  Object.keys(newConfiguration).forEach((key) => {
    const originalConfigurationValue = originalConfiguration[key];

    if (isTextConfigurationValue(key, originalConfigurationValue)) {
      const layerUuid = key.split('--')[1];
      const layer = activeVideoDescriptor.findLayerByUUID(layerUuid);

      if (layer) {
        const textLayer = layer as TextLayer;
        newConfiguration[key] = {
          ...originalConfigurationValue,
          content: textLayer.getText(),
        };
      }
    } else if (isTextOverrideConfigurationValue(key, originalConfigurationValue)) {
      const overrideUuid = key.split('--')[1];
      const reference = activeVideoDescriptor.references.text[overrideUuid];

      if (reference) {
        (newConfiguration[key] as v0_9_0.TextOverrideConfigurationValue) = reference.getText();
      } else {
        console.warn(`Skipping ${key} because it is not a valid text reference.`);
      }
    } else if (isImageConfigurationValue(key, originalConfigurationValue)) {
      const layerUuid = key.split('--')[1];
      const layer = activeVideoDescriptor.findLayerByUUID(layerUuid) as ImageLayer;
      const asset = layer.getAsset();
      if (asset) {
        if (isWaymarkImageLocation(asset.location.rawLocationData)) {
          (newConfiguration[key] as v0_9_0.NestedContentImageConfigurationValue).content.location =
            asset.location.rawLocationData;
        } else {
          console.warn(
            `Skipping ${key} because it is not a waymark image location. Asset location: ${asset.location}`,
          );
        }
      }
    } else if (isVideoConfigurationValue(key, originalConfigurationValue)) {
      const layerUuid = key.split('--')[1];
      const layer = activeVideoDescriptor.findLayerByUUID(layerUuid) as FootageLayer;
      const asset = layer.getAsset();
      if (asset) {
        if (isWaymarkVpsLocation(asset.location.rawLocationData)) {
          (newConfiguration[key] as v0_9_0.VideoFieldConfigurationValue).content.location =
            asset.location.rawLocationData;
        } else {
          console.warn(
            `Skipping ${key} because it is not a waymark vps location. Asset location: ${asset.location}`,
          );
        }
      }
    } else if (isImageOverrideConfigurationValue(key, originalConfigurationValue)) {
      const overrideUuid = key.split('--')[1];
      const reference = activeVideoDescriptor.references.image[overrideUuid];

      if (reference) {
        const rawLocationData = reference.getImageAsset().location.rawLocationData;
        if (isWaymarkImageLocation(rawLocationData)) {
          (newConfiguration[key] as v0_9_0.NestedContentImageConfigurationValue).content.location =
            rawLocationData;
        } else {
          console.warn(
            `Skipping ${key} because it is not a waymark image location. Asset location: ${rawLocationData}`,
          );
        }
      }
    } else if (isSceneSwitchConfigurationValue(key, originalConfigurationValue)) {
      const switchUuid = key.split('--')[1];
      const contentSwitch = activeVideoDescriptor.switches.find((s) => s.id === switchUuid);

      if (contentSwitch) {
        const selectedOption = contentSwitch.selectedOption;
        (newConfiguration[key] as v0_9_0.LayoutSelectorFieldConfigurationValue) = selectedOption.id;
      } else {
        console.warn(`Skipping ${key} because it is not a valid scene switch.`);
      }
    } else if (isFontOverrideConfigurationValue(key, originalConfigurationValue)) {
      const overrideUuid = key.split('--')[1];
      const reference = activeVideoDescriptor.references.font[overrideUuid];

      if (reference) {
        const rawLocationData = reference.getFontAsset().location.rawLocationData;
        if ('id' in rawLocationData) {
          (newConfiguration[key] as v0_9_0.BFSTypographyConfiguration) = {
            fontVariantUUID: rawLocationData.id,
            fontSizeAdjustment: 0,
          };
        } else if ('legacyId' in rawLocationData) {
          (newConfiguration[key] as v0_9_0.TextTypographyConfiguration) = {
            fontFamily: rawLocationData.legacyId,
            fontSizeAdjustment: 0,
            fontWeight: rawLocationData.weight as v0_9_0.FontWeight,
            fontStyle: rawLocationData.isItalic ? 'italic' : 'normal',
          };
        } else {
          console.warn(
            `Skipping ${key} because it is not a valid font location. Asset location: ${rawLocationData}`,
          );
        }
      }
    } else if (isColorOverrideConfigurationValue(key, originalConfigurationValue)) {
      const overrideUuid = key.split('--')[1];
      const reference = activeVideoDescriptor.references.color[overrideUuid];

      if (reference) {
        (newConfiguration[key] as v0_9_0.ColorOverrideConfigurationValue) = reference.getColor();
      } else {
        console.warn(`Skipping ${key} because it is not a valid color reference.`);
      }
    }
  });

  let backgroundAudioLayer: AudioLayer | null = null;
  try {
    backgroundAudioLayer = activeVideoDescriptor.getBackgroundAudioLayer();
  } catch (error) {
    console.warn('Skipping background audio layer because it is not a valid audio layer.');
  }
  if (backgroundAudioLayer) {
    const volume = backgroundAudioLayer.getMasterVolume();
    (newConfiguration[
      `waymarkAudio--${backgroundAudioLayer.getUUID()}`
    ] as v0_9_0.WaymarkAudioFieldConfigurationValue) = {
      ...(newConfiguration[`waymarkAudio--${backgroundAudioLayer.getUUID()}`] as
        | v0_9_0.WaymarkAudioFieldConfigurationValue
        | undefined),
      volume: volume,
    };

    const rawLocationData = backgroundAudioLayer.getAsset().location.rawLocationData;
    if (isWaymarkApsLocation(rawLocationData) || isWaymarkImageLocation(rawLocationData)) {
      newConfiguration.backgroundAudio = {
        type: 'audio',
        location: rawLocationData,
      };
    }
  }

  return newConfiguration;
};
