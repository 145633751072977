import { z } from 'zod';
import { VideoProcessingService } from '@libs/video-processing-sdk';

import {
  WaymarkVpsLocation,
  WaymarkTemplateStudioLocation,
  AfterEffectsExportLocation,
  LegacyWaymarkVideoLocation,
} from '@libs/waymark-video/video-descriptor-types';
import { settings } from '@libs/global-settings';

import { BaseAssetLocation } from './BaseAssetLocation';
import { getWaymarkPluginOrigin as getLegacyWaymarkPluginOrigin } from './ImageAssetLocation';
import { pathJoin } from './pathJoin';

const footageAssetMetadataZod = z.object({
  width: z.number(),
  height: z.number(),
  duration: z.number(),
});

export enum FootageAssetQuality {
  medium = 'medium',
  high = 'high',
}

export interface FootageAssetMetadata {
  width: number;
  height: number;
  duration: number;
}

const NO_VALUE = Symbol('NO_VALUE');

export class FootageAssetLocation extends BaseAssetLocation<
  | WaymarkVpsLocation
  | LegacyWaymarkVideoLocation
  | AfterEffectsExportLocation
  | WaymarkTemplateStudioLocation
> {
  private static _videoProcessingService: VideoProcessingService | null = null;

  get videoProcessingService() {
    if (!FootageAssetLocation._videoProcessingService) {
      FootageAssetLocation._videoProcessingService = new VideoProcessingService({
        // TODO: This is currently harcoded. It has to be until we have client-side service discovery
        websocketEndpoint: 'wss://mdlgolkxaf.execute-api.us-east-2.amazonaws.com/Prod',
      });
    }
    return FootageAssetLocation._videoProcessingService;
  }

  // Cache for data which is expensive to derive for a location;
  // metadata, thumbnail URLs, and asset URLs
  private __cache: {
    metadata: FootageAssetMetadata | Promise<FootageAssetMetadata> | typeof NO_VALUE;
    thumbnailURLs: string[] | Promise<string[]> | typeof NO_VALUE;
    url: {
      [FootageAssetQuality.medium]: URL | Promise<URL> | typeof NO_VALUE;
      [FootageAssetQuality.high]: URL | Promise<URL> | typeof NO_VALUE;
    };
  } = {
    metadata: NO_VALUE,
    thumbnailURLs: NO_VALUE,
    url: {
      [FootageAssetQuality.medium]: NO_VALUE,
      [FootageAssetQuality.high]: NO_VALUE,
    },
  };

  private async _getURL(quality: FootageAssetQuality): Promise<URL> {
    switch (this.rawLocationData.plugin) {
      case 'waymark-template-studio': {
        const url = new URL(
          `/api/video-template-videos/${this.rawLocationData.id}`,
          settings.get('assets.templateStudioPluginHost'),
        );

        const response = await fetch(url);
        const data = await response.json();

        // TODO: is this accurate?
        return new URL(`https://waymark-template-studio-development.imgix.net/${data.video}`);
      }
      case 'waymark-vps': {
        const sourceVideoKey = this.rawLocationData.sourceVideo;

        if (quality === FootageAssetQuality.high) {
          const highQualityURL = new URL((await this.getVPSOutputLocations('finalAsset_h264'))[0]);
          const isHighQualityAvailable = (await fetch(highQualityURL, { method: 'HEAD' })).ok;
          if (isHighQualityAvailable) {
            return highQualityURL;
          } else {
            console.warn(
              `High quality video not available for ${sourceVideoKey}. Falling back to webPlayer_h264.`,
            );
          }
        }

        const standardQualityURL = new URL((await this.getVPSOutputLocations('webPlayer_h264'))[0]);

        const isStandardQualityAvailable = (await fetch(standardQualityURL, { method: 'HEAD' })).ok;
        if (isStandardQualityAvailable) {
          return standardQualityURL;
        }

        const rawPreviewURL = new URL(
          standardQualityURL.pathname
            .replace('webPlayer_h264', 'raw_preview')
            .replace(/\.mp4$/, `/${sourceVideoKey}.mp4`),
          standardQualityURL,
        );
        return rawPreviewURL;
      }
      case 'waymark': {
        return new URL(
          this.rawLocationData.key.replace(
            'master.mp4',
            quality === FootageAssetQuality.high ? 'high.h264.mp4' : 'medium.h264.mp4',
          ),
          getLegacyWaymarkPluginOrigin(this.rawLocationData.type),
        );
      }
      case 'after-effects-export': {
        return new URL(
          pathJoin(
            settings.get('assets.afterEffectsExportLocationPath'),
            this.rawLocationData.u,
            this.rawLocationData.p,
          ),
        );
      }
      default: {
        throw new Error(`Don't know how to handle location plugin: ${this.rawLocationData}`);
      }
    }
  }

  async getURL(quality: FootageAssetQuality = FootageAssetQuality.medium): Promise<URL> {
    if (this.__cache.url[quality] !== NO_VALUE) {
      return this.__cache.url[quality];
    }

    try {
      const urlPromise = this._getURL(quality);
      this.__cache.url[quality] = urlPromise;
      const url = await urlPromise;
      this.__cache.url[quality] = url;
      return url;
    } catch (err) {
      // Remove the cached URL promise so we can do a clean retry next time
      this.__cache.url[quality] = NO_VALUE;
      // re-throw the error so the caller can handle it
      throw err;
    }
  }

  async _getThumbnailURLs(): Promise<string[]> {
    if (this.rawLocationData.plugin !== 'waymark-vps') {
      return [];
    }
    return this.getVPSOutputLocations('tenThumbnails_jpg300');
  }

  async getThumbnailURLs(): Promise<string[]> {
    if (this.__cache.thumbnailURLs !== NO_VALUE) {
      return this.__cache.thumbnailURLs;
    }

    try {
      const thumbnailURLsPromise = this._getThumbnailURLs();
      this.__cache.thumbnailURLs = thumbnailURLsPromise;
      const thumbnailURLs = await thumbnailURLsPromise;
      this.__cache.thumbnailURLs = thumbnailURLs;
      return thumbnailURLs;
    } catch (err) {
      this.__cache.thumbnailURLs = NO_VALUE;
      throw err;
    }
  }

  /**
   * @param outputType The type of output to get locations for. Note that this is not a comprehensive set of output types, but the most commonly used ones which definitely work.
   */
  async getVPSOutputLocations(
    outputType:
      | 'master'
      | 'finalAsset_h264'
      | 'webPlayer_h264'
      | 'tenThumbnails_jpg300'
      | 'everyTwoSpritesheet_jpg200',
  ): Promise<string[]> {
    if (this.rawLocationData.plugin !== 'waymark-vps') {
      throw new Error(
        `getVPSOutputLocations is only supported for waymark-vps locations. ${this.rawLocationData.plugin} locations are deprecated.`,
      );
    }

    const vps = this.videoProcessingService;
    const sourceVideoKey = this.rawLocationData.sourceVideo;

    // FIXME: verifyProcessedOutput is apparently a no-op and just logs "Not implemented" which spams our console
    // Ensure the video has been processed
    // await vps.verifyProcessedOutput(sourceVideoKey);

    return vps.describeProcessedOutput(sourceVideoKey, outputType).locations;
  }

  async _getMetadata(options: { signal?: AbortSignal }): Promise<FootageAssetMetadata> {
    if (this.rawLocationData.plugin !== 'waymark-vps') {
      throw new Error(
        `Metadata is only supported for waymark-vps locations. ${this.rawLocationData.plugin} locations are deprecated.`,
      );
    }

    const abortSignal = options.signal;
    let remainingRetryAttemptCount = 6;

    while (remainingRetryAttemptCount > 0) {
      if (abortSignal?.aborted) {
        throw new Error(`${this.toString()}.getMetadata() was aborted`);
      }

      remainingRetryAttemptCount -= 1;

      try {
        const rawMetadata = await this.videoProcessingService.analyzeProcessedOutput(
          this.rawLocationData.sourceVideo,
          'master',
        );
        // parse will throw if the data is invalid, at which point we'll try the fallback option and then
        // retry if that fails too
        return footageAssetMetadataZod.parse(rawMetadata);
      } catch (error) {
        console.warn(
          `analyzeProcessedOutput failed to get metadata for ${this.rawLocationData.sourceVideo}`,
          error,
        );
      }

      let metadataURL: string | null = null;

      // If the master metadata is not there, it's likely a shutterstock video that is still processing in the VPS.
      // In that case, we should have a lower-quality raw_preview asset which we can attempt to use instead.
      try {
        // Manually constructing and attempting to fetch the metadata.json URL for the raw_preview asset
        // because the VPS SDK's analyzeProcessedOutput method doesn't support raw_previews
        const h264VideoURL = (await this.getVPSOutputLocations('webPlayer_h264'))[0];
        metadataURL = h264VideoURL
          .replace('webPlayer_h264', 'raw_preview')
          .replace('.mp4', '/metadata.json');

        const rawPreviewMetadataResponse = await fetch(metadataURL as string, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          signal: abortSignal,
        });
        if (rawPreviewMetadataResponse.ok) {
          const rawPreviewMetadata = await rawPreviewMetadataResponse.json();
          return footageAssetMetadataZod.parse(rawPreviewMetadata);
        } else {
          throw new Error(
            `Received error response from ${metadataURL}: ${rawPreviewMetadataResponse.status} ${rawPreviewMetadataResponse.statusText}`,
          );
        }
      } catch (error) {
        if (abortSignal?.aborted) {
          throw new Error(`${this.toString()}.getMetadata() was aborted`);
        }
        if (!metadataURL) {
          console.warn(
            `failed to construct raw preview metadata URL for ${this.rawLocationData.sourceVideo}`,
            error,
          );
        } else {
          console.warn(`failed to get raw preview metadata from ${metadataURL}`, error);
        }
      }
    }

    throw new Error(
      `${this.toString()} Failed to get metadata for ${this.rawLocationData.sourceVideo}`,
    );
  }

  /**
   * Fetches the metadata for this footage asset.
   * This method will poll for up to 6 retries if it fails to get the metadata,
   * so you can pass an optional abortSignal to cancel the operation early.
   *
   * @example
   * ```ts
   * useEffect(() => {
   *  const controller = new AbortController();
   *
   *  (async () => {
   *    try {
   *      const metadata = await footageAsset.getMetadata({ signal: controller.signal });
   *      setMetadata(metadata);
   *    } catch (error) {
   *      consoler.error("Footage metadata fetch failed or aborted", error);
   *    }
   *  })();
   *
   *  // Abort the fetch if the component unmounts
   *  return () => controller.abort();
   * }, [footageAsset]);
   *
   * ```
   */
  async getMetadata(options: { signal?: AbortSignal }): Promise<FootageAssetMetadata> {
    if (this.__cache.metadata !== NO_VALUE) {
      return this.__cache.metadata;
    }

    try {
      const metadataPromise = this._getMetadata(options);
      this.__cache.metadata = metadataPromise;
      const metadata = await metadataPromise;
      this.__cache.metadata = metadata;
      return metadata;
    } catch (err) {
      this.__cache.metadata = NO_VALUE;
      throw err;
    }
  }

  getVPSKey() {
    if (this.rawLocationData.plugin === 'waymark') {
      return this.rawLocationData.key;
    }
    return null;
  }

  getKey() {
    switch (this.rawLocationData.plugin) {
      case 'waymark-vps':
        return this.rawLocationData.sourceVideo;
      case 'waymark':
        return this.rawLocationData.key;
      case 'after-effects-export':
        return `${this.rawLocationData.u}/${this.rawLocationData.p}`;
      case 'waymark-template-studio':
        return this.rawLocationData.id;
      default:
        throw new Error(
          `Don't know how to handle location plugin: ${JSON.stringify(this.rawLocationData)}`,
        );
    }
  }

  toString() {
    return `FootageAssetLocation<${this.rawLocationData.plugin}:${this.getKey()}>`;
  }
}
