import { BaseLayer } from './BaseLayer';
import { ImageAsset, FootageAsset } from '../assets';
import { FootageLayer } from './FootageLayer';

import { latest as LatestVDETypes } from '@libs/waymark-video/video-descriptor-types';

/**
 * Imgix colors must be in the following format:
 * - RGB
 * - ARGB
 * - RRGGBB
 * - AARRGGBB
 *
 * Why is the alpha channel first? Who knows. But we have to deal with it.
 */
const hexColorToImgixColor = (hexColor: string) => {
  // Strip the leading hash if it exists
  const hexColorWithoutHash = hexColor.replace('#', '');

  switch (hexColorWithoutHash.length) {
    case 3:
    case 6:
      // RGB and RRGGBB don't need any further modification
      return hexColorWithoutHash;
    case 4:
      // Convert RGBA to ARGB
      return `${hexColorWithoutHash.slice(-1)}${hexColorWithoutHash.slice(0, 3)}`;
    case 8:
      // Convert RRGGBBAA to AARRGGBB
      return `${hexColorWithoutHash.slice(-2)}${hexColorWithoutHash.slice(0, 6)}`;
    default:
      throw new Error(`Invalid hex color: ${hexColor}`);
  }
};

export class ImageLayer extends BaseLayer<
  LatestVDETypes.ImageLayer,
  {
    'change:asset': void;
    'change:contentModifications': void;
    // BaseLayer events
    'change:visibility': boolean;
    'change:fillEffectColor': void;
    removed: void;
  }
> {
  /**
   * Creates a new ImageLayer from a FootageLayer.
   *
   * @param footageLayer - The FootageLayer to base the new ImageLayer on
   * @param initialImageAsset - The image asset to use for the new ImageLayer
   */
  static fromFootageLayer(footageLayer: FootageLayer, initialImageAsset: ImageAsset): ImageLayer {
    const { rawLayerData: rawFootageLayerData, videoDescriptor } = footageLayer;

    // Ensure the new image asset is added
    videoDescriptor.addAsset(initialImageAsset);

    // Update the layer data in-place to become a video layer
    const rawLayerData: LatestVDETypes.ImageLayer = {
      ...rawFootageLayerData,
      ty: LatestVDETypes.LayerType.Image,
      refId: initialImageAsset.getID(),
    };

    return new ImageLayer(rawLayerData, footageLayer.parentComposition);
  }

  /**
   * Replaces this layer with a footage layer in the video descriptor, using the provided footage asset.
   * Returns the new footage layer.
   */
  changeToFootageLayer(initialFootageAsset: FootageAsset): FootageLayer {
    const footageLayer = FootageLayer.fromImageLayer(this, initialFootageAsset);
    this.parentComposition.replaceLayer(this, footageLayer);
    return footageLayer;
  }

  getDimensions() {
    return {
      width: this.rawLayerData.w,
      height: this.rawLayerData.h,
    };
  }

  /**
   * Get the image asset associated with this layer.
   */
  getAsset(): ImageAsset {
    const asset = this.videoDescriptor.assets.get(this.rawLayerData.refId);
    if (!(asset instanceof ImageAsset)) {
      throw new Error(`${this.toString()}: Asset ${this.rawLayerData.refId} is not an image`);
    }
    return asset;
  }

  private updateAssetRefID(newAssetRefID: string) {
    // If we're changing the asset for an image layer, we're going to make an opinionated decision
    // that all "coordinates" of cropping information (zoom level, cropX, cropY, etc.) should be reset.
    // However, we are going to maintain two cropping properties: contentFit and contentFitFillAlignment.
    // The theory here, is that `contentFit` is most influenced by the properties of the layer instead of the asset. `contentFitFillAlignment`
    // is specific to contentFit: 'fill'; so we're going to maintain that as well.
    this.updateRawLayerData({
      refId: newAssetRefID,
      // Reset all modifications to defaults
      contentCropping: ImageLayer.DEFAULT_CROPPING,
      contentZoom: ImageLayer.DEFAULT_ZOOM,
      contentPadding: ImageLayer.DEFAULT_PADDING,
      contentAdjustments: undefined,
      contentBackgroundFill: undefined,
      contentFillColor: undefined,
    });
    this.dispatchEvent('change:asset');
    this.dispatchEvent('change:contentModifications');
  }

  /**
   * Sets a new image asset for this layer.
   * Resets all modifications on the layer to defaults.
   */
  setAsset(newAsset: ImageAsset, { shouldOverrideReference = false } = {}) {
    const oldAsset = this.getAsset();

    // Ensure the asset is in the manifest
    this.videoDescriptor.addAsset(newAsset);

    if (shouldOverrideReference) {
      this.updateIgnoredReferences({
        imageContent: true,
      });
    }

    if (this.imageContentReference && !this.getIsReferenceIgnored('imageContent')) {
      // Update the image asset reference instead of directly updating this layer;
      // that will trigger the reference's change:value event, which will then come back around
      // to update this layer with any other layers pointing to the same reference
      this.imageContentReference.setImageAsset(newAsset);
    } else {
      this.updateAssetRefID(newAsset.getID());
      // Clean up the old asset if it's no longer in use
      this.videoDescriptor.removeAsset(oldAsset);
    }
  }

  async getAssetURL(): Promise<URL> {
    return await this.getAsset().location.getURL();
  }

  onImageContentReferenceChange() {
    if (!this.imageContentReference || this.getIsReferenceIgnored('imageContent')) {
      return;
    }

    const newImageAsset = this.imageContentReference.getImageAsset();
    this.updateAssetRefID(newImageAsset.getID());
  }

  static getQueryParamsForImageAdjustments(adjustments: LatestVDETypes.ImageAdjustments) {
    const searchParams = new URLSearchParams();

    for (const adjustmentKey in adjustments) {
      switch (adjustmentKey as keyof LatestVDETypes.ImageAdjustments) {
        case 'blur':
          if (adjustments.blur !== undefined) {
            // Blur is an integer from 0-1000 for us, but a float from 0-2000 for imgix
            searchParams.set('blur', String(adjustments.blur * 2));
          }
          break;
        case 'brightness':
          if (adjustments.brightness !== undefined) {
            //  Brightness is a float from -1-1 for us, but an integer from -100-100 for imgix
            searchParams.set('bri', String(Math.round(adjustments.brightness * 100)));
          }
          break;
        case 'contrast':
          if (adjustments.contrast !== undefined) {
            // Contrast is a float from -1-1 for us, but an integer from -100-100 for imgix
            searchParams.set('con', String(Math.round(adjustments.contrast * 100)));
          }
          break;
        case 'duotone':
          if (adjustments.duotone) {
            // Duotone is a tuple of two color hex strings for us, but a comma-separated string for imgix
            searchParams.set('duotone', adjustments.duotone.join(','));
          }
          break;
        case 'duotoneAlpha':
          if (adjustments.duotoneAlpha !== undefined) {
            // Duotone alpha is a float from 0-1 for us, but an integer from 0-100 for imgix
            searchParams.set('duotone-alpha', String(Math.round(adjustments.duotoneAlpha * 100)));
          }
          break;
        case 'exposure':
          if (adjustments.exposure !== undefined) {
            // Exposure is a float from -1-1 for us, but an integer from -100-100 for imgix
            searchParams.set('exp', String(Math.round(adjustments.exposure * 100)));
          }
          break;
        case 'highlight':
          if (adjustments.highlight !== undefined) {
            // Highlight is a float from -1-0 for us, but an integer from -100-0 for imgix
            searchParams.set('high', String(Math.round(adjustments.highlight * 100)));
          }
          break;
        case 'monochrome':
          if (adjustments.monochrome) {
            // Monochrome is a standard color hex string for us. Imgix is weird and takes hex strings with no '#' and the alpha channel at the start instead of the end
            searchParams.set('mono', hexColorToImgixColor(adjustments.monochrome));
          }
          break;
        case 'noiseReduction':
          if (adjustments.noiseReduction !== undefined) {
            // Both values are floats from -1-1 for us, but an integer from -100-100 for imgix
            searchParams.set('nr', String(Math.round(adjustments.noiseReduction * 100)));
          }
          break;
        case 'noiseReductionSharpen':
          if (adjustments.noiseReductionSharpen !== undefined) {
            searchParams.set('nrs', String(Math.round(adjustments.noiseReductionSharpen * 100)));
          }
          break;
        case 'saturation':
          if (adjustments.saturation !== undefined) {
            // Saturation is a float from -1-1 for us, but an integer from -100-100 for imgix
            searchParams.set('sat', String(Math.round(adjustments.saturation * 100)));
          }
          break;
        case 'shadow':
          if (adjustments.shadow !== undefined) {
            // Shadow is a float from 0-1 for us, but an integer from 0-100 for imgix
            searchParams.set('shad', String(Math.round(adjustments.shadow * 100)));
          }
          break;
        case 'sharpen':
          if (adjustments.sharpen !== undefined) {
            // Sharpen is a float from 0-1 for us, but an integer from 0-100 for imgix
            searchParams.set('sharp', String(Math.round(adjustments.sharpen * 100)));
          }
          break;
        case 'unsharpMask':
          if (adjustments.unsharpMask !== undefined) {
            // Unsharp mask is a float from -1-1 for us, but an integer from -100-100 for imgix
            searchParams.set('usm', String(Math.round(adjustments.unsharpMask * 100)));
          }
          break;
        case 'vibrance':
          if (adjustments.vibrance !== undefined) {
            // Vibrance is a float from -1-1 for us, but an integer from -100-100 for imgix
            searchParams.set('vib', String(Math.round(adjustments.vibrance * 100)));
          }
          break;
        default:
          throw new Error(`Encountered unknown image adjustment: ${adjustmentKey}`);
      }
    }

    return searchParams;
  }

  /**
   * Creates a URLSearchParams object with all necessary imgix query params
   * to apply the image adjustments on this layer to the asset URL.
   */
  getAssetAdjustmentQueryParams() {
    return ImageLayer.getQueryParamsForImageAdjustments(this.getImageAdjustments());
  }

  /**
   * Get the URL of the image asset associated with this layer, with
   * any imgix-param-based adjustment modifications applied.
   * Eventually, we will move away from using imgix for these effects,
   * at which point this can be removed.
   */
  async getAssetURLWithAdjustmentParams() {
    const assetURL = await this.getAssetURL();

    const adjustmentParams = this.getAssetAdjustmentQueryParams();
    assetURL.search = adjustmentParams.toString();

    return assetURL;
  }

  /** ~~ Modifications ~~ */

  /** contentAdjustments modification */
  static DEFAULT_ADJUSTMENTS: LatestVDETypes.ImageAdjustments = {};

  getImageAdjustments(): LatestVDETypes.ImageAdjustments {
    return this.rawLayerData.contentAdjustments ?? ImageLayer.DEFAULT_ADJUSTMENTS;
  }
  setImageAdjustments(newAdjustments: Partial<LatestVDETypes.ImageAdjustments> | null) {
    this.updateRawLayerData({
      contentAdjustments: newAdjustments
        ? {
            // Apply the new adjustments on top of the base defaults
            ...ImageLayer.DEFAULT_ADJUSTMENTS,
            ...newAdjustments,
          }
        : undefined,
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  /** contentBackgroundFill modification */
  // Default color is transparent
  static DEFAULT_BACKGROUND_FILL_COLOR = '#FFFFFF00';

  getBackgroundFillColor(): string {
    return this.rawLayerData.contentBackgroundFill ?? ImageLayer.DEFAULT_BACKGROUND_FILL_COLOR;
  }
  setBackgroundFillColor(newBGFillColor: string | null) {
    this.updateRawLayerData({
      contentBackgroundFill: newBGFillColor ?? undefined,
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  /** contentCropping modification */
  static DEFAULT_CROPPING: LatestVDETypes.ContentCropping = {
    x: 0,
    y: 0,
    width: 1,
    height: 1,
  };

  getCropping(): LatestVDETypes.ContentCropping {
    return this.rawLayerData.contentCropping ?? ImageLayer.DEFAULT_CROPPING;
  }
  setCropping(newCroppingData: Partial<LatestVDETypes.ContentCropping>) {
    if (this.getContentFitMode() !== LatestVDETypes.AssetModificationFits.Fill) {
      throw new Error('Cannot set cropping on a layer with contentFit mode other than "fill"');
    }
    this.updateRawLayerData({
      contentCropping: {
        ...this.getCropping(),
        ...newCroppingData,
      },
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  /** contentFillColor modification */
  // Default color is transparent
  static DEFAULT_FILL_COLOR = '#FFFFFF00';

  getFillColor(): string {
    return this.rawLayerData.contentFillColor ?? ImageLayer.DEFAULT_FILL_COLOR;
  }
  /** NOTE: This is an odd property. I think it's technically supported in after effects, however it shouldn't be "contentFillColor" it should just the fill color of
   * the standard bodymovin properties. This is probably something that has just been cargo-culted along and actually `contentBackgroundFillColor` is the only thing that matters.
   * @migrationtodo
   */
  setFillColor(newFillColor: string | null) {
    this.updateRawLayerData({
      contentFillColor: newFillColor ?? undefined,
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  /** contentFit modification */
  static DEFAULT_CONTENT_FIT_MODE: `${LatestVDETypes.AssetModificationFits.Fill}` = 'fill';

  getContentFitMode(): `${LatestVDETypes.AssetModificationFits}` {
    return this.rawLayerData.contentFit ?? ImageLayer.DEFAULT_CONTENT_FIT_MODE;
  }
  setContentFitMode(newFitMode: LatestVDETypes.AssetModificationFits) {
    this.updateRawLayerData({
      contentFit: newFitMode,
      // Clear fit-mode-dependent modifications
      contentPadding: undefined,
      contentBackgroundFill: undefined,
      contentZoom: undefined,
      contentCropping: undefined,
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  /** contentFitFillAlignment modification */
  static DEFAULT_FIT_FILL_ALIGNMENT: `${LatestVDETypes.FitFillAlignment.CenterCenter}` = 'CC';

  getFitFillAlignment(): `${LatestVDETypes.FitFillAlignment}` {
    return this.rawLayerData.contentFitFillAlignment ?? ImageLayer.DEFAULT_FIT_FILL_ALIGNMENT;
  }
  setFitFillAlignment(newFitFillAlignment: LatestVDETypes.FitFillAlignment) {
    this.updateRawLayerData({
      contentFitFillAlignment: newFitFillAlignment,
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  /** contentPadding modification */
  static DEFAULT_PADDING = 0;

  getPadding(): number {
    return this.rawLayerData.contentPadding ?? ImageLayer.DEFAULT_PADDING;
  }
  setPadding(newPadding: number) {
    this.updateRawLayerData({
      contentPadding: newPadding,
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  /** contentZoom modification */
  static DEFAULT_ZOOM: LatestVDETypes.ContentZoom = {
    x: 0.5,
    y: 0.5,
    z: 1,
  };
  static DEFAULT_ZOOM_MOTION_EFFECT: LatestVDETypes.ContentZoomMotionEffect = {
    from: {
      x: 0.25,
      y: 0.5,
      z: 1,
    },
    to: {
      x: 0.75,
      y: 0.5,
      z: 1.5,
    },
    ease: 'ease',
  };

  getZoom() {
    return this.rawLayerData.contentZoom ?? ImageLayer.DEFAULT_ZOOM;
  }
  setZoom(newZoomData: LatestVDETypes.ContentZoom | LatestVDETypes.ContentZoomMotionEffect) {
    this.updateRawLayerData({
      contentZoom: {
        ...newZoomData,
      },
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  cleanUp(): void {
    super.cleanUp();
    // Clean up this layer's asset
    this.videoDescriptor.removeAsset(this.getAsset());
  }
}
