// Vendor
import { find, flatten, get, isObject } from 'lodash';

// Local
import { v0_9_0 } from '@libs/waymark-video/video-descriptor-types';

/**
 * Method enumerates configuration path structure rules and how they relate to their
 * corresponding editing action paths
 *
 * Constructs a valid editing action path for a given configuration path, based on its type
 *
 * @param {str} configurationPath Base level path for a configuration item
 */
export const getEditingActionChangePath = (configurationPath: string) =>
  // We specify what type of change operation we're targeting for text fields in the
  // editing action path, so we need to append `.content` to the path
  configurationPath.split('--')[0] === 'text' ? `${configurationPath}.content` : configurationPath;

// TODO: Should we only be traversing "used" layers? i.e. What if a composition exists in the assets array, but isn't
// referenced in the main composition in any way via Precomp Source?
const findLayer = (
  projectManifest: v0_9_0.ProjectManifest,
  predicateFn: (layer: v0_9_0.Layer) => boolean,
) => {
  for (const layer of projectManifest.layers) {
    if (predicateFn(layer)) {
      return layer;
    }
  }

  for (const asset of projectManifest.assets) {
    if ('layers' in asset) {
      for (const layer of asset.layers) {
        if (predicateFn(layer)) {
          return layer;
        }
      }
    }
  }
};

/**
 * Takes a hex color and converts into a base 16 integer
 * @method hexToBase16
 * @param  {String}   colorHex   A hex string in the format of '#FFFFFF', 'FFFFFF', '#FFF', or 'FFF'
 * @return {Interger}            An integer representation of the input
 */
export function hexStringToBase16(colorHex: string) {
  let formattedHex = colorHex.replace('#', '');

  // Normally a three digit hex value should be evaluated as such,
  // but three digit hex colors are shorthand for their six digit counterparts.
  if (formattedHex.length === 3) {
    // 123 -> 112233
    formattedHex = formattedHex
      .split('')
      .map((v) => v + v)
      .join('');
  }

  /* eslint-disable-next-line prefer-numeric-literals */
  return parseInt(formattedHex, 16);
}

/**
 * Helper class for receiving inputs from a video editor
 * a video editor form, and translating those inputs into
 * WaymarkPixiRender actions.
 *
 * Configuration change events are asynchronous and if a
 * configuration change triggers multiple changes to the
 * renderer, such as a theme or logo mode change, these
 * events will run in parallel.
 */
class ConfigurationInterpreter {
  videoConfiguration: v0_9_0.VideoConfiguration;
  projectManifest: v0_9_0.ProjectManifest;
  editingActions: v0_9_0.EditingActions;

  constructor(
    editingActions: v0_9_0.EditingActions,
    projectManifest: v0_9_0.ProjectManifest,
    videoConfiguration: v0_9_0.VideoConfiguration,
  ) {
    this.videoConfiguration = videoConfiguration;
    this.projectManifest = projectManifest;
    this.editingActions = editingActions;
  }

  /**
   * Construct a set of renderer changes based on a single action.
   * This should not be called for dynamic layers, e.g. `auxiliaryAudio`since
   * they do not have a corresponding editing action.
   *
   * @param  {object}                        action      Single action
   * @param  {string|number|boolean|object}  eventValue  Event value
   * @return {object[]}   Array of changes to pass to the renderer to execute.
   */
  static getChangesFromAction(
    action: v0_9_0.EditingAction,
    eventValue: v0_9_0.EditingActionValuePayload,
  ): v0_9_0.SerializedEditingAction[] {
    let value: v0_9_0.EditingActionValuePayload;

    // Determine the final event value using the operation
    // specified on the action.
    switch (action.value.operation) {
      case v0_9_0.EditingActionValueOperationType.setExplicit: {
        value = action.value.payload;
        break;
      }

      case v0_9_0.EditingActionValueOperationType.passthrough: {
        value = eventValue;
        break;
      }

      default: {
        throw new Error(`Unknown value operation: ${action.value.operation}`);
      }
    }

    switch (action.type) {
      case v0_9_0.EditingActionType.textFontProperties: {
        return action.targets.map((target) => {
          if (!isObject(value) || !('fontFamily' in value || 'fontVariantUUID' in value)) {
            throw new Error('Unexpected value for textFontProperties');
          }
          const fontPropertiesValue = value as v0_9_0.EditingActionFontPropertiesPayload;

          return {
            type: action.type,
            payload: {
              layer: target,
              ...fontPropertiesValue,
            },
          };
        });
      }

      case v0_9_0.EditingActionType.displayObjectVisibility: {
        return action.targets.map((target) => {
          if (typeof value !== 'boolean' || !target) {
            throw new Error('Unexpected value for displayObjectVisibility');
          }
          const payload = {
            layer: target,
            isVisible: value,
          };
          return { type: action.type, payload };
        });
      }

      case v0_9_0.EditingActionType.shapeFillColor: {
        if (typeof value !== 'string') {
          throw new Error('Unexpected value for shapeFillColor');
        }
        return action.targets.map((target) => {
          const payload = {
            layer: target,
            color: hexStringToBase16(value),
          };

          return { type: action.type, payload };
        });
      }

      case v0_9_0.EditingActionType.shapeGradientFillColor: {
        // Each target can translate into multiple changes, so let's flatten the response.
        return action.targets.flatMap((target) => {
          if (!target) {
            throw new Error('Missing target for shapeGradientFillColor');
          }

          return Object.entries(value).map(([stepIndex, colorHex]) => {
            const payload = {
              stepIndex: parseInt(stepIndex, 10),
              layer: target,
              color: hexStringToBase16(colorHex),
            };
            return { type: action.type, payload };
          });
        });
      }

      case v0_9_0.EditingActionType.solidFillColor: {
        return action.targets.map((target) => {
          if (!target) {
            throw new Error('Missing target for solidFillColor');
          }

          const payload = {
            layer: target,
            color: hexStringToBase16(value as string),
          };

          return { type: action.type, payload };
        });
      }

      case v0_9_0.EditingActionType.effectFillColor: {
        return action.targets.map((target) => {
          if (!target) {
            throw new Error('Missing target for effectFillColor');
          }

          const payload = {
            layer: target,
            color: hexStringToBase16(value as string),
          };

          return { type: action.type, payload };
        });
      }

      case v0_9_0.EditingActionType.shapeStrokeColor: {
        return action.targets.map((target) => {
          if (!target) {
            throw new Error('Missing target for shapeStrokeColor');
          }

          const payload = {
            layer: target,
            color: hexStringToBase16(value as string),
          };

          return { type: action.type, payload };
        });
      }

      case v0_9_0.EditingActionType.imageLayerProperties: {
        /* Expect action value to be in the form
          {content: { type, modifications, location }, ...otherLayerData}
        */
        return action.targets.map((target) => {
          if (!target) {
            throw new Error('Missing target for imageLayerProperties');
          }

          const payload = {
            layer: target,
            ...(value as Record<string, any>),
          };

          return { type: action.type, payload };
        });
      }

      case v0_9_0.EditingActionType.imagePath: {
        return action.targets.map((target) => {
          if (!target) {
            throw new Error('Missing target for imagePath');
          }

          const payload = {
            layer: target,
            path: value,
            shouldResize: true,
          };

          return { type: action.type, payload };
        });
      }

      case v0_9_0.EditingActionType.textContent: {
        return action.targets.map((target) => {
          if (!target) {
            throw new Error('Missing target for textContent');
          }

          const payload = {
            layer: target,
            text: value as string,
          };
          return { type: action.type, payload };
        });
      }

      case v0_9_0.EditingActionType.textFillColor: {
        return action.targets.map((target) => {
          if (!target) {
            throw new Error('Missing target for textFillColor');
          }

          const payload = {
            layer: target,
            color: hexStringToBase16(value as string),
          };

          return { type: action.type, payload };
        });
      }

      case v0_9_0.EditingActionType.textStrokeColor: {
        return action.targets.map((target) => {
          if (!target) {
            throw new Error('Missing target for textStrokeColor');
          }

          const payload = {
            layer: target,
            color: hexStringToBase16(value as string),
          };

          return { type: action.type, payload };
        });
      }

      case v0_9_0.EditingActionType.videoLayerProperties: {
        return action.targets.map((target) => {
          if (!target) {
            throw new Error('Missing target for videoLayerProperties');
          }

          const payload = {
            layer: target,
            ...(value as Record<string, any>),
          };

          return { type: action.type, payload };
        });
      }

      // Deprecated action types. They are being handled in the v1.0.0 migration separately.
      case v0_9_0.EditingActionType.audioLayerProperties:
      case v0_9_0.EditingActionType.waymarkAudioAsset:
        return [];

      default:
        throw new Error(`Unknown action type: ${action.type}`);
    }
  }

  /**
   * Gets a set of changes to pass to the renderer from an array of actions.
   * Currently actions arrays are the only action set type
   * that actually fires actions (switch actions serve as
   * a filter that must result in an action array, if
   * anything).
   *
   * @param  {array}                         actions     Array of actions
   * @param  {string|number|boolean|object}  eventValue  Event value
   * @return {object[]}   Array of renderer changes.
   */
  getChangesFromActionsArray(
    actions: v0_9_0.EditingActionSet,
    eventValue: v0_9_0.EditingActionValuePayload,
  ): v0_9_0.SerializedEditingAction[] {
    if (!(actions instanceof Array)) {
      console.error('getChangesFromActionsArray called on non-array');
      return [];
    }

    // Iterate through the actions array and construct a single list of renderer changes.
    return actions.reduce<v0_9_0.SerializedEditingAction[]>(
      (accumulator, action) =>
        accumulator.concat(ConfigurationInterpreter.getChangesFromAction(action, eventValue)),
      [],
    );
  }

  /**
   * Interprets and actions switch object and translates it into an array of renderer changes.
   * Actions switch sets contain cases that can be evaluated
   * to filter more actions sets. Currently the only case
   * operation supported is "equals", which will evaluate an
   * actions set iff the case value equals the input event
   * value.
   *
   * @param  {object}                         actions    Actions switch object
   * @param  {string|number|boolean|object}  eventValue  Event value
   * @return {object[]}   Array of renderer changes.
   */
  getChangesFromActionsSwitch(
    actions: v0_9_0.SwitchActionSet,
    eventValue: v0_9_0.EditingActionValuePayload,
  ): v0_9_0.SerializedEditingAction[] {
    if (actions.type !== 'switch') {
      console.error('getChangesFromActionsSwitch called on non-switch');
      return [];
    }

    return actions.switch.reduce(
      (accumulatedChanges: v0_9_0.SerializedEditingAction[], switchCase) => {
        switch (switchCase.operation) {
          case 'equals': {
            if (eventValue === switchCase.case) {
              return accumulatedChanges.concat(
                this.getRendererChanges(switchCase.actions, eventValue),
              );
            }
            return accumulatedChanges;
          }
          default: {
            console.error('Missing case operation');
            return accumulatedChanges;
          }
        }
      },
      [],
    );
  }

  /**
   * Parses an actions array or object and returns an array of corresponding renderer changes.
   * This is the entry point for any actions array or object
   * of an unknown type. This may be recursively called by
   * actions types that can contain nested sets, such as switch
   * actions sets.
   *
   * @param  {array|object}                  actions     Array or object of actions
   * @param  {string|number|boolean|object}  eventValue  Event value
   * @return {object[]} Array of changes to provide to the renderer.
   */
  getRendererChanges(
    actions: v0_9_0.SwitchActionSet | v0_9_0.EditingActionSet,
    eventValue: v0_9_0.EditingActionValuePayload,
  ) {
    if (actions instanceof Array) {
      return this.getChangesFromActionsArray(actions, eventValue);
    }

    if (actions instanceof Object) {
      if (actions.type === 'switch') {
        return this.getChangesFromActionsSwitch(actions, eventValue);
      }
    }

    console.error(`Unknown actions requested: ${actions}`);
    return [];
  }

  /**
   * Get a Waymark author renderer compatible change list for a new video configuration.
   *
   * @param   {VideoConfiguration}    videoConfiguration  New video configuration
   * @return  {[object]}                      List of renderer changes
   */
  getConfigurationChangeList = async () => {
    const editingActionsChangeList = Object.keys(this.videoConfiguration).reduce(
      (changes: v0_9_0.SerializedEditingAction[], path) => {
        const changePath = getEditingActionChangePath(path);

        const newValue = get(this.videoConfiguration, changePath);

        // If there is an editing action defined for the path, use it to construct the renderer changes
        const editingActionForPath = find(
          get(this.editingActions, 'events'),
          (event) => event.path === changePath,
        );

        if (editingActionForPath) {
          return changes.concat(this.getRendererChanges(editingActionForPath.actions, newValue));
        }

        // We're looking at a configuration item that is not directly editable,
        // e.g. shapes, precomps
        return changes;
      },
      [],
    );

    return editingActionsChangeList;
  };
}

export default ConfigurationInterpreter;
