import { uuid as generateUuid } from '../../utils';
import { v0_9_0 as VersionTypes } from '@libs/waymark-video/video-descriptor-types';
import { faker } from '@faker-js/faker/locale/en';

type PMTextLayer = VersionTypes.TextLayer;
type PMImageLayer = VersionTypes.ImageLayer;
type PMFootageLayer = VersionTypes.WaymarkVideoLayer;

export const valueFactory = (
  value: VersionTypes.Value['k'],
  { ix = 1 }: Partial<Pick<VersionTypes.Value, 'ix'>> = {},
): VersionTypes.Value => {
  return {
    a: 0,
    k: value,
    ix: ix,
  };
};

export const multiDimensionalValueFactory = (
  value: VersionTypes.MultiDimensionalValue['k'],
  { ix = 1 }: Partial<Pick<VersionTypes.MultiDimensionalValue, 'ix'>> = {},
): VersionTypes.MultiDimensionalValue => {
  return {
    a: 0,
    k: value,
    ix: ix,
  };
};

export const fontAssetFactory = ({
  id = generateUuid(),
  fontFamily = 'Arial',
  isItalic = false,
  weight = 400,
}: Partial<VersionTypes.ProjectManifestBitmapFontAsset> & {
  fontFamily: string;
}): VersionTypes.ProjectManifestBitmapFontAsset => {
  return {
    id: id,
    type: VersionTypes.AssetType.BitmapFont,
    isItalic: isItalic,
    weight: weight,
    location: {
      plugin: 'bitmap-font-service',
      // TODO: This needs to be a UUID or else it will fuck up
      legacyId: fontFamily,
    },
  };
};

interface ReadableTextLayerOptions {
  uuid: string;
  name: string;
  text: string;
  fontAssetId: VersionTypes.ProjectManifestBitmapFontAsset['id'];
  fontFamily: VersionTypes.TextProperties['f'];
  fontSize: VersionTypes.TextProperties['s'];
  textTracking: VersionTypes.TextProperties['tr'];
  textLineHeight: VersionTypes.TextProperties['lh'];
  textBaselineShift: VersionTypes.TextProperties['ls'];
  inPoint: VersionTypes.TextLayer['ip'];
  outPoint: VersionTypes.TextLayer['op'];
  startTime: VersionTypes.TextLayer['st'];
  positionX: number;
  positionY: number;
  anchorPointX: number;
  anchorPointY: number;
  rotation: number;
  index: VersionTypes.TextLayer['ind'];
}

export const textLayerFactory = ({
  uuid = generateUuid(),
  name = `Text Layer ${uuid}`,
  text = faker.lorem.words(4),
  fontAssetId = generateUuid(),
  fontFamily = 'Arial',
  fontSize = 42,
  textTracking = 0,
  textLineHeight = 0,
  textBaselineShift = 0,
  inPoint = 0,
  outPoint = 100,
  startTime = 0,
  positionX = 0,
  positionY = 0,
  anchorPointX = 0,
  anchorPointY = 0,
  rotation = 0,
  index = 0,
}: Partial<ReadableTextLayerOptions> = {}): PMTextLayer => ({
  ao: 0,
  bm: VersionTypes.BlendMode.Normal,
  ddd: 0,
  ind: index,
  ip: inPoint,
  op: outPoint,
  st: startTime,
  nm: name,
  sr: 0,
  hasMotionBlur: false,
  hasCollapseTransformation: false,
  refId: fontAssetId,
  meta: {
    uuid,
  },
  ks: {
    p: multiDimensionalValueFactory([positionX, positionY]),
    r: valueFactory(rotation),
  },
  ty: VersionTypes.LayerType.Text,
  t: {
    a: [],
    p: {},
    m: {
      g: VersionTypes.AnchorPointGrouping.All,
      a: multiDimensionalValueFactory([anchorPointX, anchorPointY]),
    },
    d: {
      k: [
        {
          s: {
            f: fontFamily,
            t: text,
            j: VersionTypes.TextJustification.Left,
            s: fontSize,
            tr: textTracking,
            lh: textLineHeight,
            ls: textBaselineShift,
          },
          t: 0,
        },
      ],
    },
  },
});

export const imageAssetFactory = ({
  id = generateUuid(),
  width = 1920,
  height = 1080,
  location = {
    plugin: 'waymark',
    key: generateUuid(),
    type: 'socialproofImagesWeb',
  },
}: {
  id?: string;
  width?: number;
  height?: number;
  location?: VersionTypes.WaymarkImageLocation | VersionTypes.WaymarkTemplateStudioLocation;
} = {}): VersionTypes.ProjectManifestImageAsset => ({
  id,
  type: VersionTypes.AssetType.Image,
  w: width,
  h: height,
  location,
});

export const nullLayerFactory = ({
  meta,
}: {
  meta?: VersionTypes.NullLayer['meta'];
} = {}): VersionTypes.NullLayer => {
  return {
    ao: 0,
    bm: VersionTypes.BlendMode.Normal,
    ddd: 0,
    hasMotionBlur: false,
    ty: VersionTypes.LayerType.NullLayer,
    nm: 'Null Layer',
    ip: 0,
    op: 100,
    st: 0,
    ind: 0,
    sr: 0,
    hasCollapseTransformation: false,
    meta: meta === undefined ? { uuid: generateUuid() } : meta,
    ks: {
      p: multiDimensionalValueFactory([0, 0]),
      r: valueFactory(0),
    },
  };
};

export const audioLayerFactory = ({
  uuid = generateUuid(),
  inPoint = 0,
  outPoint = 100,
  refId = null,
  name = 'Audio Layer',
}: {
  uuid?: string;
  name?: string;
  inPoint?: number;
  outPoint?: number;
  refId?: string | null;
} = {}): VersionTypes.AudioLayer => {
  return {
    refId: refId,
    ip: inPoint,
    op: outPoint,
    ao: 0,
    bm: VersionTypes.BlendMode.Normal,
    ddd: 0,
    ks: { p: { a: 0, k: [0], ix: 0 }, r: { a: 0, k: 0, ix: 0 } },
    ty: VersionTypes.LayerType.Audio,
    meta: { uuid },
    nm: name,
    ind: 0,
    st: 0,
    sr: 1,
    hasMotionBlur: false,
    hasCollapseTransformation: false,
  };
};

export const footageAssetFactory = ({
  id = generateUuid(),
  width = 1920,
  height = 1080,
  location = {
    plugin: 'waymark-vps',
    sourceVideo: generateUuid(),
  },
}: {
  id?: string;
  width?: number;
  height?: number;
  location?: VersionTypes.ProjectManifestVideoAsset['location'];
} = {}): VersionTypes.ProjectManifestVideoAsset => ({
  id,
  type: VersionTypes.AssetType.Video,
  w: width,
  h: height,
  location,
});

export const imageLayerFactory = ({
  uuid = generateUuid(),
  name = `Image Layer ${uuid}`,
  imageAssetID = generateUuid(),
  inPoint = 0,
  outPoint = 100,
  startTime = 0,
  positionX = 0,
  positionY = 0,
  anchorPointX = 0,
  anchorPointY = 0,
  index = 0,
  modifications = {},
}: {
  uuid?: string;
  name?: string;
  imageAssetID?: string;
  inPoint?: number;
  outPoint?: number;
  startTime?: number;
  positionX?: number;
  positionY?: number;
  anchorPointX?: number;
  anchorPointY?: number;
  index?: number;
  modifications?: {
    contentAdjustments?: PMImageLayer['contentAdjustments'];
    contentBackgroundFill?: PMImageLayer['contentBackgroundFill'];
    contentCropping?: PMImageLayer['contentCropping'];
    contentFillColor?: PMImageLayer['contentFillColor'];
    contentFit?: PMImageLayer['contentFit'];
    contentFitFillAlignment?: PMImageLayer['contentFitFillAlignment'];
    contentPadding?: PMImageLayer['contentPadding'];
    contentZoom?: PMImageLayer['contentZoom'];
  };
}): PMImageLayer => {
  return {
    ty: VersionTypes.LayerType.Image,
    meta: {
      uuid,
    },
    refId: imageAssetID,
    ao: 0,
    bm: VersionTypes.BlendMode.Normal,
    ddd: 0,
    ind: index,
    ip: inPoint,
    op: outPoint,
    st: startTime,
    nm: name,
    sr: 0,
    hasMotionBlur: false,
    hasCollapseTransformation: false,
    ks: {
      or: multiDimensionalValueFactory([0, 0, 0]),
      p: multiDimensionalValueFactory([positionX, positionY]),
      a: multiDimensionalValueFactory([anchorPointX, anchorPointY]),
      r: valueFactory(0),
      rx: valueFactory(0),
      ry: valueFactory(0),
      rz: valueFactory(0),
    },
    ...modifications,
  };
};

export const footageLayerFactory = ({
  uuid = generateUuid(),
  name = `Footage Layer ${uuid}`,
  footageAssetID = generateUuid(),
  width = 1920,
  height = 1080,
  inPoint = 0,
  outPoint = 100,
  startTime = 0,
  positionX = 0,
  positionY = 0,
  anchorPointX = 0,
  anchorPointY = 0,
  index = 0,
  modifications = {},
}: {
  uuid?: string;
  name?: string;
  footageAssetID?: string;
  width?: number;
  height?: number;
  inPoint?: number;
  outPoint?: number;
  startTime?: number;
  positionX?: number;
  positionY?: number;
  anchorPointX?: number;
  anchorPointY?: number;
  index?: number;
  modifications?: Pick<
    PMFootageLayer,
    | 'contentBackgroundFill'
    | 'contentCropping'
    | 'contentFit'
    | 'contentFitFillAlignment'
    | 'contentPadding'
    | 'contentPlaybackDuration'
    | 'contentTrimDuration'
    | 'contentTrimStartTime'
    | 'contentZoom'
  >;
}): PMFootageLayer => {
  return {
    ty: VersionTypes.LayerType.WaymarkVideo,
    meta: {
      uuid,
    },
    refId: footageAssetID,
    nm: name,
    w: width,
    h: height,
    ao: 0,
    bm: VersionTypes.BlendMode.Normal,
    ddd: 0,
    ind: index,
    ip: inPoint,
    op: outPoint,
    st: startTime,
    sr: 0,
    hasMotionBlur: false,
    hasCollapseTransformation: false,
    ks: {
      or: multiDimensionalValueFactory([0, 0, 0]),
      p: multiDimensionalValueFactory([positionX, positionY]),
      a: multiDimensionalValueFactory([anchorPointX, anchorPointY]),
      r: valueFactory(0),
      rx: valueFactory(0),
      ry: valueFactory(0),
      rz: valueFactory(0),
    },
    ...modifications,
  };
};

export const createBlankVideoDescriptorData = ({
  duration = 900,
  width = 1920,
  height = 1080,
  name = 'Main Comp',
  frameRate = 30,
} = {}): VersionTypes.VideoDescriptor => {
  return {
    __templateSlug: faker.helpers.slugify(faker.lorem.words(3)),
    templateManifest: {
      backgroundAudio: null,
      overrides: [],
      layersExtendedAttributes: {},
      sceneGroups: [],
      sceneSwitches: [],
      __backgroundAudioLayerUUID: '1234',
    },
    projectManifest: {
      ip: 0,
      op: duration,
      fr: frameRate,
      w: width,
      h: height,
      ddd: 0,
      v: '4.8.0',
      assets: [],
      nm: name,
      layers: [],
    },
    __activeConfiguration: {},
  };
};

export const createBlankTemplateBundle = ({
  __cachedEditingActions = { events: [] },
  __cachedEditingForm = { editingFormFields: [] },
  placeholderConfiguration = undefined,
}: {
  __cachedEditingActions?: VersionTypes.TemplateBundle['__cachedEditingActions'];
  __cachedEditingForm?: VersionTypes.TemplateBundle['__cachedEditingForm'];
  placeholderConfiguration?: VersionTypes.TemplateBundle['placeholderConfiguration'];
} = {}): VersionTypes.TemplateBundle => {
  const videoDescriptor = createBlankVideoDescriptorData();
  return {
    version: `${faker.number.int(12)}.${faker.number.int(12)}`,
    id: faker.string.alphanumeric(9),
    name: faker.helpers.slugify(`${faker.animal} ${faker.animal}`),
    publishedAt: JSON.stringify(faker.date.anytime()),
    placeholderConfiguration: placeholderConfiguration ?? videoDescriptor.__activeConfiguration,
    __cachedEditingActions,
    __cachedEditingForm,
    configurationSchema: {},
    projectManifest: videoDescriptor.projectManifest,
    templateManifest: videoDescriptor.templateManifest,
  };
};
