import { BFSFontVariant } from '@libs/shared-types';
import { FontAsset } from '../assets/FontAsset';
import { BaseReference } from './BaseReference';

import type { FontOverrideProperties } from '@libs/waymark-video/video-descriptor-types';

export class FontReference extends BaseReference<FontOverrideProperties, FontAsset> {
  getFontAsset() {
    const fontAssetID = this.rawReferenceData.__activeValue;
    const fontAsset = this.videoDescriptor.assets.get(fontAssetID);
    if (!(fontAsset instanceof FontAsset)) {
      throw new Error(`Font asset with ID ${fontAssetID} not found`);
    }
    return fontAsset;
  }

  setFontAsset(newFontAsset: FontAsset) {
    const oldAsset = this.getFontAsset();

    const assetID = newFontAsset.getID();
    this.videoDescriptor.addAsset(newFontAsset);
    this.updateRawReferenceData({ __activeValue: assetID });
    this.dispatchEvent('change:value', newFontAsset);

    // Clean up the old asset if it's no longer in use
    this.videoDescriptor.removeAsset(oldAsset);
  }

  /**
   * Takes a list of available BFS font variants and sets the font asset to the variant which most closely
   * matches this reference's current font weight and style.
   */
  async setFontAssetFromBFSFontVariants(variants: BFSFontVariant[]) {
    const newFontAsset = await FontAsset.fromBFSFontVariants(variants, this.getFontAsset());
    this.setFontAsset(newFontAsset);
  }

  getKey() {
    return this.getFontAsset().getKey();
  }

  toString(): string {
    return `FontReference<${this.getLabel()}:${this.getFontAsset().getKey()}>`;
  }
}
