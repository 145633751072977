import { getLocations, VPSDefinitionKey } from './processingDefinitions';
import { environments, VPSEnvironmentKey } from './environments';
import CognitoS3Upload from './CognitoS3Upload';
import { generateKey, getPath } from './sourceVideos';
import beginProcessing from './beginProcessing';
import { VPSBulkJobKey } from './bulkProcessingJobs';

// Export some useful types
export { VPSDefinitionKey, VPSEnvironmentKey, VPSBulkJobKey };

interface VPSConfiguration {
  websocketEndpoint: string;
}

/**
 * Constructs an interface object for the Video Processing Service.
 */
export class VideoProcessingService {
  static environment: VPSEnvironmentKey = 'development';

  configuration: VPSConfiguration;
  cognitoS3Upload: CognitoS3Upload | null = null;

  constructor(configuration: VPSConfiguration) {
    if (!configuration.websocketEndpoint) {
      throw new Error('websocketEndpoint is a required piece of configuration');
    }
    this.configuration = configuration;
  }

  /**
   * Authenticates the VideoProcessingService for use.
   *
   * @returns {Promise}
   */
  async authenticate() {
    this.cognitoS3Upload = new CognitoS3Upload(
      'us-east-2:3169f208-a142-4b07-b809-a8d726608856',
      environments[VideoProcessingService.environment].region,
    );
    await this.cognitoS3Upload.authenticate();
    return this.cognitoS3Upload;
  }

  /**
   * Upload a source video to be processed by the VideoProcessingService.
   *
   * @param {File} contentBody
   * @param {Object} options
   * @param {UploadProgressCallback} [options.onProgress=()=>{}]
   *
   * @returns {Promise<string>} The key of the uploaded source video.
   *
   * @example
   * const fileInput = document.querySelector('#myFile');
   * const selectedFile = fileInput.files[0];
   *
   * const videoUploadService = new VideoUploadService();
   * await videoUploadService.authenticate();
   * const sourceVideoKey = await videoUploadService.uploadSourceVideo(
   *  selectedFile,
   *  { onProgress({ progress, totalSize }) { console.log(`Uploaded ${progress * 100}% of ${totalSize / 1024} MB`); }}
   * )
   */
  async uploadSourceVideo(
    contentBody: File | Buffer,
    {
      onProgress,
    }: {
      onProgress?: (payload: { progress: number; totalSize: number }) => void;
    } = {},
  ) {
    const sourceVideoKey = generateKey();

    const { region, bucket } = environments[VideoProcessingService.environment];

    const cognitoS3Upload = this.cognitoS3Upload || (await this.authenticate());

    await cognitoS3Upload.upload(
      {
        content: contentBody,
        region,
        bucket,
        key: getPath(sourceVideoKey, VideoProcessingService.environment),
      },
      {
        onProgress(s3Progress) {
          if (!onProgress || !s3Progress.total) {
            return;
          }

          const progress = s3Progress.loaded / s3Progress.total;

          onProgress({
            progress,
            totalSize: s3Progress.total,
          });
        },
      },
    );
    return sourceVideoKey;
  }

  async importSourceVideo() {
    console.warn('Not implemented');
  }

  /**
   * @callback UploadProgressCallback
   * @param payload {Object}
   * @param {Float} payload.progress
   * A Float between 0 and 1. 1 is 100%.
   * @param {Float} payload.totalSize
   * A Float representing the total size of the uploading object in bytes.
   */

  /**
   * @callback ProcessingProgressCallback
   * @param payload {Object}
   * @param {Float} payload.progress
   * A Float between 0 and 1. 1 is 100%.
   */

  /**
   * Begin bulk processing of a previously uploaded or imported source video.
   *
   * @example
   *
   * const sourceVideoKey = '1600176416308_NzFdc6EDAT';
   * const videoProcessingService = new VideoProcessingService();
   * await videoProcessingService.authenticate();
   * videoProcessingService.bulkProcessSourceVideo(
   *   sourceVideoKey,
   *   'webPlayer',
   *   {
   *     onProgress({ progress }) {
   *       console.log(`Bulk processing progress: ${progress * 100}% complete`);
   *     }
   *   }
   * )
   *
   * @param {string} sourceVideoKey
   * The key of the source video to process
   * @param {string} bulkProcessingJobKey
   * The key of the bulk processing job to run
   * @param {Object} [options]
   * @param {ProcessingProgressCallback} [options.onProgress=()=>{}]
   * @returns {Promise}
   * Resolves when bulk processing job is complete
   */
  async bulkProcessSourceVideo(
    sourceVideoKey: string,
    bulkProcessingJobKey: VPSBulkJobKey,
    {
      onProgress,
      simulatedProgressDuration = 240000,
      simulatedProgressDurationFrequency = 3000,
    }: {
      onProgress?: (payload: { progress: number }) => void;
      simulatedProgressDuration?: number;
      simulatedProgressDurationFrequency?: number;
    } = {},
  ) {
    let intervalId: ReturnType<typeof setInterval> | null = null;

    // We're just faking progress events on an interval
    if (onProgress) {
      const totalNumberOfProgressNotifications =
        simulatedProgressDuration / simulatedProgressDurationFrequency;

      let progressIteration = 1;
      intervalId = setInterval(() => {
        onProgress?.({
          progress: progressIteration / totalNumberOfProgressNotifications,
        });

        if (progressIteration === totalNumberOfProgressNotifications) {
          if (intervalId !== null) {
            clearInterval(intervalId);
          }
        } else {
          progressIteration = progressIteration + 1;
        }
      }, simulatedProgressDurationFrequency);
    }

    const response = await beginProcessing(
      sourceVideoKey,
      bulkProcessingJobKey,
      VideoProcessingService.environment,
      this.configuration.websocketEndpoint,
    );

    onProgress?.({
      progress: 1,
    });

    if (intervalId !== null) {
      clearInterval(intervalId);
    }

    return response;
  }

  /**
   * Not yet implemented.
   */
  async processSourceVideo(processingDefinitionKey: VPSDefinitionKey) {
    console.warn('Not implemented');
  }

  /**
   * Not yet implemented.
   */
  async verifyProcessedOutput(sourceVideoKey: string) {
    console.warn('Not implemented');
  }

  /**
   * @typedef {Object} ProcessedOutputDescription
   * @property {Array<string>} locations
   * An array of URL locations for the processed output.
   *
   * For standard video process definitions, this will be a single-member array with the URL of the processed video.
   * e.g.:
   * ```
   * [
   *   'https://static.my-host.com/videos/processed/bigHD/mySourceVideoKey.mp4'
   * ]
   * ```
   *
   *
   * For thumbnail-like process definitions (with `number=` specified), this will be an `n`-member array
   * of URLs.
   * e.g.:
   * ```
   * [
   *    'https://static.my-host.com/videos/processed/bigThumbnails/mySourceVideoKey/thumbnail_01.jpg',
   *    'https://static.my-host.com/videos/processed/bigThumbnails/mySourceVideoKey/thumbnail_02.jpg',
   *    'https://static.my-host.com/videos/processed/bigThumbnails/mySourceVideoKey/thumbnail_02.jpg',
   * ]
   *```
   *
   * For spritesheet-like process definitions (with `sprite=yes` and `vtt=yes` specified). This will be a two-member array,
   * the first member will be the spritesheet and the second-member will be the vtt file.
   * e.g.:
   *```
   * [
   *    'https://static.my-host.com/videos/processed/bigSpriteSheets/mySourceVideoKey.png',
   *    'https://static.my-host.com/videos/processed/bigThumbnails/mySourceVideoKey.vtt',
   * ]
   * ```
   */

  /**
   * @typedef {Object} ProcessedOutputAnalysis
   * @property {number} width
   * The width of the video
   * @property {number} height
   * The height of the video
   * @property {number} duration
   * @property {Object} rawMetadata
   * The raw metadata about the video (from Coconut transcoding). Use at your own risk, this could change if we change processing vendors.
   */

  /**
   * Given a source video and a processing format, will describe the processed output
   * for you. This is done eagerly and doesn't verify that any of these locations actually
   * exist. It is the responsibility of the caller to ensure that processing has occurred.
   * Otherwise, `verifyProcessedOutput` can be used.
   *
   * @example
   * const videoTemplateProcessing = new VideoTemplateProcessing();
   * const { locations } = videoTemplateProcessing('1600176416308_NzFdc6EDAT', 'webPlayer_h265');
   * console.log(`My video URL is ${locations[0]}`);
   *
   * // Returns { locations: ['https://static.my-host.com/videos/processed/webPlayer_h265/mySourceVideoKey.mp4' }
   *
   * @param {string} sourceVideoKey
   * The processed source video's key
   * @param {string} processingDefinitionKey
   * The processing definition's key (e.g. `webPlayer_h265`).
   * @returns {ProcessedOutputDescription} outputDescription
   */
  describeProcessedOutput(sourceVideoKey: string, processingDefinitionKey: VPSDefinitionKey) {
    const locations = getLocations(
      sourceVideoKey,
      processingDefinitionKey,
      VideoProcessingService.environment,
    );
    return { locations };
  }

  /**
   * Given a source video and a processing format, will analyze the processed output
   * for you. This actually examines metadata about the file itself and, therefore, requires a network request.
   *
   * @example
   * const videoTemplateProcessing = new VideoTemplateProcessing();
   * const { width, height } = videoTemplateProcessing.analyzeProcessedOutput('1600176416308_NzFdc6EDAT', 'master');
   * console.log(`My video has dimensions of ${width}x${height}`);
   *
   * // Returns { width, height, rawMetadata }
   *
   * @param {string} sourceVideoKey
   * The processed source video's key
   * @param {keyof typeof processingDefinitions} processingDefinitionKey
   * The processing definition's key (e.g. `master`).
   * @returns {ProcessedOutputAnalysis} outputAnalaysis
   */
  async analyzeProcessedOutput(sourceVideoKey: string, processingDefinitionKey: VPSDefinitionKey) {
    if (processingDefinitionKey !== 'master') {
      throw new Error(
        'Only "master" is supported for analysis right now. Other processing definitions coming soon.',
      );
    }

    const response = await fetch(
      `https://6itpdm66re.execute-api.us-east-2.amazonaws.com/videos/${sourceVideoKey}/${processingDefinitionKey}`,
    );

    if (response.ok) {
      return response.json();
    } else {
      const errorText = await response.text();
      throw new Error(`The output could not be analyzed: ${errorText}`);
    }
  }
}
