import { Json } from '@libs/shared-types';
import { v0_9_0 } from '..';

export enum EditingActionType {
  waymarkAudioAsset = 'WAYMARK_AUDIO_ASSET',
  displayObjectVisibility = 'DISPLAY_OBJECT_VISIBILITY',
  shapeFillColor = 'SHAPE_FILL_COLOR',
  shapeGradientFillColor = 'SHAPE_GRADIENT_FILL_COLOR_STEPS',
  solidFillColor = 'SOLID_FILL_COLOR',
  effectFillColor = 'EFFECT_FILL_COLOR',
  shapeStrokeColor = 'SHAPE_STROKE_COLOR',
  imageAsset = 'IMAGE_ASSET',
  imagePath = 'IMAGE_PATH',
  textContent = 'TEXT_CONTENT',
  textFillColor = 'TEXT_FILL_COLOR',
  textStrokeColor = 'TEXT_STROKE_COLOR',
  textFontProperties = 'FONT_PROPERTY',
  // This is used in footage compositions, i.e. themes
  videoLayerResource = 'WAYMARK_VIDEO_ASSET',
  // This is used in layer videos, i.e. inline-videos
  videoLayerProperties = 'LAYER_VIDEO',
  audioLayerProperties = 'LAYER_AUDIO',
  imageLayerProperties = 'LAYER_IMAGE',
  layerAdd = 'LAYER_ADD',
}
export interface BaseLayerChangeOperationPayload {
  layer: string;
}
export interface DisplayObjectVisibilityChangeOperationPayload
  extends BaseLayerChangeOperationPayload {
  isVisible: boolean;
}
export interface EffectFillColorChangeOperationPayload extends BaseLayerChangeOperationPayload {
  isImportant?: boolean;
  color: string | number;
}

export interface SerializedEditingAction {
  type: `${EditingActionType}`;
  payload: LayerChangeOperationPayload;
}

export interface FontPropertyChangeOperationPayload extends BaseLayerChangeOperationPayload {
  fontVariantUUID?: string;
  fontFamily?: string;
  fontWeight: v0_9_0.ProjectManifestBitmapFontAsset['weight'];
  fontStyle: string;
  fontSize: string;
  lineHeight: string;
  resizingStrategy: string;
  webfontloaderConfiguration?: string;
}

export interface ImagePathChangeOperationPayload extends BaseLayerChangeOperationPayload {
  path: string;
}

export interface LayerAudioChangeOperationPayload extends BaseLayerChangeOperationPayload {
  isMuted: v0_9_0.WaymarkAudioLayer['isMuted'];
  volume: v0_9_0.WaymarkAudioLayer['volume'];
  content: v0_9_0.ProjectManifestAudioAsset['location'];
  contentTrimStartTime: v0_9_0.WaymarkAudioLayer['contentTrimStartTime'];
  contentTrimDuration: v0_9_0.WaymarkAudioLayer['contentTrimDuration'];
  contentPlaybackDuration: v0_9_0.WaymarkAudioLayer['contentPlaybackDuration'];
  volumeChanges: v0_9_0.WaymarkAudioLayer['volumeChanges'];
  options?: {
    shouldAdd?: boolean;
    shouldDelete?: boolean;
  };
}

export interface LayerImageChangeOperationPayload
  extends BaseLayerChangeOperationPayload,
  v0_9_0.LayerTextureAdjustments {
  h?: v0_9_0.ProjectManifestImageAsset['h'];
  w?: v0_9_0.ProjectManifestImageAsset['w'];
  fitFillAlignment?: v0_9_0.FitFillAlignment;
  content?: Omit<v0_9_0.ImageAsset, 'w' | 'h' | 'id'> & { w?: number; h?: number; id?: string };
}

export enum EditingActionValueOperationType {
  passthrough = 'passthrough',
  setExplicit = 'setExplicit',
}

export interface LayerVideoChangeOperationPayload
  extends BaseLayerChangeOperationPayload,
  v0_9_0.LayerTextureAdjustments,
  v0_9_0.LayerPlaybackAdjustments {
  volume?: v0_9_0.LayerPlaybackAdjustments['masterVolume'];
  content?: Omit<v0_9_0.ProjectManifestVideoAsset, 'w' | 'h' | 'id'> & {
    w?: number;
    h?: number;
    id?: string;
  };
}

export interface ShapeFillColorChangeOperationPayload extends BaseLayerChangeOperationPayload {
  color: string;
}
export interface ShapeGradientFillColorStepsChangeOperationPayload
  extends BaseLayerChangeOperationPayload {
  color: string | number;
  stepIndex: number;
}

export interface ShapeStrokeColorChangeOperationPayload extends BaseLayerChangeOperationPayload {
  color: string | number;
}

export interface SolidFillColorChangeOperationPayload extends BaseLayerChangeOperationPayload {
  color: string;
}

export interface TextContentChangeOperationPayload extends BaseLayerChangeOperationPayload {
  text: string;
}

export interface TextFillColorChangeOperationPayload extends BaseLayerChangeOperationPayload {
  color: string | number;
  isImportant?: boolean;
}

export interface TextStrokeColorChangeOperationPayload extends BaseLayerChangeOperationPayload {
  color: string | number;
  isImportant?: boolean;
}

export type LayerChangeOperationPayload =
  | DisplayObjectVisibilityChangeOperationPayload
  | ShapeStrokeColorChangeOperationPayload
  | EffectFillColorChangeOperationPayload
  | FontPropertyChangeOperationPayload
  | ImagePathChangeOperationPayload
  | LayerAudioChangeOperationPayload
  | LayerImageChangeOperationPayload
  | LayerVideoChangeOperationPayload
  | ShapeFillColorChangeOperationPayload
  | ShapeGradientFillColorStepsChangeOperationPayload
  | ShapeStrokeColorChangeOperationPayload
  | SolidFillColorChangeOperationPayload
  | TextContentChangeOperationPayload
  | TextFillColorChangeOperationPayload
  | TextStrokeColorChangeOperationPayload;

type EditingActionValuePassThrough = {
  operation: `${EditingActionValueOperationType.passthrough}`;
};

type EditingActionValueSetExplicit<TPayloadValue> = {
  operation: `${EditingActionValueOperationType.setExplicit}`;
  payload: TPayloadValue;
};

export interface SerializedEditingAction {
  type: `${v0_9_0.EditingActionType}`;
  payload: LayerChangeOperationPayload;
}

export type EditingActionFontPropertiesPayload =
  | {
    fontFamily: string;
    fontVariantUUID?: never;
    fontSize?: number;
    fontWeight?: number;
    fontStyle?: 'italic' | 'normal';
    resizingStrategy?: `${v0_9_0.TextResizingStrategy}`;
  }
  | {
    fontVariantUUID: string;
    fontFamily?: never;
    fontSize?: number;
    fontWeight?: number;
    fontStyle?: 'italic' | 'normal';
    resizingStrategy?: `${v0_9_0.TextResizingStrategy}`;
  };

type NonNullJson = NonNullable<Json>;

export type EditingActionValuePayload = NonNullJson | EditingActionFontPropertiesPayload;

export type EditingAction =
  | {
    targets: string[] | [null];
    type: `${Exclude<
      EditingActionType,
      | EditingActionType.textFontProperties
      | EditingActionType.shapeFillColor
      | EditingActionType.waymarkAudioAsset
    >}`;
    value: EditingActionValueSetExplicit<NonNullJson> | EditingActionValuePassThrough;
  }
  | {
    targets: (string | null)[];
    type: `${EditingActionType.waymarkAudioAsset}`;
    value: EditingActionValueSetExplicit<NonNullJson> | EditingActionValuePassThrough;
  }
  | {
    targets: string[];
    type: `${EditingActionType.textFontProperties}`;
    value:
    | EditingActionValueSetExplicit<EditingActionFontPropertiesPayload>
    | EditingActionValuePassThrough;
  }
  | {
    targets: string[];
    type: `${EditingActionType.shapeFillColor}`;
    value: EditingActionValueSetExplicit<string> | EditingActionValuePassThrough;
  };

export type EditingActionSet = EditingAction[];

export type SwitchActionSet = {
  type: 'switch';
  switch: {
    case: boolean | string;
    operation: 'equals';
    actions: EditingActionSet | SwitchActionSet;
  }[];
};

export type EditingActionEvent = {
  path: string;
  actions: EditingActionSet | SwitchActionSet;
};
export interface EditingActions {
  events: EditingActionEvent[];
}
