import type { GlobalSettings } from '../globalSettings';
import { applyDefaultAssetSettings, AssetsSettings } from './assets';
import { applyDefaultRenderingEngineSettings, RendererSettings } from './renderingEngine';
import { applyDefaultServiceSettings, ServiceSettings } from './services';
import { applyDefaultWaymarkSettings, WaymarkSettings } from './waymarkSettings';

export type SupportedSettings = AssetsSettings &
  RendererSettings &
  ServiceSettings &
  WaymarkSettings;

export const applyDefaultSettings = (settings: GlobalSettings<SupportedSettings>) => {
  applyDefaultAssetSettings(settings);
  applyDefaultRenderingEngineSettings(settings);
  applyDefaultServiceSettings(settings);
  applyDefaultWaymarkSettings(settings);
};
