import { AudioAsset } from '../assets';

import { BaseLayer } from './BaseLayer';

import { latest as LatestVDETypes } from '@libs/waymark-video/video-descriptor-types';

import type { VideoDescriptor } from '../VideoDescriptor';
import { uuid } from '../utils';
import { AudioAssetQuality } from '../assets/locations/AudioAssetLocation';
import { VideoDescriptorLayer } from './index';

// The time (in frames) it takes for the audio to change
const DEFAULT_CHANGE_DURATION = 15;

// A default ease that works well for audio ducking
const DEFAULT_DUCKING_EASE = {
  i: {
    x: [0.72],
    y: [0.37],
  },
  o: {
    x: [0.72],
    y: [0.37],
  },
  n: ['0p72_0p37_0p72_0p37'],
};
export class AudioLayer extends BaseLayer<
  LatestVDETypes.WaymarkAudioLayer,
  {
    removed: undefined;
    'change:muted': boolean;
    'change:masterVolume': number;
    'change:asset': AudioAsset;
    'change:volumeChanges': undefined;
    // Note: these events are implemented on the BaseLayer class, but you shouldn't expect
    // an AudioLayer to emit them since they obviously don't have visibility or fill effects.
    'change:fillEffectColor': undefined;
    'change:visibility': boolean;
  }
> {
  static createAudioLayer(
    initialAsset: AudioAsset,
    videoDescriptor: VideoDescriptor,
    suppressEvents = false,
  ) {
    // We'll use the full duration of the video as the in and out points.
    const { inPoint, outPoint } = videoDescriptor.getInAndOutPointFrames();

    // We will add the layer to the root composition
    const rootComposition = videoDescriptor.getRootComposition();

    const newLayer = new AudioLayer(
      {
        ty: LatestVDETypes.LayerType.WaymarkAudio,
        meta: {
          uuid: uuid(),
        },
        masterVolume: 1,
        isMuted: false,
        refId: initialAsset.getID(),
        volumeChanges: [],
        ip: inPoint,
        op: outPoint,
        ao: 0,
        bm: LatestVDETypes.BlendMode.Normal,
        ddd: 0,
        ks: { p: { a: 0, k: [0], ix: 0 }, r: { a: 0, k: 0, ix: 0 } },
        ind: 0,
        st: 0,
        sr: 1,
        hasMotionBlur: false,
        hasCollapseTransformation: false,
      },
      rootComposition,
    );

    // Make sure the asset is registered before we add a layer referencing it
    videoDescriptor.addAsset(initialAsset);
    rootComposition.addLayer(newLayer, suppressEvents);

    // Update the template manifest to include the new layer
    // so we can mark that the layer's content is editable
    videoDescriptor.updateRawData((currentRawData) => ({
      templateManifest: {
        ...currentRawData.templateManifest,
        layersExtendedAttributes: {
          ...currentRawData.templateManifest.layersExtendedAttributes,
          [newLayer.getUUID()]: {
            content: null,
          },
        },
      },
    }));

    return newLayer;
  }

  static getVolumeForVolumeChanges(
    volumeChanges: NonNullable<LatestVDETypes.WaymarkAudioLayer['volumeChanges']>,
    videoDescriptor: VideoDescriptor,
  ): LatestVDETypes.ValueKeyframed {
    const defaultStartingVolume = 1;

    if (volumeChanges.length === 0) {
      // Return default keyframes to hold at the default volume
      // if there are no volume changes
      return {
        a: 1,
        k: [
          {
            s: [defaultStartingVolume],
            h: 1,
            t: 0,
          },
        ],
      };
    }

    // Sort these volume changes
    const volumeChangesWithInAndOutPoints: {
      volumeChange: LatestVDETypes.VolumeChange;
      inPoint: number;
      outPoint: number;
    }[] = [];

    volumeChanges.forEach((volumeChange) => {
      const duckingTargetLayer = videoDescriptor.findLayerByUUID(volumeChange.duckingTarget);
      if (!duckingTargetLayer) {
        throw new Error(`Could not find layer with UUID ${volumeChange.duckingTarget}`);
      }
      const inAndOutPoints = duckingTargetLayer.getInAndOutPointFrames();

      if (inAndOutPoints === null) {
        throw new Error(
          'Cannot duck audio on a target that is not referenced in the main composition (or any sub compositions).',
        );
      }

      inAndOutPoints.forEach(({ inPoint, outPoint }) => {
        volumeChangesWithInAndOutPoints.push({
          volumeChange,
          inPoint,
          outPoint,
        });
      });
    });

    // Sort volumeChangesWithInAndOutPoints by inPoint
    volumeChangesWithInAndOutPoints.sort((a, b) => a.inPoint - b.inPoint);

    const preTweens: {
      start: number;
      end: number;
      target: number;
    }[] = [];

    volumeChangesWithInAndOutPoints.forEach(({ volumeChange, inPoint, outPoint }, index) => {
      const currentPreTween = {
        start: inPoint,
        end: outPoint,
        target: volumeChange.targetVolume,
      };

      if (index > 0) {
        const previousPreTween = preTweens[index - 1];
        if (previousPreTween.end > inPoint) {
          // The previous tween ends after the current tween starts
          // We need to adjust the previous tween to end at the start of the current tween
          previousPreTween.end = Math.max(outPoint, previousPreTween.end);
          previousPreTween.start = Math.min(inPoint, previousPreTween.start);
          previousPreTween.target = Math.min(volumeChange.targetVolume, previousPreTween.target);
          return;
        }
      }
      preTweens.push(currentPreTween);
    });

    const tweens: LatestVDETypes.ValueKeyframed['k'] = [
      {
        s: [defaultStartingVolume],
        h: 1,
        t: 0,
      },
    ];
    preTweens.forEach(({ start, end, target }) => {
      const startingVolume = defaultStartingVolume;
      const targetVolume = target;
      const inPoint = start;
      const outPoint = end;

      // Construct the tweens for the audio ducking
      // The first tween (when the volume decreases)
      const duckingOutTween = {
        ...DEFAULT_DUCKING_EASE,
        s: [startingVolume],
        e: [targetVolume],
        t: inPoint,
      };

      // The tween for when the volume is decreased
      const holdTween = {
        s: [targetVolume],
        h: 1,
        t: inPoint + DEFAULT_CHANGE_DURATION,
      };

      // The tween for when the volume is increased back to the normal volume
      const duckingInTween = {
        ...DEFAULT_DUCKING_EASE,
        s: [targetVolume],
        e: [startingVolume],
        t: outPoint - DEFAULT_CHANGE_DURATION,
      };

      // A Tween to set the duration of the duckingInTween
      const durationTween = {
        t: outPoint,
      };

      tweens.push(duckingOutTween, holdTween, duckingInTween, durationTween);
    });

    const volume: LatestVDETypes.WaymarkAudioLayer['volume'] = {
      a: 1,
      k: tweens,
    };

    return volume;
  }

  /**
   * Get the audio asset associated with this layer.
   */
  getAsset() {
    const asset = this.videoDescriptor.assets.get(this.rawLayerData.refId);
    if (!(asset instanceof AudioAsset)) {
      throw new Error(`${this.toString()}: Asset ${this.rawLayerData.refId} is not an audio asset`);
    }
    return asset;
  }

  /**
   * Get the URL of the footage asset associated with this layer.
   *
   * @param quality - The quality of the footage asset URL to get from the VPS.
   */
  getAssetURL(quality: AudioAssetQuality = AudioAssetQuality.medium) {
    return this.getAsset().location.getURL(quality);
  }

  /**
   * Sets a new audio asset for this layer.
   * Accepts an optional param to reset the layer's volume settings to default values in the process.
   */
  setAsset(asset: AudioAsset, shouldResetVolumeToDefault = false) {
    const oldAsset = this.getAsset();

    // Ensure the asset is in the manifest
    this.videoDescriptor.addAsset(asset);
    if (shouldResetVolumeToDefault) {
      this.updateRawLayerData({
        refId: asset.getID(),
        // Reset master volume, isMuted, and volume changes
        masterVolume: AudioLayer.DEFAULT_MASTER_VOLUME,
        isMuted: false,
        volumeChanges: [],
        volume: AudioLayer.getVolumeForVolumeChanges([], this.videoDescriptor),
      });
      this.dispatchEvent('change:masterVolume', AudioLayer.DEFAULT_MASTER_VOLUME);
      this.dispatchEvent('change:muted', false);
      this.dispatchEvent('change:volumeChanges', undefined);
    } else {
      this.updateRawLayerData({
        refId: asset.getID(),
      });
    }
    this.dispatchEvent('change:asset', asset);

    // Clean up the old asset if it's no longer in use
    this.videoDescriptor.removeAsset(oldAsset);
  }

  /** masterVolume modification */
  static DEFAULT_MASTER_VOLUME = 1;

  getMasterVolume(): number {
    return this.rawLayerData.masterVolume ?? AudioLayer.DEFAULT_MASTER_VOLUME;
  }
  setMasterVolume(volume: number) {
    this.updateRawLayerData({
      masterVolume: volume,
    });
    this.dispatchEvent('change:masterVolume', volume);
  }

  getIsMuted(): boolean {
    return this.rawLayerData.isMuted ?? false;
  }

  setIsMuted(isMuted: boolean) {
    this.updateRawLayerData({
      isMuted,
    });
    this.dispatchEvent('change:muted', isMuted);
  }

  getVolume(): LatestVDETypes.Value | LatestVDETypes.ValueKeyframed {
    if (this.rawLayerData.volume) {
      // If we already have a volume value on the layer, return it
      return this.rawLayerData.volume;
    }

    // We may not have a volume value on the layer, especially for layers which have just been migrated;
    // in this case, we should derive a value on the fly based on the layer's volume changes and store that
    // on the layer
    const volumeChanges = this.getVolumeChanges();
    const volumeValue = AudioLayer.getVolumeForVolumeChanges(volumeChanges, this.videoDescriptor);
    this.updateRawLayerData({
      volume: volumeValue,
    });
    return volumeValue;
  }

  getVolumeChanges(): LatestVDETypes.VolumeChange[] {
    return this.rawLayerData.volumeChanges ?? [];
  }

  setVolumeChanges(volumeChanges: LatestVDETypes.VolumeChange[]) {
    this.updateRawLayerData({
      volumeChanges: volumeChanges,
      volume: AudioLayer.getVolumeForVolumeChanges(volumeChanges, this.videoDescriptor),
    });
    this.dispatchEvent('change:volumeChanges', undefined);
  }

  /**
   * Adds a volume change to target a volume while a given target layer is on screen.
   * If a volume change already exists for the target layer, it will be replaced.
   */
  setDuckingTargetVolumeForLayer(targetLayer: VideoDescriptorLayer, targetVolume: number) {
    const currentVolumeChanges = this.getVolumeChanges();

    const duckingTargetUUID = targetLayer.getUUID();

    const newVolumeChange: LatestVDETypes.VolumeChange = {
      type: 'targetDucking',
      duckingTarget: targetLayer.getUUID(),
      targetVolume,
    };
    this.setVolumeChanges(
      currentVolumeChanges
        .filter(({ duckingTarget }) => duckingTarget !== duckingTargetUUID)
        .concat(newVolumeChange),
    );
  }

  /**
   * Gets the target volume for a given layer when it is on screen, or null if no volume change exists for the layer.
   */
  getDuckingTargetVolumeForLayer(targetLayer: VideoDescriptorLayer): number | null {
    const currentVolumeChanges = this.getVolumeChanges();
    const volumeChange = currentVolumeChanges.find(
      ({ duckingTarget }) => duckingTarget === targetLayer.getUUID(),
    );
    return volumeChange ? volumeChange.targetVolume : null;
  }

  cleanUp(): void {
    super.cleanUp();
    // Clean up this layer's asset
    this.videoDescriptor.removeAsset(this.getAsset());
  }
}
