/**
 * This migration is responsible for moving from 0.9.0 to 1.0.0.
 *
 * The type-related migration changes are as follows:
 * - Requires Layer.meta.uuid
 * - Removes 'w' and 'h' from all assets
 * - Adds optional 'w' and 'h' properties to ImageLayer
 * - Adds AfterEffectsExportLocation for ProjectManifestVideoAsset, ProjectManifestImageAsset, and ProjectManifestAudioAsset
 * - Removes MalformStudioAudioAsset, StudioAudioAsset, AfterEffectsAsset, AfterEffectsFlattenedFootageAsset in favor of ProjectManifestVideoAsset, ProjectManifestImageAsset, and ProjectManifestAudioAsset.
 * - Adds __activeValue to SceneSwitch and Override
 * - Removes meta.__importantChanges from Layer in favor of ignoredReferences
 * - TODO: Removes asset.content and asset.modifications in favor of layer content modifications properties (e.g. contentCropping, contentFit, etc.)
 * - TODO: Removes VideoLayer in favor of WaymarkVideoLayer
 * - TODO: Removes AudioLayer in favor of WaymarkAudioLayer
 *
 * This migration also performs the following data migrations:
 * - Ensures all layers have a meta.uuid
 * - Removes any image/video/audio asset from the projectManifest assets array that isn't referenced by a layer
 * - Transfers h and w properties from image assets to the layers that references them
 * - Ensures there are only WaymarkAudioLayers (with proper identifiers) in the projectManifest that reference audio assets
 * created from __activeConfiguration.backgroundAudio and __activeConfiguration.auxiliaryAudio
 * - TODO: Migrate modifications on media assets to their layers (e.g. contentCropping, contentFit, etc.)
 * - TODO: Migrate footage and audio volume and volumeChanges to their layers (audio is done)
 * - TODO: De-dupe assets with identical locations. We are now able to have a single asset referenced by multiple layers.
 */
import { Migration, VideoDescriptor_v0_9_0, VideoDescriptor_v1_0_0 } from '../types';
import { v0_9_0 as OldTypes, v1_0_0 as NewTypes } from '@libs/waymark-video/video-descriptor-types';
import { uuid as generateUuid } from '../../utils';
import { isEqual } from 'lodash';
import { getSanitizedConfiguration } from '../../legacy/utils';
import omit from 'lodash/omit';
import ConfigurationInterpreter from '../../legacy/ConfigurationInterpreter';
import { TChangeOperation, ChangeOperations } from '../../legacy/ChangeOperations';
import { templateBundleService } from '../../utils/TemplateBundleService';

/**
 * Useful types/type aliases
 */

type OldVideoDescriptor = OldTypes.VideoDescriptor;
type NewVideoDescriptor = NewTypes.VideoDescriptor;

type OldAsset = OldTypes.ProjectManifest['assets'][number];
// We removed h and w from `BaseBodymovinAsset` so we are going to explicitly type that that no longer exists
type NewAsset = NewTypes.ProjectManifest['assets'][number] & {
  h?: never;
  w?: never;
};

/** A non-font and non-composition asset */
type OldMediaAsset =
  | OldTypes.AfterEffectsAsset
  | OldTypes.ProjectManifestFlattenedFootageAsset
  | OldTypes.ProjectManifestImageAsset
  | OldTypes.ProjectManifestVideoAsset
  | OldTypes.ProjectManifestAudioAsset
  | OldTypes.MalformStudioAudioAsset
  | OldTypes.StudioAudioAsset;

/** Useful type guards/type utilities */
const hasMetaWithUuid = (
  layer: OldTypes.Layer,
): layer is OldTypes.Layer & { meta: OldTypes.LayerMeta } => {
  return 'meta' in layer && layer.meta !== null && layer.meta !== undefined;
};

const doesContainBodymovinAssetProperties = (asset: OldAsset) => {
  return 'id' in asset;
};

const doesContainFlattenedFootageProperties = (asset: OldAsset) => {
  return 'footageCompositionFor' in asset && 'sourceCompositionId' in asset;
};

const isAfterEffectsAsset = (asset: OldAsset): asset is OldTypes.AfterEffectsAsset => {
  return (
    doesContainBodymovinAssetProperties(asset) &&
    'u' in asset &&
    'p' in asset &&
    !('location' in asset)
  );
};

const findTopLevelParentWithOverride = (
  layersExtendedAttributes: OldTypes.TemplateManifest['layersExtendedAttributes'],
  targetOverride: string,
): string | null => {
  for (const layerId in layersExtendedAttributes) {
    const extendedAttributes = layersExtendedAttributes[layerId];
    for (const property in extendedAttributes) {
      const value = extendedAttributes[property as keyof OldTypes.LayerExtendedAttributes];
      if (value && 'override' in value && value.override === targetOverride) {
        return layerId; // Return the top-level parent key
      }
    }
  }
  return null; // Return null if no match is found
};

const isBfsTypographyConfiguration = (
  configurationValue: OldTypes.FontOverrideConfigurationValue,
): configurationValue is OldTypes.BFSTypographyConfiguration => {
  return 'fontVariantUUID' in configurationValue;
};

const doesContainProjectManifestMediaAssetProperties = (asset: OldAsset) => {
  return doesContainBodymovinAssetProperties(asset) && 'location' in asset && 'type' in asset;
};

const generateNewVideoLocation = (
  asset:
    | OldTypes.ProjectManifestVideoAsset
    | OldTypes.AfterEffectsFlattenedFootageAsset
    | OldTypes.AfterEffectsFootageAsset,
): NewTypes.ProjectManifestVideoAsset['location'] => {
  // We should create a new plugin-style location for after effects assets and add a legacyTimecode because they almost certainly have one
  if (isAfterEffectsFootageAsset(asset) || isFlattenedFootageAfterEffectsAsset(asset)) {
    return {
      ...generateLocationForAfterEffectsAsset(asset),
      legacyTimecode: {
        nativeVideoWidth: asset.w,
        nativeVideoHeight: asset.h,
      },
    };
  }

  const existingLegacyTimecode =
    'legacyTimecode' in asset.location ? asset.location.legacyTimecode : null;

  // If the asset is a waymark or waymark-template-studio asset, we will add a legacyTimecode if it doesn't already exist
  if (asset.location.plugin === 'waymark' || asset.location.plugin === 'waymark-template-studio') {
    return {
      ...asset.location,
      legacyTimecode: existingLegacyTimecode ?? {
        nativeVideoWidth: asset.w,
        nativeVideoHeight: asset.h,
      },
    };
  }

  // Otherwise, we only add legacyTimecode if it already existed
  return {
    ...asset.location,
    ...(existingLegacyTimecode ? { legacyTimecode: existingLegacyTimecode } : null),
  };
};

const isAfterEffectsFootageAsset = (
  asset: OldAsset,
): asset is OldTypes.AfterEffectsFootageAsset => {
  if (
    'h' in asset &&
    'w' in asset &&
    isAfterEffectsAsset(asset) &&
    'modifications' in asset &&
    asset.modifications !== null &&
    asset.modifications !== undefined &&
    // Does it contain *any* timecode modifications? If it does, then we can presume it's footage
    (('shouldUseTimecode' in asset.modifications &&
      asset.modifications.shouldUseTimecode !== undefined) ||
      ('hasTimecode' in asset.modifications && asset.modifications.hasTimecode !== undefined) ||
      ('videoAssetHeightMode' in asset.modifications &&
        asset.modifications.videoAssetHeightMode !== undefined) ||
      ('videoAssetHeightPadding' in asset.modifications &&
        asset.modifications.videoAssetHeightPadding !== undefined) ||
      ('timecodeScaleMode' in asset.modifications &&
        asset.modifications.timecodeScaleMode !== undefined) ||
      ('timecodeSettings' in asset.modifications &&
        asset.modifications.timecodeSettings !== undefined))
  ) {
    return true;
  }
  return false;
};

const isFlattenedFootageAfterEffectsAsset = (
  asset: OldAsset,
): asset is OldTypes.AfterEffectsFlattenedFootageAsset => {
  return (
    'h' in asset &&
    'w' in asset &&
    isAfterEffectsAsset(asset) &&
    doesContainFlattenedFootageProperties(asset)
  );
};

const isProjectManifestVideoAsset = (
  asset: OldAsset,
): asset is OldTypes.ProjectManifestVideoAsset => {
  return (
    doesContainProjectManifestMediaAssetProperties(asset) &&
    'h' in asset &&
    'w' in asset &&
    'type' in asset &&
    asset.type === 'video'
  );
};

const isProjectManifestImageAsset = (
  asset: OldAsset,
): asset is OldTypes.ProjectManifestImageAsset => {
  return (
    doesContainProjectManifestMediaAssetProperties(asset) &&
    'h' in asset &&
    'w' in asset &&
    'type' in asset &&
    asset.type === 'image'
  );
};

const isProjectManifestAudioAsset = (
  asset: OldAsset,
): asset is OldTypes.ProjectManifestAudioAsset => {
  return 'id' in asset && 'type' in asset && asset.type === 'audio';
};

const isProjectManifestFlattenedFootageAsset = (
  asset: OldAsset,
): asset is OldTypes.ProjectManifestFlattenedFootageAsset => {
  return isProjectManifestVideoAsset(asset) && doesContainFlattenedFootageProperties(asset);
};

const isPrecompSourceAsset = (asset: OldAsset): asset is OldTypes.PreCompSource => {
  return 'layers' in asset;
};

const isMalformStudioAudioAsset = (asset: OldAsset): asset is OldTypes.MalformStudioAudioAsset => {
  return (
    'id' in asset &&
    'p' in asset &&
    'u' in asset &&
    'type' in asset &&
    'name' in asset &&
    asset.type === 'audio'
  );
};

const isStudioAudioAsset = (asset: OldAsset): asset is OldTypes.StudioAudioAsset => {
  return isProjectManifestAudioAsset(asset) && 'name' in asset;
};

const isProjectManifestBitmapFontAsset = (
  asset: OldAsset,
): asset is OldTypes.ProjectManifestBitmapFontAsset => {
  return 'type' in asset && asset.type === OldTypes.AssetType.BitmapFont;
};

const isMediaAsset = (asset: OldAsset): asset is OldMediaAsset => {
  return !isPrecompSourceAsset(asset) && asset.type !== OldTypes.AssetType.BitmapFont;
};

/** Useful utilities */

const generateFontAssetLegacyId = (properties: {
  fontFamily: string;
  fontWeight: number;
  isItalic: boolean;
}): string => {
  return `${properties.fontFamily}.${properties.fontWeight}.${
    properties.isItalic ? 'italic' : 'normal'
  }`;
};

/**
 * Loop over every layer within the project manifest (including layers within precomp sources)
 * @param videoDescriptor
 */
const forEachLayer = function* (videoDescriptor: OldVideoDescriptor): Generator<{
  container: OldTypes.PreCompSource | OldTypes.ProjectManifest;
  layer: OldTypes.Layer;
  index: number;
}> {
  for (const [index, layer] of videoDescriptor.projectManifest.layers.entries()) {
    yield { layer, container: videoDescriptor.projectManifest, index };
  }
  for (const asset of videoDescriptor.projectManifest.assets) {
    if ('layers' in asset) {
      for (const [index, layer] of asset.layers.entries()) {
        yield { layer, container: asset, index };
      }
    }
  }
};

const generateLocationForAfterEffectsAsset = (
  asset: OldTypes.AfterEffectsAsset | OldTypes.MalformStudioAudioAsset,
): NewTypes.AfterEffectsExportLocation => {
  return {
    plugin: 'after-effects-export',
    u: asset.u,
    p: asset.p,
  };
};
/**
 * Given an AfterEffectsAsset or MalformStudioAudioAsset, this function:
 * - Removes modifications (TODO: Is this good? We probably still need to preserve timecode references.)
 * - Removes name (TODO: is this good?)
 * - Deduces a type from 'u' and 'p' properties if 'type' is not present. If this is not possible, type 'image' is assumed.
 * - Migrates to new asset with a location of type AfterEffectsExportLocation
 *
 * Errors will be thrown if
 * @param oldAsset
 * @returns {NewAsset} New asset
 */
const convertAfterEffectsAsset = (
  oldAsset: OldTypes.AfterEffectsAsset | OldTypes.MalformStudioAudioAsset,
):
  | NewTypes.ProjectManifestVideoAsset
  | NewTypes.ProjectManifestImageAsset
  | NewTypes.ProjectManifestAudioAsset => {
  let assetType: NewTypes.BaseProjectManifestMediaAsset['type'];

  // Audio file extensions
  const audioFileExtensions = ['.mp3', '.wav', '.m4a', '.flac', '.aiff', '.aac', '.ogg'];
  // Video file extensions
  const videoFileExtensions = ['.mp4', '.mov', '.avi', '.mkv', '.webm'];
  // Image file extensions
  const imageFileExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.tiff'];

  if (oldAsset.type !== undefined) {
    assetType = oldAsset.type;
  } else {
    // First we'll test file extension
    if (audioFileExtensions.some((extension) => oldAsset.p.endsWith(extension))) {
      assetType = 'audio';
    } else if (imageFileExtensions.some((extension) => oldAsset.p.endsWith(extension))) {
      assetType = 'image';
    } else if (videoFileExtensions.some((extension) => oldAsset.p.endsWith(extension))) {
      assetType = 'video';
      // Then we'll test directory
    } else if (oldAsset.u === 'videos/') {
      assetType = 'video';
    } else if (oldAsset.u === 'audio/') {
      assetType = 'audio';
    } else if (oldAsset.u === 'images/') {
      assetType = 'image';
      // If all else fails, we're guessing this is an image
    } else {
      assetType = 'image';
    }
  }

  const partialAsset = {
    id: oldAsset.id,
    location: generateLocationForAfterEffectsAsset(oldAsset),
  };

  let newAsset: NewAsset;

  if (assetType === 'video') {
    if (isMalformStudioAudioAsset(oldAsset)) {
      throw new Error('Unexpected migration path. MalformStudioAudioAsset should not be here.');
    }

    if (isFlattenedFootageAfterEffectsAsset(oldAsset)) {
      newAsset = {
        ...partialAsset,
        type: 'video',
        name: oldAsset.name || `Video ${oldAsset.u}${oldAsset.p}`,
        footageCompositionFor: oldAsset.footageCompositionFor,
        sourceCompositionId: oldAsset.sourceCompositionId,
        location: generateNewVideoLocation(oldAsset),
      } satisfies NewTypes.ProjectManifestVideoAsset;
    } else if (isAfterEffectsFootageAsset(oldAsset)) {
      newAsset = {
        ...partialAsset,
        type: 'video',
        name: oldAsset.name || `Video ${oldAsset.u}${oldAsset.p}`,
        location: generateNewVideoLocation(oldAsset),
      } satisfies NewTypes.ProjectManifestVideoAsset;
    } else {
      newAsset = {
        ...partialAsset,
        type: 'video',
        name: oldAsset.name || `Video ${oldAsset.u}${oldAsset.p}`,
      } satisfies NewTypes.ProjectManifestVideoAsset;
    }
  } else if (assetType === 'audio') {
    newAsset = {
      ...partialAsset,
      type: 'audio',
    } satisfies NewTypes.ProjectManifestAudioAsset;
  } else if (assetType === 'image') {
    newAsset = {
      ...partialAsset,
      type: 'image',
    } satisfies NewTypes.ProjectManifestImageAsset;
  } else {
    throw new Error(
      `Unexpected migration path. Unrecognized asset type ${assetType}. Asset type is assumed to be 'image', 'video', or 'audio' at this point.`,
    );
  }
  return newAsset;
};

const applyActiveConfiguration = async (
  inputVideoDescriptor: OldTypes.VideoDescriptor,
): Promise<OldTypes.VideoDescriptor> => {
  // Get the template bundle
  const templateSlug = inputVideoDescriptor.__templateSlug;

  if (Object.keys(inputVideoDescriptor.__activeConfiguration).length === 0) {
    return inputVideoDescriptor;
  }

  if (typeof templateSlug !== 'string') {
    throw new Error('You need a __templateSlug to migrate.');
  }
  const bundleJson = await templateBundleService.getTemplateBundle(templateSlug);

  if (bundleJson === null) {
    throw new Error('Could not retrieve bundle.');
  }

  const editingActions = bundleJson.__cachedEditingActions;
  const projectManifest = inputVideoDescriptor.projectManifest;
  const templateManifest = inputVideoDescriptor.templateManifest;
  const activeConfiguration = getSanitizedConfiguration(
    inputVideoDescriptor.__activeConfiguration ?? bundleJson.placeholderConfiguration,
  );

  // NOTE: We want to move away from the 'IMAGE_ASSET' and 'WAYMARK_VIDEO_ASSET' change operation types and to the
  // 'LAYER_IMAGE' and 'LAYER_VIDEO' types, respectively. Also, inside our cached editing actions we need to remove
  // the 'content' portion of the path, that way we are editing the entire layer object with configuration changes.
  const imageActionRegex = /image-.*\.content/;
  const videoActionRegex = /waymarkVideo-.*\.content/;
  editingActions.events.forEach((actionEvent) => {
    // Remove `.content` from editing action targets
    if (imageActionRegex.test(actionEvent.path)) {
      // eslint-disable-next-line no-param-reassign
      actionEvent.path = actionEvent.path.replace('.content', '');
    } else if (videoActionRegex.test(actionEvent.path)) {
      // eslint-disable-next-line no-param-reassign
      actionEvent.path = actionEvent.path.replace('.content', '');
    }

    // Replace `IMAGE_ASSET` action types with `LAYER_IMAGE` and
    // `WAYMARK_VIDEO_ASSET action types with `LAYER_VIDEO`
    // Test if actions is an array without lodash, as some action events have a single action object
    // NOTE: This logic is being left in-place to replicate previous functionality. However,
    // it is odd that there's no else case for the array check. It's fine because the only
    // type of action that isn't an array is a switch which only contains "DISPLAY_OBJECT_VISIBILITY" actions.
    if (Array.isArray(actionEvent.actions)) {
      actionEvent.actions.forEach((action) => {
        if (action.type === 'IMAGE_ASSET') {
          // eslint-disable-next-line no-param-reassign
          action.type = 'LAYER_IMAGE';
        } else if (action.type === 'WAYMARK_VIDEO_ASSET') {
          // eslint-disable-next-line no-param-reassign
          action.type = 'LAYER_VIDEO';
        }
      });
    }
  });

  /**
   * END BUNDLE MODIFICATIONS
   */
  const configurationInterpreter = new ConfigurationInterpreter(
    editingActions,
    projectManifest,
    activeConfiguration,
  );

  const layerChanges: { [layer: string]: OldTypes.SerializedEditingAction[] } = {};
  const layerChangeOperations: {
    [layer: string]: TChangeOperation[];
  } = {};
  const nonlayerChanges: OldTypes.SerializedEditingAction[] = [];
  const nonlayerChangeOperations: TChangeOperation[] = [];

  // Get the renderer changes list for the initial configuration.
  // The template manifest is not required to match the initial configuration, so supplying this
  // changes list now instead of relying on the later configuratorDidLoadConfiguration call allows
  // the renderer to skip redundant assets that are replaced in the manifest by the initial
  // configuration.
  const changesList = await configurationInterpreter.getConfigurationChangeList();

  changesList.forEach((change) => {
    if (change.payload && change.type !== 'FONT_PROPERTY') {
      const { layer } = change.payload;

      if (!(layer in layerChanges)) {
        layerChanges[layer] = [];
        layerChangeOperations[layer] = [];
      }

      layerChanges[layer].push(change);
    } else {
      nonlayerChanges.push(change);
    }
  });

  // We want to run each layer's manifest change operation sequentially so there aren't
  // conflicts that arise due to race conditions and simultaneous work.
  // We are free, however, to update all of the layers in parallel
  const runUpdateManifest = async (layer: string) => {
    const changes = layerChanges[layer];
    const changeOperations = changes.map((change) => {
      return new ChangeOperations[change.type](projectManifest, change.payload as any);
    });
    layerChangeOperations[layer] = changeOperations;

    const updateManifestOperations = changeOperations.map(async (changeOperation) => {
      await changeOperation.updateManifest();
    });
    await Promise.all(updateManifestOperations);
  };

  // Update all of the manifests for each layer
  await Promise.all(Object.keys(layerChanges).map(runUpdateManifest));

  const nonLayerManifestOperations = nonlayerChanges.map(async (change) => {
    const changeOperation = new ChangeOperations[change.type](
      projectManifest,
      // NOTE: Frustrating I have to cast as any here
      change.payload as any,
    );
    nonlayerChangeOperations.push(changeOperation);
  });

  await Promise.all(nonLayerManifestOperations);

  return {
    projectManifest,
    templateManifest,
    __templateSlug: templateSlug,
    __activeConfiguration: activeConfiguration,
  };
};

/** The logic of the migration */
const implementation = async (
  inputVideoDescriptor: VideoDescriptor_v0_9_0,
): Promise<VideoDescriptor_v1_0_0> => {
  let backgroundAudio: {
    asset: NewTypes.ProjectManifestAudioAsset;
    layer: NewTypes.WaymarkAudioLayer;
  } | null = null;

  let auxiliaryAudio: {
    asset: NewTypes.ProjectManifestAudioAsset;
    layer: NewTypes.WaymarkAudioLayer;
  } | null = null;

  // First, we'll repair missing information
  let massagedInputVideoDescriptor = structuredClone(inputVideoDescriptor);
  massagedInputVideoDescriptor = await applyActiveConfiguration(massagedInputVideoDescriptor);

  const refIdMapping: Record<string, OldTypes.Source> = {};
  // A mapping of asset ids to an array of layer uuids that reference them
  const assetToLayerMapping: Record<string, string[]> = {};
  // A mapping of layer uuids to their layer objects
  const layerUuidMapping: Record<string, OldTypes.Layer> = {};

  /**
   * MIGRATION STEP
   *
   * Take the background audio from the configuration, create an audio asset, and create a waymarkAudio layer.
   */

  /**
   * Handle background audio
   * TODO: We will have to add a proper `volume` tweent to these layers
   */
  const defaultVolumeChanges: NewTypes.VolumeChange[] = [];
  const defaultIsMuted = false;
  const defaultVolume = 1;
  const defaultTargetVolume = 0.3;
  const storedBackgroundAudio = massagedInputVideoDescriptor.__activeConfiguration.backgroundAudio;

  if (storedBackgroundAudio !== undefined && storedBackgroundAudio !== null) {
    const backgroundAudioAsset: NewTypes.ProjectManifestAudioAsset = {
      id: generateUuid(),
      type: 'audio',
      location: storedBackgroundAudio.location,
    };

    const backgroundAudioLayerUUID =
      massagedInputVideoDescriptor.templateManifest.__backgroundAudioLayerUUID;

    const audioLayerModifications = massagedInputVideoDescriptor.__activeConfiguration[
      `waymarkAudio--${backgroundAudioLayerUUID}`
    ] as OldTypes.WaymarkAudioFieldConfigurationValue | undefined;

    const volumeChanges: NewTypes.VolumeChange[] =
      // Convert away from implied target volumes to explicit target volumes
      audioLayerModifications?.volumeChanges?.map((vc) => ({
        ...vc,
        targetVolume: vc.targetVolume ?? defaultTargetVolume,
      })) ?? defaultVolumeChanges;
    const volume = audioLayerModifications?.volume ?? defaultVolume;
    const isMuted = audioLayerModifications?.isMuted ?? defaultIsMuted;

    const backgroundAudioLayer: NewTypes.WaymarkAudioLayer = {
      ty: NewTypes.LayerType.WaymarkAudio,
      refId: backgroundAudioAsset.id,
      masterVolume: volume,
      volumeChanges,
      isMuted,
      // TODO: We should probably change our typings so that audio layers don't require properties like
      // blendMode, motionBlur, etc.
      ao: 0,
      bm: NewTypes.BlendMode.Normal,
      ddd: 0,
      ks: { p: { a: 0, k: [0], ix: 0 }, r: { a: 0, k: 0, ix: 0 } },
      // Needs to be accurate?
      ind: 0,
      ip: massagedInputVideoDescriptor.projectManifest.ip,
      op: massagedInputVideoDescriptor.projectManifest.op,
      meta: {
        uuid: backgroundAudioLayerUUID,
      },
      st: 0,
      sr: 1,
      hasMotionBlur: false,
      hasCollapseTransformation: false,
    };

    backgroundAudio = {
      asset: backgroundAudioAsset,
      layer: backgroundAudioLayer,
    };
  }

  /**
   * MIGRATION STEP
   *
   * Take the auxiliary audio from the configuration, create an audio asset, and create a waymarkAudio layer.
   */

  const storedAuxiliaryAudio = massagedInputVideoDescriptor.__activeConfiguration.auxiliaryAudio;
  if (storedAuxiliaryAudio !== undefined && storedAuxiliaryAudio !== null) {
    const auxiliaryAudioAsset: NewTypes.ProjectManifestAudioAsset = {
      id: generateUuid(),
      type: 'audio',
      location: storedAuxiliaryAudio.content.location,
    };

    const volumeChanges: NewTypes.VolumeChange[] =
      // Convert away from implied target volumes to explicit target volumes
      storedAuxiliaryAudio.volumeChanges.map((vc) => ({
        ...vc,
        targetVolume: vc.targetVolume ?? defaultTargetVolume,
      })) ?? defaultVolumeChanges;
    const volume = storedAuxiliaryAudio.volume;
    const isMuted = storedAuxiliaryAudio.isMuted;

    const auxiliaryAudioLayer: NewTypes.WaymarkAudioLayer = {
      ty: NewTypes.LayerType.WaymarkAudio,
      refId: auxiliaryAudioAsset.id,
      masterVolume: volume,
      volumeChanges,
      isMuted,
      // TODO: We should probably change our typings so that audio layers don't require properties like
      // blendMode, motionBlur, etc.
      ao: 0,
      bm: NewTypes.BlendMode.Normal,
      ddd: 0,
      ks: { p: { a: 0, k: [0], ix: 0 }, r: { a: 0, k: 0, ix: 0 } },
      // Needs to be accurate?
      ind: 0,
      ip: massagedInputVideoDescriptor.projectManifest.ip,
      op: massagedInputVideoDescriptor.projectManifest.op,
      meta: {
        uuid: generateUuid(),
      },
      st: 0,
      sr: 1,
      hasMotionBlur: false,
      hasCollapseTransformation: false,
    };

    auxiliaryAudio = {
      asset: auxiliaryAudioAsset,
      layer: auxiliaryAudioLayer,
    };
  }

  // Ensure all overrides get an __activeValue added, and the placeholder value is removed.
  // Removing placeholder should be very safe since it was never really respected before and
  // is now even more redundant with the addition of __activeValue
  const newOverrides = new Array<
    NewTypes.Override & {
      // Ensure TS enforces that we remove the placeholder value
      placeholder?: never;
    }
  >();

  /**
   * MIGRATION STEP
   *
   * Loop over all font overrides and create a new font asset for each of them.
   *
   * The font asset will be constructed with an id of the form 'fontFamily.fontWeight.fontStyle' for legacyId BFS fonts and
   * '${uuid}' for non-legacyId BFS fonts. The font asset will be (in order of availability) either: the associated
   * `fontOverride--` configuration value (formatted as a BFS font leagcy or otherwise) or the override's placeholder value.
   *
   * The override will be migrated to have an __activeValue of the font asset's id and the 'placeholder' property will be removed.
   *
   * A map of layer uuids to font asset uuids will be created for later use.
   */
  const newFonts: Map<string, NewTypes.ProjectManifestBitmapFontAsset> = new Map();
  const layerUuidToFontAssetUuid: Map<string, string> = new Map();

  // Loop through each override, create a fontasset for the override's value in the
  // configuration, and then add an entry to the layerUuidToFontAssetUuid mapping
  for (const override of massagedInputVideoDescriptor.templateManifest.overrides ?? []) {
    if (override.type === 'font') {
      const overrideUuid = override.id;
      const configurationValue = massagedInputVideoDescriptor.__activeConfiguration[
        `fontOverride--${override.id}`
      ] as OldTypes.FontOverrideConfigurationValue | undefined;

      let fontAsset: NewTypes.ProjectManifestBitmapFontAsset;
      if (configurationValue === undefined) {
        const fontAssetId = generateFontAssetLegacyId({
          fontFamily: override.placeholder.fontFamily,
          fontWeight: override.placeholder.fontWeight,
          isItalic: override.placeholder.fontStyle !== 'normal',
        });
        fontAsset = {
          id: fontAssetId,
          type: 'bitmapFont',
          location: {
            plugin: 'bitmap-font-service',
            legacyId: override.placeholder.fontFamily,
            weight: override.placeholder.fontWeight,
            isItalic: override.placeholder.fontStyle !== 'normal',
          },
        };
      } else if (isBfsTypographyConfiguration(configurationValue)) {
        fontAsset = {
          id: configurationValue.fontVariantUUID,
          type: 'bitmapFont',
          location: {
            plugin: 'bitmap-font-service',
            id: configurationValue.fontVariantUUID,
          },
        };
      } else {
        const fontAssetId = generateFontAssetLegacyId({
          fontFamily: configurationValue.fontFamily,
          fontWeight: configurationValue.fontWeight,
          isItalic: configurationValue.fontStyle !== 'normal',
        });
        fontAsset = {
          id: fontAssetId,
          type: 'bitmapFont',
          location: {
            plugin: 'bitmap-font-service',
            legacyId: configurationValue.fontFamily,
            weight: configurationValue.fontWeight,
            isItalic: configurationValue.fontStyle !== 'normal',
          },
        };
      }

      newFonts.set(fontAsset.id, fontAsset);

      newOverrides.push({
        ...omit(override, 'placeholder'),
        __activeValue: fontAsset.id,
      });

      if (massagedInputVideoDescriptor.templateManifest.layersExtendedAttributes) {
        for (const [layerUuid, extendedAttributes] of Object.entries(
          massagedInputVideoDescriptor.templateManifest.layersExtendedAttributes,
        )) {
          if (extendedAttributes.font?.override === overrideUuid) {
            layerUuidToFontAssetUuid.set(layerUuid, fontAsset.id);
          }
        }
      }
    }
  }

  /**
   * MIGRATION STEP
   *
   * Every layer will be given a uuid if it doesn't already have one.
   *
   * A mapping of asset ids to layer uuuids will be created for later use when pruning unused assets.
   */
  for (const { layer } of forEachLayer(massagedInputVideoDescriptor)) {
    // Add uuids where they're missing
    if (layer.meta?.uuid === undefined) {
      layer.meta = {
        uuid: generateUuid(),
        ...layer.meta,
      };
    }
    const layerUuid = layer.meta.uuid;

    // Keep a layer mapping by uuid
    layerUuidMapping[layerUuid] = layer;

    // Keep a log of the refId for each layer
    if ('refId' in layer && layer.refId !== null && layer.refId !== undefined) {
      if (!(layer.refId in assetToLayerMapping)) {
        assetToLayerMapping[layer.refId] = [layerUuid];
      } else {
        assetToLayerMapping[layer.refId].push(layerUuid);
      }
    }
  }

  /**
   * MIGRATION STEP
   *
   * Remove all font assets (because they're handled separately) and remove image/video/audio assets that are not
   * referenced by any layers.
   */
  for (
    let index = 0;
    index < massagedInputVideoDescriptor.projectManifest.assets.length;
    index += 1
  ) {
    const asset = massagedInputVideoDescriptor.projectManifest.assets[index];

    const isUnusedMediaAsset = isMediaAsset(asset) && !(asset.id in assetToLayerMapping);
    const isFontAsset = isProjectManifestBitmapFontAsset(asset);

    // All fonts are removed (because they're being handled elsewhere) and all unused image/video/audio assets
    // are removed.
    if (isUnusedMediaAsset || isFontAsset) {
      // Remove the asset from the project manifest if it's not referenced by any layers
      massagedInputVideoDescriptor.projectManifest.assets.splice(index, 1);
      // Decrement the index so we don't skip the next asset
      index -= 1;
      continue;
    }
    if (asset.id) {
      refIdMapping[asset.id] = asset;
    }
  }

  const removedLayerUuids: string[] = [];

  /**
   * Perform all layer operations for layers within a given operation.
   *
   * @param layers
   * @returns New layers
   */
  const processCompositionLayers = (layers: OldTypes.Layer[]): NewTypes.Layer[] => {
    const newLayers: NewTypes.Layer[] = [];
    for (const layer of layers) {
      // We'll be re-adding audio layers at the end of this migration
      if (OldTypes.LayerType.Audio === layer.ty || OldTypes.LayerType.WaymarkAudio === layer.ty) {
        if (layer.meta?.uuid !== undefined) {
          removedLayerUuids.push(layer.meta.uuid);
        }
        continue;
      }

      // This is a type guard just to help TypeScript understand that we have a meta object
      if (!hasMetaWithUuid(layer)) {
        throw new Error(
          'Layer is missing a uuid. It should have been ensured at this point. Migration cannot proceed.',
        );
      }

      // Transfer h and w if needed
      let layerImageAssetDimensions: { w: number; h: number } | null = null;
      if (layer.ty === OldTypes.LayerType.Image) {
        if ('refId' in layer && layer.refId !== null && layer.refId !== undefined) {
          const refAsset = refIdMapping[layer.refId];

          if (refAsset === undefined) {
            throw new Error(
              'Layer references an asset that does not exist. Migration cannot proceed.',
            );
          }

          if (
            !(
              'w' in refAsset &&
              'h' in refAsset &&
              refAsset.w !== undefined &&
              refAsset.h !== undefined
            )
          ) {
            throw new Error(
              'Image layer references an asset that does not have width and height. Migration cannot proceed.',
            );
          }

          layerImageAssetDimensions = {
            w: refAsset.w,
            h: refAsset.h,
          };
        }
      } else if (layer.ty === OldTypes.LayerType.Text) {
        let refId: string | null = null;
        const potentialRefId = layerUuidToFontAssetUuid.get(layer.meta.uuid);
        if (potentialRefId !== undefined) {
          refId = potentialRefId;
        } else {
          let newFontAsset: NewTypes.ProjectManifestBitmapFontAsset | null = null;
          // Find the referenced font in the keyframe and create a new font asset from it
          for (const keyframe of layer.t.d.k) {
            if (newFontAsset === null) {
              let foundRendererFont: OldTypes.WaymarkWebfont | null = null;
              const easyLookup =
                massagedInputVideoDescriptor.projectManifest.fonts?.rendererFonts[keyframe.s.f];
              if (easyLookup) {
                foundRendererFont = easyLookup;
              } else {
                const deepLookup = Object.values(
                  massagedInputVideoDescriptor.projectManifest.fonts?.rendererFonts ?? {},
                ).find((font) => {
                  // We need to check if keyframe.s.f can be split as a string delimited by three periods
                  // If it can, we'll parse the fontFamily, fontWeight, and fontStyle from it
                  const [fontFamily, fontWeight, fontStyle] = keyframe.s.f.split('.');
                  return (
                    fontFamily === font.family &&
                    fontWeight === String(font.weight) &&
                    fontStyle === (font.style !== 'normal' ? 'italic' : 'normal')
                  );
                });

                foundRendererFont = deepLookup ?? null;
              }

              if (foundRendererFont) {
                let fontWeight;
                if (foundRendererFont.weight === 'normal') {
                  fontWeight = 400;
                } else if (foundRendererFont.weight === 'bold') {
                  fontWeight = 700;
                } else if (typeof foundRendererFont.weight === 'number') {
                  fontWeight = foundRendererFont.weight;
                } else {
                  throw new Error(
                    `Unexpected font weight: ${foundRendererFont.weight}. Migration cannot proceed.`,
                  );
                }
                newFontAsset = {
                  id: generateFontAssetLegacyId({
                    fontFamily: foundRendererFont.family,
                    fontWeight: fontWeight,
                    isItalic: foundRendererFont.style !== 'normal',
                  }),
                  type: 'bitmapFont',
                  location: {
                    plugin: 'bitmap-font-service',
                    legacyId: foundRendererFont.family,
                    weight: fontWeight,
                    isItalic: foundRendererFont.style !== 'normal',
                  },
                };
                newFonts.set(newFontAsset.id, newFontAsset);
                refId = newFontAsset.id;
                break;
              }
            }
          }
        }
        // If we don't have a refId, we can't proceed (or we have to change this code to specify a default font)
        if (refId === null) {
          throw new Error(
            `Unable to find/create a font for the give text layer. Migration cannot proceed. ${JSON.stringify(
              layer,
            )}`,
          );
        }

        // Now that we *finally* have a refId, we can set it on the layer and on the keyframes
        layer.refId = refId;
        for (const keyframe of layer.t.d.k) {
          keyframe.s.f = refId;
        }
      }

      const { __importantChanges: importantChangeList, ...layerMetaWithoutImportantChanges } =
        layer.meta;

      // We can infer initial ignoredReferences values from legacy __importantChanges values
      let ignoredReferences: NewTypes.Layer['ignoredReferences'] | null = null;

      for (const importantChange of importantChangeList ?? []) {
        // Ensure we have an ignoredReferences object
        ignoredReferences ??= {};

        switch (importantChange) {
          case 'TEXT_FILL_COLOR.color':
            ignoredReferences.fillColor = true;
            break;
          case 'TEXT_STROKE_COLOR.color':
            ignoredReferences.strokeColor = true;
            break;
          case 'EFFECT_FILL_COLOR.color':
            ignoredReferences.fillEffect = true;
            break;
        }
      }

      const newLayerMeta: NewTypes.Layer['meta'] = layerMetaWithoutImportantChanges;

      let newLayer: NewTypes.Layer;
      // Remove modifications from waymark video layers
      if (layer.ty === OldTypes.LayerType.WaymarkVideo) {
        newLayer = {
          ...omit(layer, 'modifications'),
          ...layerImageAssetDimensions,
          meta: newLayerMeta,
        };
      } else {
        newLayer = {
          ...layer,
          ...layerImageAssetDimensions,
          meta: newLayerMeta,
        };
      }

      if (ignoredReferences) {
        newLayer.ignoredReferences = ignoredReferences;
      }

      newLayers.push(newLayer);
    }

    return newLayers;
  };

  /**
   * MIGRATION STEP
   *
   * For everything except font assets, a new assets array will be populated with:
   * - Only used assets
   * - Image/footage assets without 'h' and 'w' properties
   * - Compositions will get their layers processed (read more of the description of this in the next migration step)
   * - Old AfterEffects assets will be converted to new ProjectManifestMediaAssets
   */
  const newAssets: NewAsset[] = [];
  for (const asset of massagedInputVideoDescriptor.projectManifest.assets) {
    if (isPrecompSourceAsset(asset)) {
      const newLayers = processCompositionLayers(asset.layers);
      newAssets.push({
        ...asset,
        layers: newLayers,
      } satisfies NewTypes.PreCompSource);
      // Handle ProjectManifestImageAsset, ProjectManifestVideoAsset, FlattenedFootageAsset, and ProjectManifestAudioAsset
    } else if (isProjectManifestFlattenedFootageAsset(asset)) {
      newAssets.push({
        ...omit(asset, 'h', 'w', 'modifications', 'content'),
        location: generateNewVideoLocation(asset),
      });
    } else if (isProjectManifestAudioAsset(asset)) {
      // newAssets.push(omit(asset, 'h', 'w'));
    } else if (isProjectManifestImageAsset(asset)) {
      newAssets.push(omit(asset, 'h', 'w'));
    } else if (isProjectManifestVideoAsset(asset)) {
      newAssets.push({
        ...omit(asset, 'h', 'w', 'modifications', 'content'),
        location: generateNewVideoLocation(asset),
      });
    } else if (
      isAfterEffectsAsset(asset) ||
      isFlattenedFootageAfterEffectsAsset(asset) ||
      isAfterEffectsFootageAsset(asset)
    ) {
      const newAsset = convertAfterEffectsAsset(asset);
      // We're handling audio separately
      if (newAsset.type !== 'audio') {
        newAssets.push(newAsset);
      }
    } else if (isStudioAudioAsset(asset)) {
      const newAsset: NewTypes.ProjectManifestAudioAsset = omit(asset, 'name');
      // TODO: @migrationtodo Does this need to work?
      // newAssets.push(newAsset);
    } else if (isMalformStudioAudioAsset(asset)) {
      // TODO: @migrationtodo Does this need to work?
      // newAssets.push(convertAfterEffectsAsset(asset));
    } else if (isProjectManifestBitmapFontAsset(asset)) {
      // Skipping purposefully. We're handling fonts separately.
    } else {
      throw new Error(`Unknown asset type. Migration cannot proceed. ${JSON.stringify(asset)}`);
    }
  }

  /**
   * MIGRATION STEP
   *
   * We've now created a preliminary list of fonts, pruned unused assets, and made sure that all layers have a uuid.
   *
   * So, we're now going to further process every layer in the project manifest.
   *
   * - Image and footage layers will have their bounding dimensions transfered from the asset to the layer
   * - Non-editable text layers will have fonts created for them and refIds associated
   * - Editable text layers will have their newly-recreated font assets associated with them via refId
   * - 'ignoredReferences' will be created for any layers that previously had overridden any properties associated with an override (like fill color)
   */

  // Handle main composition
  const newMainCompositionLayers = processCompositionLayers(
    massagedInputVideoDescriptor.projectManifest.layers,
  );

  /**
   * MIGRATION STEP
   *
   * The previously-created audio layers will be added to the end of the main composition.
   */
  if (backgroundAudio !== null) {
    newAssets.push(backgroundAudio.asset);
    newMainCompositionLayers.push(backgroundAudio.layer);
  }

  if (auxiliaryAudio !== null) {
    newAssets.push(auxiliaryAudio.asset);
    newMainCompositionLayers.push(auxiliaryAudio.layer);
  }

  /**
   * MIGRATION STEP
   *
   * We will now process the template manifest.
   *
   * - All content overrides will be removed and migrated to layer changes
   * - All remaining overrides will be given an __activeValue (replacing configuration values like `fontOverride--` and `colorOverride--`)
   * - Scene switches will be given an __activeValue (replacing configuration values like `sceneSwitch--`)
   * - A displayname will be defined (sometimes null) for each color override
   * - backgroundAudio will be removed from the template manifest
   * - "options" for video layer placeholders will be removed
   */

  const newSceneSwitches = new Array<NewTypes.SceneSwitch>();

  // Ensure all scene switches get an __activeValue added
  for (const sceneSwitch of massagedInputVideoDescriptor.templateManifest.sceneSwitches ?? []) {
    const selectedGroupUUID =
      (massagedInputVideoDescriptor.__activeConfiguration[`sceneSwitch--${sceneSwitch.id}`] as
        | OldTypes.LayoutSelectorFieldConfigurationValue
        | undefined) ?? sceneSwitch.defaultSelection;
    newSceneSwitches.push({
      ...sceneSwitch,
      __activeValue: selectedGroupUUID,
    });
  }

  for (const sceneGroups of massagedInputVideoDescriptor.templateManifest.sceneGroups ?? []) {
    sceneGroups.layers = sceneGroups.layers.filter(
      (layerUuid) => !removedLayerUuids.includes(layerUuid),
    );
  }

  for (const override of massagedInputVideoDescriptor.templateManifest.overrides ?? []) {
    switch (override.type) {
      case 'color':
        const selectedColor =
          (massagedInputVideoDescriptor.__activeConfiguration[`colorOverride--${override.id}`] as
            | OldTypes.ColorOverrideConfigurationValue
            | undefined) ?? override.placeholder;
        newOverrides.push({
          ...omit(override, 'placeholder'),
          __activeValue: selectedColor,
          displayName: override.displayName ?? null,
        });
        break;
      case 'image':
        // There's a chance that the image override was setup but not connected to
        // a layer. If that's the case, we can skip it.
        if (massagedInputVideoDescriptor.templateManifest.layersExtendedAttributes) {
          const layerUuid = findTopLevelParentWithOverride(
            massagedInputVideoDescriptor.templateManifest.layersExtendedAttributes,
            override.id,
          );
          if (!layerUuid) {
            continue;
          }
        }

        // We can cast this as NestedContentImageConfigurationValue because `getSanitizedConfiguration` is run at the beginning of the migration.
        const imageConfigurationValue = massagedInputVideoDescriptor.__activeConfiguration[
          `imageOverride--${override.id}`
        ] as OldTypes.NestedContentImageConfigurationValue | undefined;

        // Try to find an asset that has the same location as the image override
        let selectedImageAssetID: string | null = null;
        if (imageConfigurationValue !== undefined) {
          const location = imageConfigurationValue.content.location;
          const foundAsset = newAssets.find((asset) => {
            return 'location' in asset && isEqual(asset.location, location);
          });
          if (foundAsset) {
            selectedImageAssetID = foundAsset.id;
          }
        }

        if (selectedImageAssetID === null) {
          console.log(newAssets);
          throw new Error('Could not find an asset for a given image override!');
        }

        newOverrides.push({
          ...omit(override, 'placeholder'),
          __activeValue: selectedImageAssetID,
        });
        break;
      case 'text':
        const selectedText = massagedInputVideoDescriptor.__activeConfiguration[
          `textOverride--${override.id}`
        ] as OldTypes.TextOverrideConfigurationValue | undefined;

        if (selectedText === undefined) {
          throw new Error(`Unable to find initial active value for text override ${override.id}`);
        }
        newOverrides.push({
          ...omit(override, 'placeholder'),
          __activeValue: selectedText,
        });
        break;
    }
  }

  // Convert all extended attributes that reference image or text overrides to
  // merely content editable. The frameNumber from the override will be used.
  // 'placeholder' will be removed from all content-editable fields.
  const newAttributes: NewTypes.TemplateManifest['layersExtendedAttributes'] = {};
  for (const layerUuid in massagedInputVideoDescriptor.templateManifest.layersExtendedAttributes) {
    const oldAttributesForLayer =
      massagedInputVideoDescriptor.templateManifest.layersExtendedAttributes[layerUuid];

    const oldContent = oldAttributesForLayer.content;
    let newContent;
    if (oldContent === undefined) {
      newAttributes[layerUuid] = omit(oldAttributesForLayer, 'content');
      continue;
    }

    if (oldContent === null) {
      newAttributes[layerUuid] = {
        ...oldAttributesForLayer,
        content: null,
      };
      continue;
    }

    if ('override' in oldContent) {
      newContent = oldContent satisfies NewTypes.LayerExtendedAttributes['content'];
    } else {
      newContent = omit(
        oldContent,
        'placeholder',
        'options',
      ) satisfies NewTypes.LayerExtendedAttributes['content'];
    }
    newAttributes[layerUuid] = {
      ...oldAttributesForLayer,
      content: newContent,
    };
  }

  const newTemplateManifest: NewTypes.TemplateManifest = {
    ...omit(massagedInputVideoDescriptor.templateManifest, 'backgroundAudio'),
    layersExtendedAttributes: newAttributes,
    sceneSwitches: newSceneSwitches,
    overrides: newOverrides,
  };

  // Add new fonts to the new assets array
  newAssets.push(...newFonts.values());

  // @migrationtodo: We should do a final check to see if there are any assets that are not referenced by any layers

  // NOTE: This inherently deletes:
  // - __activeConfiguration
  // - __cachedEditingForm
  // - __cachedEditingActions

  const newVideoDescriptor: NewVideoDescriptor = {
    __templateSlug: massagedInputVideoDescriptor.__templateSlug,
    version: '1.0.0',
    projectManifest: {
      ...omit(massagedInputVideoDescriptor.projectManifest, 'fonts'),
      layers: newMainCompositionLayers,
      assets: newAssets,
    },
    templateManifest: newTemplateManifest,
  };

  return newVideoDescriptor;
};

const migration: Migration<VideoDescriptor_v0_9_0, VideoDescriptor_v1_0_0> = {
  inputVersion: '0.9.0',
  targetVersion: '1.0.0',
  description: 'Migrates from 0.9.0 to 1.0.0. See migration file for details.',
  name: 'v1_0_0_migration',
  implementation,
};

export default migration;
