import * as utils from './utils';
import { v0_9_0 } from '@libs/waymark-video/video-descriptor-types';
import { uuid } from '../utils';
import { isObject, set } from 'lodash';

export class BaseLayerChangeOperation<TPayload extends v0_9_0.BaseLayerChangeOperationPayload> {
  layerData: v0_9_0.Layer;
  payload: TPayload;
  projectManifest: v0_9_0.ProjectManifest;

  constructor(projectManifest: v0_9_0.ProjectManifest, payload: TPayload) {
    this.payload = payload;
    this.projectManifest = projectManifest;

    const layerData = utils.findLayer(projectManifest, (layer) =>
      layer.meta !== undefined && 'uuid' in layer.meta ? layer.meta.uuid === payload.layer : false,
    );
    if (layerData === undefined) {
      throw new Error(`Layer ${payload.layer} does not exist`);
    }
    this.layerData = layerData;
  }

  /**
   * Get a unique string type identifier for the change operation.
   *
   * @abstract
   * @returns  {string}  Type identifier
   */
  static get type(): string {
    throw new Error('BaseChangeOperation type getter override required');
  }

  /**
   * Update the template manifest for the change operation payload.
   *
   * @abstract
   */
  /* eslint-disable-next-line class-methods-use-this */
  updateManifest = async (): Promise<void> => {
    throw new Error('BaseChangeOperation.updateManifest(...) method override required');
  };
}

// Local
// import { findLayerData } from '../manifest/index.js';
// import BaseChangeOperation from './BaseChangeOperation.js';

/**
 * DISPLAY_OBJECT_VISIBILITY change operation
 *
 * Changes if the layer is shown or hidden
 *
 * @param {object} renderer The renderer the change operation is for
 * @param {object} payload The payload of the change operation
 * @param {string} payload.layer The uuid of the layer to be changed
 * @param {boolean} payload.isVisible If the layer should be made visible or not
 *
 * @memberof ChangeOperations
 * @public
 */
export class DisplayObjectVisibilityChangeOperation extends BaseLayerChangeOperation<v0_9_0.DisplayObjectVisibilityChangeOperationPayload> {
  static get type() {
    return 'DISPLAY_OBJECT_VISIBILITY';
  }

  updateManifest = async () => {
    this.layerData.hd = !this.payload.isVisible;
  };
}

/**
 * EFFECT_FILL_COLOR change operation
 *
 * @memberof ChangeOperations
 *
 * Changing the color of a effect, a color overlay on a layer.
 *
 * @param {object} renderer The renderer the change operation is for
 * @param {object} payload The payload of the change operation
 * @param {string} payload.layer The uuid of the layer to be changed
 * @param {boolean} payload.color The Fill color (as a hex string)
 *
 * @memberof ChangeOperations
 * @public
 */
export class EffectFillColorChangeOperation extends BaseLayerChangeOperation<v0_9_0.EffectFillColorChangeOperationPayload> {
  static get type() {
    return 'EFFECT_FILL_COLOR';
  }

  updateManifest = async () => {
    if (this.layerData.meta === undefined) {
      this.layerData.meta = {};
    }
    const importantChanges =
      '__importantChanges' in this.layerData.meta
        ? this.layerData.meta.__importantChanges || []
        : [];

    if (this.payload.isImportant && !importantChanges.includes('EFFECT_FILL_COLOR.color')) {
      importantChanges.push('EFFECT_FILL_COLOR.color');
      (this.layerData.meta as v0_9_0.LayerMeta).__importantChanges = importantChanges;
    }

    if (this.layerData.ef === undefined) {
      throw new Error("Layer doesn't have effects");
    }

    if (this.payload.isImportant || !importantChanges.includes('EFFECT_FILL_COLOR.color')) {
      const colorArray = utils.hexToColorArray(utils.forceHexNumber(this.payload.color));

      // Modify effects property
      this.layerData.ef.forEach((effect) => {
        if (effect.ty === 21) {
          effect.ef.forEach((prop) => {
            if (prop.nm === 'Color') {
              let colorValue: number[];
              if (prop.v.a) {
                const firstKeyframe = prop.v.k[0] as v0_9_0.MultiDimensionalKeyframe;
                colorValue = firstKeyframe.s;
              } else {
                colorValue = prop.v.k as number[];
              }
              // NOTE: the `colorValue` array may have more than 3 elements, so anything after
              // the first 3 that we're updating here will be left in place. This can result in semi-malformed
              // or at least unexpected data, but it's been working this way for a while so we will keep it
              // this way for now.
              colorValue[0] = colorArray[0];
              colorValue[1] = colorArray[1];
              colorValue[2] = colorArray[2];
            }
          });
        }
      });
    }
  };
}

const KEYFRAME_DOTPATHS = {
  fontSize: 's.s',
  fontFamily: 's.f',
  lineHeight: 's.lh',
};

/**
 * FONT_PROPERTY change operation. Handles modifying font related properties for a given
 * text layer. Valid font properties include: `fontFamily`, `fontWeight`, `fontStyle`, and
 * `fontSizeAdjustment`.
 *
 * Example of valid font family change payload:
 * ```
 *  {
 *    layer: '5b6ca0e7-3739-413e-978a-9a1b115795a6',
 *    fontFamily: 'Roboto',
 *    fontWeight: '300',
 *    fontStyle: 'italic',
 *    fontSizeAdjustment: 0.1,
 *    webfontloaderConfiguration: {
 *      google: {
 *        families: ['Roboto:300italic'],
 *      },
 *    },
 *  }
 * ```
 *
 * @param {object} renderer The renderer the change operation is for
 * @param {object} payload The payload of the change operation
 * @param {string} payload.layer The uuid of the layer to be changed
 * @param {string} payload.fontFamily  The font family ex: 'Roboto'
 * @param {string} payload.fontWeight The weight or boldness of type ex: '300'
 * @param {string} payload.fontStyle  The font style. One of ['normal', 'italic', 'oblique]
 * @param {number} payload.fontSize
 * @param {number} payload.lineHeight
 * @param {string} payload.resizingStrategy?
 * @param {object} payload.webfontloaderConfiguration A configuration to pass to webfontloader that maps to a particular font
 *
 * @memberof ChangeOperations
 * @public
 */
export class FontPropertyChangeOperation extends BaseLayerChangeOperation<v0_9_0.FontPropertyChangeOperationPayload> {
  static get type() {
    return 'FONT_PROPERTY';
  }

  updateManifest = async () => {
    if (this.layerData.ty !== v0_9_0.LayerType.Text) {
      throw new Error('Font properties can only be changed on text layers');
    }

    // If we have a font change, ensure the font is loaded.
    const { fontVariantUUID, lineHeight, fontSize, fontWeight, fontStyle, resizingStrategy } =
      this.payload;
    const { fontFamily } = this.payload;

    let fontId: string;
    if (fontFamily) {
      fontId = utils.formatFontAssetId({
        family: fontFamily,
        weight: fontWeight,
        style: fontStyle,
      });

      let location: v0_9_0.ProjectManifestBitmapFontAsset['location'];
      if (fontVariantUUID) {
        location = { id: fontVariantUUID, plugin: 'bitmap-font-service' };
      } else {
        location = { legacyId: fontFamily, plugin: 'bitmap-font-service' };
      }

      // Create a new asset to load
      const newAsset: v0_9_0.ProjectManifestBitmapFontAsset = {
        id: fontId,
        type: v0_9_0.AssetType.BitmapFont,
        isItalic: fontStyle === 'italic',
        weight: fontWeight,
        location,
      };

      this.projectManifest.assets.push(newAsset);
      this.layerData.refId = newAsset.id;
    }

    const textPropertyKeyframes = this.layerData.t.d.k;

    if (resizingStrategy) {
      set(this.layerData, 'meta.textOptions.resizingStrategy', resizingStrategy);
    }

    textPropertyKeyframes.forEach((textPropertyKeyframe) => {
      if (
        isObject(textPropertyKeyframe) &&
        textPropertyKeyframe.s &&
        typeof textPropertyKeyframe.s.f !== 'undefined'
      ) {
        if (fontId) {
          set(textPropertyKeyframe, KEYFRAME_DOTPATHS.fontFamily, fontId);
        }
        if (fontSize) {
          set(textPropertyKeyframe, KEYFRAME_DOTPATHS.fontSize, fontSize);
        }
        if (lineHeight) {
          set(textPropertyKeyframe, KEYFRAME_DOTPATHS.lineHeight, lineHeight);
        }
      }
    });
  };
}

/**
 * IMAGE_PATH change operation
 *
 * @memberof ChangeOperations
 * @public
 * @deprecated In favor of ChangeOperations.LayerImageChangeOperation
 */
export class ImagePathChangeOperation extends BaseLayerChangeOperation<v0_9_0.ImagePathChangeOperationPayload> {
  static get type() {
    return 'IMAGE_PATH';
  }

  updateManifest = async () => {
    if (this.layerData.ty !== v0_9_0.LayerType.Image) {
      throw new Error('Image path can only be changed on image layers');
    }

    for (const asset of this.projectManifest.assets) {
      if (asset.id === this.layerData.refId && 'p' in asset) {
        asset.p = this.payload.path;
      }
    }
  };
}

/**
 * The LAYER_AUDIO change operation for altering properties on an audio layer
 *
 * Example payload
 * ```
 * {
 *   layer: '[UUID]|#[myIDName]',
 *   isMuted: true,
 *   volume: .8,
 *   content: {type, key, location},
 *   contentTrimStartTime: 3.33,
 *   contentTrimDuration: 10.5,
 *   contentPlaybackDuration: 250
 *   volumeChanges: [{
 *       type: 'targetDucking'
 *       duckingLayer: '[UUID]',
 *       targetVolume: .3
 *   }]
 *   options: {
 *     shouldAdd: true
 *     shouldDelete: false
 *   }
 *  }
 * ```
 *
 * @param {object} renderer The renderer the change operation is for
 * @param {object} payload The payload of the change operation
 * @param {string} payload.layer The uuid of the layer to be changed
 * @param {boolean} payload.isMuted If the audio overall is muted
 * @param {number} payload.volume The overall (Master) volume for an audio layer from 0 -> 1.0
 * @param {object} payload.content The reference to the asset used for this layer ex: `{type, key, location}`
 * @param {duckingVolumeChange[]} payload.volumeChanges An array of changes to the volume (used for ducking, fade outs, etc)
 * @param {object} options Additional options for the change operations
 * @param {boolean} options.shouldAdd If the a layer should be added instead of updating a layer with the given uuid/id
 * @param {boolean} options.shouldDelete If the a layer should be deleted instead of updating a layer with the given uuid/id
 *
 * @memberof ChangeOperations
 * @public
 */
export class LayerAudioChangeOperation extends BaseLayerChangeOperation<v0_9_0.LayerAudioChangeOperationPayload> {
  static get type() {
    return 'LAYER_AUDIO';
  }

  updateManifest = async () => {
    // NOTE: This is purposefully a no-op. The migration will handle the migration of `backgroundAudio` and `auxiliaryAudio`
    // itself and won't rely on any of the change operations. This is primarily becaus (a) it's easier and (b) all of the needed
    // information is stored in the configuration and not on the layer.
  };
}

/**
 *  The LAYER_IMAGE change operation for altering properties on an image layer
 *
 * Example payload:
 * ```
 * {
 *   layer: '[UUID]',
 *   content: {type, key, location},
 *   contentPlaybackDuration: 250,
 *   contentBackgroundFill: "#FFCCAA",
 *   contentCropping: {
 *      x: .15,
 *      y: .25,
 *      width: .8,
 *      height: .5
 *   },
 *   contentPadding: 20,
 *   contentFit: 'fill',
 *   contentZoom: {
 *      x: .5,
 *      y: .5,
 *      z: 2.0
 *   },
 *   contentFitFillAlignment: 'CC',
 * }
 * ```
 *
 * @param {object} renderer The renderer the change operation is for
 * @param {object} payload The payload of the change operation
 * @param {string} payload.layer The uuid of the layer to be changed
 * @param {object} payload.content The reference to the asset used for this layer ex: `{type, key, location}`
 * @param {string} payload.contentBackgroundFill The content's background fill color
 * @param {object} payload.contentCropping The content's cropping object
 * @param {number} payload.contentCropping.x The cropping x position
 * @param {number} payload.contentCropping.y The cropping y position
 * @param {number} payload.contentCropping.width The cropping width
 * @param {number} payload.contentCropping.height The cropping height
 * @param {number} payload.contentPadding The content's padding value
 * @param {string} payload.contentFit The content's fit type
 * @param {object} payload.contentZoom The content's zoom object
 * @param {number} payload.contentZoom.x The content's zoom focus point on the texture. A unitless number representing the proportion of the width (0.0 -> 1.0)
 * @param {number} payload.contentZoom.y The content's zoom focus point on the texture. A unitless number representing the proportion of the hight (0.0 -> 1.0)
 * @param {number} payload.contentZoom.z The content's zoom. A number repressenting to amount to zoom in (ex: 1.0 is no zoom, 2.0 is 2x zoom)
 * @param {string} payload.contentFitFillAlignment The texture alignment (Defaults to Center, Center) ex `CC`
 * @memberof ChangeOperations
 * @public
 */
export class LayerImageChangeOperation extends BaseLayerChangeOperation<v0_9_0.LayerImageChangeOperationPayload> {
  static get type() {
    return 'LAYER_IMAGE';
  }

  updateManifest = async () => {
    if (this.layerData.ty !== v0_9_0.LayerType.Image) {
      throw new Error('Image properties can only be changed on image layers');
    }

    const content = {
      ...this.payload.content,
    };

    if (this.payload.content) {
      if (this.payload.w === undefined || this.payload.h === undefined) {
        // Get the height and width from the existing asset
        const existingAsset = this.projectManifest.assets.find(
          ({ id }) => id === (this.layerData as v0_9_0.ImageLayer).refId,
        );

        if (existingAsset === undefined) {
          throw new Error('Cannot find existing asset for layer');
        }

        if (
          ('h' in existingAsset && existingAsset.h === undefined) ||
          ('h' in existingAsset && existingAsset.h === undefined)
        ) {
          throw new Error('Cannot find dimensions for existing asset');
        }

        this.payload.content.w = 'w' in existingAsset ? existingAsset.w : undefined;
        this.payload.content.h = 'h' in existingAsset ? existingAsset.h : undefined;
      }

      this.payload.content.id = `layer_image_change_operation_${uuid()}`;
    }

    const newAsset = utils.updateLayerContentProperty(this.layerData, this.projectManifest.assets, {
      content: this.payload.content,
    });

    const contentAdjustments = (this.payload.content as any).modifications?.adjustments as
      | undefined
      | v0_9_0.ImageLayer['contentAdjustments'];

    // Clean "#" from any colors within content adjustments and then apply them to the layer
    if (contentAdjustments) {
      if (contentAdjustments.duotone) {
        contentAdjustments.duotone = contentAdjustments.duotone.map((color) =>
          color.replace('#', ''),
        ) as [string, string];
      }

      if (contentAdjustments.monochrome) {
        contentAdjustments.monochrome = contentAdjustments.monochrome.replace('#', '');
      }
      this.layerData.contentAdjustments = contentAdjustments;
    }

    utils.updateTextureLayerProperties(this.layerData, newAsset, this.payload);
  };
}

/**
 * The LAYER_VIDEO change operation for altering properties on a video layer
 *
 * Example payload:
 * ```
 * {
 *   layer: '[UUID]',
 *   isMuted: true,
 *   volume: .8,
 *   content: {type, key, location},
 *   contentTrimStartTime: 3.33,
 *   contentTrimDuration: 10.5,
 *   contentPlaybackDuration: 250,
 *   contentBackgroundFill: "#FFCCAA",
 *   contentCropping: {
 *      x: .15,
 *      y: .25,
 *      width: .8,
 *      height: .5
 *   },
 *   contentPadding: 20,
 *   contentFit: 'fill',
 *   contentZoom: {
 *      x: .5,
 *      y: .5,
 *      z: 2.0
 *   },
 *   contentFitFillAlignment: 'CC',
 *   volumeChanges: [{
 *       type: 'targetDucking'
 *       duckingLayer: '[UUID]',
 *       targetVolume: .3
 *   }]
 *  }
 * ```
 *
 * @param {object} renderer The renderer the change operation is for
 * @param {object} payload The payload of the change operation
 * @param {string} payload.layer The uuid of the layer to be changed
 * @param {number} payload.volume The overall (Master) volume for an audio layer from 0 -> 1.0
 * @param {boolean} payload.isMuted If the audio overall is muted
 * @param {object} payload.content The reference to the asset used for this layer ex: `{type, key, location}`
 * @param {duckingVolumeChange[]} payload.volumeChanges An array of changes to the volume (used for ducking, fade outs, etc)
 * @param {number} payload.contentTrimStartTime The frame the video should start at (in its own asset time) ex: `3.33`
 * @param {number} payload.contentTrimDuration The duration of the video (in its own asset time) ex: `10.5`
 * @param {number} payload.contentPlaybackDuration Changes the playback rate of the video inside the layer (how long in the layer timeline it should take). Ex: `250` Longer times than duration will slow the playback rate, shorter times will speed up the playback rate.
 * @param {string} payload.contentBackgroundFill The content's background fill color
 * @param {object} payload.contentCropping The content's cropping object
 * @param {number} payload.contentCropping.x The cropping x position
 * @param {number} payload.contentCropping.y The cropping y position
 * @param {number} payload.contentCropping.width The cropping width
 * @param {number} payload.contentCropping.height The cropping height
 * @param {number} payload.contentPadding The content's padding value
 * @param {string} payload.contentFit The content's fit type
 * @param {object} payload.contentZoom The content's zoom object
 * @param {number} payload.contentZoom.x The content's zoom focus point on the texture. A unitless number representing the proportion of the width (0.0 -> 1.0)
 * @param {number} payload.contentZoom.y The content's zoom focus point on the texture. A unitless number representing the proportion of the hight (0.0 -> 1.0)
 * @param {number} payload.contentZoom.z The content's zoom. A number repressenting to amount to zoom in (ex: 1.0 is no zoom, 2.0 is 2x zoom)
 * @param {string} payload.contentFitFillAlignment The texture alignment (Defaults to Center, Center) ex `CC`
 *
 * @memberof ChangeOperations
 * @public
 */
export class LayerVideoChangeOperation extends BaseLayerChangeOperation<v0_9_0.LayerVideoChangeOperationPayload> {
  static get type() {
    return 'LAYER_VIDEO';
  }

  updateManifest = async () => {
    if (this.layerData.ty !== v0_9_0.LayerType.WaymarkVideo) {
      throw new Error('Video properties can only be changed on video layers');
    }

    const newAsset = utils.updateLayerContentProperty(this.layerData, this.projectManifest.assets, {
      content: this.payload.content,
    });
    utils.updateTextureLayerProperties(this.layerData, newAsset, this.payload);
    utils.updateMediaLayerProperties(this.layerData, this.projectManifest, this.payload);
    utils.updateLayerContentTimeProperties(this.layerData, this.payload, this.projectManifest.fr);
  };
}

/**
 * SHAPE_FILL_COLOR change operation
 *
 * @memberof ChangeOperations
 * @param {object} renderer The renderer the change operation is for
 * @param {object} payload The payload of the change operation
 * @param {string} payload.layer The uuid of the layer to be changed
 * @param {boolean} payload.color The Fill color (as a hex string)
 *
 * @public
 */
export class ShapeFillColorChangeOperation extends BaseLayerChangeOperation<v0_9_0.ShapeFillColorChangeOperationPayload> {
  static get type() {
    return 'SHAPE_FILL_COLOR';
  }

  updateManifest = async () => {
    if (this.layerData.ty !== v0_9_0.LayerType.Shape) {
      throw new Error('Shape fill color can only be changed on shape layers');
    }
    const colorArray = utils.hexToColorArray(parseInt(this.payload.color, 16));
    utils.updateShapesFillColor(this.layerData.shapes, colorArray);
  };
}

/**
 * SHAPE_GRADIENT_FILL_COLOR_STEPS change operation
 *
 * @memberof ChangeOperations
 * @public
 */
export class ShapeGradientFillColorStepsChangeOperation extends BaseLayerChangeOperation<v0_9_0.ShapeGradientFillColorStepsChangeOperationPayload> {
  static get type() {
    return 'SHAPE_GRADIENT_FILL_COLOR_STEPS';
  }

  updateManifest = async () => {
    if (this.layerData.ty !== v0_9_0.LayerType.Shape) {
      throw new Error('Shape gradient fill color can only be changed on shape layers');
    }
    const colorArray = utils.hexToColorArray(utils.forceHexNumber(this.payload.color));
    utils.updateShapeGradientFillColorSteps(
      this.layerData.shapes,
      colorArray,
      this.payload.stepIndex,
    );
  };
}

/**
 * Update a manifest shapes array for a color array.
 *
 * @param {object[]} shapes The shapes to update
 * @param {number[]} colorArray An array [r,g,b] of the fill color
 */
function updateShapesStrokeColor(
  shapes: v0_9_0.ShapeLayer['shapes'] | v0_9_0.GroupShape['it'],
  colorArray: [number, number, number],
) {
  shapes.forEach((shape) => {
    switch (shape.ty) {
      case 'gr': {
        updateShapesStrokeColor(shape.it, colorArray);
        break;
      }
      case 'st': {
        const colorValue = shape.c.k;
        [colorValue[0], colorValue[1], colorValue[2]] = colorArray;
        break;
      }
      default: {
        break;
      }
    }
  });
}

/**
 * SHAPE_STROKE_COLOR change operation
 *
 * @param {object} renderer The renderer the change operation is for
 * @param {object} payload The payload of the change operation
 * @param {string} payload.layer The uuid of the layer to be changed
 * @param {boolean} payload.color The Fill color (as a hex string)
 *
 * @memberof ChangeOperations
 * @public
 */
export class ShapeStrokeColorChangeOperation extends BaseLayerChangeOperation<v0_9_0.ShapeStrokeColorChangeOperationPayload> {
  static get type() {
    return 'SHAPE_STROKE_COLOR';
  }

  updateManifest = async () => {
    if (this.layerData.ty !== v0_9_0.LayerType.Shape) {
      throw new Error("Can't change stroke color on a non-shape layer");
    }
    const colorArray = utils.hexToColorArray(utils.forceHexNumber(this.payload.color));
    updateShapesStrokeColor(this.layerData.shapes, colorArray);
  };
}

/**
 * SOLID_FILL_COLOR change operation
 *
 * @param {object} renderer The renderer the change operation is for
 * @param {object} payload The payload of the change operation
 * @param {string} payload.layer The uuid of the layer to be changed
 * @param {boolean} payload.color The Fill color (as a hex string)
 *
 * @memberof ChangeOperations
 * @public
 */
export class SolidFillColorChangeOperation extends BaseLayerChangeOperation<v0_9_0.SolidFillColorChangeOperationPayload> {
  static get type() {
    return 'SOLID_FILL_COLOR';
  }

  updateManifest = async () => {
    if (this.layerData.ty !== v0_9_0.LayerType.Solid) {
      throw new Error('Solid fill color can only be changed on solid layers');
    }
    this.layerData.sc = utils.hexToString(utils.forceHexNumber(this.payload.color));
  };
}

/**
 * TEXT_CONTENT change operation
 *
 * @param {object} renderer The renderer the change operation is for
 * @param {object} payload The payload of the change operation
 * @param {string} payload.layer The uuid of the layer to be changed
 * @param {string} payload.text The new text for the layer

 * @memberof ChangeOperations
 * @public

 */
export class TextContentChangeOperation extends BaseLayerChangeOperation<v0_9_0.TextContentChangeOperationPayload> {
  static get type() {
    return 'TEXT_CONTENT';
  }

  updateManifest = async () => {
    if (this.layerData.ty !== v0_9_0.LayerType.Text) {
      throw new Error('Text content can only be changed on text layers');
    }

    const textPropertyKeyframes = this.layerData.t.d.k;
    textPropertyKeyframes.forEach((textPropertyKeyframe) => {
      if (isObject(textPropertyKeyframe)) {
        if (textPropertyKeyframe.s && typeof textPropertyKeyframe.s.t !== 'undefined') {
          // eslint-disable-next-line no-param-reassign
          textPropertyKeyframe.s.t = this.payload.text;
        } else {
          // This code-path was previously handled in our legacy system (by not adding text anywhere). But it's not clear what the correct behavior should be.
          throw new Error(
            `This is an unexpected form of a text document keyframe. Investigate why this is happening. ${JSON.stringify(
              textPropertyKeyframe,
            )}`,
          );
        }
      } else {
        // This code-path was previously handled in our legacy system (by adding a string instead of a keyframe). But it's not clear what the correct behavior should be.
        throw new Error(
          `This is an unexpected form of a text document keyframe. Investigate why this is happening. ${JSON.stringify(
            textPropertyKeyframe,
          )}`,
        );
        // textPropertyKeyframes[index] = this.payload.text;
      }
    });
  };
}

/**
 * TEXT_FILL_COLOR change operation
 *
 * @param {object} renderer The renderer the change operation is for
 * @param {object} payload The payload of the change operation
 * @param {string} payload.layer The uuid of the layer to be changed
 * @param {boolean} payload.color The Fill color (as a hex string)
 * @param {boolean} [payload.isImportant=false] Will mark the change as important. It only can be changed again if `isImportant` is true.
 *
 * @memberof ChangeOperations
 * @public
 */
export class TextFillColorChangeOperation extends BaseLayerChangeOperation<v0_9_0.TextFillColorChangeOperationPayload> {
  static get type() {
    return 'TEXT_FILL_COLOR';
  }

  updateManifest = async () => {
    if (this.layerData.ty !== v0_9_0.LayerType.Text) {
      throw new Error('Text fill color can only be changed on text layers');
    }

    if (this.layerData.meta === undefined) {
      this.layerData.meta = {};
    }

    const importantChanges =
      '__importantChanges' in this.layerData.meta &&
      this.layerData.meta.__importantChanges !== undefined
        ? this.layerData.meta.__importantChanges
        : [];

    if (this.payload.isImportant && !importantChanges.includes('TEXT_FILL_COLOR.color')) {
      importantChanges.push('TEXT_FILL_COLOR.color');
      (this.layerData.meta as v0_9_0.LayerMeta).__importantChanges = importantChanges;
    }

    if (this.payload.isImportant || !importantChanges.includes('TEXT_FILL_COLOR.color')) {
      const colorArray = utils.hexToColorArray(utils.forceHexNumber(this.payload.color));

      const textPropertyKeyframes = this.layerData.t.d.k;
      textPropertyKeyframes.forEach((textPropertyKeyframe) => {
        if (
          isObject(textPropertyKeyframe) &&
          textPropertyKeyframe.s &&
          typeof textPropertyKeyframe.s.fc !== 'undefined'
        ) {
          // eslint-disable-next-line no-param-reassign
          textPropertyKeyframe.s.fc = colorArray;
        }
      });
    }
  };
}

/**
 * TEXT_STROKE_COLOR change
 *
 * @param {object} renderer The renderer the change operation is for
 * @param {object} payload The payload of the change operation
 * @param {string} payload.layer The uuid of the layer to be changed
 * @param {boolean} payload.color The Fill color (as a hex string)
 * @param {boolean} [payload.isImportant=false] Will mark the change as important. It only can be changed again if `isImportant` is true.
 *
 * @memberof ChangeOperations
 * @public
 */
export default class TextStrokeColorChangeOperation extends BaseLayerChangeOperation<v0_9_0.TextStrokeColorChangeOperationPayload> {
  static get type() {
    return 'TEXT_STROKE_COLOR';
  }

  updateManifest = async () => {
    if (this.layerData.ty !== v0_9_0.LayerType.Text) {
      throw new Error('Text stroke color can only be changed on text layers');
    }

    const importantChanges = (this.layerData.meta as v0_9_0.LayerMeta).__importantChanges || [];

    if (this.payload.isImportant && !importantChanges.includes('TEXT_STROKE_COLOR.color')) {
      importantChanges.push('TEXT_STROKE_COLOR.color');
      (this.layerData.meta as v0_9_0.LayerMeta).__importantChanges = importantChanges;
    }

    if (this.payload.isImportant || !importantChanges.includes('TEXT_STROKE_COLOR.color')) {
      const colorArray = utils.hexToColorArray(utils.forceHexNumber(this.payload.color));

      const textPropertyKeyframes = this.layerData.t.d.k;
      textPropertyKeyframes.forEach((textPropertyKeyframe) => {
        if (
          isObject(textPropertyKeyframe) &&
          textPropertyKeyframe.s &&
          typeof textPropertyKeyframe.s.sc !== 'undefined'
        ) {
          // eslint-disable-next-line no-param-reassign
          textPropertyKeyframe.s.sc = colorArray;
        } else {
          throw new Error(
            `Unexpected text property keyframe: ${JSON.stringify(
              textPropertyKeyframe,
            )}. Expected a keyframe with a stroke color property.`,
          );
        }
      });
    }
  };
}

/**
 * The possible options for change operations
 *
 * @public
 */
export const ChangeOperations = {
  [DisplayObjectVisibilityChangeOperation.type]: DisplayObjectVisibilityChangeOperation,
  [TextContentChangeOperation.type]: TextContentChangeOperation,
  [FontPropertyChangeOperation.type]: FontPropertyChangeOperation,
  [TextFillColorChangeOperation.type]: TextFillColorChangeOperation,
  [ImagePathChangeOperation.type]: ImagePathChangeOperation,
  [TextStrokeColorChangeOperation.type]: TextStrokeColorChangeOperation,
  [SolidFillColorChangeOperation.type]: SolidFillColorChangeOperation,
  [ShapeGradientFillColorStepsChangeOperation.type]: ShapeGradientFillColorStepsChangeOperation,
  [EffectFillColorChangeOperation.type]: EffectFillColorChangeOperation,
  [ShapeFillColorChangeOperation.type]: ShapeFillColorChangeOperation,
  [ShapeStrokeColorChangeOperation.type]: ShapeStrokeColorChangeOperation,
  // New change operation types
  [LayerAudioChangeOperation.type]: LayerAudioChangeOperation,
  [LayerImageChangeOperation.type]: LayerImageChangeOperation,
  [LayerVideoChangeOperation.type]: LayerVideoChangeOperation,
};

// NOTE: How would I dynamically type this?
export type TChangeOperation =
  | DisplayObjectVisibilityChangeOperation
  | TextContentChangeOperation
  | FontPropertyChangeOperation
  | TextFillColorChangeOperation
  | ImagePathChangeOperation
  | TextStrokeColorChangeOperation
  | SolidFillColorChangeOperation
  | ShapeGradientFillColorStepsChangeOperation
  | EffectFillColorChangeOperation
  | ShapeFillColorChangeOperation
  | ShapeStrokeColorChangeOperation
  | LayerAudioChangeOperation
  | LayerImageChangeOperation
  | LayerVideoChangeOperation;
