import axios from 'axios';
import { v0_9_0 } from '@libs/waymark-video/video-descriptor-types';

// URL to the s3 bucket where template bundles live
const baseTemplateDataDirectoryURL =
  'https://sp-prod-s3-assets.s3.amazonaws.com/web/video_creatives';

// Gets URL for a template's bundle.json file for its template slug
const getTemplateBundleURL = (templateSlug: string) =>
  `${baseTemplateDataDirectoryURL}/${templateSlug}/bundle.json`;

/**
 * Used for fetching templates published by the studio by template slug.
 */
export class TemplateBundleService {
  __latestResultCache: [string, v0_9_0.TemplateBundle] | null = null;
  __mockedGetTemplateBundle: null | typeof TemplateBundleService.prototype.getTemplateBundle = null;

  /**
   * Mocks the response of `getTemplateBundle`. All instances of TemplateBundleService will
   * execute mockedFn instead.
   * @param mockedFn
   */
  setMock__getTemplateBundle = (
    mockedFn: typeof TemplateBundleService.prototype.getTemplateBundle | null,
  ) => {
    this.__mockedGetTemplateBundle = mockedFn;
  };

  /**
   * Resets any `setMock__getTemplateBundle` calls so that `getTemplateBundle` will use its
   * default behavior.
   * @param mockedFn
   */
  resetMock__getTemplateBundle = () => {
    this.setMock__getTemplateBundle(null);
  };

  getTemplateBundle = async (templateSlug: string): Promise<v0_9_0.TemplateBundle | null> => {
    if (this.__mockedGetTemplateBundle !== null) {
      return this.__mockedGetTemplateBundle.call(this, templateSlug);
    }

    // We're storing a very small cache for the latest template fetched. Return it if the slugs match.
    if (this.__latestResultCache !== null && this.__latestResultCache[0] === templateSlug) {
      return this.__latestResultCache[1];
    }

    try {
      const response = await axios.get(getTemplateBundleURL(templateSlug));

      this.__latestResultCache = [templateSlug, response.data];

      return response.data;
    } catch (error) {
      const msg = `Could not retrieve bundle ${templateSlug}: ${error}`;
      throw new Error(msg);
    }
  };
}

export const templateBundleService = new TemplateBundleService();
