import {
  FootageLayer,
  ImageLayer,
  TextLayer,
  SubCompositionLayer,
  VideoDescriptorLayer,
  ShapeLayer,
  SolidLayer,
  UnsupportedLayer,
  AudioLayer,
} from '../layers';
import { CustomEventTarget } from '@libs/util-ts';

import type { VideoDescriptor } from '../';
import { latest as LatestVDETypes } from '@libs/waymark-video/video-descriptor-types';

const { LayerType } = LatestVDETypes;

export class Composition extends CustomEventTarget<{
  layerAdded: VideoDescriptorLayer;
  layerRemoved: VideoDescriptorLayer;
}> {
  readonly id: string;

  rawLayers: LatestVDETypes.Layer[];

  layers: VideoDescriptorLayer[];

  // Array of layers which reference this composition
  compositionInstanceLayers: Set<SubCompositionLayer>;

  videoDescriptor: VideoDescriptor;

  constructor(compID: string, rawLayers: LatestVDETypes.Layer[], videoDescriptor: VideoDescriptor) {
    super();

    this.id = compID;
    this.rawLayers = rawLayers;
    this.videoDescriptor = videoDescriptor;

    this.layers = [];
    this.compositionInstanceLayers = new Set();

    for (const rawLayerData of this.rawLayers) {
      switch (rawLayerData.ty) {
        case LayerType.Text:
          this.layers.push(new TextLayer(rawLayerData, this));
          break;
        case LayerType.Image:
          this.layers.push(new ImageLayer(rawLayerData, this));
          break;
        case LayerType.WaymarkVideo:
          this.layers.push(new FootageLayer(rawLayerData, this));
          break;
        case LayerType.Precomp:
          this.layers.push(new SubCompositionLayer(rawLayerData, this));
          break;
        case LayerType.Shape:
          this.layers.push(new ShapeLayer(rawLayerData, this));
          break;
        case LayerType.Solid:
          this.layers.push(new SolidLayer(rawLayerData, this));
          break;
        case LayerType.WaymarkAudio:
          this.layers.push(new AudioLayer(rawLayerData, this));
          break;
        default:
          this.layers.push(new UnsupportedLayer(rawLayerData, this));
      }
    }

    // Forward layerAdded and layerRemoved events from the composition to the video descriptor
    this.addEventListener('layerAdded', (event) =>
      videoDescriptor.dispatchEvent('layerAdded', event.detail),
    );
    this.addEventListener('layerRemoved', (event) =>
      videoDescriptor.dispatchEvent('layerRemoved', event.detail),
    );
  }

  getIsRootComposition() {
    return this.id === this.videoDescriptor.rootCompID;
  }

  addLayer(newLayer: VideoDescriptorLayer, suppressEvents = false) {
    if (this.layers.includes(newLayer)) {
      // The layer has already been added!
      return;
    }

    newLayer.parentComposition = this;

    this.layers.push(newLayer);
    this.rawLayers.push(newLayer.rawLayerData);

    if (!suppressEvents) {
      this.dispatchEvent('layerAdded', newLayer);
    }
  }

  /**
   * Replaces a given layer with a new one, or removes it if a new replacement layer is not provided.
   */
  replaceLayer(oldLayer: VideoDescriptorLayer, newLayer?: VideoDescriptorLayer | null) {
    if (oldLayer.parentComposition !== this) {
      console.error(`${this.toString()}: Cannot replace layer in composition it doesn't belong to`);
      return;
    }

    const layerIndex = this.layers.indexOf(oldLayer);
    if (layerIndex === -1) {
      console.error(`${this.toString()}: Unable to find layer to replace in parent composition`);
      return;
    }

    if (newLayer) {
      this.layers[layerIndex] = newLayer;
    } else {
      this.layers.splice(layerIndex, 1);
    }

    const manifestLayerIndex = this.rawLayers.indexOf(oldLayer.rawLayerData);
    if (manifestLayerIndex >= 0) {
      if (newLayer) {
        this.rawLayers[manifestLayerIndex] = newLayer.rawLayerData;
      } else {
        this.rawLayers.splice(manifestLayerIndex, 1);
      }
    }

    // Loop over every option of every switch and replace the old layer with the new one
    for (const contentSwitch of this.videoDescriptor.switches) {
      for (const option of contentSwitch.options) {
        const layerIndex = option.layers.indexOf(oldLayer);
        if (layerIndex !== -1) {
          if (newLayer) {
            option.layers[layerIndex] = newLayer;
          } else {
            option.layers.splice(layerIndex, 1);
          }
        }
      }
    }

    // Run cleanup for the layer
    oldLayer.cleanUp();

    this.dispatchEvent('layerRemoved', oldLayer);
    if (newLayer) {
      this.dispatchEvent('layerAdded', newLayer);
    }

    // Dispatch an event to notify that the composition has mutated layers
    this.videoDescriptor.dispatchEvent('change', undefined);
  }

  removeLayer(layerToRemove: VideoDescriptorLayer) {
    this.replaceLayer(layerToRemove, null);
  }

  registerCompositionInstanceLayer(layer: SubCompositionLayer) {
    if (this.compositionInstanceLayers.has(layer)) {
      // The layer has already been registered
      return;
    }

    this.compositionInstanceLayers.add(layer);
    // Listen for layerRemoved events on the layer's parent composition so we can clean it up
    // from the list of composition instance layers if it's removed
    layer.parentComposition.addEventListener('layerRemoved', (event) => {
      if (event.detail !== layer) {
        return;
      }

      this.compositionInstanceLayers.delete(layer);
      if (this.compositionInstanceLayers.size === 0) {
        // If this composition is no longer referenced by any active layers,
        // clean it up from the video descriptor to avoid memory leaks
        this.videoDescriptor.removeComposition(this);
      }
    });
  }

  toString() {
    return `Composition<${this.id}>`;
  }
}
