import { BaseReference } from './BaseReference';
import { ImageAsset } from '../assets';

import type { ImageOverrideProperties } from '@libs/waymark-video/video-descriptor-types';

export class ImageReference extends BaseReference<ImageOverrideProperties, ImageAsset> {
  getImageAsset() {
    const imageAssetID = this.rawReferenceData.__activeValue;
    const imageAsset = this.videoDescriptor.assets.get(imageAssetID);
    if (!(imageAsset instanceof ImageAsset)) {
      throw new Error(`Image asset with ID ${imageAssetID} not found`);
    }
    return imageAsset;
  }

  setImageAsset(newImageAsset: ImageAsset) {
    const oldAsset = this.getImageAsset();

    // Ensure the asset is in the manifest
    this.videoDescriptor.addAsset(newImageAsset);
    this.updateRawReferenceData({ __activeValue: newImageAsset.getID() });
    this.dispatchEvent('change:value', newImageAsset);

    // Clean up the old asset if it's no longer in use
    this.videoDescriptor.removeAsset(oldAsset);
  }

  toString(): string {
    return `ImageReference<${this.getLabel()}:${this.getImageAsset().getKey()}>`;
  }
}
