/**
 * This is a patch migration from 1.0.0 to 1.0.1.
 *
 * - Removes the background audio layer UUID from the template manifest if the layer is not present.
 *
 */

import { Migration, VideoDescriptor_v1_0_0, VideoDescriptor_v1_0_1 } from '../types';

const implementation = async (
  inputVideoDescriptor: VideoDescriptor_v1_0_0,
): Promise<VideoDescriptor_v1_0_1> => {
  const backgroundAudioLayerUUID = inputVideoDescriptor.templateManifest.__backgroundAudioLayerUUID;

  /**
   * MIGRATION STEP
   *
   * If the background audio layer is not present, remove the background audio layer UUID from the template manifest.
   */

  const backgroundAudioLayer = inputVideoDescriptor.projectManifest.layers.find(
    (layer) => layer.meta.uuid === backgroundAudioLayerUUID,
  );

  if (!backgroundAudioLayer) {
    inputVideoDescriptor.templateManifest.__backgroundAudioLayerUUID = null;
  }

  return {
    ...inputVideoDescriptor,
    version: '1.0.1',
  };
};

const migration: Migration<VideoDescriptor_v1_0_0, VideoDescriptor_v1_0_1> = {
  inputVersion: '1.0.0',
  targetVersion: '1.0.1',
  description: 'Migrates from 1.0.0 to 1.0.1. See migration file for details.',
  name: 'v1_0_1_migration',
  implementation,
};

export default migration;
