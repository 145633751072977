import {
  ProjectManifestAudioAsset,
  VolumeChange,
  WaymarkImageLocation,
  WaymarkVpsLocation,
} from './projectManifest';

export type LayoutSelectorFieldConfigurationValue = string;

export type ShapeConfigurationValue = {
  fillColor: string;
  strokeColor: string;
};

export type LegacyTimecode = {
  nativeVideoWidth: number;
  nativeVideoHeight: number;
};

export type BackgroundAudioFieldConfigurationValue = {
  location: ProjectManifestAudioAsset['location'];
  type: 'audio';
};

export type WaymarkAudioFieldConfigurationValue = {
  volumeChanges?: VolumeChange[];
  isMuted?: boolean;
  volume?: number;
};

export type AuxiliaryAudioLocation = {
  plugin: 'waymark-aps';
  sourceAudio: string;
};

export type AuxiliaryAudioFieldConfigurationValue = {
  volume: number;
  content: {
    location: AuxiliaryAudioLocation;
    type: 'audio';
  };
  isMuted: boolean;
  volumeChanges: VolumeChange[];
};

export type AuxiliaryAudio =
  | AuxiliaryAudioFieldConfigurationValue
  | WaymarkAudioFieldConfigurationValue;

type FontWeight =
  | 100
  | 150
  | 200
  | 250
  | 300
  | 350
  | 400
  | 450
  | 500
  | 550
  | 600
  | 650
  | 700
  | 750
  | 800
  | 850
  | 900
  | 950;

export type TextTypographyConfiguration = {
  fontFamily: string;
  fontSizeAdjustment: number;
  fontStyle: string;
  fontWeight: FontWeight;
};

export type BFSTypographyConfiguration = {
  fontFamily?: string;
  fontSizeAdjustment: number;
  fontVariantUUID: string;
  // TODO: Does this belong here? It was needed for a real TemplateBundle to pass
  fontStyle?: 'italic' | 'normal';
  fontWeight?: FontWeight;
  modifications?: any;
};

export type TextFieldConfigurationValue = {
  // yes, content is optional. In the studio, a text element can be created such that its typography
  // can be edited and its actual (non-existent) text cannot. ¯\_(ツ)_/¯
  content?: string;
  fillColor?: string;
  strokeColor?: string;
  typography?: BFSTypographyConfiguration | TextTypographyConfiguration;
};

type ImageContentConfigurationValue = {
  id?: string;
  w?: number | null;
  h?: number | null;
  location: WaymarkImageLocation;
  name?: string;
  modifications?: {
    adjustments?: {
      blur?: number;
      brightness?: number;
      contrast?: number;
      duotone?: [string, string];
      duotoneAlpha?: number;
      exposure?: number;
      highlight?: number;
      monochrome?: string;
      noiseReduction?: number;
      noiseReductionSharpen?: number;
      saturation?: number;
      shadow?: number;
      sharpen?: number;
      unsharpMask?: number;
      vibrance?: number;
    };
    backgroundFill?: string;
    cropping?: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
    fillColor?: string;
    fit?: string;
    padding?: number;
    zoom?: {
      x: number;
      y: number;
      z: number;
    };
  } | null;
  type: 'image';
};

type FlatImageConfigurationValue = ImageContentConfigurationValue;
export type NestedContentImageConfigurationValue = {
  // Or empty object
  content: ImageContentConfigurationValue | Record<string, never>;
  fitFillAlignment?: string;
};

export type ImageOverrideConfigurationValue = (
  | FlatImageConfigurationValue
  | NestedContentImageConfigurationValue
) & { name?: string };

export type ImageFieldConfigurationValue =
  | FlatImageConfigurationValue
  | NestedContentImageConfigurationValue;

export type TextOverrideConfigurationValue = string;

export type VideoFieldContent = {
  type: 'video';
  location: WaymarkVpsLocation;
  // TODO: There may be a non-null type needed here. Leaving it as null now and hoping for future
  // typescript errors to inform us.
  modifications?: null;
};

export type VideoFieldContentCropping = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type VideoFieldContentZoom = {
  x: number;
  y: number;
  z: number;
};

export enum VideoFieldContentFit {
  Crop = 'crop',
  Fill = 'fill',
}

export type VideoFieldConfigurationValue = {
  layer?: string;
  isMuted?: boolean;
  volume?: number;
  content: VideoFieldContent;
  contentTrimStartTime?: number;
  contentTrimDuration?: number;
  contentPlaybackDuration?: number;
  contentBackgroundFill?: string;
  contentCropping?: VideoFieldContentCropping;
  contentPadding?: number;
  contentFit?: `${VideoFieldContentFit}`;
  contentZoom?: VideoFieldContentZoom;
};

export type ColorOverrideConfigurationValue = string;

// Original configuration values in the editing form follow the old (pre-BFS) TextTypographyConfiguration structure
// but should be converted to the modern BFSTypographyConfiguration structure for output.
export type FontOverrideConfigurationValue =
  | BFSTypographyConfiguration
  | TextTypographyConfiguration;

export type FieldConfigurationValue =
  | LayoutSelectorFieldConfigurationValue
  | ShapeConfigurationValue
  | ColorOverrideConfigurationValue
  | FontOverrideConfigurationValue
  | ImageOverrideConfigurationValue
  | TextOverrideConfigurationValue
  | BackgroundAudioFieldConfigurationValue
  | TextFieldConfigurationValue
  | ImageFieldConfigurationValue
  | VideoFieldConfigurationValue
  | WaymarkAudioFieldConfigurationValue
  | AuxiliaryAudioFieldConfigurationValue;

export type VideoConfiguration = {
  backgroundAudio?: BackgroundAudioFieldConfigurationValue;
  auxiliaryAudio?: AuxiliaryAudioFieldConfigurationValue;
} & {
  [key: string]: FieldConfigurationValue;
};
