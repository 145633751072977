import { BaseLayer } from './BaseLayer';
import { latest } from '@libs/waymark-video/video-descriptor-types';
import {
  getFormattedColor,
  hexStringToRGBArray,
  interpolateGradientColorStops,
  isRGBColorTuple,
  rgbArrayToHexString,
} from '../utils/colors';

/**
 * Iterator yields all shapes of a given type for a shape layer.
 * @param shapes - The array of shapes to iterate over from `rawLayerData.shapes`
 * @param shapeType - The type of shape to yield
 *
 * @example
 * for (const shape of shapeTypeIterator(rawLayerData.shapes, latest.ShapeType.Fill)) {
 *  // typeof shape => latest.FillShape
 * }
 */
function* shapeTypeIterator<
  TShapeType extends latest.ShapeType,
  // Extract the shape type whose `ty` property matches the given `TShapeType` value. This allows us to type our yielded values
  // to match the given type without having to do any unnecessary casting or type assertions.
  TShapeItem extends Extract<
    latest.ShapeItem,
    {
      ty: `${TShapeType}`;
    }
  >,
>(
  shapes: Array<latest.ShapeItem | latest.GroupShapeTransform>,
  shapeType: TShapeType,
): Generator<TShapeItem> {
  for (const shapeItem of shapes) {
    if (shapeItem.ty === shapeType) {
      yield shapeItem as TShapeItem;
    } else if (shapeItem.ty === latest.ShapeType.Group) {
      yield* shapeTypeIterator(shapeItem.it, shapeType);
    }
  }
}

export class ShapeLayer extends BaseLayer<
  latest.ShapeLayer,
  {
    'change:fillColor': undefined;
    'change:strokeColor': undefined;
    'change:gradientFillColor': undefined;
    'change:visibility': boolean;
    'change:fillEffectColor': undefined;
    removed: undefined;
  }
> {
  gradientFillReferenceListeners: Array<(evt: CustomEvent<string>) => void> = [];

  /** ---- FILL COLOR ---- */

  /**
   * Get the current fill color of this layer.
   */
  getFillColor<TColorFormat extends 'hex' | 'rgb' = 'hex'>(format = 'hex' as TColorFormat) {
    let rgbFillColor: [number, number, number] | null = null;

    for (const fillShape of shapeTypeIterator(this.rawLayerData.shapes, latest.ShapeType.Fill)) {
      const fillColorValue = fillShape.c;

      if (fillColorValue.a === 1) {
        if (isRGBColorTuple(fillColorValue.k)) {
          // We can have errantly configured fill colors which were set as non-animated keyframe values but
          // the value is still marked as animated. In this case, we should just use the keyframe value.
          rgbFillColor = fillColorValue.k;
          break;
        }

        if ('s' in fillColorValue.k[0] && isRGBColorTuple(fillColorValue.k[0].s)) {
          // If the stroke color is an animated value, use the first keyframe's start value
          rgbFillColor = fillColorValue.k[0].s;
          break;
        }
      } else if (isRGBColorTuple(fillColorValue.k)) {
        rgbFillColor = fillColorValue.k;
        break;
      }
    }
    return rgbFillColor ? getFormattedColor(rgbFillColor, format) : null;
  }

  /**
   * Updates the fill color of this layer
   */
  private updateFillColor(newColor: [number, number, number]): void {
    const updatedShapes = structuredClone(this.rawLayerData.shapes);

    for (const fillShape of shapeTypeIterator(updatedShapes, latest.ShapeType.Fill)) {
      fillShape.c.k = newColor;
    }
    this.updateRawLayerData({
      shapes: updatedShapes,
    });
    this.dispatchEvent('change:fillColor', undefined);
  }

  /**
   * Updates the fill color of this layer, or indirectly updates it via its reference.
   * @param newColor - The new color to apply. Can be either a hex string or an RGB tuple array (where each channel is 0-1).
   * @param shouldOverrideReference  - If true, any fill color reference for the layer will be set to be ignored and the new color will be applied just to this layer.
   *
   * @throws {Error} If the layer does not have fill color editing attributes.
   */
  setFillColor(newColor: string | [number, number, number], shouldOverrideReference = false): void {
    if (shouldOverrideReference) {
      this.updateIgnoredReferences({
        fillColor: true,
      });
    }

    if (this.fillColorReference && !this.getIsReferenceIgnored('fillColor')) {
      // Update the reference; this will then propagate back to this layer via the reference's `change:value` event
      this.fillColorReference.setColor(
        Array.isArray(newColor) ? rgbArrayToHexString(newColor) : newColor,
      );
    } else {
      this.updateFillColor(Array.isArray(newColor) ? newColor : hexStringToRGBArray(newColor));
    }
  }

  /**
   * Listener updates the fill color when the fill color reference changes, unless the layer is ignoring the reference.
   * This is an arrow function to avoid weirdness with `this` when this gets passed to `addEventListener`.
   */
  onFillColorReferenceChange(): void {
    if (!this.fillColorReference || this.getIsReferenceIgnored('fillColor')) {
      return;
    }

    const newFillColorHex = this.fillColorReference.getColor();
    this.updateFillColor(hexStringToRGBArray(newFillColorHex));
  }

  /** ---- STROKE COLOR ---- */

  /**
   * Get the current stroke color of this layer.
   */
  getStrokeColor<TColorFormat extends 'hex' | 'rgb' = 'hex'>(format = 'hex' as TColorFormat) {
    let rgbStrokeColor: [number, number, number] | null = null;

    for (const strokeShape of shapeTypeIterator(
      this.rawLayerData.shapes,
      latest.ShapeType.Stroke,
    )) {
      const strokeColorValue = strokeShape.c;

      if (strokeColorValue.a === 1) {
        // If the stroke color is an  keyframed value, use the first keyframe's start value
        if ('s' in strokeColorValue.k[0] && isRGBColorTuple(strokeColorValue.k[0].s)) {
          rgbStrokeColor = strokeColorValue.k[0].s;
          break;
        }
      } else if (isRGBColorTuple(strokeColorValue.k)) {
        rgbStrokeColor = strokeColorValue.k;
        break;
      }
    }

    return rgbStrokeColor ? getFormattedColor(rgbStrokeColor, format) : null;
  }

  /**
   * Updates the stroke color of this layer
   */
  private updateStrokeColor(newColor: [number, number, number]): void {
    const updatedShapes = structuredClone(this.rawLayerData.shapes);

    for (const strokeShape of shapeTypeIterator(updatedShapes, latest.ShapeType.Stroke)) {
      strokeShape.c.k = newColor;
    }

    this.updateRawLayerData({
      shapes: updatedShapes,
    });
    this.dispatchEvent('change:strokeColor', undefined);
  }

  /**
   * Updates the fill color of this layer, or indirectly updates it via its reference.
   * @param newColor - The new color to apply. Can be either a hex string or an RGB tuple array (where each channel is 0-1).
   * @param shouldOverrideReference  - If true, any stroke color reference for the layer will be set to be ignored and the new color will be applied just to this layer.
   */
  setStrokeColor(
    newColor: string | [number, number, number],
    shouldOverrideReference = false,
  ): void {
    if (shouldOverrideReference) {
      this.updateIgnoredReferences({
        strokeColor: true,
      });
    }

    if (this.strokeColorReference && !this.getIsReferenceIgnored('strokeColor')) {
      // Update the reference; this will then propagate back to this layer via the reference's `change:value` event
      this.strokeColorReference.setColor(
        Array.isArray(newColor) ? rgbArrayToHexString(newColor) : newColor,
      );
    } else {
      this.updateStrokeColor(Array.isArray(newColor) ? newColor : hexStringToRGBArray(newColor));
    }
  }

  /**
   * Listener updates the stroke color when the stroke color reference changes, unless the layer is ignoring the reference.
   * This is an arrow function to avoid weirdness with `this` when this gets passed to `addEventListener`.
   */
  onStrokeColorReferenceChange(): void {
    if (!this.strokeColorReference || this.getIsReferenceIgnored('strokeColor')) {
      return;
    }

    const newStrokeColorHex = this.strokeColorReference.getColor();
    this.updateStrokeColor(hexStringToRGBArray(newStrokeColorHex));
  }

  /** ---- GRADIENT FILL COLORS ---- */

  /**
   * Gets an array of all gradient fill colors for this layer.
   */
  getGradientFillColors<TColorFormat extends 'hex' | 'rgb' = 'hex'>(
    format = 'hex' as TColorFormat,
  ) {
    const gradientColors: Array<{
      color: ReturnType<typeof getFormattedColor<TColorFormat>>;
      position: number;
    }> = [];

    for (const gradientFillShape of shapeTypeIterator(
      this.rawLayerData.shapes,
      latest.ShapeType.GradientFill,
    )) {
      const gradientFillColors = gradientFillShape.g.k.k;

      // Increment by 8 to skip over midpoint colors
      for (let i = 0; i < gradientFillColors.length; i += 8) {
        gradientColors.push({
          position: gradientFillColors[i],
          color: getFormattedColor(
            gradientFillColors.slice(i + 1, i + 4) as [number, number, number],
            format,
          ),
        });
      }

      // Break right away; we only need to get the colors from the first gradient fill shape we encounter
      break;
    }

    return gradientColors;
  }

  protected updateGradientFillColor(
    newColor: [number, number, number],
    gradientStepIndex: number,
  ): void {
    const updatedShapes = structuredClone(this.rawLayerData.shapes);

    for (const gradientFillShape of shapeTypeIterator(
      updatedShapes,
      latest.ShapeType.GradientFill,
    )) {
      const totalGradientStepsCount = gradientFillShape.g.p;
      const gradientSteps = [...gradientFillShape.g.k.k];

      // The gradientSteps array is a flat array of gradient position and color channel values for each gradient step.
      // Each step has 4 values, where the first represents the step's position in the gradient and the following 3 represent the RGB channels.
      // So, we need to multiply the step index by 4 to get the correct index in the array. However, there are also midpoint color entries inserted
      // between every controlled color step, so we also have to multiply by 2 to skip over those. After this change, we will need to
      // update the midpoint colors on either side of this step as well.
      const trueStepColorStartIndex = gradientStepIndex * 4 * 2;
      const redTrueStepColorIndex = trueStepColorStartIndex + 1;
      const greenTrueStepColorIndex = trueStepColorStartIndex + 2;
      const blueTrueStepColorIndex = trueStepColorStartIndex + 3;

      // Update the color for the step
      gradientSteps[redTrueStepColorIndex] = newColor[0];
      gradientSteps[greenTrueStepColorIndex] = newColor[1];
      gradientSteps[blueTrueStepColorIndex] = newColor[2];

      // If this is not the first step, update the midpoint color before this step
      if (gradientStepIndex > 0) {
        // Get the index of the midpoint color stop and the previous color stop which we will interpolate with
        const midPointColorPositionIndex = trueStepColorStartIndex - 4;
        const previousColorStartIndex = trueStepColorStartIndex - 8;

        const interpolatedColor = interpolateGradientColorStops(
          gradientSteps.slice(previousColorStartIndex, previousColorStartIndex + 4) as [
            number,
            number,
            number,
            number,
          ],
          gradientSteps.slice(trueStepColorStartIndex, trueStepColorStartIndex + 4) as [
            number,
            number,
            number,
            number,
          ],
          gradientSteps[midPointColorPositionIndex],
        );

        // Update the midpoint color with the new interpolated color values
        const redMidpointColorIndex = midPointColorPositionIndex + 1;
        const greenMidpointColorIndex = midPointColorPositionIndex + 2;
        const blueMidpointColorIndex = midPointColorPositionIndex + 3;

        gradientSteps[redMidpointColorIndex] = interpolatedColor[1];
        gradientSteps[greenMidpointColorIndex] = interpolatedColor[2];
        gradientSteps[blueMidpointColorIndex] = interpolatedColor[3];
      }

      // If this is not the last step, update the midpoint color after this step
      if (gradientStepIndex < totalGradientStepsCount - 1) {
        // Get the index of the midpoint color stop and the next color stop which we will interpolate with
        const midPointColorPositionIndex = trueStepColorStartIndex + 4;
        const nextColorStartIndex = trueStepColorStartIndex + 8;

        const interpolatedColor = interpolateGradientColorStops(
          gradientSteps.slice(trueStepColorStartIndex, trueStepColorStartIndex + 4) as [
            number,
            number,
            number,
            number,
          ],
          gradientSteps.slice(nextColorStartIndex, nextColorStartIndex + 4) as [
            number,
            number,
            number,
            number,
          ],
          gradientSteps[midPointColorPositionIndex],
        );

        const redMidpointColorIndex = midPointColorPositionIndex + 1;
        const greenMidpointColorIndex = midPointColorPositionIndex + 2;
        const blueMidpointColorIndex = midPointColorPositionIndex + 3;

        gradientSteps[redMidpointColorIndex] = interpolatedColor[1];
        gradientSteps[greenMidpointColorIndex] = interpolatedColor[2];
        gradientSteps[blueMidpointColorIndex] = interpolatedColor[3];
      }

      gradientFillShape.g.k.k = gradientSteps;
    }

    this.updateRawLayerData({
      shapes: updatedShapes,
    });
    this.dispatchEvent('change:gradientFillColor', undefined);
  }

  /**
   * Handler for events when the gradient fill color reference changes.
   */
  onGradientFillColorReferenceChange(gradientStepIndex: number): void {
    const gradientFillColorReference = this.gradientFillColorReferences?.[gradientStepIndex];

    if (!gradientFillColorReference || this.getIsReferenceIgnored('gradientFill')) {
      return;
    }

    const newColorHex = gradientFillColorReference.getColor();
    this.updateGradientFillColor(hexStringToRGBArray(newColorHex), gradientStepIndex);
  }
}
