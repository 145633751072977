export class GlobalSettings<
  TSupportedSettings extends Record<
    // All settings must be dot-delimited strings
    `${string}.${string}`,
    unknown
  >,
> {
  private settings: Partial<TSupportedSettings> = {};

  get<TSettingName extends keyof TSupportedSettings>(
    settingName: TSettingName,
  ): TSupportedSettings[TSettingName] {
    const settingValue = this.settings[settingName];

    if (settingValue === undefined) {
      throw new Error(`Setting "${String(settingName)}" is not set.`);
    }

    return settingValue;
  }

  /**
   * Set a global setting.
   * Returns the GlobalSettings instance to allow chaining.
   *
   * @example
   * ```ts
   * settings
   *  .set("waymark.env", "local")
   *  .set("s3.env", "dev");
   * ```
   */
  set<TSettingName extends keyof TSupportedSettings>(
    settingName: TSettingName,
    settingValue: TSupportedSettings[TSettingName],
  ): typeof this {
    this.settings[settingName] = settingValue;

    // Return this to allow chaining
    return this;
  }
}
