import { BaseReference } from './BaseReference';

import type { latest as LatestVDETypes } from '@libs/waymark-video/video-descriptor-types';

export class ColorReference extends BaseReference<LatestVDETypes.ColorOverrideProperties> {
  getLabel() {
    // Context: rawReferenceData.name may not be a good descriptive name which we want to use as a label
    // in the UI or in autofill, so we use `displayName` for nice representative labels for colors.
    // This is a relatively new change, so old templates most likely will not have displayNames on color references.
    // We could fall back to rawReferenceData.name if displayName is not available, but I'm nervous to do so since it could
    // have negative implications for autofill.
    return this.rawReferenceData.displayName ?? '';
  }

  getColor() {
    return this.rawReferenceData.__activeValue;
  }

  setColor(color: string) {
    this.updateRawReferenceData({ __activeValue: color });
    this.dispatchEvent('change:value', color);
  }

  toString(): string {
    return `ColorReference<${this.getLabel()}:${this.getColor()}>`;
  }
}
