import { BooleanInt } from './helpers';
import {
  BezierCurve,
  LastTimeKeyframe,
  MultiDimensionalValue,
  MultiDimensionalValueKeyframed,
  Value,
  ValueKeyframed,
} from './values';

export type Composite = 1 | 2;

export enum LineCap {
  Butt = 1,
  Round = 2,
  Projecting = 3,
}

export enum LineJoin {
  Miter = 1,
  Round = 2,
  Bevel = 3,
}

export interface Shape {
  /** Property Value */
  k: ShapeProp;
  /** Property Expression. An AE expression that modifies the value. */
  x?: string;
  /** Property Index. Used for expressions. */
  ix: number;
  /** Defines if property is animated */
  a: BooleanInt;
}

export interface ShapeKeyframed {
  /** If the property has multiple keyframes, this is 1 */
  a: 1;
  /** Property Value keyframes */
  k: [...(ShapePropKeyframe | ShapePropHoldKeyframe)[], ShapePropHoldKeyframe | LastTimeKeyframe];
  /** Property Expression. An AE expression that modifies the value. */
  x?: string;
  /** Property Index. Used for expressions. */
  ix: number;
  /** In Spatial Tangent. Only for spatial properties. Array of numbers. */
  ti?: number[];
  /** Out Spatial Tangent. Only for spatial properties. Array of numbers. */
  to?: number[];
}

export interface ShapeProp {
  /** Closed property of shape */
  c: boolean;
  /** Bezier curve In points. Array of 2 dimensional arrays. */
  i: number[][];
  /** Bezier curve Out points. Array of 2 dimensional arrays. */
  o: number[][];
  /** Bezier curve Vertices. Array of 2 dimensional arrays. */
  v: number[][];
}

export interface ShapePropHoldKeyframe {
  /** Start time of keyframe segment. */
  t: ShapePropKeyframe['t'];
  /** Start value of keyframe segment. */
  s: ShapePropKeyframe['s'];
  /** The keyframe interpolation of type HOLD */
  h: 1;
}

export interface ShapePropKeyframe {
  /** Start value of keyframe segment. */
  s: ShapeProp[];
  /** End value of keyframe segment. */
  e: ShapeProp[];
  /** Start time of keyframe segment. */
  t: number;
  /** A string representation of the curves for i and o */
  n: string | string[];
  /** Bezier curve interpolation in value. */
  i: BezierCurve;
  /** Bezier curve interpolation out value. */
  o: BezierCurve;
  /** Is the keyframe interpolation of type HOLD */
  h?: BooleanInt;
}

/**
 * When applying color changes, we errantly specify:
 * (a) A color that is [number, number, number] instead of [number, number, number, number]
 * (b) A single value even if a: 1
 * https://github.com/stikdev/waymark/blob/b52f88df7460c0601af25a800afe0c714700e631/libs/rendering-engine/src/changeOperations/ShapeFillColorChangeOperation.js#L22
 *
 * @migrationtodo
 */
export interface ErrantWaymarkColorMultidimensionalKeyframed {
  /** This is wrong because it's not actually animated */
  a: 1;
  k: [number, number, number];
}

// Shapes
/**
 * Shape Types
 * 'sh' = shape
 * 'rc' = rect
 * 'el' = ellipse
 * 'sr' = star
 * 'fl' = fill
 * 'gf' = gfill
 * 'gs' = gStroke
 * 'st' = stroke
 * 'mm' = merge
 * 'tm' = trim
 * 'tw' = twist
 * 'gr' = group
 * 'rp' = repeater
 * 'rd' = roundedCorners
 * 'op' = offsetPath
 * 'pb' = puckerAndBloat
 * 'tr' = repeater
 * 'zz' = zigZag
 */
export enum ShapeType {
  Shape = 'sh',
  Rectangle = 'rc',
  Ellipse = 'el',
  Star = 'sr',
  Fill = 'fl',
  GradientFill = 'gf',
  GradientStroke = 'gs',
  Stroke = 'st',
  Merge = 'mm',
  Trim = 'tm',
  Twist = 'tw',
  Group = 'gr',
  Repeater = 'rp',
  RoundedCorners = 'rd',
  OffsetPath = 'op',
  PuckerAndBloat = 'pb',
  ZigZag = 'zz',
}

export enum ShapeDirection {
  Forward = 2,
  Reverse = 3,
  /**
   * It appears that it is possible for `.property('Shape Direction').value` to be
   * 1, but I am unable to reproduce this in ExtendScript at the moment.
   *
   * Maybe it is an old value from a previous version of AE? As of AE 2024, it appears that
   * 2 and 3 are the only Shape Direction's that are achievable in the UI.
   */
  __INVALID_UNKNOWN = 1,
}

export interface BaseShape {
  /** After Effect's Match Name. Used for expressions. */
  mn: string;
  /** After Effect's Name. Used for expressions. */
  nm: string;
  /** Shape content type. */
  ty: `${ShapeType}`;
  /** Is hidden? */
  hd: boolean;
  /**
   * Parsed layer name originally used as html class in bodymovin's SVG/HTML renderer
   *
   * If you're interested in investigating what the heck tg, ln, and cl are
   * please refer to the following file:
   * https://github.com/stikdev/bodymovin-extension-private/blob/5109465daf3d5fc902d66267b4159f9676f1029d/bundle/jsx/elements/layerElement.jsx#L77-L78
   *
   * They don't appear to be used. May the odds be ever in your favor.
   * */
  ln?: string;
  /**
   * Parsed layer name used as html id on SVG/HTML renderer.
   *
   * If you're interested in investigating what the heck tg, ln, and cl are
   * please refer to the following file:
   * https://github.com/stikdev/bodymovin-extension-private/blob/5109465daf3d5fc902d66267b4159f9676f1029d/bundle/jsx/elements/layerElement.jsx#L77-L78
   *
   * They don't appear to be used. May the odds be ever in your favor.
   * */
  cl?: string;
}

export interface EllipseShape extends BaseShape {
  ty: `${ShapeType.Ellipse}`;
  /** ('Shape Direction') */
  d: ShapeDirection;
  /** Ellipse's position */
  p: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Ellipse's size */
  s: MultiDimensionalValue | MultiDimensionalValueKeyframed;
}

export enum FillRule {
  NonZeroWinding = 1,
  EvenOdd = 2,
}
export interface FillShape extends BaseShape {
  ty: `${ShapeType.Fill}`;
  /** Fill Opacity */
  o: Value | ValueKeyframed;
  /** Fill Color */
  c:
    | MultiDimensionalValue
    | MultiDimensionalValueKeyframed
    | ErrantWaymarkColorMultidimensionalKeyframed;
  /** Fill Rule */
  r: FillRule;
}

/**
 * Gradient Types
 */
export enum GradientType {
  Linear = 1,
  Radial = 2,
}

/** Gradient Colors */
export type GradientColors = {
  p: number;
  /**
   * The "keyframes" of the gradient. This is the only place I can tell where
   * a: 0 doesn't have k = a single value. So, we haven't extended the Value type here.
   *
   * https://github.com/stikdev/bodymovin-extension-private/blob/5109465daf3d5fc902d66267b4159f9676f1029d/bundle/jsx/utils/shapeHelper.jsx#L509
   */
  k: {
    a: 0;
    /** An array of color stops */
    k: number[];
    /**
     * The property index
     */
    ix: number;
  };
};

export interface BaseGradientShape extends BaseShape {
  /** Gradient Colors */
  g: GradientColors;
  /** Gradient Start Point */
  s: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Gradient End Point */
  e: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Gradient Type */
  t: GradientType;
  /** Gradient Highlight Length. Only if type is Radial */
  h?: Value | ValueKeyframed;
  /** Highlight Angle. Only if type is Radial */
  a?: Value | ValueKeyframed;
}

export interface GradientFillShape extends BaseGradientShape {
  ty: `${ShapeType.GradientFill}`;
  /** Fill Opacity */
  o: Value | ValueKeyframed;
  /** Fill Rule */
  r: FillRule;
}

export enum DashDataType {
  /** 'ADBE Vector Stroke Dash' */
  Dash = 'd',
  /** 'ADBE Vector Stroke Gap' */
  Gap = 'g',
  /** 'ADBE Vector Stroke Offset' */
  Offset = 'o',
}

export type DashData = {
  /**
   * An enum of the 'type' of dash data
   */
  n: `${DashDataType}`;
  /**
   * A concatenation of the property name.
   * `prop.property('Dashes').property(j + 1).name.toLowerCase().split(' ').join('');`
   * https://github.com/stikdev/bodymovin-extension-private/blob/5109465daf3d5fc902d66267b4159f9676f1029d/bundle/jsx/utils/shapeHelper.jsx#L537
   */
  nm: string;
  /**
   * Value of the dash, gap, or offset.
   */
  v: Value | ValueKeyframed;
};

export interface GradientStrokeShape extends BaseGradientShape {
  ty: `${ShapeType.GradientStroke}`;
  /** Stroke Opacity */
  o: Value | ValueKeyframed;
  /** Gradient Stroke Width */
  w: Value | ValueKeyframed;
  /** Gradient Stroke Line Cap */
  lc: LineCap;
  /** Gradient Stroke Line Join */
  lj: LineJoin;
  /**
   * Gradient Stroke Miter Limit (if keyframed, this is the initial value).
   * Only if Line Join is set to Miter.
   * */
  ml?: number;
  /**
   * Gradient Stroke Miter Limit.
   * Only if Line Join is set to Miter.
   * */
  ml2?: Value | ValueKeyframed;
  /**
   * Dashes data
   */
  d?: DashData[];
}

export interface GroupShape extends BaseShape {
  ty: `${ShapeType.Group}`;
  /** Group number of properties. Used for expressions. */
  np: number;
  /**
   * The property index where "Contents" is located
   */
  cix: number;
  /**
   * The property index shape relative to the 'Contents' array.
   */
  ix: number;
  /** Group list of items */
  it: Array<ShapeItem | GroupShapeTransform>;
}

export interface MergeShape extends BaseShape {
  ty: `${ShapeType.Merge}`;
  /** Merge Mode */
  mm: number;
}

export interface RectangleShape extends BaseShape {
  ty: `${ShapeType.Rectangle}`;
  /** ('Shape Direction') */
  d: ShapeDirection;
  /** Rect's position */
  p: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Rect's size */
  s: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Rect's rounded corners ('Roundness') */
  r: Value | ValueKeyframed;
}

export interface RepeaterShape extends BaseShape {
  ty: `${ShapeType.Repeater}`;
  /** Number of Copies */
  c: Value | ValueKeyframed;
  /** Offset of Copies */
  o: Value | ValueKeyframed;
  /** Composite of copies */
  m: Composite;
  /** Transform values for each repeater copy */
  tr: RepeaterShapeTransform;
  /**
   * The property index shape relative to the 'Contents' array.
   */
  ix: number;
}

export interface RoundShape extends BaseShape {
  ty: `${ShapeType.RoundedCorners}`;
  /** Rounded Corner Radius */
  r: Value | ValueKeyframed;
  /**
   * The property index shape relative to the 'Contents' array.
   */
  ix: number;
}

export interface ShapeShape extends BaseShape {
  ty: `${ShapeType.Shape}`;
  /** @deprecated Seems to be synoymous with ix. Do not use. */
  ind: number;
  /** Shape's vertices */
  ks: Shape | ShapeKeyframed;
  /**
   * The property index shape relative to the 'Contents' array.
   */
  ix: number;
}

export enum StarType {
  Star = 1,
  Polygon = 2,
}

export interface StarShape extends BaseShape {
  ty: `${ShapeType.Star}`;
  /** ('Shape Direction') */
  d: ShapeDirection;
  /** Star's position */
  p: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Star's inner radius. (Only for star; sy = 1) */
  ir?: Value | ValueKeyframed;
  /** Star's inner roundness. (Only for star; sy = 1) */
  is?: Value | ValueKeyframed;
  /** Star's outer radius. */
  or: Value | ValueKeyframed;
  /** Star's outer roundness. */
  os: Value | ValueKeyframed;
  /** Star's rotation. */
  r: Value | ValueKeyframed;
  /** Star's number of points. */
  pt: Value | ValueKeyframed;
  /**
   * Star's type.
   */
  sy: StarType;
  /**
   * The property index shape relative to the 'Contents' array.
   */
  ix: number;
}

export interface StrokeShape extends BaseShape {
  ty: `${ShapeType.Stroke}`;
  /** Stroke Line Cap */
  lc: LineCap;
  /** Stroke Line Join */
  lj: LineJoin;
  /** Stroke Opacity */
  o: Value | ValueKeyframed;
  /** Stroke Width */
  w: Value | ValueKeyframed;
  /** Stroke Color */
  c: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /**
   * Miter Limit (if keyframed, this is the initial value).
   * Only if Line Join is set to Miter.
   * */
  ml?: number;
  /**
   * Miter Limit.
   * Only if Line Join is set to Miter.
   * */
  ml2?: Value | ValueKeyframed;
  /**
   * Dashes data
   */
  d?: DashData[];
}

/**
 * 1 = "Simultaneously"
 * 2 = "Individually"
 */
export enum TrimMultipleShapes {
  Simultaneously = 1,
  Individually = 2,
}

export interface TrimShape extends BaseShape {
  ty: `${ShapeType.Trim}`;
  /** Trim Start. */
  s: Value | ValueKeyframed;
  /** Trim End. */
  e: Value | ValueKeyframed;
  /** Trim Offset. */
  o: Value | ValueKeyframed;
  /** "Trim Multiple Shapes" */
  m: TrimMultipleShapes;
  /**
   * The property index shape relative to the 'Contents' array.
   */
  ix: number;
}

export interface TwistShape extends BaseShape {
  ty: `${ShapeType.Twist}`;
  /** 'ADBE Vector Twist Angle' */
  a: Value | ValueKeyframed;
  /** 'ADBE Vector Twist Center' */
  c: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /**
   * The property index shape relative to the 'Contents' array.
   */
  ix: number;
}

export interface OffsetPathShape extends BaseShape {
  ty: `${ShapeType.OffsetPath}`;
  /** "Amount" */
  a: Value | ValueKeyframed;
  /** Line Join */
  lj: LineJoin;
  /** Miter Limit */
  ml: Value | ValueKeyframed;
  /**
   * The property index shape relative to the 'Contents' array.
   */
  ix: number;
}

interface BaseShapeTransform {
  /** Type; always "tr" for shape transforms */
  ty: 'tr';
  /** Transform Property name */
  nm: string;
  /** Transform Anchor Point */
  a: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Transform Position */
  p: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Transform Scale */
  s: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Transform Rotation */
  r: Value | ValueKeyframed;
}

/**
 * The transform of a Repeater Shape.
 *
 * Note where it's added: https://github.com/stikdev/bodymovin-extension-private/blob/5109465daf3d5fc902d66267b4159f9676f1029d/bundle/jsx/utils/shapeHelper.jsx#L408
 */
export interface RepeaterShapeTransform extends BaseShapeTransform {
  /** Repeater Transform Start Opacity */
  so: Value | ValueKeyframed;
  /** Repeater Transform End Opacity */
  eo: Value | ValueKeyframed;
}

/**
 * The transform of a Group Shape.
 *
 * This is sort of an odd "shape". It's technically the transform associated with a Group Shape.
 * But, bodymovin adds it into the groups `.it` array and, due to a bug in bodymovin, it doesn't
 * inherit properties like "hd" and "mn" from BaseShape.
 *
 * Note where it's added: https://github.com/stikdev/bodymovin-extension-private/blob/5109465daf3d5fc902d66267b4159f9676f1029d/bundle/jsx/utils/shapeHelper.jsx#L467
 */
export interface GroupShapeTransform extends BaseShapeTransform {
  /** Shape Transform Opacity */
  o: Value | ValueKeyframed;
  /** Shape Transform Skew */
  sk: Value | ValueKeyframed;
  /** Shape Transform Skew Axis */
  sa: Value | ValueKeyframed;
}

export type ShapeItem =
  | ShapeShape
  | RectangleShape
  | EllipseShape
  | StarShape
  | FillShape
  | GradientFillShape
  | GradientStrokeShape
  | StrokeShape
  | MergeShape
  | TrimShape
  | GroupShape
  | RoundShape
  | RepeaterShape
  | TwistShape
  | OffsetPathShape;
