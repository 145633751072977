import { BaseAsset } from './BaseAsset';
import { FootageAssetLocation, FootageAssetQuality } from './locations';

import type { latest as LatestVDETypes } from '@libs/waymark-video/video-descriptor-types';

export class FootageAsset extends BaseAsset {
  rawAssetData: LatestVDETypes.ProjectManifestVideoAsset;
  location: FootageAssetLocation;

  static fromVPSKey(vpsKey: string) {
    const location = new FootageAssetLocation({ plugin: 'waymark-vps', sourceVideo: vpsKey });

    return new FootageAsset({
      id: location.getKey(),
      location: location.rawLocationData,
      type: 'video',
    });
  }

  static cloneFrom(asset: FootageAsset) {
    return new FootageAsset({
      ...structuredClone(asset.rawAssetData),
      id: asset.getKey(),
    });
  }

  constructor(rawAssetData: LatestVDETypes.ProjectManifestVideoAsset) {
    super();

    this.rawAssetData = rawAssetData;
    this.location = new FootageAssetLocation(rawAssetData.location);
  }

  getURL(quality: FootageAssetQuality = FootageAssetQuality.medium) {
    return this.location.getURL(quality);
  }

  getThumbnailURLs() {
    return this.location.getThumbnailURLs();
  }

  /**
   * Fetches the metadata for this footage asset.
   * This method will poll for up to 6 retries if it fails to get the metadata,
   * so you can pass an optional abortSignal to cancel the operation early.
   *
   * @example
   * ```ts
   * useEffect(() => {
   *  const controller = new AbortController();
   *
   *  (async () => {
   *    try {
   *      const metadata = await footageAsset.getMetadata({ signal: controller.signal });
   *      setMetadata(metadata);
   *    } catch (error) {
   *      consoler.error("Footage metadata fetch failed or aborted", error);
   *    }
   *  })();
   *
   *  // Abort the fetch if the component unmounts
   *  return () => controller.abort();
   * }, [footageAsset]);
   *
   * ```
   */
  getMetadata(options: { signal?: AbortSignal } = {}) {
    return this.location.getMetadata(options);
  }

  getKey(): string {
    return this.location.getKey();
  }

  getID() {
    return this.rawAssetData.id;
  }
}
