import { uuid as generateUuid } from '../../utils';
import { v1_0_0 as VersionTypes } from '@libs/waymark-video/video-descriptor-types';
import { faker } from '@faker-js/faker/locale/en';
import { hexStringToRGBArray, interpolateGradientColorStops } from '../../utils/colors';

type PMTextLayer = VersionTypes.TextLayer;
type PMImageLayer = VersionTypes.ImageLayer;
type PMFootageLayer = VersionTypes.WaymarkVideoLayer;

export const valueFactory = (
  value: VersionTypes.Value['k'],
  { ix = 1 }: Partial<Pick<VersionTypes.Value, 'ix'>> = {},
): VersionTypes.Value => {
  return {
    a: 0,
    k: value,
    ix: ix,
  };
};

export const multiDimensionalValueFactory = (
  value: VersionTypes.MultiDimensionalValue['k'],
  { ix = 1 }: Partial<Pick<VersionTypes.MultiDimensionalValue, 'ix'>> = {},
): VersionTypes.MultiDimensionalValue => {
  return {
    a: 0,
    k: value,
    ix: ix,
  };
};

interface BaseLayerFactoryOptions {
  uuid?: string;
  name?: string;
  inPoint?: number;
  outPoint?: number;
  startTime?: number;
  positionX?: number;
  positionY?: number;
  anchorPointX?: number;
  anchorPointY?: number;
  rotation?: number;
  index?: number;
  isHidden?: boolean;
}

const baseLayerFactory = <TLayerType extends VersionTypes.LayerType>(
  layerType: TLayerType,
  {
    uuid = generateUuid(),
    name = `Layer ${uuid}`,
    inPoint = 0,
    outPoint = 100,
    startTime = 0,
    positionX = 0,
    positionY = 0,
    anchorPointX = 0,
    anchorPointY = 0,
    rotation = 0,
    index = 0,
    isHidden = false,
  }: BaseLayerFactoryOptions,
): VersionTypes.BaseLayer & { ty: TLayerType } => ({
  ty: layerType,
  meta: {
    uuid,
  },
  nm: name,
  ao: 0,
  bm: VersionTypes.BlendMode.Normal,
  ddd: 0,
  ind: index,
  ip: inPoint,
  op: outPoint,
  st: startTime,
  sr: 0,
  hasMotionBlur: false,
  hasCollapseTransformation: false,
  ks: {
    or: multiDimensionalValueFactory([0, 0, 0]),
    p: multiDimensionalValueFactory([positionX, positionY]),
    a: multiDimensionalValueFactory([anchorPointX, anchorPointY]),
    r: valueFactory(rotation),
  },
  hd: isHidden,
});

export const fontAssetFactory = ({
  id = generateUuid(),
  fontFamily,
  bfsUUID = generateUuid(),
  legacyFontWeight = 400,
  legacyFontIsItalic = false,
}: Partial<VersionTypes.ProjectManifestBitmapFontAsset> & {
  fontFamily?: string;
  bfsUUID?: string;
  legacyFontWeight?: number;
  legacyFontIsItalic?: boolean;
} = {}): VersionTypes.ProjectManifestBitmapFontAsset => {
  return {
    id: id,
    type: VersionTypes.AssetType.BitmapFont,
    location: fontFamily
      ? {
          plugin: 'bitmap-font-service',
          legacyId: fontFamily,
          weight: legacyFontWeight,
          isItalic: legacyFontIsItalic,
        }
      : {
          plugin: 'bitmap-font-service',
          id: bfsUUID,
        },
  };
};

interface TextLayerFactoryOptions extends BaseLayerFactoryOptions {
  text?: string;
  fontAssetId?: VersionTypes.ProjectManifestBitmapFontAsset['id'];
  fontFamily?: VersionTypes.TextProperties['f'];
  fontSize?: VersionTypes.TextProperties['s'];
  textTracking?: VersionTypes.TextProperties['tr'];
  textLineHeight?: VersionTypes.TextProperties['lh'];
  textBaselineShift?: VersionTypes.TextProperties['ls'];
  fillColor?: string;
  strokeColor?: string;
  textResizingStrategy?: VersionTypes.TextResizingStrategy;
}

export const textLayerFactory = ({
  text = faker.lorem.words(4),
  fontAssetId = generateUuid(),
  fontFamily,
  fontSize = 42,
  textTracking = 0,
  textLineHeight = 0,
  textBaselineShift = 0,
  fillColor,
  strokeColor,
  textResizingStrategy = VersionTypes.TextResizingStrategy.Default,
  ...baseLayerFactoryOptions
}: TextLayerFactoryOptions = {}): PMTextLayer => {
  const baseLayerData = baseLayerFactory(VersionTypes.LayerType.Text, baseLayerFactoryOptions);

  const textLayerData: PMTextLayer = {
    ...baseLayerData,
    refId: fontAssetId,
    t: {
      a: [],
      p: {},
      m: {
        g: VersionTypes.AnchorPointGrouping.All,
        a: baseLayerData.ks.a!,
      },
      d: {
        k: [
          {
            s: {
              f: fontFamily || fontAssetId,
              t: text,
              j: VersionTypes.TextJustification.Left,
              s: fontSize,
              tr: textTracking,
              lh: textLineHeight,
              ls: textBaselineShift,
            },
            t: 0,
          },
        ],
      },
    },
  };

  textLayerData.meta.textOptions ??= {};
  textLayerData.meta.textOptions.resizingStrategy = textResizingStrategy;

  if (fillColor) {
    textLayerData.t.d.k[0].s.fc = hexStringToRGBArray(fillColor);
  }

  if (strokeColor) {
    textLayerData.t.d.k[0].s.sc = hexStringToRGBArray(strokeColor);
  }

  return textLayerData;
};

export const imageAssetFactory = ({
  id = generateUuid(),
  location = {
    plugin: 'waymark',
    key: generateUuid(),
    type: 'socialproofImagesWeb',
  },
}: {
  id?: string;
  width?: number;
  height?: number;
  location?: VersionTypes.WaymarkImageLocation | VersionTypes.WaymarkTemplateStudioLocation;
} = {}): VersionTypes.ProjectManifestImageAsset => ({
  id,
  type: VersionTypes.AssetType.Image,
  location,
});

interface ImageLayerFactoryOptions extends BaseLayerFactoryOptions {
  width?: number;
  height?: number;
  imageAssetID?: string;
  modifications?: Pick<
    PMImageLayer,
    | 'contentAdjustments'
    | 'contentBackgroundFill'
    | 'contentCropping'
    | 'contentFillColor'
    | 'contentFit'
    | 'contentFitFillAlignment'
    | 'contentPadding'
    | 'contentZoom'
  >;
}

export const imageLayerFactory = ({
  imageAssetID = generateUuid(),
  width = 1920,
  height = 1080,
  modifications = {},
  ...baseLayerFactoryOptions
}: ImageLayerFactoryOptions = {}): PMImageLayer => {
  const baseLayerData = baseLayerFactory(VersionTypes.LayerType.Image, baseLayerFactoryOptions);

  return {
    ...baseLayerData,
    w: width,
    h: height,
    refId: imageAssetID,
    ...modifications,
  };
};

export const footageAssetFactory = ({
  id = generateUuid(),
  location = {
    plugin: 'waymark-vps',
    sourceVideo: generateUuid(),
  },
}: {
  id?: string;
  location?: VersionTypes.WaymarkVpsLocation;
} = {}): VersionTypes.ProjectManifestVideoAsset => {
  return {
    id,
    type: VersionTypes.AssetType.Video,
    location,
  };
};

interface FootageLayerFactoryOptions extends BaseLayerFactoryOptions {
  footageAssetID?: string;
  width?: number;
  height?: number;
  modifications?: Pick<
    PMFootageLayer,
    | 'contentBackgroundFill'
    | 'contentCropping'
    | 'contentFit'
    | 'contentFitFillAlignment'
    | 'contentPadding'
    | 'contentPlaybackDuration'
    | 'contentTrimDuration'
    | 'contentTrimStartTime'
    | 'contentZoom'
  >;
}

export const footageLayerFactory = ({
  footageAssetID = generateUuid(),
  width = 1920,
  height = 1080,
  modifications = {},
  ...baseLayerFactoryOptions
}: FootageLayerFactoryOptions = {}): PMFootageLayer => {
  return {
    ...baseLayerFactory(VersionTypes.LayerType.WaymarkVideo, baseLayerFactoryOptions),
    refId: footageAssetID,
    w: width,
    h: height,
    ...modifications,
  };
};

interface SolidLayerFactoryOptions extends BaseLayerFactoryOptions {
  fillColor?: string;
  width?: number;
  height?: number;
}

export const solidLayerFactory = ({
  fillColor = faker.internet.color(),
  width = 100,
  height = 100,
  ...baseLayerFactoryOptions
}: SolidLayerFactoryOptions = {}): VersionTypes.SolidLayer => ({
  ...baseLayerFactory(VersionTypes.LayerType.Solid, baseLayerFactoryOptions),
  sc: fillColor,
  sh: height,
  sw: width,
});

interface BaseShapeFactoryOptions {
  isHidden?: boolean;
}

const baseShapeFactory = <TShapeType extends `${VersionTypes.ShapeType}`>(
  shapeType: TShapeType,
  { isHidden = false }: BaseShapeFactoryOptions = {},
): Omit<VersionTypes.BaseShape, 'ty'> & { ty: TShapeType } => ({
  ty: shapeType,
  mn: faker.word.noun(),
  nm: faker.word.noun(),
  hd: isHidden,
});

interface RectangleShapeFactoryOptions extends BaseShapeFactoryOptions {
  positionX?: number;
  positionY?: number;
  width?: number;
  height?: number;
  cornerRadius?: number;
  direction?: VersionTypes.ShapeDirection;
}

export const rectangleShapeFactory = ({
  positionX = 0,
  positionY = 0,
  width = 100,
  height = 100,
  cornerRadius = 0,
  direction = VersionTypes.ShapeDirection.Forward,
  ...baseShapeFactoryOptions
}: RectangleShapeFactoryOptions = {}): VersionTypes.RectangleShape => ({
  ...baseShapeFactory(VersionTypes.ShapeType.Rectangle, baseShapeFactoryOptions),
  d: direction,
  p: multiDimensionalValueFactory([positionX, positionY]),
  s: multiDimensionalValueFactory([width, height]),
  r: valueFactory(cornerRadius),
});

interface EllipseShapeFactoryOptions extends BaseShapeFactoryOptions {
  direction?: VersionTypes.ShapeDirection;
  positionX?: number;
  positionY?: number;
  width?: number;
  height?: number;
}

export const ellipseShapeFactory = ({
  direction = VersionTypes.ShapeDirection.Forward,
  positionX = 0,
  positionY = 0,
  width = 100,
  height = 100,
  ...baseShapeFactoryOptions
}: EllipseShapeFactoryOptions = {}): VersionTypes.EllipseShape => ({
  ...baseShapeFactory(VersionTypes.ShapeType.Ellipse, baseShapeFactoryOptions),
  d: direction,
  p: multiDimensionalValueFactory([positionX, positionY]),
  s: multiDimensionalValueFactory([width, height]),
});

interface FillShapeFactoryOptions extends BaseShapeFactoryOptions {
  fillColor?: string;
  opacity?: number;
  fillRule?: VersionTypes.FillRule;
}
export const fillShapeFactory = ({
  fillColor = faker.internet.color(),
  opacity = 1,
  fillRule = VersionTypes.FillRule.NonZeroWinding,
  ...baseShapeFactoryOptions
}: FillShapeFactoryOptions = {}): VersionTypes.FillShape => ({
  ...baseShapeFactory(VersionTypes.ShapeType.Fill, baseShapeFactoryOptions),
  c: multiDimensionalValueFactory(hexStringToRGBArray(fillColor)),
  o: valueFactory(opacity),
  r: fillRule,
});

interface StrokeShapeFactoryOptions extends BaseShapeFactoryOptions {
  strokeColor?: string;
  strokeWidth?: number;
  opacity?: number;
  lineCap?: VersionTypes.LineCap;
  lineJoin?: VersionTypes.LineJoin;
}

export const strokeShapeFactory = ({
  strokeColor = faker.internet.color(),
  opacity = 1,
  strokeWidth = 1,
  lineCap = VersionTypes.LineCap.Butt,
  lineJoin = VersionTypes.LineJoin.Miter,
  ...baseShapeFactoryOptions
}: StrokeShapeFactoryOptions): VersionTypes.StrokeShape => ({
  ...baseShapeFactory(VersionTypes.ShapeType.Stroke, baseShapeFactoryOptions),
  c: multiDimensionalValueFactory(hexStringToRGBArray(strokeColor)),
  o: valueFactory(opacity),
  w: valueFactory(strokeWidth),
  lj: lineJoin,
  lc: lineCap,
});

interface GradientFillShapeFactoryOptions extends BaseShapeFactoryOptions {
  gradientColors?: {
    position: number;
    color: string;
  }[];
  opacity?: number;
  fillRule?: VersionTypes.FillRule;
  gradientType?: VersionTypes.GradientType;
}

export const gradientFillShapeFactory = ({
  gradientColors = [
    {
      position: 0,
      color: faker.internet.color(),
    },
    {
      position: 1,
      color: faker.internet.color(),
    },
  ],
  opacity = 1,
  fillRule = VersionTypes.FillRule.NonZeroWinding,
  gradientType = VersionTypes.GradientType.Linear,
  ...baseShapeFactoryOptions
}: GradientFillShapeFactoryOptions): VersionTypes.GradientFillShape => {
  const colorStops = new Array<number>();
  for (let i = 0; i < gradientColors.length; i++) {
    const { position, color } = gradientColors[i];
    const currentColorStop = [position, ...hexStringToRGBArray(color)] as [
      number,
      number,
      number,
      number,
    ];

    if (i > 0) {
      const previousColorStop = colorStops.slice((i - 1) * 4, 4) as [
        number,
        number,
        number,
        number,
      ];
      colorStops.push(
        ...interpolateGradientColorStops(
          previousColorStop,
          currentColorStop,
          previousColorStop[0] + (currentColorStop[0] - previousColorStop[0]) / 2,
        ),
      );
    }
    colorStops.push(...currentColorStop);
  }

  return {
    ...baseShapeFactory(VersionTypes.ShapeType.GradientFill, baseShapeFactoryOptions),
    o: valueFactory(opacity),
    r: fillRule,
    g: {
      p: gradientColors.length,
      k: {
        a: 0,
        k: colorStops,
        ix: 0,
      },
    },
    s: multiDimensionalValueFactory([0, 0]),
    e: multiDimensionalValueFactory([1, 1]),
    t: gradientType,
  };
};

interface ShapeLayerFactoryOptions extends BaseLayerFactoryOptions {
  shapes?: VersionTypes.ShapeItem[];
}

export const shapeLayerFactory = ({
  shapes = [rectangleShapeFactory(), fillShapeFactory()],
  ...baseLayerFactoryOptions
}: ShapeLayerFactoryOptions = {}): VersionTypes.ShapeLayer => ({
  ...baseLayerFactory(VersionTypes.LayerType.Shape, baseLayerFactoryOptions),
  shapes: shapes,
});

export const precompAssetFactory = ({
  id = generateUuid(),
  layers = [],
  name = 'Precomp',
}: {
  id?: string;
  layers?: VersionTypes.Layer[];
  name?: string;
} = {}): VersionTypes.PreCompSource => ({
  id,
  layers: layers,
  nm: name,
});

interface PrecompLayerFactoryOptions extends BaseLayerFactoryOptions {
  compID?: string;
  width?: number;
  height?: number;
}

export const precompLayerFactory = ({
  compID = generateUuid(),
  width = 1920,
  height = 1080,
  ...baseLayerFactoryOptions
}: PrecompLayerFactoryOptions = {}): VersionTypes.PreCompLayer => ({
  ...baseLayerFactory(VersionTypes.LayerType.Precomp, baseLayerFactoryOptions),
  refId: compID,
  w: width,
  h: height,
});

export const audioAssetFactory = ({
  id = generateUuid(),
  location = {
    plugin: 'waymark-aps',
    sourceAudio: generateUuid(),
  },
}: {
  id?: string;
  location?: VersionTypes.ProjectManifestAudioAsset['location'];
} = {}): VersionTypes.ProjectManifestAudioAsset => ({
  id,
  type: VersionTypes.AssetType.Audio,
  location,
});

interface AudioLayerFactoryOptions extends BaseLayerFactoryOptions {
  audioAssetID?: string;
}

export const audioLayerFactory = ({
  audioAssetID = generateUuid(),
  ...baseLayerFactoryOptions
}: AudioLayerFactoryOptions = {}): VersionTypes.WaymarkAudioLayer => ({
  ...baseLayerFactory(VersionTypes.LayerType.WaymarkAudio, baseLayerFactoryOptions),
  refId: audioAssetID,
});

export const createBlankVideoDescriptorData = ({
  duration = 900,
  width = 1920,
  height = 1080,
  name = 'Main Comp',
  frameRate = 30,
  layers = [],
  assets = [],
}: {
  duration?: number;
  width?: number;
  height?: number;
  name?: string;
  frameRate?: number;
  layers?: VersionTypes.Layer[];
  assets?: VersionTypes.Source[];
} = {}): VersionTypes.VideoDescriptor => {
  return {
    version: '1.0.0',
    __templateSlug: null,
    templateManifest: {
      overrides: [],
      layersExtendedAttributes: {},
      sceneGroups: [],
      sceneSwitches: [],
      __backgroundAudioLayerUUID: '1234',
    },
    projectManifest: {
      ip: 0,
      op: duration,
      fr: frameRate,
      w: width,
      h: height,
      ddd: 0,
      v: '4.8.0',
      assets,
      nm: name,
      layers,
    },
  };
};

export const sceneGroupFactory = ({
  id = generateUuid(),
  name = `Scene Group ${id} `,
  layers,
  layerUUIDs = layers?.map((layer) => layer.meta.uuid) ??
    Array.from({ length: 3 }, () => generateUuid()),
}: {
  id?: string;
  name?: string;
  layers?: VersionTypes.Layer[];
  layerUUIDs?: string[];
}): VersionTypes.SceneGroup => ({
  id,
  name,
  layers: layerUUIDs,
});

export const sceneSwitchFactory = ({
  id = generateUuid(),
  frameNumber = 100,
  defaultSelection = generateUuid(),
  activeValue = generateUuid(),
  name = `Scene Switch ${id} `,
  groups,
  groupIDs = groups?.map((group) => group.id) ?? Array.from({ length: 3 }, () => generateUuid()),
}: {
  id?: string;
  frameNumber?: number;
  defaultSelection?: string;
  activeValue?: string | undefined;
  name?: string;
  groups?: VersionTypes.SceneGroup[];
  groupIDs?: string[];
}): VersionTypes.SceneSwitch => ({
  id,
  name,
  frameNumber,
  defaultSelection,
  __activeValue: activeValue,
  groups: groupIDs,
});

export const imageReferenceFactory = ({
  id = generateUuid(),
  activeValue = generateUuid(),
  frameNumber = 100,
  name = `Image Reference ${id} `,
}: {
  id?: string;
  activeValue?: string;
  frameNumber?: number;
  name?: string;
} = {}): VersionTypes.ImageOverrideProperties => ({
  type: 'image',
  id,
  __activeValue: activeValue,
  frameNumber,
  name,
});

export const textReferenceFactory = ({
  id = generateUuid(),
  activeValue = faker.word.words(5),
  frameNumber = 100,
  name = `Text Reference ${id} `,
}: {
  id?: string;
  activeValue?: string;
  frameNumber?: number;
  name?: string;
}): VersionTypes.TextOverrideProperties => ({
  type: 'text',
  id,
  __activeValue: activeValue,
  frameNumber,
  name,
});

export const fontReferenceFactory = ({
  id = generateUuid(),
  activeValue = generateUuid(),
  frameNumber = 100,
  name = `Font Reference ${id} `,
  originalTypography = {
    family: 'Arial',
    weight: 400,
    style: 'normal',
  },
}: {
  id?: string;
  activeValue?: string;
  frameNumber?: number;
  name?: string;
  originalTypography?: VersionTypes.FontOverrideProperties['originalTypography'];
}): VersionTypes.FontOverrideProperties => ({
  type: 'font',
  id,
  __activeValue: activeValue,
  originalTypography,
  frameNumber,
  name,
});

export const colorReferenceFactory = ({
  id = generateUuid(),
  name = `Color Reference ${id} `,
  activeValue = faker.internet.color(),
  frameNumber = 100,
  displayName = faker.color.human(),
}: {
  id?: string;
  name?: string;
  activeValue?: string;
  frameNumber?: number;
  displayName?: string;
} = {}): VersionTypes.ColorOverrideProperties => ({
  type: 'color',
  id,
  __activeValue: activeValue,
  frameNumber,
  name,
  displayName,
});
