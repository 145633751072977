import type { GlobalSettings } from '../globalSettings';

export type ServiceSettings = {
  'service.aps.assetHost': string;
  'service.bfs.apiEndpoint': string;
  'service.bfs.s3Host': string;
};

export const applyDefaultServiceSettings = (settings: GlobalSettings<ServiceSettings>) => {
  // There is only one value for the BFS settings for now, so we'll set the defaults
  settings.set('service.bfs.apiEndpoint', 'https://l4n96idusk.execute-api.us-east-2.amazonaws.com');
  settings.set('service.bfs.s3Host', 'https://d37jciuk89b6o9.cloudfront.net');
};
