import { CustomEventTarget } from '@libs/util-ts';
import { FontAsset, FootageAsset, ImageAsset, VideoDescriptorSource, AudioAsset } from './assets';
import { Switch } from './switches';
import { Composition } from './compositions';
import { ColorReference, FontReference, ImageReference, TextReference } from './references';

import { runAllMigrations } from './migrations/migrationEngine';
import type { AnyVideoDescriptor } from './migrations/types';
import { templateBundleService } from './utils/TemplateBundleService';

import {
  AudioLayer,
  FootageLayer,
  ImageLayer,
  TextLayer,
  type VideoDescriptorLayer,
} from './layers';
import type {
  VideoDescriptor as PMVideoDescriptor,
  SerializedVideoDescriptor,
} from '@libs/waymark-video/video-descriptor-types';

// TODO: Allow creation of empty VideoDecriptor?
export class VideoDescriptor extends CustomEventTarget<{
  layerAdded: VideoDescriptorLayer;
  layerRemoved: VideoDescriptorLayer;
  assetRemoved: VideoDescriptorSource;
  compositionRemoved: Composition;
  // Event emitted every time a change is made to the underlying raw video descriptor data.
  // This event is debounced to avoid emitting a fire-hose of events as a change is made.
  change: void;
}> {
  rawData: PMVideoDescriptor;

  readonly rootCompID: string;
  compositions: Map<string, Composition>;

  switches: Switch[];

  // Map of asset IDs to asset instances
  assets: Map<string, VideoDescriptorSource>;
  // Maps reference IDs to reference instances, grouped by type
  // Grouping by type makes typings easier and allows simpler lookup of all references of a given type
  references: {
    text: Record<string, TextReference>;
    image: Record<string, ImageReference>;
    color: Record<string, ColorReference>;
    font: Record<string, FontReference>;
  };

  static async from(serialized: SerializedVideoDescriptor): Promise<VideoDescriptor> {
    let videoDescriptorData: AnyVideoDescriptor;
    if ('templateSlug' in serialized && 'configuration' in serialized) {
      const bundleJson = await templateBundleService.getTemplateBundle(serialized.templateSlug);

      if (!bundleJson) {
        throw new Error(`Could not retrieve bundle ${serialized.templateSlug}`);
      }

      videoDescriptorData = {
        projectManifest: bundleJson.projectManifest,
        templateManifest: bundleJson.templateManifest,
        __activeConfiguration: serialized.configuration ?? bundleJson.placeholderConfiguration,
        __templateSlug: serialized.templateSlug,
      };
    } else {
      videoDescriptorData = serialized;
    }
    return new VideoDescriptor(await runAllMigrations(videoDescriptorData));
  }

  toJSON(): SerializedVideoDescriptor {
    return this.rawData;
  }

  toJSONString(): string {
    return JSON.stringify(this.rawData);
  }

  constructor(serialized: PMVideoDescriptor) {
    super();

    // Deeply proxy the raw data object so we can emit a `change` event any time it is modified.
    // This is mainly used for the editor to know when to save changes to the DB
    this.rawData = serialized;

    /**
     * NOTE: the order of how we initialize everything in this constructor is very important!
     * Switches depend on layers (created by Compositions), which may depend on references, which may depend on assets.
     * So we need to make sure the order of creation is always:
     * 1. Assets
     * 2. References
     * 3. Compositions
     * 4. Switches
     */

    const rawAssets = this.rawData.projectManifest.assets;
    this.assets = new Map();

    for (const rawAsset of rawAssets) {
      if ('type' in rawAsset) {
        switch (rawAsset.type) {
          case 'image':
            this.assets.set(rawAsset.id, new ImageAsset(rawAsset));
            break;
          case 'video':
            this.assets.set(rawAsset.id, new FootageAsset(rawAsset));
            break;
          case 'bitmapFont':
            this.assets.set(rawAsset.id, new FontAsset(rawAsset));
            break;
          case 'audio':
            this.assets.set(rawAsset.id, new AudioAsset(rawAsset));
            break;
        }
      }
    }

    this.references = {
      text: {},
      color: {},
      image: {},
      font: {},
    };

    for (const rawReferenceData of this.rawData.templateManifest.overrides ?? []) {
      switch (rawReferenceData.type) {
        case 'text':
          this.references.text[rawReferenceData.id] = new TextReference(rawReferenceData, this);
          break;
        case 'image':
          this.references.image[rawReferenceData.id] = new ImageReference(rawReferenceData, this);
          break;
        case 'color':
          this.references.color[rawReferenceData.id] = new ColorReference(rawReferenceData, this);
          break;
        case 'font':
          this.references.font[rawReferenceData.id] = new FontReference(rawReferenceData, this);
          break;
      }
    }

    this.rootCompID = String(this.rawData.projectManifest.compId ?? '__ROOT__');

    this.compositions = new Map();
    this.compositions.set(
      this.rootCompID,
      new Composition(this.rootCompID, this.rawData.projectManifest.layers, this),
    );

    const templateManifest = this.rawData.templateManifest;

    this.switches =
      templateManifest.sceneSwitches?.map((sceneSwitchData) => new Switch(sceneSwitchData, this)) ??
      [];
  }

  getRootComposition(): Composition {
    const rootComposition = this.compositions.get(this.rootCompID);
    if (!rootComposition) {
      throw new Error(`Root composition with ID ${this.rootCompID} not found in video descriptor`);
    }
    return rootComposition;
  }

  removeComposition(composition: Composition) {
    this.compositions.delete(composition.id);
    this.dispatchEvent('compositionRemoved', composition);
  }

  getFramerate() {
    return this.rawData.projectManifest.fr;
  }

  getDimensions() {
    return {
      width: this.rawData.projectManifest.w,
      height: this.rawData.projectManifest.h,
    };
  }

  /**
   * Updates the raw data of the video descriptor and emits a `change` event. It is highly recommended to use this
   * method to update the raw data of the video descriptor, as it ensures that the `change` event is emitted.
   *
   * @param newRawData - A partial object with the new raw data to merge into the existing raw data, or a function that takes the current raw data and returns
   *                      a partial object with new raw data.
   */
  updateRawData(
    newRawData:
      | Partial<PMVideoDescriptor>
      | ((currentRawData: Readonly<PMVideoDescriptor>) => Partial<PMVideoDescriptor>),
  ) {
    if (typeof newRawData === 'function') {
      Object.assign(this.rawData, newRawData(this.rawData));
    } else {
      Object.assign(this.rawData, newRawData);
    }
    this.dispatchEvent('change');
  }

  getAllLayers(): VideoDescriptorLayer[] {
    // Gather all layers from the root composition and all sub-compositions into a single array
    const layers = new Array<VideoDescriptorLayer>();
    for (const composition of this.compositions.values()) {
      layers.push(...composition.layers);
    }
    return layers;
  }

  /**
   * Finds a layer matching a predicate.
   */
  findLayer<TPredicateLayerType extends VideoDescriptorLayer>(
    predicate:
      | ((layer: VideoDescriptorLayer) => layer is TPredicateLayerType)
      | ((layer: VideoDescriptorLayer) => boolean),
  ): TPredicateLayerType | undefined {
    for (const composition of this.compositions.values()) {
      for (const layer of composition.layers) {
        if (predicate(layer)) {
          return layer as TPredicateLayerType;
        }
      }
    }

    return undefined;
  }

  /**
   * Finds a layer in the video descriptor by UUID or by a predicate function.
   * @param {string} uuid - The UUID of the layer to find
   */
  findLayerByUUID(uuid: string): VideoDescriptorLayer | undefined {
    return this.findLayer((layer) => layer.getUUID() === uuid);
  }

  /**
   * Runs a callback for every layer from all compositions in the video descriptor.
   */
  forEachLayer(callback: (layer: VideoDescriptorLayer) => void) {
    for (const composition of this.compositions.values()) {
      for (const layer of composition.layers) {
        callback(layer);
      }
    }
  }

  /**
   * Takes a predicate callback and returns all layers that match the predicate.
   * If you include a type predicate on the filter callback, TypeScript will infer the correct type for the returned layers.
   */
  filterLayers<TPredicateLayerType extends VideoDescriptorLayer>(
    predicate:
      | ((layer: VideoDescriptorLayer) => layer is TPredicateLayerType)
      | ((layer: VideoDescriptorLayer) => boolean),
  ): TPredicateLayerType[] {
    const layers = new Array<TPredicateLayerType>();

    this.forEachLayer((layer) => {
      if (predicate(layer)) {
        layers.push(layer as TPredicateLayerType);
      }
    });

    return layers;
  }

  /**
   * Registers an asset in the VideoDescriptor. This is necessary to run every time a new asset is
   * set on a layer to ensure the layer can safely reference that asset.
   */
  addAsset(asset: VideoDescriptorSource) {
    const assetID = asset.getID();

    this.assets.set(assetID, asset);

    this.updateRawData((currentRawData) => {
      const updatedAssets = [...currentRawData.projectManifest.assets];

      const assetIndex = updatedAssets.findIndex(({ id }) => id === assetID);

      if (assetIndex === -1) {
        updatedAssets.push(asset.rawAssetData);
      } else {
        updatedAssets[assetIndex] = asset.rawAssetData;
      }

      return {
        projectManifest: {
          ...currentRawData.projectManifest,
          assets: updatedAssets,
        },
      };
    });
  }

  /**
   * Removes an asset from the VideoDescriptor. This is necessary to run every time an asset is
   * removed/replaced on a layer to avoid memory leaks.
   * If the asset is still referenced by another layer, this method will do nothing.
   */
  removeAsset(asset: VideoDescriptorSource) {
    // Determine if the asset is still being used by any layers and skip removal if so
    if (asset instanceof ImageAsset) {
      // Make sure no image references or image layers are using this image asset
      for (const imageReferences of Object.values(this.references.image)) {
        if (imageReferences.getImageAsset() === asset) {
          return;
        }
      }

      if (this.findLayer((layer) => layer instanceof ImageLayer && layer.getAsset() === asset)) {
        return;
      }
    } else if (asset instanceof FootageAsset) {
      // Make sure no footage layers are using this footage asset
      if (this.findLayer((layer) => layer instanceof FootageLayer && layer.getAsset() === asset)) {
        return;
      }
    } else if (asset instanceof FontAsset) {
      // Make sure no font references or text layers are using this font asset
      for (const fontReferences of Object.values(this.references.font)) {
        if (fontReferences.getFontAsset() === asset) {
          return;
        }
      }
      if (this.findLayer((layer) => layer instanceof TextLayer && layer.getFontAsset() === asset)) {
        return;
      }
    } else if (asset instanceof AudioAsset) {
      // Make sure no audio layers are using this audio asset
      if (this.findLayer((layer) => layer instanceof AudioLayer && layer.getAsset() === asset)) {
        return;
      }
    } else {
      throw new Error(`Attempted to remove unsupported asset type: ${asset}`);
    }

    const assetID = asset.getID();
    if (this.assets.get(assetID) === asset) {
      // This asset could have been replaced with a new asset with the same id,
      // in which case we don't want to delete it
      this.assets.delete(assetID);

      this.updateRawData((currentRawData) => {
        const assetIndex = currentRawData.projectManifest.assets.findIndex(
          ({ id }) => id === assetID,
        );

        if (assetIndex === -1) {
          return {};
        }

        const updatedAssets = currentRawData.projectManifest.assets
          .slice(0, assetIndex)
          .concat(currentRawData.projectManifest.assets.slice(assetIndex + 1));

        return {
          projectManifest: {
            ...currentRawData.projectManifest,
            assets: updatedAssets,
          },
        };
      });
    }

    this.dispatchEvent('assetRemoved', asset);
  }

  /**
   * Gets the background audio layer for this video descriptor.
   */
  getBackgroundAudioLayer(): AudioLayer | null {
    const backgroundAudioLayerUUID = this.rawData.templateManifest.__backgroundAudioLayerUUID;
    if (!backgroundAudioLayerUUID) {
      return null;
    }
    const backgroundAudioLayer = this.findLayerByUUID(backgroundAudioLayerUUID);
    if (!(backgroundAudioLayer instanceof AudioLayer)) {
      return null;
    }
    return backgroundAudioLayer;
  }

  /**
   * Gets all auxiliary audio layers for this video descriptor.
   */
  getAuxiliaryAudioLayers(): AudioLayer[] {
    const backgroundAudioLayerUUID = this.rawData.templateManifest.__backgroundAudioLayerUUID;
    // Get all audio layers which are not the background audio layer
    return this.filterLayers(
      (layer): layer is AudioLayer =>
        layer instanceof AudioLayer && layer.getUUID() !== backgroundAudioLayerUUID,
    );
  }

  /**
   * Creates a new background audio layer and adds it to this video descriptor's root composition.
   * @param initialAudioAsset - Initial audio asset to use for the layer
   */
  createBackgroundAudioLayer(initialAudioAsset: AudioAsset) {
    // We should only have one background audio layer, so if there is already one, we should remove it.
    const backgroundAudioLayer = this.getBackgroundAudioLayer();
    if (backgroundAudioLayer) {
      this.getRootComposition().removeLayer(backgroundAudioLayer);
    }

    /**
     * The renderer creates the AudioMediaHandler on the layerAdded event, and the editor uses the
     * layerAdded event in a hook to update the background audio layer variable, _but_ when the layer is
     * added it is not yet known to be the background audio layer because the __backgroundAudioLayerUUID
     * is not yet set. So we suppress the layerAdded event on the createAudioLayer call and then manually
     * emit the event after the UUID is assigned.
     */
    const newAudioLayer = AudioLayer.createAudioLayer(initialAudioAsset, this, true);
    this.rawData.templateManifest.__backgroundAudioLayerUUID = newAudioLayer.getUUID();
    this.dispatchEvent('layerAdded', newAudioLayer);
    return newAudioLayer;
  }

  /**
   * Creates a new auxiliary audio layer and adds it to this video descriptor's root composition.
   *
   * @param initialAudioAsset - Initial audio asset to use for the layer
   * @param shouldCopyBackgroundAudioVolumeChanges - Whether to copy volume changes from the background audio layer. This is usually
   *                                                  what we want because we usually want to apply the same volume ducking to both background and auxiliary audio layers.
   */
  createAuxiliaryAudioLayer(
    initialAudioAsset: AudioAsset,
    shouldCopyBackgroundAudioVolumeChanges = true,
  ) {
    const newAudioLayer = AudioLayer.createAudioLayer(initialAudioAsset, this);

    // this.getRootComposition().removeLayer(newAudioLayer);

    if (shouldCopyBackgroundAudioVolumeChanges) {
      const backgroundAudioLayer = this.getBackgroundAudioLayer();
      if (backgroundAudioLayer) {
        newAudioLayer.setVolumeChanges(backgroundAudioLayer.getVolumeChanges());
      }
    }

    return newAudioLayer;
  }

  getColorReferences(): ColorReference[] {
    return Object.values(this.references.color).sort(
      // Sort by ascending ideal frame number
      (refA, refB) => refA.getIdealDisplayFrame() - refB.getIdealDisplayFrame(),
    );
  }

  getFontReferences(): FontReference[] {
    return Object.values(this.references.font).sort(
      // Sort by ascending ideal frame number
      (refA, refB) => refA.getIdealDisplayFrame() - refB.getIdealDisplayFrame(),
    );
  }

  getInAndOutPointFrames(): { inPoint: number; outPoint: number } {
    return {
      inPoint: this.rawData.projectManifest.ip,
      outPoint: this.rawData.projectManifest.op,
    };
  }

  getInAndOutPointSeconds(): { inPoint: number; outPoint: number } {
    const frameRate = this.getFramerate();
    const { inPoint, outPoint } = this.getInAndOutPointFrames();
    return {
      inPoint: inPoint / frameRate,
      outPoint: outPoint / frameRate,
    };
  }
}
