import { customAlphabet } from 'nanoid';
import { nolookalikes } from 'nanoid-dictionary';

import { SOURCE_VIDEOS_KEY_PREFIX } from './constants';
import { environments, VPSEnvironmentKey } from './environments';

export const generateKey = () => {
  return `${Math.floor(Date.now())}_${customAlphabet(nolookalikes, 10)()}`;
};

export const getPath = (sourceVideoKey: string, environmentKey: VPSEnvironmentKey) => {
  const environment = environments[environmentKey];
  return `${environment.bucketRoot}/${SOURCE_VIDEOS_KEY_PREFIX}/${sourceVideoKey}`;
};

export const getUrl = (sourceVideoKey: string, environmentKey: VPSEnvironmentKey) => {
  const environment = environments[environmentKey];
  return `https://${environment.bucket}.s3.${environment.region}.amazonaws.com/${getPath(
    sourceVideoKey,
    environmentKey,
  )}`;
};
