import type { VideoDescriptor } from '..';
import { Override } from '@libs/waymark-video/video-descriptor-types';
import { CustomEventTarget } from '@libs/util-ts';

export abstract class BaseReference<
  TOverrideType extends Override,
  TValueType = TOverrideType['__activeValue'],
> extends CustomEventTarget<{
  'change:value': TValueType;
}> {
  rawReferenceData: TOverrideType;
  videoDescriptor: VideoDescriptor;

  constructor(referenceData: TOverrideType, videoDescriptor: VideoDescriptor) {
    super();

    this.rawReferenceData = referenceData;
    this.videoDescriptor = videoDescriptor;
  }

  getUUID() {
    return this.getID();
  }

  getID() {
    return this.rawReferenceData.id;
  }

  getLabel() {
    return this.rawReferenceData.name;
  }

  getIdealDisplayFrame(): number {
    return this.rawReferenceData.frameNumber ?? 0;
  }

  getIdealDisplayTime() {
    return this.getIdealDisplayFrame() / this.videoDescriptor.getFramerate();
  }

  updateRawReferenceData(newData: Partial<TOverrideType>) {
    Object.assign(this.rawReferenceData, newData);
    this.videoDescriptor.dispatchEvent('change', undefined);
  }

  abstract toString(): string;
}
