import { Composition } from '../compositions/Composition';
import { PreCompLayer, PreCompSource } from '@libs/waymark-video/video-descriptor-types';
import { BaseLayer } from './BaseLayer';

/**
 * A layer that references a sub-composition.
 */
export class SubCompositionLayer extends BaseLayer<PreCompLayer> {
  composition: Composition;

  constructor(serializedLayerData: PreCompLayer, parentComposition: Composition) {
    super(serializedLayerData, parentComposition);

    const compositionID = this.rawLayerData.refId;

    let composition = this.videoDescriptor.compositions.get(compositionID);
    if (!composition) {
      const compositionSource = this.videoDescriptor.rawData.projectManifest.assets.find(
        (asset): asset is PreCompSource => asset.id === compositionID && 'layers' in asset,
      );
      if (!compositionSource) {
        throw new Error(
          `${this.toString()}: Composition with ID ${compositionID} not found in project manifest`,
        );
      }
      composition = new Composition(compositionID, compositionSource?.layers, this.videoDescriptor);
      this.videoDescriptor.compositions.set(compositionID, composition);
    } else if (composition === parentComposition) {
      throw new Error(
        `${this.toString()}: Encountered circular reference to composition ${composition.toString()}. A composition cannot contain a subcomp layer which references itself`,
      );
    }

    this.composition = composition;
    this.composition.registerCompositionInstanceLayer(this);
  }
}
