import { PROCESSED_VIDEOS_KEY_PREFIX } from './constants';
import { environments, VPSEnvironmentKey } from './environments';

interface ProcessingDefinition {
  format: string;
  output: string;
  outputOptions?: string[];
}

export const processingDefinitions = {
  // master is our archive format, constrained at 1920px width, maintains aspect ratio, forces 30fps and 4:2:0 color space
  master: {
    format: 'mkv:mpeg2video_50000k_1920x0_30fps:flac_512k_48000hz_stereo:yuv420p',
    output: '$root/$presetKey/$sourceKey.mkv',
  },
  // webPlayer is constrained at 720px width, maintains aspect ratio, forces 30fps, is optimized for download speed and pixel count (WebGL performance)
  webPlayer_h264: {
    format: 'mp4:h264_1500k_900x0_30fps:aac_128k_44100hz_stereo:yuv420p',
    output: '$root/$presetKey/$sourceKey.mp4',
  },
  webPlayer_h265: {
    format: 'mp4:hevc_700k_900x0_30fps:aac_128k_44100hz_stereo:yuv420p',
    output: '$root/$presetKey/$sourceKey.mp4',
  },
  webPlayer_vp9: {
    format: 'webm:vp9_700k_900x0_30fps:opus_96k_44100hz_stereo:yuv420p',
    output: '$root/$presetKey/$sourceKey.webm',
  },
  // The final asset is constrained at 1920, maintains aspect ratio, forces 30fps and a 4:2:0 color space
  finalAsset_h264: {
    format: 'mp4:h264_7552k_1920x0_30fps:aac_256k_44100hz_stereo:yuv420p',
    output: '$root/$presetKey/$sourceKey.mp4',
  },
  gif300: {
    format: 'gif:300x',
    output: '$root/$presetKey/$sourceKey.gif',
  },
  storyboardTemplateA_jpg1200: {
    format: 'jpg:1200x',
    output: '$root/$presetKey/$sourceKey.jpg',
    outputOptions: ['storyboard_template=a', 'storyboard=yes', 'storyboard_border_color=#D8E3EB'],
  },
  tenThumbnails_jpg300: {
    format: 'jpg:300x',
    output: '$root/$presetKey/$sourceKey/thumbnail_#num#.jpg',
    outputOptions: ['number=10'],
  },
  everyTwoSpritesheet_jpg200: {
    format: 'jpg:200x',
    output: '$root/$presetKey/$sourceKey.jpg',
    outputOptions: ['every=2', 'sprite=yes', 'vtt=yes'],
  },
} as const satisfies Record<string, ProcessingDefinition>;

export type VPSDefinitionKey = keyof typeof processingDefinitions;

export const getCocountFormatEntry = (
  sourceVideoKey: string,
  processingDefinitionKey: VPSDefinitionKey,
  environmentKey: VPSEnvironmentKey,
  {
    accessKeyId,
    secretAccessKey,
  }: {
    accessKeyId: string;
    secretAccessKey: string;
  },
) => {
  const processingDefinition = processingDefinitions[processingDefinitionKey];
  const environment = environments[environmentKey];

  const root = `s3://${accessKeyId}:${secretAccessKey}@${environment.bucket}/${environment.bucketRoot}/${PROCESSED_VIDEOS_KEY_PREFIX}`;
  const formatOutputSansOptions = processingDefinition.output
    .replaceAll('$root', root)
    .replaceAll('$presetKey', processingDefinitionKey)
    .replaceAll('$sourceKey', sourceVideoKey);

  const outputOptions =
    'outputOptions' in processingDefinition ? processingDefinition.outputOptions : [];
  const formatOutput = [formatOutputSansOptions, ...outputOptions].join(', ');

  return [processingDefinition.format, formatOutput];
};

const renderOutputUrl = (
  sourceKey: string,
  processingDefinitionKey: VPSDefinitionKey,
  environmentKey: VPSEnvironmentKey,
  extraReplacements: Record<string, string> = {},
) => {
  const processingDefinition = processingDefinitions[processingDefinitionKey];
  const environment = environments[environmentKey];

  const root = `${environment.outputHost}/${environment.bucketRoot}/${PROCESSED_VIDEOS_KEY_PREFIX}`;
  let outputPath = processingDefinition.output
    .replaceAll('$root', root)
    .replaceAll('$presetKey', processingDefinitionKey)
    .replaceAll('$sourceKey', sourceKey);

  for (const replacementKey in extraReplacements) {
    outputPath = outputPath.replaceAll(replacementKey, extraReplacements[replacementKey]);
  }

  return outputPath;
};

const replaceFileExtension = (source: string, newExtension: string) => {
  const extensionPosition = source.lastIndexOf('.');
  const transformedSource = `${
    extensionPosition < 0 ? source : source.substring(0, extensionPosition)
  }${newExtension}`;
  return transformedSource;
};

export const getLocations = (
  sourceKey: string,
  processingDefinitionKey: VPSDefinitionKey,
  environmentKey: VPSEnvironmentKey,
): string[] => {
  const processingDefinition = processingDefinitions[processingDefinitionKey];

  if ('outputOptions' in processingDefinition) {
    for (const option of processingDefinition.outputOptions) {
      if (option.includes('number=')) {
        // If the output options specify a number, we need to generate that many locations,
        // replacing "#num#" with the one-based index of the location.
        const locationCount = parseInt(option.split('=')[1], 10);
        const locations = new Array<string>(locationCount);
        for (let i = 0; i < locationCount; i += 1) {
          locations[i] = renderOutputUrl(sourceKey, processingDefinitionKey, environmentKey, {
            '#num#': String(i + 1).padStart(2, '0'),
          });
        }
        return locations;
      } else if (option.includes('vtt=yes')) {
        // If the output options specify vtt=yes, we need to include a second location with a .vtt extension.
        const outputUrl = renderOutputUrl(sourceKey, processingDefinitionKey, environmentKey);
        return [outputUrl, replaceFileExtension(outputUrl, '.vtt')];
      }
    }
  }

  return [renderOutputUrl(sourceKey, processingDefinitionKey, environmentKey)];
};
