import { CustomEventTarget } from '@libs/util-ts';
import { ColorReference, FontReference, ImageReference, TextReference } from '../references';

import type { Composition } from '../compositions';
import type { VideoDescriptor, VideoDescriptorLayer } from '../index';
import { latest as LatestVDETypes } from '@libs/waymark-video/video-descriptor-types';
import { getFormattedColor, hexStringToRGBArray } from '../utils/colors';

// Inheritance with CustomEventTarget types gets a little funky if we have some base-level
// events which then can be extended by subclasses. We'll define a base event map here and
// all subclasses will need to include these events in their own event map to keep TypeScript
// from blowing up with all of the complex key extraction stuff that CustomEventTarget does.
type BaseLayerEventMap = {
  'change:visibility': boolean;
  'change:fillEffectColor': void;
  removed: void;
};

const NO_VALUE = Symbol('NO_VALUE');

export abstract class BaseLayer<
  TRawDataType extends LatestVDETypes.Layer,
  TEventMap extends BaseLayerEventMap = BaseLayerEventMap,
> extends CustomEventTarget<TEventMap> {
  rawLayerData: TRawDataType;
  parentComposition: Composition;
  // Keep a reference to the video descriptor instance; this is often needed for operations like looking up
  // a layer's asset.
  videoDescriptor: VideoDescriptor;

  imageContentReference: ImageReference | null = null;
  textContentReference: TextReference | null = null;
  fontReference: FontReference | null = null;
  fillColorReference: ColorReference | null = null;
  strokeColorReference: ColorReference | null = null;
  fillEffectColorReference: ColorReference | null = null;
  gradientFillColorReferences: ColorReference[] | null = null;
  // Track gradient fill color reference change listeners in an array for each gradient step so we can remove them later if needed
  gradientFillColorReferenceChangeListeners: Array<(event: CustomEvent<string>) => void> | null =
    null;

  onImageContentReferenceChange?(): void;
  onTextContentReferenceChange?(): void;
  onFontReferenceChange?(): void;
  onFillColorReferenceChange?(): void;
  onStrokeColorReferenceChange?(): void;
  onGradientFillColorReferenceChange?(gradientStepIndex: number): void;

  constructor(serializedLayerData: TRawDataType, parentComposition: Composition) {
    super();
    this.rawLayerData = serializedLayerData;
    this.parentComposition = parentComposition;
    this.videoDescriptor = parentComposition.videoDescriptor;

    // Ensure reference change handlers are bound to the class instance
    this.onImageContentReferenceChange = this.onImageContentReferenceChange?.bind(this);
    this.onTextContentReferenceChange = this.onTextContentReferenceChange?.bind(this);
    this.onFontReferenceChange = this.onFontReferenceChange?.bind(this);
    this.onFillColorReferenceChange = this.onFillColorReferenceChange?.bind(this);
    this.onStrokeColorReferenceChange = this.onStrokeColorReferenceChange?.bind(this);
    this.onFillEffectColorReferenceChange = this.onFillEffectColorReferenceChange?.bind(this);
    this.onGradientFillColorReferenceChange = this.onGradientFillColorReferenceChange?.bind(this);

    this.registerReferences();
  }

  getName(): string {
    return this.rawLayerData.nm || '';
  }

  getUUID(): string {
    // @migrationtodo: This is a temporary fix to get the UUID of the layer. We probably want to ensure this exists.
    return 'uuid' in this.rawLayerData.meta ? this.rawLayerData.meta.uuid : '';
  }

  toString(): string {
    return `${this.constructor.name}<${this.getUUID()}>`;
  }

  /**
   * Updates the layer's raw data with the new data and triggers a global video descriptor change event.
   */
  updateRawLayerData(
    newData:
      | Partial<TRawDataType>
      | ((currentRawData: Readonly<TRawDataType>) => Partial<TRawDataType>),
  ): void {
    if (typeof newData === 'function') {
      Object.assign(this.rawLayerData, newData(this.rawLayerData));
    } else {
      Object.assign(this.rawLayerData, newData);
    }
    this.videoDescriptor.dispatchEvent('change');
  }

  getEditingAttributes(): LatestVDETypes.LayerExtendedAttributes | null {
    const templateManifest = this.videoDescriptor.rawData.templateManifest;
    if (!templateManifest.layersExtendedAttributes) {
      return null;
    }

    return templateManifest.layersExtendedAttributes[this.getUUID()];
  }

  getIsEditable(): boolean {
    return this.getEditingAttributes() !== null;
  }

  getCanEditFieldType(fieldType: keyof LatestVDETypes.LayerExtendedAttributes): boolean {
    const editingAttributes = this.getEditingAttributes();
    return editingAttributes?.[fieldType] !== undefined && editingAttributes?.[fieldType] !== null;
  }

  getIsHidden(): boolean {
    return this.rawLayerData.hd ?? false;
  }
  setIsHidden(isHidden: boolean): void {
    this.updateRawLayerData({
      hd: isHidden,
      // We have to do this cast because TS is being very weird about inferring
      // that TRawDataType will definitely always have an "hd" property, and therefore
      // it is valid to set for a `Partial<TRawDataType>` value.
    } as Partial<TRawDataType>);
    this.dispatchEvent('change:visibility', isHidden);
  }

  private cachedParentLayer: VideoDescriptorLayer | null | typeof NO_VALUE = NO_VALUE;
  /**
   * Get the linked parent layer for this layer, if one exists.
   * If a layer has a linked parent, it will be positioned relative to that parent layer's
   * position.
   */
  getParentLayer() {
    if (this.cachedParentLayer !== NO_VALUE) {
      return this.cachedParentLayer;
    }

    const parentIndex = this.rawLayerData.parent;
    if (typeof parentIndex !== 'number') {
      return null;
    }

    const parentLayer =
      this.parentComposition.layers.find((layer) => layer.rawLayerData.ind === parentIndex) ?? null;
    if (!parentLayer) {
      throw new Error(
        `Parent layer with index ${parentIndex} not found in composition ${this.parentComposition.toString()}`,
      );
    }
    this.cachedParentLayer = parentLayer;

    return parentLayer;
  }

  /**
   * Gets an array of objects representing the in and out points for each instance of the layer in frames.
   * A layer can be present in a composition which is referenced in multiple places in the video, hence why
   * we need an array instead of a single i/o point.
   *
   * Returns null if the layer is not present in any active compositions in the video.
   */
  getInAndOutPointFrames(): { inPoint: number; outPoint: number }[] | null {
    const isInRootComposition = this.parentComposition.getIsRootComposition();
    const parentCompInstanceLayers = Array.from(this.parentComposition.compositionInstanceLayers);
    if (!isInRootComposition && parentCompInstanceLayers.length === 0) {
      // Return null if the layer is not in the root comp and its parent comp is not referenced by any active layers
      return null;
    }

    const parentCompositionInOutPoints = isInRootComposition
      ? [this.videoDescriptor.getInAndOutPointFrames()]
      : parentCompInstanceLayers.flatMap(
          (subCompLayer) => subCompLayer.getInAndOutPointFrames() ?? [],
        );

    return parentCompositionInOutPoints.flatMap(
      ({ inPoint: baseInPoint, outPoint: baseOutPoint }) => ({
        // Clamp in and out points to stay within the bounds of the parent layer's in and out points
        inPoint: Math.max(Math.min(baseInPoint + this.rawLayerData.ip, baseOutPoint), 0),
        outPoint: Math.max(Math.min(baseInPoint + this.rawLayerData.op, baseOutPoint), 0),
      }),
    );
  }

  getInAndOutPointSeconds(): { inPoint: number; outPoint: number }[] | null {
    const frameRate = this.videoDescriptor.getFramerate();
    return (
      this.getInAndOutPointFrames()?.map(({ inPoint, outPoint }) => ({
        inPoint: inPoint / frameRate,
        outPoint: outPoint / frameRate,
      })) ?? null
    );
  }

  private cachedIdealDisplayFrame: number | typeof NO_VALUE = NO_VALUE;
  getIdealDisplayFrame(): number {
    if (this.cachedIdealDisplayFrame !== NO_VALUE) {
      return this.cachedIdealDisplayFrame;
    }

    const editingAttributes = this.getEditingAttributes();
    if (
      editingAttributes &&
      'content' in editingAttributes &&
      editingAttributes.content !== undefined &&
      editingAttributes.content !== null
    ) {
      // If it has an override, use the override's ideal display time
      if ('override' in editingAttributes.content) {
        if (this.textContentReference) {
          this.cachedIdealDisplayFrame = this.textContentReference.getIdealDisplayFrame();
        } else if (this.imageContentReference) {
          this.cachedIdealDisplayFrame = this.imageContentReference.getIdealDisplayFrame();
        }
      } else {
        // Otherwise, use the frame number from the template manifest if it isn't null
        if (editingAttributes.content.frameNumber !== null) {
          this.cachedIdealDisplayFrame = editingAttributes.content.frameNumber;
        }
      }
    }

    if (this.cachedIdealDisplayFrame === NO_VALUE) {
      // Fall back to the layer's in point if the template manifest doesn't specify a frame number
      this.cachedIdealDisplayFrame = this.getInAndOutPointFrames()?.[0]?.inPoint ?? 0;
    }
    return this.cachedIdealDisplayFrame;
  }

  getIdealDisplayTime(): number {
    return this.getIdealDisplayFrame() / this.videoDescriptor.getFramerate();
  }

  /** ---- REFERENCES ---- */

  updateIgnoredReferences(
    ignoredReferenceUpdates: Partial<LatestVDETypes.BaseLayer['ignoredReferences']>,
  ): void {
    this.updateRawLayerData({
      ignoredReferences: {
        ...this.rawLayerData.ignoredReferences,
        ...ignoredReferenceUpdates,
      },
    } as Partial<TRawDataType>);
  }

  getIsReferenceIgnored(
    referenceType: keyof NonNullable<LatestVDETypes.BaseLayer['ignoredReferences']>,
  ): boolean {
    return this.rawLayerData.ignoredReferences?.[referenceType] ?? false;
  }

  /**
   * Goes through the layer's REFERENCES and gathers all available reference instances for the layer.
   */
  registerReferences() {
    const editingAttributes = this.getEditingAttributes();
    if (!editingAttributes) {
      return;
    }

    for (const attributeName in editingAttributes) {
      switch (attributeName as keyof LatestVDETypes.LayerExtendedAttributes) {
        case 'content': {
          const contentAttributes = editingAttributes.content;
          if (
            !contentAttributes ||
            !('override' in contentAttributes) ||
            !contentAttributes.override
          ) {
            // If the content override is not set, we don't need to do anything
            break;
          }

          // A "content" override can either be an image or text reference, depending on the layer type
          const imageContentReference =
            this.videoDescriptor.references.image[contentAttributes.override];

          if (imageContentReference) {
            this.setImageContentReference(imageContentReference);
          } else {
            const textContentReference =
              this.videoDescriptor.references.text[contentAttributes.override];
            if (textContentReference) {
              this.setTextContentReference(textContentReference);
            } else {
              throw new Error(
                `${this.toString()}: Unable to find valid TextReference or ImageReference for content override id "${
                  contentAttributes.override
                }"`,
              );
            }
          }
          break;
        }
        case 'font': {
          const fontAttributes = editingAttributes.font;
          if (!fontAttributes?.override) {
            // If the font override is not set, we don't need to do anything
            break;
          }

          const fontReference = this.videoDescriptor.references.font[fontAttributes.override];
          if (!fontReference) {
            throw new Error(
              `${this.toString()}: Unable to find valid FontReference for font override id "${
                fontAttributes.override
              }"`,
            );
          }

          this.setFontReference(fontReference);
          break;
        }
        case 'fillColor': {
          const fillColorAttributes = editingAttributes.fillColor;
          if (
            !fillColorAttributes ||
            typeof fillColorAttributes !== 'object' ||
            !fillColorAttributes.override
          ) {
            // If the fill color override is not set, we don't need to do anything
            break;
          }

          const colorReference =
            this.videoDescriptor.references.color[fillColorAttributes.override];
          if (!colorReference) {
            throw new Error(
              `${this.toString()}: Unable to find valid ColorReference for fill color reference id "${
                fillColorAttributes.override
              }"`,
            );
          }

          this.setFillColorReference(colorReference);
          break;
        }
        case 'strokeColor': {
          const strokeColorAttributes = editingAttributes.strokeColor;
          if (
            !strokeColorAttributes ||
            typeof strokeColorAttributes !== 'object' ||
            !strokeColorAttributes.override
          ) {
            // If the stroke color override is not set, we don't need to do anything
            break;
          }

          const colorReference =
            this.videoDescriptor.references.color[strokeColorAttributes.override];
          if (!colorReference) {
            throw new Error(
              `${this.toString()}: Unable to find valid ColorReference for stroke color reference id "${
                strokeColorAttributes.override
              }"`,
            );
          }

          this.setStrokeColorReference(colorReference);
          break;
        }
        case 'fillEffect': {
          const fillEffectAttributes = editingAttributes.fillEffect;
          if (
            !fillEffectAttributes ||
            typeof fillEffectAttributes !== 'object' ||
            !fillEffectAttributes.override
          ) {
            break;
          }

          const colorReference =
            this.videoDescriptor.references.color[fillEffectAttributes.override];
          if (!colorReference) {
            throw new Error(
              `${this.toString()}: Unable to find valid ColorReference for fill effect reference id "${
                fillEffectAttributes.override
              }"`,
            );
          }

          this.setFillEffectColorReference(colorReference);
          break;
        }
        case 'gradientFill': {
          const gradientFillAttributes = editingAttributes.gradientFill;
          if (!gradientFillAttributes) {
            // If there aren't any gradient fill attributes, we don't need to do anything
            break;
          }

          for (
            let gradientStepIndex = 0, gradientStepCount = gradientFillAttributes.length;
            gradientStepIndex < gradientStepCount;
            gradientStepIndex += 1
          ) {
            const gradientFillAttribute = gradientFillAttributes[gradientStepIndex];
            if (
              !gradientFillAttribute ||
              typeof gradientFillAttribute !== 'object' ||
              !gradientFillAttribute.override
            ) {
              continue;
            }

            const colorReference =
              this.videoDescriptor.references.color[gradientFillAttribute.override];
            if (!colorReference) {
              throw new Error(
                `${this.toString()}: Unable to find valid ColorReference for gradient fill reference id "${
                  gradientFillAttribute.override
                }"`,
              );
            }

            this.setGradientFillColorReference(colorReference, gradientStepIndex);
          }
          break;
        }
      }
    }
  }

  setTextContentReference(newTextContentReference: TextReference): void {
    if (!this.onTextContentReferenceChange) {
      throw new Error(
        `${this.toString()}: Cannot set text content reference without a change handler defined.`,
      );
    }

    const oldTextContentReference = this.textContentReference;
    if (oldTextContentReference) {
      oldTextContentReference.removeEventListener(
        'change:value',
        this.onTextContentReferenceChange,
      );
    }

    // Update the template manifest with the new content reference
    const templateManifest = this.videoDescriptor.rawData.templateManifest;
    templateManifest.layersExtendedAttributes ??= {};
    const layerUUID = this.getUUID();
    templateManifest.layersExtendedAttributes[layerUUID] ??= {};
    templateManifest.layersExtendedAttributes[layerUUID].content = {
      override: newTextContentReference.getID(),
    };

    this.textContentReference = newTextContentReference;
    this.textContentReference.addEventListener('change:value', this.onTextContentReferenceChange);

    // Trigger the change handler manually to get the layer synced up with the new reference value
    this.onTextContentReferenceChange();
  }

  setImageContentReference(newImageContentReference: ImageReference): void {
    if (!this.onImageContentReferenceChange) {
      throw new Error(
        `${this.toString()}: Cannot set image content reference without a change handler defined.`,
      );
    }

    const oldImageContentReference = this.imageContentReference;
    if (oldImageContentReference) {
      oldImageContentReference.removeEventListener(
        'change:value',
        this.onImageContentReferenceChange,
      );
    }

    // Update the template manifest with the new content reference
    const templateManifest = this.videoDescriptor.rawData.templateManifest;
    templateManifest.layersExtendedAttributes ??= {};
    const layerUUID = this.getUUID();
    templateManifest.layersExtendedAttributes[layerUUID] ??= {};
    templateManifest.layersExtendedAttributes[layerUUID].content = {
      override: newImageContentReference.getID(),
    };

    this.imageContentReference = newImageContentReference;
    this.imageContentReference.addEventListener('change:value', this.onImageContentReferenceChange);

    // Trigger the change handler manually to get the layer synced up with the new reference value
    this.onImageContentReferenceChange();
  }

  setFontReference(newFontReference: FontReference): void {
    if (!this.onFontReferenceChange) {
      throw new Error(
        `${this.toString()}: Cannot set font reference without a change handler defined.`,
      );
    }

    const oldFontReference = this.fontReference;
    if (oldFontReference) {
      oldFontReference.removeEventListener('change:value', this.onFontReferenceChange);
    }

    // Update the template manifest with the new fill color reference
    const templateManifest = this.videoDescriptor.rawData.templateManifest;
    templateManifest.layersExtendedAttributes ??= {};
    const layerUUID = this.getUUID();
    templateManifest.layersExtendedAttributes[layerUUID] ??= {};
    templateManifest.layersExtendedAttributes[layerUUID].font = {
      override: newFontReference.getID(),
    };

    this.fontReference = newFontReference;
    this.fontReference.addEventListener('change:value', this.onFontReferenceChange);

    // Trigger the change handler manually to get the layer synced up with the new reference value
    this.onFontReferenceChange();
  }

  /**
   * Updates the fill color reference for this layer.
   */
  setFillColorReference(newFillColorReference: ColorReference): void {
    if (!this.onFillColorReferenceChange) {
      throw new Error(
        `${this.toString()}: Cannot set fill color reference without a change handler defined.`,
      );
    }

    const oldFillColorReference = this.fillColorReference;
    if (oldFillColorReference) {
      oldFillColorReference.removeEventListener('change:value', this.onFillColorReferenceChange);
    }

    // Update the template manifest with the new fill color reference
    const templateManifest = this.videoDescriptor.rawData.templateManifest;
    templateManifest.layersExtendedAttributes ??= {};
    const layerUUID = this.getUUID();
    templateManifest.layersExtendedAttributes[layerUUID] ??= {};
    templateManifest.layersExtendedAttributes[layerUUID].fillColor = {
      override: newFillColorReference.getID(),
    };

    this.fillColorReference = newFillColorReference;
    this.fillColorReference.addEventListener('change:value', this.onFillColorReferenceChange);

    // Trigger the change handler manually to get the layer synced up with the new reference value
    this.onFillColorReferenceChange();
  }

  setStrokeColorReference(newStrokeColorReference: ColorReference): void {
    if (!this.onStrokeColorReferenceChange) {
      throw new Error(
        `${this.toString()}: Cannot set stroke color reference without a change handler defined.`,
      );
    }

    const oldStrokeColorReference = this.strokeColorReference;
    if (oldStrokeColorReference) {
      oldStrokeColorReference.removeEventListener(
        'change:value',
        this.onStrokeColorReferenceChange,
      );
    }

    // Update the template manifest with the new stroke color reference
    const templateManifest = this.videoDescriptor.rawData.templateManifest;
    templateManifest.layersExtendedAttributes ??= {};
    const layerUUID = this.getUUID();
    templateManifest.layersExtendedAttributes[layerUUID] ??= {};
    templateManifest.layersExtendedAttributes[layerUUID].strokeColor = {
      override: newStrokeColorReference.getID(),
    };

    this.strokeColorReference = newStrokeColorReference;
    this.strokeColorReference.addEventListener('change:value', this.onStrokeColorReferenceChange);

    // Trigger the change handler manually to get the layer synced up with the new reference value
    this.onStrokeColorReferenceChange();
  }

  setFillEffectColorReference(newFillEffectColorReference: ColorReference): void {
    if (!this.onFillEffectColorReferenceChange) {
      throw new Error(
        `${this.toString()}: Cannot set fill effect color reference without a change handler defined.`,
      );
    }

    const oldFillEffectColorReference = this.fillEffectColorReference;
    if (oldFillEffectColorReference) {
      oldFillEffectColorReference.removeEventListener(
        'change:value',
        this.onFillEffectColorReferenceChange,
      );
    }

    // Update the template manifest with the new fill effect color reference
    const templateManifest = this.videoDescriptor.rawData.templateManifest;
    templateManifest.layersExtendedAttributes ??= {};
    const layerUUID = this.getUUID();
    templateManifest.layersExtendedAttributes[layerUUID] ??= {};
    templateManifest.layersExtendedAttributes[layerUUID].fillEffect = {
      override: newFillEffectColorReference.getID(),
    };

    this.fillEffectColorReference = newFillEffectColorReference;
    this.fillEffectColorReference.addEventListener(
      'change:value',
      this.onFillEffectColorReferenceChange,
    );

    // Trigger the change handler manually to get the layer synced up with the new reference value
    this.onFillEffectColorReferenceChange();
  }

  setGradientFillColorReference(
    newGradientFillColorReference: ColorReference,
    gradientStepIndex: number,
  ): void {
    if (!this.onGradientFillColorReferenceChange) {
      throw new Error(
        `${this.toString()}: Cannot set gradient fill color reference without a change handler defined.`,
      );
    }

    const oldGradientFillColorReference = this.gradientFillColorReferences?.[gradientStepIndex];
    if (oldGradientFillColorReference && this.gradientFillColorReferenceChangeListeners) {
      oldGradientFillColorReference.removeEventListener(
        'change:value',
        this.gradientFillColorReferenceChangeListeners[gradientStepIndex],
      );
    }

    // Update the template manifest with the new gradient fill color reference
    const templateManifest = this.videoDescriptor.rawData.templateManifest;
    templateManifest.layersExtendedAttributes ??= {};
    const layerUUID = this.getUUID();
    templateManifest.layersExtendedAttributes[layerUUID] ??= {};
    templateManifest.layersExtendedAttributes[layerUUID].gradientFill ??= [];
    const gradientFillAttributes =
      templateManifest.layersExtendedAttributes[layerUUID].gradientFill;
    if (gradientFillAttributes) {
      gradientFillAttributes[gradientStepIndex] = {
        override: newGradientFillColorReference.getID(),
      };
    }

    this.gradientFillColorReferences ??= [];
    this.gradientFillColorReferences[gradientStepIndex] = newGradientFillColorReference;

    const onGradientFillColorReferenceChangeListener = () => {
      this.onGradientFillColorReferenceChange?.(gradientStepIndex);
    };

    this.gradientFillColorReferenceChangeListeners ??= [];
    this.gradientFillColorReferenceChangeListeners[gradientStepIndex] =
      onGradientFillColorReferenceChangeListener;

    newGradientFillColorReference.addEventListener(
      'change:value',
      onGradientFillColorReferenceChangeListener,
    );

    onGradientFillColorReferenceChangeListener();
  }

  /** ---- FILL EFFECT; effects can theoretically be applied to all layer types ---- */

  getFillEffectColor<TColorFormat extends 'hex' | 'rgb' = 'hex'>(format = 'hex' as TColorFormat) {
    const layerEffects = this.rawLayerData.ef;
    if (!layerEffects) {
      return null;
    }

    const fillEffectColorValue = layerEffects
      .find((effect) => effect.ty === LatestVDETypes.EffectType.Fill)
      ?.ef.find((effectControl) => effectControl.nm === 'Color')?.v;
    if (!fillEffectColorValue) {
      return null;
    }

    let colorRGBTuple: [number, number, number] | null = null;

    if (fillEffectColorValue.a === 1 && 's' in fillEffectColorValue.k[0]) {
      const colorValue = fillEffectColorValue.k[0].s;
      if (Array.isArray(colorValue)) {
        colorRGBTuple = colorValue as [number, number, number];
      }
    } else {
      const colorValue = fillEffectColorValue.k;
      if (Array.isArray(colorValue)) {
        colorRGBTuple = colorValue as [number, number, number];
      }
    }

    return colorRGBTuple ? getFormattedColor(colorRGBTuple, format) : null;
  }

  private updateFillEffectColor(newColor: [number, number, number]): void {
    const layerEffects = this.rawLayerData.ef;
    if (!layerEffects) {
      return;
    }

    const updatedLayerEffects = structuredClone(layerEffects);

    for (const effect of updatedLayerEffects) {
      if (effect.ty === LatestVDETypes.EffectType.Fill) {
        for (const effectControl of effect.ef) {
          if (effectControl.nm === 'Color') {
            if (effectControl.v.a === 1 && 's' in effectControl.v.k[0]) {
              effectControl.v.k[0].s = newColor;
            } else {
              effectControl.v.k = newColor;
            }
          }
        }
      }
    }

    this.updateRawLayerData({
      ef: updatedLayerEffects,
    } as Partial<TRawDataType>);

    this.dispatchEvent('change:fillEffectColor', undefined);
  }

  setFillEffectColor(
    newColor: string | [number, number, number],
    shouldOverrideReference = false,
  ): void {
    if (!this.getCanEditFieldType('fillEffect')) {
      throw new Error(`${this.toString()}: Fill effect color is not editable for this layer.`);
    }

    if (shouldOverrideReference) {
      this.updateIgnoredReferences({
        fillEffect: true,
      });
    }

    if (this.fillEffectColorReference && !this.getIsReferenceIgnored('fillEffect')) {
      this.fillEffectColorReference.setColor(getFormattedColor(newColor, 'hex'));
    } else {
      this.updateFillEffectColor(getFormattedColor(newColor, 'rgb'));
    }
  }

  /**
   * Event listener for when the fill effect color reference changes.
   */
  onFillEffectColorReferenceChange(): void {
    if (!this.fillEffectColorReference || this.getIsReferenceIgnored('fillEffect')) {
      return;
    }

    const newColorHex = this.fillEffectColorReference.getColor();
    this.updateFillEffectColor(hexStringToRGBArray(newColorHex));
  }

  /**
   * Method called when the layer is removed from the video descriptor.
   * Layers should extend this method to perform any necessary clean up,
   * ie removing assets from the video descriptor.
   */
  cleanUp(): void {
    this.dispatchEvent('removed', undefined);
  }
}
