import { BFSFontVariant } from '@libs/shared-types';
import { getClosestFontVariant } from '@libs/util-fonts';

import { BaseAsset } from './BaseAsset';
import { BitmapFontLocation } from './locations';

import type { latest as LatestVDETypes } from '@libs/waymark-video/video-descriptor-types';

interface BfsFontVariantResponse {
  uuid: string;
  name: string | null;
  file: string;
  fileType: string;
  previewImage: string | null;
  tags: string | null;
  fontFamilyId: number;
  origin: string | null;
  weightIndex: number;
  isItalic: boolean;
  rawNameTable: string;
  createdAt: string;
  sizes: {
    [key: string]: string;
  };
}

export class FontAsset extends BaseAsset {
  rawAssetData: LatestVDETypes.ProjectManifestBitmapFontAsset;
  location: BitmapFontLocation;
  private _bfsFontVariantResponse: BfsFontVariantResponse | null = null;
  static __mockedFetchBfsFontVariant: ((fontAsset: FontAsset) => Promise<BFSFontVariant>) | null =
    null;

  /**
   * Creates a new font asset from a list of available font variants.
   * Accepts a target font asset to use as a reference to find the closest matching variant,
   * but if not provided, will use the closest variant to default font weight and style.
   *
   * @param {string} fontID - ID of the font asset
   * @param {BFSFontVariant[]} variants - Array of available font variants to choose from
   * @param {FontAsset} targetFontAsset - Existing target font asset to attempt to match the size/style of
   */
  static async fromBFSFontVariants(
    variants: BFSFontVariant[],
    targetFontAsset?: FontAsset | null,
  ): Promise<FontAsset> {
    let targetFontWeight = FontAsset.DEFAULT_FONT_WEIGHT;
    let targetIsItalic = false;

    if (targetFontAsset) {
      const targetBfsFontVariant = await targetFontAsset?.fetchBfsFontVariant();
      targetFontWeight = targetBfsFontVariant.weightIndex;
      targetIsItalic = targetBfsFontVariant.isItalic;
    }

    const closestVariant = getClosestFontVariant(variants, {
      weight: targetFontWeight,
      isItalic: targetIsItalic,
    });

    if (!closestVariant) {
      throw new Error(
        `Unable to find a matching font variant for ${targetFontWeight} ${targetIsItalic} from variants ${variants}`,
      );
    }

    const fontAsset = new FontAsset({
      id: closestVariant.uuid,
      location: {
        id: closestVariant.uuid,
        plugin: 'bitmap-font-service',
      },
      type: 'bitmapFont',
    });

    return fontAsset;
  }

  static cloneFrom(asset: FontAsset) {
    return new FontAsset({
      ...structuredClone(asset.rawAssetData),
      id: asset.getKey(),
    });
  }

  constructor(rawAssetData: LatestVDETypes.ProjectManifestBitmapFontAsset) {
    super();

    this.rawAssetData = rawAssetData;
    this.location = new BitmapFontLocation(rawAssetData.location);
  }

  static DEFAULT_FONT_WEIGHT = 400;

  async getIsItalic(): Promise<boolean> {
    return (await this.fetchBfsFontVariant()).isItalic;
  }

  async getWeight(): Promise<number> {
    return (await this.fetchBfsFontVariant()).weightIndex;
  }

  static setGlobalMock__fetchBfsFontVariant = (
    mockedFn: ((fontAsset: FontAsset) => Promise<BFSFontVariant>) | null,
  ) => {
    FontAsset.__mockedFetchBfsFontVariant = mockedFn;
  };

  static resetGlobalMock__fetchBfsFontVariant = () => {
    FontAsset.setGlobalMock__fetchBfsFontVariant(null);
  };

  async fetchBfsFontVariant(): Promise<BFSFontVariant> {
    if (FontAsset.__mockedFetchBfsFontVariant !== null) {
      return FontAsset.__mockedFetchBfsFontVariant(this);
    }
    let fullBfsFontVariant;
    if (this._bfsFontVariantResponse !== null) {
      fullBfsFontVariant = this._bfsFontVariantResponse;
    } else {
      const response = await fetch((await this.location.getURL()).href);
      fullBfsFontVariant = (await response.json()) as BfsFontVariantResponse;
      // Throw if the response is not ok
      if (!response.ok) {
        throw new Error(`Failed to fetch font variant for ${this.getKey()}`);
      }
    }

    return {
      uuid: fullBfsFontVariant.uuid,
      isItalic: fullBfsFontVariant.isItalic,
      weightIndex: fullBfsFontVariant.weightIndex,
    };
  }

  async getURL() {
    return this.location.getURL();
  }

  getKey(): string {
    return this.location.getKey();
  }

  getID(): string {
    return this.rawAssetData.id;
  }
}
