import "../../libs/shared-ui-styles/src/utils/utilityClasses.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/shared-ui-styles/src/utils/utilityClasses.css.ts.vanilla.css\",\"source\":\"Ll8xbDhqeHo2MCB7CiAgbGVmdDogMDsKICByaWdodDogMDsKICB0b3A6IDA7CiAgYm90dG9tOiAwOwogIHdpZHRoOiAxMDAlOwogIGhlaWdodDogMTAwJTsKfQouXzFsOGp4ejYxOm5vdCgjXCMpIHsKICBwb3NpdGlvbjogYWJzb2x1dGU7Cn0KLl8xbDhqeHo2MyB7CiAgdG9wOiA1MCU7CiAgbGVmdDogNTAlOwogIHRyYW5zZm9ybTogdHJhbnNsYXRlKC01MCUsIC01MCUpOwp9Ci5fMWw4anh6NjQgewogIHRvcDogNTAlOwogIHRyYW5zZm9ybTogdHJhbnNsYXRlWSgtNTAlKTsKfQouXzFsOGp4ejY1IHsKICBsZWZ0OiA1MCU7CiAgdHJhbnNmb3JtOiB0cmFuc2xhdGVYKC01MCUpOwp9Ci5fMWw4anh6NjY6bm90KCNcIykgewogIHBvc2l0aW9uOiBmaXhlZDsKfQouXzFsOGp4ejY4IHsKICBkaXNwbGF5OiBmbGV4Owp9Ci5fMWw4anh6NjkgewogIGp1c3RpZnktY29udGVudDogY2VudGVyOwogIGFsaWduLWl0ZW1zOiBjZW50ZXI7Cn0KLl8xbDhqeHo2YSB7CiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjsKfQouXzFsOGp4ejZiIHsKICBvdmVyZmxvdzogaGlkZGVuOwogIHdoaXRlLXNwYWNlOiBub3dyYXA7CiAgdGV4dC1vdmVyZmxvdzogZWxsaXBzaXM7Cn0KLl8xbDhqeHo2YyB7CiAgcG9zaXRpb246IGFic29sdXRlOwogIGxlZnQ6IC0xMDAwMHB4OwogIHRvcDogYXV0bzsKICB3aWR0aDogMXB4OwogIGhlaWdodDogMXB4OwogIG92ZXJmbG93OiBoaWRkZW47Cn0=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var absoluteCentered = '_1l8jxz63 _1l8jxz61';
export var absoluteCenteredHorizontal = '_1l8jxz65 _1l8jxz61';
export var absoluteCenteredVertical = '_1l8jxz64 _1l8jxz61';
export var absoluteFill = '_1l8jxz61 _1l8jxz60';
export var ellipsisTruncatedText = '_1l8jxz6b';
export var fixedFill = '_1l8jxz66 _1l8jxz60';
export var flex = '_1l8jxz68';
export var flexCenter = '_1l8jxz69 _1l8jxz68';
export var flexColumn = '_1l8jxz6a _1l8jxz68';
export var positionAbsolute = '_1l8jxz61';
export var positionFixed = '_1l8jxz66';
export var screenReaderOnly = '_1l8jxz6c';