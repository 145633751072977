import type { GlobalSettings } from '../globalSettings';

export enum WaymarkS3AssetEnvironments {
  Dev = 'dev',
  Prod = 'prod',
}

export type AssetsSettings = {
  'assets.s3.env': `${WaymarkS3AssetEnvironments}`;
  'assets.imgixDomain': string;
  'assets.templateStudioPluginHost': string;
  'assets.afterEffectsExportLocationPath': string;
};

export const applyDefaultAssetSettings = (settings: GlobalSettings<AssetsSettings>) => {
  settings.set('assets.s3.env', WaymarkS3AssetEnvironments.Prod);
  settings.set('assets.templateStudioPluginHost', 'https://studio-api.waymark.com');
};
