import { AssetLocation as PMAssetLocation } from '@libs/waymark-video/video-descriptor-types';

export abstract class BaseAssetLocation<TRawDataType extends PMAssetLocation> {
  rawLocationData: TRawDataType;

  constructor(rawLocationData: TRawDataType) {
    this.rawLocationData = rawLocationData;
  }

  abstract getURL(...args: unknown[]): Promise<URL>;

  abstract getKey(): string;

  abstract toString(): string;
}
