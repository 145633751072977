import type { processingDefinitions } from './processingDefinitions';

interface BulkProcessingJobConfig {
  processingDefinitions: Array<keyof typeof processingDefinitions>;
}

// master is our archive format, constrained at 1920px width, maintains aspect ratio, forces 30fps and 4:2:0 color space
export const bulkProcessingJobs = {
  webPlayer: {
    processingDefinitions: [
      'master',
      'webPlayer_h265',
      'webPlayer_h264',
      'webPlayer_vp9',
      'gif300',
      'storyboardTemplateA_jpg1200',
      'tenThumbnails_jpg300',
      'everyTwoSpritesheet_jpg200',
    ],
  },
  webPlayerFastv1: {
    processingDefinitions: [
      'master',
      'webPlayer_h264',
      'finalAsset_h264',
      'gif300',
      'storyboardTemplateA_jpg1200',
      'tenThumbnails_jpg300',
      'everyTwoSpritesheet_jpg200',
    ],
  },
} as const satisfies Record<string, BulkProcessingJobConfig>;

export type VPSBulkJobKey = keyof typeof bulkProcessingJobs;
