import { settings } from '@libs/global-settings';
import {
  BitmapFontServiceLegacyLocation,
  BitmapFontServiceLocation,
} from '@libs/waymark-video/video-descriptor-types';
import { BaseAssetLocation } from './BaseAssetLocation';

export class BitmapFontLocation extends BaseAssetLocation<
  BitmapFontServiceLocation | BitmapFontServiceLegacyLocation
> {
  async getURL(): Promise<URL> {
    if ('legacyId' in this.rawLocationData) {
      const url = new URL(
        `/legacy-font-families/${encodeURIComponent(this.rawLocationData.legacyId)}`,
        settings.get('service.bfs.apiEndpoint'),
      );
      url.searchParams.set('weight', String(this.rawLocationData.weight));
      if (this.rawLocationData.isItalic) {
        url.searchParams.set('isItalic', '1');
      }
      return url;
    }

    // Make an API call to the bitmap font service with the id
    return new URL(
      `/font-variants/${encodeURIComponent(this.rawLocationData.id)}`,
      settings.get('service.bfs.apiEndpoint'),
    );
  }

  getKey(): string {
    if ('legacyId' in this.rawLocationData) {
      return `${this.rawLocationData.legacyId}.${this.rawLocationData.weight}.${
        this.rawLocationData.isItalic ? 'italic' : 'normal'
      }`;
    } else {
      return this.rawLocationData.id;
    }
  }

  toString(): string {
    return `BitmapFontLocation${this.rawLocationData.plugin}:${this.getKey()}>`;
  }
}
