import { settings } from '@libs/global-settings';

import { BaseAssetLocation } from './BaseAssetLocation';
import { ProjectManifestAudioAsset } from '@libs/waymark-video/video-descriptor-types';
import { pathJoin } from './pathJoin';

export enum AudioAssetQuality {
  medium = 'medium',
  high = 'high',
}

/**
 * Construct a URL to an audio asset in the old APS S3 location scheme.
 */
export function buildOldAudioAssetURL(derivative: string, sourceAudioKey: string) {
  const host =
    settings.get('waymark.env') === 'prod'
      ? 'https://wm-aps-production.s3.us-west-1.amazonaws.com'
      : 'https://wm-aps-development.s3.us-west-1.amazonaws.com';
  return new URL(`/derivatives/_${derivative}/${sourceAudioKey}_${derivative}.m4a`, host);
}

const NO_VALUE = Symbol('NO_VALUE');

export class AudioAssetLocation extends BaseAssetLocation<ProjectManifestAudioAsset['location']> {
  private __cache: {
    url: {
      [key in AudioAssetQuality]: URL | Promise<URL> | typeof NO_VALUE;
    };
  } = {
    url: {
      [AudioAssetQuality.medium]: NO_VALUE,
      [AudioAssetQuality.high]: NO_VALUE,
    },
  };

  /**
   * Gets the URL of this audio asset location.
   * This function caches URL results and will re-use those for future calls
   * instead of performing the expensive operation of deriving the URL again.
   */
  async getURL(quality: AudioAssetQuality) {
    if (this.__cache.url[quality] !== NO_VALUE) {
      return this.__cache.url[quality];
    }

    try {
      const urlPromise = this._getURL(quality);
      this.__cache.url[quality] = urlPromise;
      const url = await urlPromise;
      this.__cache.url[quality] = url;
      return url;
    } catch (err) {
      // Clear the cached value so that it can be re-fetched
      this.__cache.url[quality] = NO_VALUE;
      // Re-throw
      throw err;
    }
  }

  /**
   * Derives a URL for this location.
   */
  private async _getURL(quality: AudioAssetQuality): Promise<URL> {
    // Don't do anything about size, or cap it, or cap it for high and medium differently

    switch (this.rawLocationData.plugin) {
      case 'waymark-aps': {
        const derivative = quality === 'high' ? 'downloadRender' : 'webPlayer';

        const sourceAudioKey = this.rawLocationData.sourceAudio;

        const urls = [];

        urls.push(
          new URL(
            `/assets/audio/derivatives/${derivative}/${sourceAudioKey}_${derivative}.m4a`,
            settings.get('service.aps.assetHost'),
          ),
        );

        urls.push(buildOldAudioAssetURL(derivative, sourceAudioKey));

        for (const url of urls) {
          const response = await fetch(url, { method: 'HEAD' });

          if (response.ok) {
            return new URL(url);
          } else {
            console.error(`Audio asset not found at ${url}`);
          }
        }

        // Throw up our hands and return the first URL, which probably won't work, but maybe processing
        // just hasn't completed yet.
        return urls[0];
      }
      case 'waymark-template-studio': {
        const url = new URL(
          `/api/video-template-audio/${this.rawLocationData.id}`,
          settings.get('assets.templateStudioPluginHost'),
        );

        const response = await fetch(url);
        const data = await response.json();

        // TODO: is this accurate?
        return new URL(
          `https://waymark-template-studio-development.s3.amazonaws.com/${data.audio}`,
        );
      }
      case 'waymark': {
        let s3URLOrigin: string | null = null;

        const s3Environment = settings.get('assets.s3.env');

        switch (this.rawLocationData.type) {
          case 'socialproofImagesWeb':
            s3URLOrigin = `https://sp-${s3Environment}-s3-images-web.s3.amazonaws.com`;
            break;
          case 'socialproofCustomerAssets':
            s3URLOrigin = `https://sp-${s3Environment}-s3-customer-assets.s3.amazonaws.com`;
            break;
          case 'socialproofS3Assets':
            s3URLOrigin = `https://sp-prod-s3-assets.s3.amazonaws.com`;
            break;
          case 'waymarkMattkahlScratch':
            s3URLOrigin = 'https://waymark-mattkahl-scratch.s3.amazonaws.com';
            break;
          case 'waymarkTemplateStudio':
            s3URLOrigin = 'https://waymark-template-studio-development.s3.amazonaws.com';
            break;
        }

        if (!s3URLOrigin) {
          throw new Error(`Invalid type on location: ${this.rawLocationData.type}`);
        }

        return new URL(this.rawLocationData.key, s3URLOrigin);
      }
      case 'after-effects-export': {
        return new URL(
          pathJoin(
            settings.get('assets.afterEffectsExportLocationPath'),
            this.rawLocationData.u,
            this.rawLocationData.p,
          ),
        );
      }
      default: {
        throw new Error(`Don't know how to handle location plugin: ${this.rawLocationData}`);
      }
    }
  }

  getKey() {
    switch (this.rawLocationData.plugin) {
      case 'waymark':
        return this.rawLocationData.key;
      case 'waymark-aps':
        return this.rawLocationData.sourceAudio;
      case 'after-effects-export':
        return `${this.rawLocationData.u}/${this.rawLocationData.p}`;
      case 'waymark-template-studio':
        return this.rawLocationData.id;
      default:
        throw new Error(`Don't know how to handle location plugin: ${this.rawLocationData}`);
    }
  }

  toString() {
    return `AudioAssetLocation<${this.rawLocationData.plugin}:${this.getKey()}>`;
  }
}
