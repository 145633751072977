import { Layer } from './layers';
import {
  AfterEffectsExportLocation,
  AssetLocation,
  BitmapFontServiceLegacyLocation,
  BitmapFontServiceLocation,
  LegacyWaymarkAudioLocation,
  LegacyWaymarkVideoLocation,
  WaymarkApsLocation,
  WaymarkImageLocation,
  WaymarkTemplateStudioLocation,
  WaymarkVpsLocation,
} from './locations';
import { ImageAssetModifications, VideoAssetModifications } from './modifications';

export interface CharsSource {
  /** Character Value */
  ch: string;
  /** Character Font Family */
  fFamily: string;
  /** Character Font Size */
  size: string;
  /** Character Font Style */
  style: string;
  /** Character Width */
  w: number;
  /** Character Data */
  data: CharacterData;
}

export interface BaseBodymovinAsset {
  /** Asset ID */
  id: string;
}

export enum AssetType {
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  BitmapFont = 'bitmapFont',
}

export interface BaseProjectManifestMediaAsset extends BaseBodymovinAsset {
  /** If present, disregard. */
  e?: 0;
  type: `${AssetType}`;
  location: AssetLocation;
}
export interface ProjectManifestVideoAsset extends BaseProjectManifestMediaAsset {
  type: `${AssetType.Video}`;
  name?: string;
  // TODO: footageCompositionFor and sourceCompositionId should always exists or not-exist together
  footageCompositionFor?: number[];
  sourceCompositionId?: number;
  location:
    | WaymarkVpsLocation
    | LegacyWaymarkVideoLocation
    | WaymarkTemplateStudioLocation
    | AfterEffectsExportLocation;
  content?: {
    modifications?: null | VideoAssetModifications;
  };
  modifications?: null | VideoAssetModifications;
}

export interface ProjectManifestImageAsset extends BaseProjectManifestMediaAsset {
  type: `${AssetType.Image}`;
  location: WaymarkImageLocation | WaymarkTemplateStudioLocation | AfterEffectsExportLocation;
  content?: {
    modifications?: null | ImageAssetModifications;
  };
  modifications?: null | ImageAssetModifications;
}

export interface ProjectManifestAudioAsset extends BaseProjectManifestMediaAsset {
  type: `${AssetType.Audio}`;
  location:
    | WaymarkApsLocation
    | LegacyWaymarkAudioLocation
    | WaymarkTemplateStudioLocation
    | AfterEffectsExportLocation;
  modifications?: null | Record<string, never>;
}

export type ProjectManifestMediaAsset =
  | ProjectManifestImageAsset
  | ProjectManifestVideoAsset
  | ProjectManifestAudioAsset;

export type FontWeight =
  | 100
  | 150
  | 200
  | 250
  | 300
  | 350
  | 400
  | 450
  | 500
  | 550
  | 600
  | 650
  | 700
  | 750
  | 800
  | 850
  | 900
  | 950;

export interface ProjectManifestBitmapFontAsset {
  id: string;
  type: `${AssetType.BitmapFont}`;
  location: BitmapFontServiceLocation | BitmapFontServiceLegacyLocation;
  modifications?: never;
}

export interface BaseComposition {
  /** List of Composition Layers */
  layers: Layer[];
  /** After Effects name */
  nm?: string;
  /**
   * Added in 2020. This is a unique identifier of the composition.
   *
   * https://github.com/stikdev/waymark-author-ae-extension/blob/2e886b9b9e440d3c185861b617da94efd8b0098f/src/host/lib/compItemIDManager.jsx#L36
   */
  compId?: number;
}

export interface PreCompSource extends BaseComposition {
  /** Precomp ID */
  id: string;
}

export type Source = PreCompSource | ProjectManifestMediaAsset | ProjectManifestBitmapFontAsset;
