import { BooleanInt } from './helpers';
import { Layer } from './layers';
import {
  AssetLocation,
  BitmapFontServiceLegacyLocation,
  BitmapFontServiceLocation,
  LegacyWaymarkAudioLocation,
  LegacyWaymarkVideoLocation,
  WaymarkApsLocation,
  WaymarkImageLocation,
  WaymarkTemplateStudioLocation,
  WaymarkVpsLocation,
} from './locations';
import { ImageAssetModifications, VideoAssetModifications } from './modifications';

export interface CharsSource {
  /** Character Value */
  ch: string;
  /** Character Font Family */
  fFamily: string;
  /** Character Font Size */
  size: string;
  /** Character Font Style */
  style: string;
  /** Character Width */
  w: number;
  /** Character Data */
  data: CharacterData;
}

export interface BaseBodymovinAsset {
  /** Asset Height */
  h?: number;
  /** Asset Width */
  w?: number;
  /** Asset ID */
  id: string;
}

export enum AssetType {
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  BitmapFont = 'bitmapFont',
}

export type FlattenedFootageAssetMixin = {
  footageCompositionFor: number[];
  sourceCompositionId: number;
  modifications?: null | VideoAssetModifications;
};

export interface BaseAfterEffectsAsset extends BaseBodymovinAsset {
  /** Asset name */
  p: string;
  /** Asset path */
  u: string;
  /** Is encoded data url? */
  e?: BooleanInt;
  /** Asset type is sometimes defined post-studio-publish or via manual edit */
  type?: `${AssetType}`;
  /** Name is sometimes defined post-studio-publish or via manual edit */
  name?: string;
}

export type AfterEffectsAsset = BaseAfterEffectsAsset;

/**
 * This is a type that is used to say "ok, we know we have a video asset because it contains VideoAssetModifications"
 */
export type AfterEffectsFootageAsset = BaseAfterEffectsAsset & {
  modifications: VideoAssetModifications;
  /** Asset Height */
  h: number;
  /** Asset Width */
  w: number;
};

export interface AfterEffectsFlattenedFootageAsset
  extends BaseAfterEffectsAsset,
    FlattenedFootageAssetMixin {
  modifications?: null | VideoAssetModifications;
  /** Asset Height */
  h: number;
  /** Asset Width */
  w: number;
}
export interface BaseProjectManifestMediaAsset extends BaseBodymovinAsset {
  /** If present, disregard. */
  e?: 0;
  /** If present, disregard. */
  p?: string;
  /** If present, disregard. */
  u?: string;
  type: `${AssetType}`;
  location: AssetLocation;
}

export interface ProjectManifestFlattenedFootageAsset extends BaseProjectManifestMediaAsset {
  /** Asset Height */
  h: number;
  /** Asset Width */
  w: number;
  type: `${AssetType.Video}`;
  name: string;
  footageCompositionFor: number[];
  sourceCompositionId: number;
  location: WaymarkVpsLocation | LegacyWaymarkVideoLocation | WaymarkTemplateStudioLocation;
  content?: {
    modifications?: null | VideoAssetModifications;
  };
  modifications?: null | VideoAssetModifications;
}

export interface ProjectManifestVideoAsset extends BaseProjectManifestMediaAsset {
  /** Asset Height */
  h: number;
  /** Asset Width */
  w: number;
  type: `${AssetType.Video}`;
  name?: string;
  location: WaymarkVpsLocation | LegacyWaymarkVideoLocation | WaymarkTemplateStudioLocation;
  content?: {
    modifications?: null | VideoAssetModifications;
  };
  modifications?: null | VideoAssetModifications;
}

export interface ProjectManifestImageAsset extends BaseProjectManifestMediaAsset {
  /** Asset Height */
  h: number;
  /** Asset Width */
  w: number;
  type: `${AssetType.Image}`;
  location: WaymarkImageLocation | WaymarkTemplateStudioLocation;
  content?: {
    modifications?: null | ImageAssetModifications;
  };
  modifications?: null | ImageAssetModifications;
}

type ProjectManifestAudioAssetLocation =
  | WaymarkApsLocation
  | LegacyWaymarkAudioLocation
  | WaymarkTemplateStudioLocation;
export interface ProjectManifestAudioAsset extends BaseProjectManifestMediaAsset {
  /** Asset Height */
  h: never;
  /** Asset Width */
  w: never;
  type: `${AssetType.Audio}`;
  location: ProjectManifestAudioAssetLocation;
  modifications?: null | Record<string, never>;
}

export type VideoAsset =
  | ProjectManifestVideoAsset
  | ProjectManifestFlattenedFootageAsset
  | AfterEffectsFlattenedFootageAsset
  | AfterEffectsFootageAsset
  | AfterEffectsAsset;

export type ImageAsset = ProjectManifestImageAsset | AfterEffectsAsset;

export type AudioAsset =
  | ProjectManifestAudioAsset
  | StudioAudioAsset
  | MalformStudioAudioAsset
  | AfterEffectsAsset;

export type ProjectManifestMediaAsset =
  | ProjectManifestImageAsset
  | ProjectManifestVideoAsset
  | ProjectManifestAudioAsset
  | ProjectManifestFlattenedFootageAsset;

export type FontWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export interface ProjectManifestBitmapFontAsset {
  id: string;
  type: `${AssetType.BitmapFont}`;
  isItalic: boolean;
  // TODO: weight appears to not always be present. Seems weird.
  weight?: FontWeight;
  location: BitmapFontServiceLocation | BitmapFontServiceLegacyLocation;
  /**
   * TODO: Modifications seem to sometimes accidentally get set to an empty object when
   * font assets do not support modifications.
   */
  modifications?: null | Record<string, never>;
}

export interface StudioAudioAsset {
  type: `${AssetType.Audio}`;
  /** The name specified in the studio */
  name?: string;
  /** The ID generated in the studio */
  id: string;
  /** The location of the asset */
  location: WaymarkApsLocation | LegacyWaymarkAudioLocation | WaymarkTemplateStudioLocation;
  /**
   * TODO: Modifications seem to sometimes accidentally get set to an empty object when
   * audio assets do not support modifications.
   */
  modifications?: null | Record<string, never>;
}

/** @migrationtodo */
export interface MalformStudioAudioAsset {
  /** Asset ID */
  id: string;
  /** Asset name */
  p: string;
  /** Asset path */
  u: string;
  /** Asset type is sometimes defined post-studio-publish or via manual edit */
  type: `${AssetType.Audio}`;
  /** The name */
  name: string;
}

export interface BaseComposition {
  /** List of Composition Layers */
  layers: Layer[];
  /** After Effects name */
  nm?: string;
  /**
   * Added in 2020. This is a unique identifier of the composition.
   *
   * https://github.com/stikdev/waymark-author-ae-extension/blob/2e886b9b9e440d3c185861b617da94efd8b0098f/src/host/lib/compItemIDManager.jsx#L36
   */
  compId?: number;
}

export interface PreCompSource extends BaseComposition {
  /** Precomp ID */
  id: string;
}

export type Source =
  | PreCompSource
  | ProjectManifestMediaAsset
  | ProjectManifestBitmapFontAsset
  | MalformStudioAudioAsset
  | StudioAudioAsset
  | AfterEffectsAsset
  | AfterEffectsFootageAsset
  | AfterEffectsFlattenedFootageAsset;
