import { BaseAsset } from './BaseAsset';

import type { latest as LatestVDETypes } from '@libs/waymark-video/video-descriptor-types';
import { AudioAssetLocation, AudioAssetQuality } from './locations/AudioAssetLocation';

export class AudioAsset extends BaseAsset {
  rawAssetData: LatestVDETypes.ProjectManifestAudioAsset;
  location: AudioAssetLocation;

  static fromAPSSourceAudioKey(sourceAudioKey: string) {
    const location = new AudioAssetLocation({ plugin: 'waymark-aps', sourceAudio: sourceAudioKey });

    return new AudioAsset({
      type: 'audio',
      id: location.getKey(),
      location: location.rawLocationData,
    });
  }

  /**
   * Creates an AudioAsset for a legacy Waymark asset. This is used for assets that are not
   * processed by the APS, namely background audio tracks.
   *
   * @param assetID - Unique ID for the asset
   * @param assetPath - Path to the asset in the Waymark S3 bucket
   * @param [s3BucketKey] - Key identifying the S3 bucket where the asset is stored; defaults to 'socialproofImagesWeb'
   *                      since it's usually safe to assume that's where most audio assets are
   */
  static fromWaymarkAssetPath(
    assetPath: string,
    s3BucketKey: LatestVDETypes.WaymarkImageLocation['type'] = 'socialproofImagesWeb',
  ) {
    const location = new AudioAssetLocation({
      plugin: 'waymark',
      key: assetPath,
      type: s3BucketKey,
    });

    return new AudioAsset({
      type: 'audio',
      id: location.getKey(),
      location: location.rawLocationData,
    });
  }

  constructor(rawAssetData: LatestVDETypes.ProjectManifestAudioAsset) {
    super();

    this.rawAssetData = rawAssetData;
    this.location = new AudioAssetLocation(rawAssetData.location);
  }

  getURL(quality: AudioAssetQuality = AudioAssetQuality.medium) {
    return this.location.getURL(quality);
  }

  getKey(): string {
    return this.location.getKey();
  }

  getID() {
    return this.rawAssetData.id;
  }
}
