import { ImageAssetLocation } from './locations';
import { BaseAsset } from './BaseAsset';

import type { latest as LatestVDETypes } from '@libs/waymark-video/video-descriptor-types';

export class ImageAsset extends BaseAsset {
  rawAssetData: LatestVDETypes.ProjectManifestImageAsset;
  location: ImageAssetLocation;

  static fromURL(url: string | URL) {
    const location = ImageAssetLocation.fromURL(url);
    return new ImageAsset({
      id: location.getKey(),
      location: location.rawLocationData,
      type: 'image',
    });
  }

  static cloneFrom(asset: ImageAsset) {
    return new ImageAsset({
      ...structuredClone(asset.rawAssetData),
      id: asset.getKey(),
    });
  }

  constructor(rawAssetData: LatestVDETypes.ProjectManifestImageAsset) {
    super();

    this.rawAssetData = rawAssetData;
    this.location = new ImageAssetLocation(rawAssetData.location);
  }

  getURL() {
    return this.location.getURL();
  }

  getKey(): string {
    return this.location.getKey();
  }

  getID() {
    return this.rawAssetData.id;
  }
}
