import {
  WaymarkImageLocation,
  WaymarkTemplateStudioLocation,
  AfterEffectsExportLocation,
} from '@libs/waymark-video/video-descriptor-types';
import { settings } from '@libs/global-settings';
import { BaseAssetLocation } from './BaseAssetLocation';
import { pathJoin } from './pathJoin';

// socialproofImagesWeb location hostnames are either:
//  "socialproof-<environment>.imgix.net"
//  "sp-<environment>-images-web.s3.amazonaws.com"
// s3.amazonaws URLs may also have a region suffix, e.g. "sp-<environment>-images-web.s3.<region>.amazonaws.com"
const socialproofImagesWebHostnameRegex =
  /^socialproof-[a-z]+\.imgix\.net|sp-[a-z]+-images-web\.s3(\.[a-z]{2}-[a-z]+-[0-9])?\.amazonaws.com$/;

// Customer asset location hostnames are either:
//  "socialproof-<environment>-customer-assets.imgix.net"
//  "sp-<environment>-s3-customer-assets.s3.amazonaws.com"
// s3.amazonaws URLs may also have a region suffix, e.g. "sp-<environment>-s3-customer-assets.s3.<region>.amazonaws.com"
const customerAssetsHostnameRegex =
  /^socialproof-[a-z]+-customer-assets\.imgix\.net|sp-[a-z]+-s3-customer-assets\.s3(\.[a-z]{2}-[a-z]+-[0-9])?\.amazonaws.com$/;

const getLocationTypeFromURL = (url: URL): WaymarkImageLocation['type'] | Error => {
  if (url.hostname === 'socialproof-prod-s3-assets.imgix.net') {
    return 'socialproofS3Assets';
  }

  if (customerAssetsHostnameRegex.test(url.hostname)) {
    return 'socialproofCustomerAssets';
  }

  if (socialproofImagesWebHostnameRegex.test(url.hostname)) {
    return 'socialproofImagesWeb';
  }

  if (url.hostname === 'waymark-template-studio-development.imgix.net') {
    return 'waymarkTemplateStudio';
  }

  if (url.hostname === 'waymark-mattkahl-scratch.imgix.net') {
    return 'waymarkMattkahlScratch';
  }

  return new Error(`Hostname ${url.hostname} does not match any supported location types`);
};

export const getWaymarkPluginOrigin = (locationType: WaymarkImageLocation['type']): string => {
  switch (locationType) {
    case 'socialproofImagesWeb':
      return `https://socialprof-${settings.get('assets.s3.env')}.imgix.net`;
    case 'socialproofCustomerAssets':
      return `https://socialproof-${settings.get('assets.s3.env')}-customer-assets.imgix.net`;
    case 'socialproofS3Assets':
      return 'https://socialproof-prod-s3-assets.imgix.net';
    case 'waymarkMattkahlScratch':
      return 'https://waymark-mattkahl-scratch.imgix.net';
    case 'waymarkTemplateStudio':
      return 'https://waymark-template-studio-development.imgix.net';
    default:
      throw new Error(`Invalid type on location: ${locationType}`);
  }
};

const NO_VALUE = Symbol('NO_VALUE');

/**
 * NOTES:
 * - We think we want to guarantee that an imgix URL is returned for images
 * - If we remove sizing from URLs, that means we probably have to handle it elsewhere (e.g. Layer.boundingBox)
 *   - Because we used to be inherently upscaling
 *   - What about monster images?
 * - It is still valid to return two totally separate URLs (esp. for video and audio assets)
 * - Contexts where images are used:
 *   - Image processing (needs smaller)
 *   - UI (likely needs smaller)
 *   - Rendering engine needs capped ceiling and then it resizes itself
 */
export class ImageAssetLocation extends BaseAssetLocation<
  WaymarkImageLocation | AfterEffectsExportLocation | WaymarkTemplateStudioLocation
> {
  static fromURL(url: string | URL): ImageAssetLocation {
    const parsedURL = url instanceof URL ? url : new URL(url);

    const locationType = getLocationTypeFromURL(parsedURL);

    if (locationType instanceof Error) {
      throw locationType;
    }

    return new ImageAssetLocation({
      plugin: 'waymark',
      // URL.pathname includes the leading slash, but we don't want that on location keys
      key: parsedURL.pathname.slice(1),
      type: locationType,
    });
  }

  // Cache for data which is expensive to derive for a location, ie asset URLs
  private __cache: {
    url: URL | Promise<URL> | typeof NO_VALUE;
  } = {
    url: NO_VALUE,
  };

  /**
   * Gets the URL of the image asset location.
   * This function caches URL results and will re-use those for future calls
   * instead of performing the expensive operation of deriving the URL again.
   */
  async getURL(): Promise<URL> {
    if (this.__cache.url !== NO_VALUE) {
      return this.__cache.url;
    }

    try {
      const urlPromise = this._getURL();
      this.__cache.url = urlPromise;
      const url = await urlPromise;
      this.__cache.url = url;
      return url;
    } catch (err) {
      // Clear the cached URL so that it can be re-fetched
      this.__cache.url = NO_VALUE;
      // re-throw error so caller can handle it
      throw err;
    }
  }

  /**
   * Derives a URL for the location.
   */
  private async _getURL(): Promise<URL> {
    switch (this.rawLocationData.plugin) {
      case 'waymark-template-studio': {
        const url = `${settings.get('assets.templateStudioPluginHost')}/api/video-template-images/${
          this.rawLocationData.id
        }`;

        const response = await fetch(url);
        const data = await response.json();

        return new URL(data.image, 'https://waymark-template-studio-development.imgix.net');
      }
      case 'waymark': {
        return new URL(this.rawLocationData.key, getWaymarkPluginOrigin(this.rawLocationData.type));
      }
      case 'after-effects-export': {
        return new URL(
          pathJoin(
            settings.get('assets.afterEffectsExportLocationPath'),
            this.rawLocationData.u,
            this.rawLocationData.p,
          ),
        );
      }
      default: {
        throw new Error(`Don't know how to handle location plugin: ${this.rawLocationData}`);
      }
    }
  }

  getKey() {
    switch (this.rawLocationData.plugin) {
      case 'waymark-template-studio': {
        return this.rawLocationData.id;
      }
      case 'waymark': {
        return this.rawLocationData.key;
      }
      case 'after-effects-export': {
        return `${this.rawLocationData.u}/${this.rawLocationData.p}`;
      }
      default: {
        throw new Error(
          `Don't know how to handle location plugin: ${JSON.stringify(this.rawLocationData)}`,
        );
      }
    }
  }

  toString() {
    return `ImageAssetLocation<${this.rawLocationData.plugin}:${this.getKey()}>`;
  }
}
