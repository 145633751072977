import axios from 'axios';

// These are anonymous credentials if the identityID and token are undefined.
export interface WaymarkCognitoAnonymousCredentials {
  identityPoolID: string;
}
export interface WaymarkCognitoAuthenticatedCredentials {
  identityPoolID: string;
  identityID: string;
  token: string;
}
export type WaymarkCognitoCredentials =
  | WaymarkCognitoAnonymousCredentials
  | WaymarkCognitoAuthenticatedCredentials;

export interface WaymarkIdentity {
  credentials: WaymarkCognitoCredentials;
  region?: string;
  userName?: string;
  // Date.getTime(), or milliseconds from the Epoch in UTC
  expiration?: number;
  refreshURL?: string;
}

export interface WaymarkServiceAccessKey {
  identity?: WaymarkIdentity;
  serviceDiscoveryEndpoint: string;
}

export type SignedConnection = ReturnType<typeof axios.create>;

export enum SignatureV4Service {
  Lambda = 'lambda',
  ExecuteApi = 'execute-api',
}

export interface SignedConnectionSource {
  getSignedConnection(service?: `${SignatureV4Service}`): Promise<SignedConnection>;
}
