/**
 * Takes a hex string and converts it to a tuple of RGB channel values on a scale of 0-1
 */
export const hexStringToRGBArray = (hex: string): [number, number, number] => {
  // Remove the hash
  const hexValue = hex.replace('#', '');

  const r = parseInt(hexValue.substring(0, 2), 16) / 255;
  const g = parseInt(hexValue.substring(2, 4), 16) / 255;
  const b = parseInt(hexValue.substring(4, 6), 16) / 255;

  return [r, g, b];
};

/**
 * Takes a tuple of RGB channel values on a scale of 0-1 and converts it to a hex string
 */
export const rgbArrayToHexString = (rgbColor: [number, number, number]): string => {
  return `#${rgbColor
    .map((channel) =>
      Math.floor(channel * 255)
        .toString(16)
        .padStart(2, '0'),
    )
    .join('')}`;
};

export const isRGBColorTuple = (
  maybeRGBArray: unknown[],
): maybeRGBArray is [number, number, number] => {
  return (
    maybeRGBArray.length >= 3 &&
    typeof maybeRGBArray[0] === 'number' &&
    typeof maybeRGBArray[1] === 'number' &&
    typeof maybeRGBArray[2] === 'number'
  );
};

/**
 * Gets the interpolated gradient color stop at a given position between two color stops
 *
 * @param startColorStop - Array of [position, red, green, blue] for the start color stop
 * @param endColorstop - Array of [position, red, green, blue] for the end color stop
 * @param interpolatedPosition - Position of the interpolated color stop between the start and end color stops
 */
export const interpolateGradientColorStops = (
  startColorStop: [number, number, number, number],
  endColorstop: [number, number, number, number],
  interpolatedPosition: number,
): [number, number, number, number] => {
  const [startPosition, startRed, startGreen, startBlue] = startColorStop;
  const [endPosition, endRed, endGreen, endBlue] = endColorstop;

  const relativePosition = (interpolatedPosition - startPosition) / (endPosition - startPosition);

  return [
    interpolatedPosition,
    startRed + (endRed - startRed) * relativePosition,
    startGreen + (endGreen - startGreen) * relativePosition,
    startBlue + (endBlue - startBlue) * relativePosition,
  ];
};

/**
 * Takes a color either in hex string or rgb tuple format and returns it in the desired format
 */
export function getFormattedColor<
  // We have to do some weird generic/casting stuff here because TypeScript isn't smart enough to correctly
  // infer some things in this function which it seems like it should be able to.
  TColorFormat extends 'hex' | 'rgb',
  TReturnType = TColorFormat extends 'hex' ? string : [number, number, number],
>(color: [number, number, number] | string, format: TColorFormat): TReturnType {
  if (format === 'hex') {
    return (
      typeof color === 'string' ? color : rgbArrayToHexString(color as [number, number, number])
    ) as TReturnType;
  }

  return (typeof color === 'string' ? hexStringToRGBArray(color as string) : color) as TReturnType;
}
