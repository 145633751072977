import AWS from 'aws-sdk';

class CognitoS3Upload {
  credentials: AWS.CognitoIdentityCredentials;

  constructor(identityPoolId: string, identityPoolRegion: string) {
    this.credentials = new AWS.CognitoIdentityCredentials(
      {
        IdentityPoolId: identityPoolId,
      },
      { region: identityPoolRegion },
    );
  }

  async authenticate() {
    await this.credentials.refreshPromise();
  }

  async upload(
    {
      content,
      region,
      bucket,
      key,
    }: {
      content: File | Buffer;
      region: string;
      bucket: string;
      key: string;
    },
    {
      onProgress,
    }: {
      onProgress?: (progress: AWS.S3.ManagedUpload.Progress) => void;
    } = {},
  ) {
    if (!(content && region && bucket && key)) {
      throw new Error('content, region, bucket, and key options are required.');
    }

    const s3 = new AWS.S3({
      region: region,
      credentials: this.credentials,
      params: {
        Bucket: bucket,
      },
    });

    const uploading = new AWS.S3.ManagedUpload({
      params: {
        Key: key,
        Body: content,
        Bucket: bucket,
      },
      service: s3,
    });

    if (onProgress) {
      uploading.on('httpUploadProgress', onProgress);
    }

    return await uploading.promise();
  }
}

export default CognitoS3Upload;
