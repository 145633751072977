export abstract class BaseAsset {
  abstract getID(): string;
  abstract getURL(...args: unknown[]): Promise<URL>;
  /**
   * Returns a key identifying the asset's location.
   */
  abstract getKey(): string;

  toString() {
    return `${this.constructor.name}<${this.getID()}>`;
  }
}
