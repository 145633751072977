const waitFor = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Takes an async callback and throttles it so that it can only run after the previous call has resolved and at least `minThrottleTime` milliseconds have passed since the last call.
 */
export const asyncThrottle = <TCallback extends (...args: any[]) => Promise<any>>(
  throttledAsyncCallback: TCallback,
  minThrottleTime: number = 0,
) => {
  let activeCallPromise: Promise<ReturnType<TCallback>> | null = null;
  let timeoutPromise: Promise<void> | null = null;
  let pendingNextCallPromise: Promise<ReturnType<TCallback>> | null = null;

  let nextCallArgs: Parameters<TCallback> | null = null;

  const runNextCall = async (): Promise<ReturnType<TCallback>> => {
    if (!nextCallArgs) {
      throw new Error('nextCallArgs unexpectedly null');
    }

    if (minThrottleTime > 0) {
      // Create a promise which will resolve after the throttle time has passed
      // so we can ensure we wait for the appropriate amount of time before the next call can run
      timeoutPromise = waitFor(minThrottleTime).then(() => {
        timeoutPromise = null;
      });
    }

    return throttledAsyncCallback(...nextCallArgs);
  };

  return (...args: Parameters<TCallback>): Promise<ReturnType<TCallback>> => {
    nextCallArgs = args;

    if (pendingNextCallPromise) {
      // If we already have a pending call waiting for the current active call/timeout to resolve,
      // just return that promise. We updated nextCallArgs above so the most recent call args
      // will be used when the pending call runs.
      return pendingNextCallPromise;
    }

    if (activeCallPromise || timeoutPromise) {
      // If there is an active call in progress or the timeout has not yet passed, queue up the next call
      // once both of those promises resolve
      pendingNextCallPromise = Promise.all([activeCallPromise, timeoutPromise]).then(() => {
        pendingNextCallPromise = null;
        // Run the next call and set it as our new active promise
        activeCallPromise = runNextCall();
        return activeCallPromise;
      });
      return pendingNextCallPromise;
    }

    // We don't have an active call or a timeout, so we can run the call immediately
    activeCallPromise = runNextCall().then((result) => {
      activeCallPromise = null;
      return result;
    });
    return activeCallPromise;
  };
};
