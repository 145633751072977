import { BaseReference } from './BaseReference';

import type { latest as LatestVDETypes } from '@libs/waymark-video/video-descriptor-types';

export class TextReference extends BaseReference<LatestVDETypes.TextOverrideProperties> {
  getText() {
    return this.rawReferenceData.__activeValue;
  }

  setText(text: string) {
    this.updateRawReferenceData({ __activeValue: text });
    this.dispatchEvent('change:value', text);
  }

  toString(): string {
    return `TextReference<${this.getLabel()}:${this.getText()}>`;
  }
}
