import { latest as LatestVDETypes } from '@libs/waymark-video/video-descriptor-types';
import { getFormattedColor } from '../utils/colors';
import { BaseLayer } from './BaseLayer';

export class SolidLayer extends BaseLayer<
  LatestVDETypes.SolidLayer,
  {
    'change:fillColor': undefined;
    'change:visibility': boolean;
    'change:fillEffectColor': undefined;
    removed: undefined;
  }
> {
  getFillColor<TColorFormat extends 'hex' | 'rgb' = 'hex'>(format = 'hex' as TColorFormat) {
    const fillColorHex = this.rawLayerData.sc;
    return getFormattedColor(fillColorHex, format);
  }

  private updateFillColor(newColorHex: string) {
    // Note: you may be thinking, doesn't "sc" usually mean "stroke color" in the project manifest?
    // You're right! But in this case, "sc" means "solid color" for solid layers.
    // Also, we usually store colors as [r,g,b] number tuples, but in this case,
    // we're storing them as hex strings. Don't worry about it. It's fine.
    this.updateRawLayerData({
      sc: newColorHex,
    });
    this.dispatchEvent('change:fillColor', undefined);
  }

  /**
   * Updates the fill color of the solid layer, or indirectly updates it via its fill color reference.
   * @param newColor - The new fill color to apply, as a hex code string.
   * @param shouldOverrideReference - If true, the fill color reference will be set to be ignored and the new color will be applied directly.
   */
  setFillColor(newColor: string, shouldOverrideReference = false): void {
    if (shouldOverrideReference) {
      this.updateIgnoredReferences({
        fillColor: true,
      });
    }

    if (this.fillColorReference && !this.getIsReferenceIgnored('fillColor')) {
      this.fillColorReference.setColor(newColor);
    } else {
      this.updateFillColor(newColor);
    }
  }

  onFillColorReferenceChange(): void {
    if (!this.fillColorReference || this.getIsReferenceIgnored('fillColor')) {
      return;
    }

    const newColorHex = this.fillColorReference.getColor();
    this.updateFillColor(newColorHex);
  }
}
