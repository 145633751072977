interface EnvironmentConfig {
  bucket: string;
  region: string;
  websocketEndpoint: string;
  bucketRoot: string;
  outputHost: string;
}

export const environments = {
  testRunner: {
    bucket: 'fake-bucket',
    region: 'us-east-fake',
    websocketEndpoint: 'wss://fake-host.com/Prod',
    bucketRoot: 'videos',
    outputHost: 'http://static.fake-host.com',
  },
  development: {
    bucket: 'wm-vps-development',
    region: 'us-east-2',
    websocketEndpoint: 'wss://17ces040z5.execute-api.us-east-2.amazonaws.com/Prod',
    bucketRoot: 'videos',
    outputHost: 'https://d2rurqg7ni0ccj.cloudfront.net',
  },
} as const satisfies Record<string, EnvironmentConfig>;

export type VPSEnvironmentKey = keyof typeof environments;
