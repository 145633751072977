import { Layer as PMLayer } from '@libs/waymark-video/video-descriptor-types';
import { BaseLayer } from './BaseLayer';

/**
 * A layer that references a sub-composition.
 */
export class UnsupportedLayer extends BaseLayer<PMLayer> {
  toString(): string {
    return `UnsupportedLayer-${this.rawLayerData.ty}<${this.getUUID()}>`;
  }
}
