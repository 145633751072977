import { BaseLayer } from './BaseLayer';
import { getFormattedColor, hexStringToRGBArray, rgbArrayToHexString } from '../utils/colors';
import { FontAsset } from '../assets';

import { latest as LatestVDETypes } from '@libs/waymark-video/video-descriptor-types';

export class TextLayer extends BaseLayer<
  LatestVDETypes.TextLayer,
  {
    'change:textContent': undefined;
    'change:fillColor': undefined;
    'change:strokeColor': undefined;
    'change:font': undefined;
    'change:fontSize': undefined;
    'change:lineHeight': undefined;
    'change:resizingStrategy': undefined;
    'change:visibility': boolean;
    'change:fillEffectColor': undefined;
    removed: undefined;
  }
> {
  /** ---- TEXT CONTENT ---- */

  /**
   * Get the current text content of this layer.
   */
  getText() {
    return this.rawLayerData.t.d.k[0].s.t;
  }

  /**
   * @emits contentChange
   * Performs the actual text content update. Necessary because setText and onTextContentReferenceChange
   * need to have slightly different behavior.
   */
  private updateTextContent(text: string) {
    const updatedKeyframes = structuredClone(this.rawLayerData.t.d.k);
    // Change text content for all keyframes
    for (const keyframe of updatedKeyframes) {
      keyframe.s.t = text;
    }

    this.updateRawLayerData({
      t: {
        ...this.rawLayerData.t,
        d: {
          ...this.rawLayerData.t.d,
          k: updatedKeyframes,
        },
      },
    });
    this.dispatchEvent('change:textContent', undefined);
  }

  /**
   * Updates the text content of this layer.
   * If shouldUpdateReference is true, the reference will be updated and the change will be
   * propagated to all layers using the same reference.
   */
  setText(text: string, shouldOverrideReference = false) {
    if (!this.getCanEditFieldType('content')) {
      throw new Error(`${this.toString()}: Text layer content is not configured to be editable.`);
    }

    if (shouldOverrideReference) {
      this.updateIgnoredReferences({
        textContent: true,
      });
    }

    if (this.textContentReference && !this.getIsReferenceIgnored('textContent')) {
      // Update the reference instead of directly just updating this layer;
      // that will trigger the reference's change:value event, which will then come back around
      // to update this layer with any other layers pointing to the same reference
      this.textContentReference.setText(text);
    } else {
      // Update the text content directly if we're not updating a reference
      this.updateTextContent(text);
    }
  }

  /**
   * Event listener for when the text content reference changes.
   * This is an arrow function to avoid weirdness with `this` when this gets passed to `addEventListener`.
   */
  onTextContentReferenceChange(): void {
    if (!this.textContentReference || this.getIsReferenceIgnored('textContent')) {
      return;
    }

    const newTextContent = this.textContentReference.getText();
    this.updateTextContent(newTextContent);
  }

  /** ---- FILL COLOR ---- */

  /**
   * Gets the current fill color of this text layer.
   *
   * @param format - The format to return the color in. 'hex' returns a hex code string, 'rgb' returns a tuple of 0-1 RGB channel values.
   */
  getFillColor<TColorFormat extends 'hex' | 'rgb' = 'hex'>(format = 'hex' as TColorFormat) {
    const rgbFillColor = this.rawLayerData.t.d.k[0].s.fc;
    return rgbFillColor
      ? getFormattedColor(rgbFillColor as [number, number, number], format)
      : null;
  }

  /**
   * @emits contentChange
   * Performs the actual text content update; used by setFillColor and onFillColorReferenceChange listener
   */
  private updateFillColor(newColor: [number, number, number]) {
    const updatedKeyframes = structuredClone(this.rawLayerData.t.d.k);
    // Change fill color for all keyframes
    for (const keyframe of updatedKeyframes) {
      keyframe.s.fc = newColor;
    }

    this.updateRawLayerData({
      t: {
        ...this.rawLayerData.t,
        d: {
          ...this.rawLayerData.t.d,
          k: updatedKeyframes,
        },
      },
    });

    this.dispatchEvent('change:fillColor', undefined);
  }

  /**
   * Updates the fill color of this layer.
   * If shouldOverrideReference is true, the layer will be updated to ignore its fill color reference
   * and the fill color will be set directly.
   */
  setFillColor(newColor: string | [number, number, number], shouldOverrideReference = false) {
    if (!this.getCanEditFieldType('fillColor')) {
      throw new Error(
        `${this.toString()}: Text layer fill color is not configured to be editable.`,
      );
    }

    if (shouldOverrideReference) {
      this.updateIgnoredReferences({
        fillColor: true,
      });
    }

    if (this.fillColorReference && !this.getIsReferenceIgnored('fillColor')) {
      // Ensure the color is a hex string because that's how we store color reference values
      this.fillColorReference.setColor(
        Array.isArray(newColor) ? rgbArrayToHexString(newColor) : newColor,
      );
    } else {
      // Ensure the color is an RGB array
      this.updateFillColor(Array.isArray(newColor) ? newColor : hexStringToRGBArray(newColor));
    }
  }

  /**
   * Listener updates the fill color when the fill color reference changes, unless the layer is ignoring the reference.
   * This is an arrow function to avoid weirdness with `this` when this gets passed to `addEventListener`.
   */
  onFillColorReferenceChange(): void {
    if (!this.fillColorReference || this.getIsReferenceIgnored('fillColor')) {
      return;
    }

    const newFillColorHex = this.fillColorReference.getColor();
    this.updateFillColor(hexStringToRGBArray(newFillColorHex));
  }

  /** ---- STROKE COLOR ---- */

  /**
   * Gets the current stroke color of this text layer.
   *
   * @param format - The format to return the color in. 'hex' returns a hex code string, 'rgb' returns a tuple of 0-1 RGB channel values.
   */
  getStrokeColor<TColorFormat extends 'hex' | 'rgb' = 'hex'>(format = 'hex' as TColorFormat) {
    const rgbFillColor = this.rawLayerData.t.d.k[0].s.sc;
    return rgbFillColor
      ? getFormattedColor(rgbFillColor as [number, number, number], format)
      : null;
  }

  /**
   * @emits contentChange
   * Performs the actual stroke color update; used by setStrokeColor and onStrokeColorReferenceChange listener
   */
  private updateStrokeColor(newColor: [number, number, number]) {
    const updatedKeyframes = structuredClone(this.rawLayerData.t.d.k);
    // Change stroke color for all keyframes
    for (const keyframe of updatedKeyframes) {
      keyframe.s.sc = newColor;
    }

    this.updateRawLayerData({
      t: {
        ...this.rawLayerData.t,
        d: {
          ...this.rawLayerData.t.d,
          k: updatedKeyframes,
        },
      },
    });

    this.dispatchEvent('change:strokeColor', undefined);
  }

  /**
   * Updates the stroke color of this layer, or the layer's color reference if it has one.
   */
  setStrokeColor(newColor: string | [number, number, number], shouldOverrideReference = false) {
    if (!this.getCanEditFieldType('strokeColor')) {
      throw new Error(
        `${this.toString()}: Text layer stroke color is not configured to be editable.`,
      );
    }

    if (shouldOverrideReference) {
      this.updateIgnoredReferences({
        strokeColor: true,
      });
    }

    if (this.strokeColorReference && !this.getIsReferenceIgnored('strokeColor')) {
      this.strokeColorReference.setColor(
        Array.isArray(newColor) ? rgbArrayToHexString(newColor) : newColor,
      );
    } else {
      this.updateStrokeColor(Array.isArray(newColor) ? newColor : hexStringToRGBArray(newColor));
    }
  }

  /**
   * Listener updates the stroke color when the stroke color reference changes, unless the layer is ignoring the reference.
   * This is an arrow function to avoid weirdness with `this` when this gets passed to `addEventListener`.
   */
  onStrokeColorReferenceChange(): void {
    if (!this.strokeColorReference || this.getIsReferenceIgnored('strokeColor')) {
      return;
    }

    const newStrokeColorHex = this.strokeColorReference.getColor();
    this.updateStrokeColor(hexStringToRGBArray(newStrokeColorHex));
  }

  /** ---- FONT ---- */

  /**
   * Gets the font asset which this layer is currently using.
   */
  getFontAsset(): FontAsset {
    const fontAssetID = this.rawLayerData.refId;
    if (!fontAssetID) {
      throw new Error(`${this.toString()}: Cannot get font asset for text layer missing refId`);
    }

    const fontAsset = this.videoDescriptor.assets.get(fontAssetID);
    if (!(fontAsset instanceof FontAsset)) {
      throw new Error(`${this.toString()}: Font asset with ID ${fontAssetID} not found`);
    }

    return fontAsset;
  }

  /**
   * Updates the font asset which this layer is using.
   * @emits contentChange
   */
  private updateFontAsset(fontAsset: FontAsset) {
    const fontAssetID = fontAsset.getID();

    const updatedKeyframes = structuredClone(this.rawLayerData.t.d.k);
    // Change font ID for all keyframes
    for (const keyframe of updatedKeyframes) {
      keyframe.s.f = fontAssetID;
    }

    this.updateRawLayerData({
      refId: fontAssetID,
      t: {
        ...this.rawLayerData.t,
        d: {
          ...this.rawLayerData.t.d,
          k: updatedKeyframes,
        },
      },
    });

    this.dispatchEvent('change:font', undefined);
  }

  /**
   * Listener updates the font asset when the font reference changes, unless the layer is ignoring the reference.
   * This is an arrow function to avoid weirdness with `this` when this gets passed to `addEventListener`.
   */
  onFontReferenceChange(): void {
    if (!this.fontReference || this.getIsReferenceIgnored('font')) {
      return;
    }

    const newFontAsset = this.fontReference.getFontAsset();
    this.updateFontAsset(newFontAsset);
  }

  /**
   * Get the font size of the text layer.
   */
  getFontSize(): number {
    return this.rawLayerData.t.d.k[0].s.s;
  }

  /**
   * Get the line height of the text layer.
   */
  getLineHeight(): number {
    return this.rawLayerData.t.d.k[0].s.lh;
  }

  /**
   * Get the suggested line height for a given font size, based on the layer's
   * current line height and font size.
   */
  getSuggestedLineHeightForFontSize(fontSize: number): number {
    const currentLineHeight = this.getLineHeight();
    const currentFontSize = this.getFontSize();
    return Math.max(fontSize * (currentLineHeight / currentFontSize), 1);
  }

  /**
   * Updates the font size of the text layer.
   * @param newFontSize - The new font size to set.
   * @param shouldAutoUpdateLineHeight - Whether we should also update the line height based on the new font size.
   */
  setFontSize(newFontSize: number, shouldAutoUpdateLineHeight = true): void {
    if (
      this.rawLayerData.meta.textOptions?.resizingStrategy ===
      LatestVDETypes.TextResizingStrategy.StepAndBreakWords
    ) {
      throw new Error(
        `${this.toString()}: Cannot set font size on a text layer with the StepAndBreakWords resizing strategy, as its font size dynamically changes based on available space.`,
      );
    }

    const newLineHeight = shouldAutoUpdateLineHeight
      ? this.getSuggestedLineHeightForFontSize(newFontSize)
      : null;

    const updatedTextPropertyKeyframes = structuredClone(this.rawLayerData.t.d.k);
    for (const keyframe of updatedTextPropertyKeyframes) {
      // Ensure we can't set a negative font size
      keyframe.s.s = Math.max(newFontSize, 1);
      if (newLineHeight !== null) {
        keyframe.s.lh = newLineHeight;
      }
    }

    this.updateRawLayerData({
      t: {
        ...this.rawLayerData.t,
        d: {
          ...this.rawLayerData.t.d,
          k: updatedTextPropertyKeyframes,
        },
      },
    });

    this.dispatchEvent('change:fontSize', undefined);
  }

  /**
   * Updates the line height of the text layer.
   */
  setLineHeight(newLineHeight: number) {
    const textPropertyKeyframes = this.rawLayerData.t.d.k;
    for (const keyframe of textPropertyKeyframes) {
      // Ensure we can't set a negative line height
      keyframe.s.lh = Math.max(newLineHeight, 0);
    }

    this.dispatchEvent('change:lineHeight', undefined);
  }

  /**
   * Get the current resizing strategy for the text layer.
   */
  getResizingStrategy(): `${LatestVDETypes.TextResizingStrategy}` {
    const resizingStrategy = this.rawLayerData.meta.textOptions?.resizingStrategy;

    if (!resizingStrategy) {
      return LatestVDETypes.TextResizingStrategy.Default;
    }

    // resizingStrategy may be a tuple where the first item is the TextResizingStrategy and the
    // second item is a TextResizingStrategyStepDirection
    if (Array.isArray(resizingStrategy)) {
      return resizingStrategy[0];
    }

    return resizingStrategy;
  }

  /**
   * Set the resizing strategy for the text layer.
   */
  setResizingStrategy(resizingStrategy: `${LatestVDETypes.TextResizingStrategy}`) {
    const updatedTextOptions = structuredClone(this.rawLayerData.meta.textOptions ?? {});

    if (Array.isArray(updatedTextOptions.resizingStrategy)) {
      // resizingStrategy may be a tuple where the first item is the TextResizingStrategy and the
      // second item is a TextResizingStrategyStepDirection
      updatedTextOptions.resizingStrategy[0] = resizingStrategy;
    } else {
      updatedTextOptions.resizingStrategy = resizingStrategy;
    }

    this.updateRawLayerData({
      meta: {
        ...this.rawLayerData.meta,
        textOptions: updatedTextOptions,
      },
    });

    this.dispatchEvent('change:resizingStrategy', undefined);
  }
}
