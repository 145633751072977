import validateSemver from 'semver/functions/valid';
import compareSemver from 'semver/functions/compare';
import {
  AnyVideoDescriptor,
  LatestVideoDescriptor,
  LATEST_VIDEO_DESCRIPTOR_VERSION,
  Migration,
} from './types';
import allMigrations from './migrations';

/**
 * Type guard to check if a string is a valid semantic version.
 *
 * @param   {string}   version  The version string to check
 *
 * @return  {boolean}           True if the version is a valid semantic version
 */
export function isValidSemver(version: string) {
  return validateSemver(version) !== null;
}

/**
 * Sort the migrations by ascending target version and by ascending name
 *
 * @param   {Migration[]}  migrations  Migrations to sort
 *
 * @return  {Migration[]}              Sorted migrations
 */
export const sortMigrations = (migrations: Migration<any, any>[]) => {
  // Slice before sorting to avoid mutating the original (it probably doesn't matter, but for craft)
  return migrations.slice().sort((a, b) => {
    const versionComparison = compareSemver(a.targetVersion, b.targetVersion);
    if (versionComparison === 0) {
      return a.name.localeCompare(b.name);
    }
    return versionComparison;
  });
};

/**
 * Returns the version of a video descriptor. Needed because the legacy video descriptor does not have a version field.
 */

/**
 * Returns the version of a video descriptor. Needed because the legacy video descriptor does not have a version field.
 *
 * @param   {AnyVideoDescriptor}  videoDescriptor  The video descriptor to get the version of.
 *
 * @return  {SemanticVersion}                      The version string.
 */
const getVersionForVideoDescriptor = (videoDescriptor: AnyVideoDescriptor) => {
  const version = videoDescriptor.version ?? '0.9.0';
  if (!isValidSemver(version as string)) {
    throw new Error(`Invalid version string: ${version}`);
  }
  return version;
};

/**
 * Sort and run a given set of migrations on a video descriptor.
 *
 * @param   {AnyVideoDescriptor}  inputVideoDescriptor  The video descriptor to migrate
 * @param   {Migration[]}         migrations            The migrations to run
 * @return  {AnyVideoDescriptor}                        The migrated video descriptor
 */
export const runMigrations = async (
  inputVideoDescriptor: AnyVideoDescriptor,
  migrations: Migration<any, any>[],
): Promise<AnyVideoDescriptor> => {
  // Validate
  // (a) Ensure that all migrations have unique target versions
  // (b) Ensure that all migrations have target versions that are greater than the input version
  const targetVersions = new Set<string>();
  for (const migration of migrations) {
    if (targetVersions.has(migration.targetVersion)) {
      throw new Error(
        `Duplicate target version ${migration.targetVersion} (${migration.name}) found in migrations. Each migration must have a unique target version.`,
      );
    }
    targetVersions.add(migration.targetVersion);

    if (compareSemver(migration.targetVersion, migration.inputVersion) <= 0) {
      throw new Error(
        `Migration ${migration.name} has a target version ${migration.targetVersion} which is not greater than the input version ${inputVideoDescriptor}.`,
      );
    }
  }

  // Sort the migrations by target version and name
  const sortedMigrations = sortMigrations(migrations);

  // Apply each migration in order
  let newVideoDescriptor: AnyVideoDescriptor = inputVideoDescriptor;
  for (const migration of sortedMigrations) {
    const inputVersion = getVersionForVideoDescriptor(newVideoDescriptor);
    // We'll skip the migration if the input version is already at or beyond the target version
    if (compareSemver(inputVersion, migration.targetVersion) < 0) {
      // This is a runtime check for version compatibility due to the fact this is somewhat hard to tightly type
      if (migration.inputVersion === inputVersion) {
        newVideoDescriptor = await migration.implementation(
          newVideoDescriptor as Parameters<typeof migration.implementation>[0],
        );
      } else {
        // We should never get here, but if we do, it's a bug in the migration engine.
        throw new Error(
          `Current VideoDescriptor version ${inputVersion} does not match the input version ${migration.inputVersion} of migration ${migration.name}. There is a bug in the VideoDescriptor migration engine.`,
        );
      }
    }
  }

  return newVideoDescriptor;
};

/**
 * Run all migrations (listed in ./migrations.ts) on a video descriptor ending up with latest-version video descriptor.
 *
 * @param   {AnyVideoDescriptor}  inputVideoDescriptor  The video descriptor to migrate
 *
 * @return  {LatestVideoDescriptor}  The migrated video descriptor
 */
export const runAllMigrations = async (
  inputVideoDescriptor: AnyVideoDescriptor,
): Promise<LatestVideoDescriptor> => {
  const newVideoDescriptor = await runMigrations(inputVideoDescriptor, allMigrations);
  const finalVersion = getVersionForVideoDescriptor(newVideoDescriptor);

  if (finalVersion !== LATEST_VIDEO_DESCRIPTOR_VERSION) {
    throw new Error(
      `Final version ${finalVersion} does not match the expected version ${LATEST_VIDEO_DESCRIPTOR_VERSION}. Something is wrong with the specified VideoDescriptor migrations or there is a bug in the VideoDescriptor migration engine.`,
    );
  }

  return newVideoDescriptor as LatestVideoDescriptor;
};
