import type { VideoDescriptor } from '..';
import type { VideoDescriptorLayer } from '../layers';

import type { latest as LatestVDETypes } from '@libs/waymark-video/video-descriptor-types';
import { CustomEventTarget } from '@libs/util-ts';

export interface SwitchOption {
  id: string;
  name: string;
  layers: VideoDescriptorLayer[];
}

export class Switch extends CustomEventTarget<{
  'change:selection': void;
}> {
  id: string;
  name: string;

  selectedOption: SwitchOption;
  options: SwitchOption[];

  frameNumber: number | null;

  videoDescriptor: VideoDescriptor;

  rawData: LatestVDETypes.SceneSwitch;

  constructor(sceneSwitch: LatestVDETypes.SceneSwitch, videoDescriptor: VideoDescriptor) {
    super();

    this.videoDescriptor = videoDescriptor;
    this.rawData = sceneSwitch;

    this.id = sceneSwitch.id;
    this.name = sceneSwitch.name;
    this.frameNumber = sceneSwitch.frameNumber;

    const switchGroupIDs = new Set(sceneSwitch.groups);
    const templateManifest = videoDescriptor.rawData.templateManifest;

    this.options = [];

    const initialSelectedOptionID = sceneSwitch.__activeValue ?? sceneSwitch.defaultSelection;
    let initialSelectedOption: SwitchOption | null = null;

    for (const sceneGroup of templateManifest.sceneGroups ?? []) {
      if (!switchGroupIDs.has(sceneGroup.id)) {
        continue;
      }

      const option: SwitchOption = {
        id: sceneGroup.id,
        name: sceneGroup.name,
        layers: sceneGroup.layers.map((layerID) => {
          const layer = videoDescriptor.findLayerByUUID(layerID);
          if (!layer) {
            throw new Error(`Layer with ID ${layerID} not found in video descriptor`);
          }

          // Normalize all layers to be hidden; we'll go back and make the initial selected option's layers
          // visible after the fact.
          // It's mainly necessary to do things this way because scene groups can technically share layers
          // with one another, so we have to make sure we always make the correct layers visible.
          layer.setIsHidden(true);

          return layer;
        }),
      };

      this.options.push(option);
      if (sceneGroup.id === initialSelectedOptionID) {
        initialSelectedOption = option;
      }
    }

    // Default to the first option if we didn't find a default selection
    if (!initialSelectedOption) {
      throw new Error(
        `Switch ${this.id} failed to find an initial selected option for __activeValue ${sceneSwitch.__activeValue} or defaultSelection ${sceneSwitch.defaultSelection}. This template may be misconfigured.`,
      );
    }

    this.selectedOption = initialSelectedOption;
    this.rawData.__activeValue = initialSelectedOption.id;

    // NOTE: Is this the right place to do this?
    // Make all layers for the initial selected option visible
    this.selectedOption.layers.forEach((layer) => {
      layer.setIsHidden(false);
    });
  }

  getUUID() {
    return this.id;
  }

  getSelectedOption() {
    return this.selectedOption;
  }

  updateRawData(updatedData: Partial<LatestVDETypes.SceneSwitch>) {
    Object.assign(this.rawData, updatedData);
    this.videoDescriptor.dispatchEvent('change', undefined);
  }

  /**
   * Takes the ID for a switch option and sets it as the selected option.
   */
  setSelectedOption(optionID: string) {
    // Hide all layers for the previously selected option
    const previousSelectedOption = this.selectedOption;
    if (previousSelectedOption.id === optionID) {
      // Bail out if the option is already selected
      return;
    }
    previousSelectedOption.layers.forEach((layer) => {
      layer.setIsHidden(true);
    });

    const newOption = this.options.find((option) => option.id === optionID);
    if (!newOption) {
      throw new Error(`Switch option with ID ${optionID} not found`);
    }

    // Show all layers for the new option
    this.selectedOption = newOption;
    this.updateRawData({
      __activeValue: newOption.id,
    });
    this.selectedOption.layers.forEach((layer) => {
      layer.setIsHidden(false);
    });

    this.dispatchEvent('change:selection', undefined);
  }

  getIdealDisplayFrame() {
    if (this.frameNumber !== null) {
      return this.frameNumber;
    }

    // If we didn't get an ideal frame number from the template manifest,
    // use the ideal display frame of the first layer in the selected option
    return this.selectedOption.layers[0].getIdealDisplayFrame();
  }

  /**
   * Get the ideal display time for this switch in seconds.
   */
  getIdealDisplayTime() {
    return this.getIdealDisplayFrame() / this.videoDescriptor.getFramerate();
  }

  getSelectedContentEditableLayers() {
    return this.selectedOption.layers.filter((layer) => layer.getCanEditFieldType('content'));
  }

  getAllContentEditableLayers() {
    const editableLayers = new Array<VideoDescriptorLayer>();
    for (const option of this.options) {
      for (const layer of option.layers) {
        if (layer.getCanEditFieldType('content')) {
          editableLayers.push(layer);
        }
      }
    }

    return editableLayers.sort((layerA, layerB) => {
      const displayTimeA = layerA.getIdealDisplayTime();
      const displayTimeB = layerB.getIdealDisplayTime();
      return displayTimeA - displayTimeB;
    });
  }
}
